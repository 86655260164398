import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putPlnReloadTac,
  putPlnReloadDetail,
  putPlnReloadResult,
  putPlnReloadReceipt,
  putPlnReloadConfirmation,
} from 'middleware/actions/purchase';
import { formatKeys } from 'utils/formatter';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';

const { DETAIL, CONFIRMATION, REQUEST_TAC, RESULT, RECEIPT } = actions.GET.PLN_RELOAD;

const getClientIP = () => remoteIpInstance.get();

const getDetail = ({ body }) => Instance.post(endpoint.plnReload.getDetail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.plnReload.getConfirm, body);
const requestTAC = ({ body }) => Instance.post(endpoint.plnReload.getRequestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.plnReload.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.plnReload.getReceipt, body);

const getLocale = state => state.LanguageReducer.locale;

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);

    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });

    yield put(putPlnReloadDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getConfirmation, { body });

    yield put(putPlnReloadConfirmation(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });

    yield put(putPlnReloadTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });

    yield put(putPlnReloadResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };

    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });

    yield put(putPlnReloadReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PurchasePlnReload() {
  yield all([
    takeLatest(DETAIL, getDetailSaga),
    takeLatest(CONFIRMATION, getConfirmationSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(RESULT, getResultSaga),
    takeLatest(RECEIPT, getReceiptSaga),
  ]);
}
