import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Slide } from '@material-ui/core';

import Icon from 'components/common/Icon';
import IconButton from 'components/common/buttons/IconButton';

import './style.scss';
import { REPLY_INBOX } from 'settings/constants/inboxs';
import { RESET_CREDENTIAL } from 'settings/constants/login';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

// Specific modal for forms with pre-defined styling for header and action footer
const FormModal = ({
  children,
  className,
  classNameDesc,
  id,
  footer,
  hasCloseButton,
  hasContentHeader,
  onClose,
  isOpen,
  title,
  fullWidth,
  fullScreen,
  maxWidth,
  contentTitle,
  contentSubtitle,
  description,
  hasTextEditor,
  composeType,
  typeModal,
  formChangePasswordTitle,
  formChangePhoneNumber,
  resetCredential,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  const heightDialog = composeType === REPLY_INBOX ? '250px' : '300px';

  const paddingLeftSubHeader = () => {
    let padding = '32px';

    if (formChangePasswordTitle) {
      padding = '22px';
    }

    if (formChangePhoneNumber) {
      padding = '32px';
    }

    return padding;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={id}
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      className={`modal modal-form ${className}`}
    >
      <DialogTitle id={id} disableTypography style={{ marginBottom: resetCredential ? '15px' : '0px' }}>
        <Typography variant="h6" id="title-modal">
          {title}
        </Typography>
        {hasCloseButton && (
          <IconButton onClick={onClose} className="close-btn">
            <Icon isAssetIcon={false} type="close" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent style={{ height: `${hasTextEditor ? heightDialog : ''}` }}>
        {hasContentHeader && (
          <div
            className={`${formChangePasswordTitle || formChangePhoneNumber ? 'tl pt1 pb3 pr5' : 'tc pa4'} bg-light-gray`}
            style={{
              paddingLeft: paddingLeftSubHeader(),
              marginBottom: formChangePasswordTitle || resetCredential || formChangePhoneNumber ? '15px' : '0px',
            }}
          >
            {typeModal === RESET_CREDENTIAL ? (
              <h1 className="lh-copy ma0 content--title">
                <strong style={{ fontSize: '34px' }}>{contentTitle}</strong>
              </h1>
            ) : (
              <h1 className="lh-copy ma0 content--title">{contentTitle}</h1>
            )}
            <h1 className="ma0 content--subtitle">{contentSubtitle}</h1>
            <p className={`${'mb0 content--description'} ${classNameDesc}`}>{description}</p>
          </div>
        )}
        {children}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
};

FormModal.defaultProps = {
  id: '',
  footer: '',
  className: '',
  classNameDesc: '',
  contentTitle: '',
  contentSubtitle: '',
  description: '',
  children: null,
  fullWidth: false,
  fullScreen: false,
  hasCloseButton: true,
  hasContentHeader: true,
  onClose: null,
  maxWidth: 'sm',
  hasTextEditor: false,
  composeType: '',
  typeModal: '',
  formChangePasswordTitle: false,
  resetCredential: false,
  formChangePhoneNumber: false,
};

FormModal.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  classNameDesc: PropTypes.string,
  children: PropTypes.node,
  contentTitle: PropTypes.string,
  contentSubtitle: PropTypes.string,
  description: PropTypes.node,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  hasContentHeader: PropTypes.bool,
  onClose: PropTypes.func,
  footer: PropTypes.node,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', false]),
  hasTextEditor: PropTypes.bool,
  composeType: PropTypes.string,
  typeModal: PropTypes.string,
  formChangePasswordTitle: PropTypes.bool,
  formChangePhoneNumber: PropTypes.bool,
  resetCredential: PropTypes.bool,
};

export default FormModal;
