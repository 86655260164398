export const resetCredential = {
  modal: {
    title: 'Reset Credential',
    header: {
      title: 'We Will Reset Your Credentials',
      description: 'Please make sure you are at the nearest Maybank Branch Office to do Reset Credential.',
    },
    container: {
      user: 'User Id',
      code: 'Input Code',
      accountNumber: 'Account Number',
      otpNumber: 'OTP Number',
      messageOtp: 'Your OTP will be valid for 10 minutes',
    },
    placeholder: {
      numberPlaceholder: 'Enter Account Number',
      otpPlaceholder: 'Enter the OTP Number here',
    },
    passwordForm: {
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      placeholder: {
        newPassword: 'Enter New Password',
        confirmNewPassword: 'Confirm New Password',
      },
    },
  },
  errorMessage: {
    invalidUserId: 'Invalid User ID',
    invalidCode: 'Invalid Captcha Code',
    invalidUserAndCode: 'Invalid User ID and Captcha Code',
    invalidAccountNumber: 'Invalid Account Number',
    invalidOtpNumber: 'Invalid OTP Number',
    invalidAccountOtpNumber: 'Invalid Account or OTP Number',
    invalidTac: 'Invalid TAC',
    invalidOldPassword: 'You cannot use the same 5 previous password. Please try another password',
    maxTac: 'Maximum TAC error limit reached.',
  },
  tac: {
    title: 'Please confirm your new password',
    description: 'To continue, please submit your <b>TAC</b> request.',
    descriptionFinal: 'Reset Credential is successful, please continue to Login using the new password.',
  },
};
