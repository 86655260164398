import { get } from 'utils/lodash';

export const formatUtilityAddFavouriteResultPayload = payload => {
  const tacValue = get(payload, 'tacValue', '');
  const isFromTransferResult = get(payload, 'confirmationDetail.isFromTransferResult', false);

  return {
    isFromTransferResult,
    ...(!isFromTransferResult ? { tacValue } : {}),
    userId: get(payload, 'userId', ''),
    tacValue: get(payload, 'tacValue', ''),
    restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', {}),
    nickname: get(payload, 'confirmationDetail.nickname', ''),
    customerNo: get(payload, 'confirmationDetail.customerNo', ''),
    payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
  };
};

export const formatUtilityEditFavouritePayload = payload => ({
  userId: get(payload, 'userId', ''),
  nickname: get(payload, 'nickname', ''),
  customerNo: get(payload, 'customerNumber', ''),
  payeeCode: get(payload, 'payeeCode', ''),
});

export const formatUtilityEditFavouriteResultPayload = payload => {
  return {
    userId: get(payload, 'userId', ''),
    tacValue: get(payload, 'tacValue', ''),
    restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', ''),
    nickname: get(payload, 'confirmationDetail.nickname', ''),
    payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
    customerNo: get(payload, 'confirmationDetail.customerNo', ''),
  };
};
