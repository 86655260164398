import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import './style.scss';

const BasicButton = ({
  title,
  onClick,
  variant,
  disabled,
  size,
  fullWidth,
  children,
  className,
  disableRipple,
  disabledElevation,
  icon,
  endIcon,
  onNav,
}) => (
  <Button
    size={size}
    variant={variant}
    onClick={onClick}
    disabled={disabled}
    fullWidth={fullWidth}
    disableRipple={disableRipple}
    disableElevation={disabledElevation}
    className={`btn btn-basic ${className}`}
    startIcon={icon === '' || icon === undefined || icon === null ? '' : icon}
    endIcon={endIcon === '' || endIcon === undefined || endIcon === null ? '' : endIcon}
  >
    <span style={{ marginTop: onNav ? '-1px' : '' }}>{title || children}</span>
  </Button>
);

BasicButton.defaultProps = {
  title: '',
  className: '',
  size: 'medium',
  onClick: null,
  children: null,
  disabled: false,
  fullWidth: false,
  variant: 'contained',
  disableRipple: false,
  disabledElevation: true,
  icon: '',
  endIcon: '',
  onNav: false,
};

BasicButton.propTypes = {
  title: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  disabledElevation: PropTypes.bool,
  icon: PropTypes.element,
  endIcon: PropTypes.element,
  onNav: PropTypes.bool,
};

export default BasicButton;
