import PropTypes from 'prop-types';

import { Card as MaterialCard, CardHeader } from '@material-ui/core';

import Icon from '../Icon';

import './style.scss';
import { TextButton } from '../buttons';

const PanelHeaderCard = ({
  className,
  title,
  headerAction,
  headerStyle,
  iconType,
  children,
  isDefaultCard,
  hasHeaderBorder,
  hasHeaderIcon,
  hasHeaderAction,
  hasInbox,
}) => {
  const headerBorderStyle = hasHeaderBorder || isDefaultCard ? 'bb-yellow' : '';

  return (
    <MaterialCard className={`card card--panel-header ${className}`}>
      <CardHeader
        title={title}
        className={`header--${headerStyle} ${headerBorderStyle}`}
        action={hasHeaderAction && headerAction}
        avatar={hasHeaderIcon && <Icon type={iconType} />}
        style={{ height: hasInbox ? '82.5px' : '' }}
      />
      {children}
    </MaterialCard>
  );
};

PanelHeaderCard.defaultProps = {
  className: '',
  title: '',
  headerStyle: 'default',
  iconType: 'check',
  children: null,
  headerAction: null,
  hasHeaderIcon: false,
  isDefaultCard: true,
  hasHeaderAction: false,
  hasHeaderBorder: false,
  hasInbox: false,
};

PanelHeaderCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  headerAction: PropTypes.node,
  headerStyle: PropTypes.string,
  iconType: PropTypes.string,
  isDefaultCard: PropTypes.bool,
  hasHeaderIcon: PropTypes.bool,
  hasHeaderAction: PropTypes.bool,
  hasHeaderBorder: PropTypes.bool,
  hasInbox: PropTypes.bool,
};

export default PanelHeaderCard;
