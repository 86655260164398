import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const DynoSectionHeader = ({ label }) => (
  <Grid container alignItems="center" className="pv3 ph4 bg-light-gray">
    <Grid item xs={12}>
      <Typography variant="subtitle2">{label && <FormattedMessage id={label} />}</Typography>
    </Grid>
  </Grid>
);

DynoSectionHeader.propTypes = { label: PropTypes.string.isRequired };

export default DynoSectionHeader;
