import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { putErrorResponse } from 'middleware/actions/error';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import {
  actions,
  putSwiftDetail,
  putSwiftDetailAfterTransfer,
  putSwiftResult,
  putSwiftSearchResult,
  putSwiftTAC,
  putSwiftConfirmation,
} from 'middleware/actions/favourite/transfer';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatFavouriteSwiftBank } from 'utils/transfer/swift/formatter';

const {
  DETAIL,
  DETAIL_AFTER_TRANSFER,
  CONFIRMATION,
  REQUEST_TAC,
  RESULT,
  SEARCH_RESULT,
  EDIT_DETAILS,
  EDIT_CONFIRMATION,
  EDIT_REQUEST_TAC,
  EDIT_RESULT,
} = actions.GET.SWIFT;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.SWIFT;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const deleteSwiftFavourite = ({ body }) => Instance.post(endpoint.swift.favourite.delete, body);
const deleteSwiftResult = ({ body }) => Instance.post(endpoint.swift.favourite.deleteResult, body);
const requestDeleteSwiftTac = ({ body }) => Instance.post(endpoint.swift.favourite.deleteRequestTac, body);

const swiftSearchBank = ({ body }) => Instance.post(endpoint.swift.favourite.searchBank, body);
const swiftResultFavourite = ({ body }) => Instance.post(endpoint.swift.favourite.result, body);
const newFavouriteDetail = ({ body }) => Instance.post(endpoint.swift.favourite.addNewDetail, body);
const swiftConfirmationFavourite = ({ body }) => Instance.post(endpoint.swift.favourite.confirmation, body);
const swiftAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.swift.favourite.addNewRequestTac, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.swift.favourite.addNewDetailFromTransfer, body);
const swiftEditFormDetail = ({ body }) => Instance.post(endpoint.swift.favourite.editDetail, body);
const swiftEditConfirmDetail = ({ body }) => Instance.post(endpoint.swift.favourite.editConfirm, body);
const swiftEditTac = ({ body }) => Instance.post(endpoint.swift.favourite.editRequestTac, body);
const swiftEditResult = ({ body }) => Instance.post(endpoint.swift.favourite.editResult, body);

function* swiftSearchBankSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(swiftSearchBank, { body });

    yield put(putSwiftSearchResult(formatFavouriteSwiftBank(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });

    yield put(putSwiftDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });

    yield put(putSwiftDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(swiftConfirmationFavourite, { body });
    yield put(putSwiftConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(swiftAddNewRequestTacFavourite, { body });

    yield put(putSwiftTAC(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(swiftResultFavourite, { body });

    yield put(putSwiftResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteSwiftFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteSwiftFavourite, { body });
    yield put(putSwiftConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteSwiftTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteSwiftTac, { body });
    yield put(putSwiftTAC(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteSwiftResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteSwiftResult, { body });
    yield put(putSwiftResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(swiftEditFormDetail, { body });
    yield put(putSwiftDetail(formatTransferConfirmation({ ...data, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(swiftEditConfirmDetail, { body });
    yield put(putSwiftConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(swiftEditTac, { body });
    yield put(putSwiftTAC(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* swiftEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(swiftEditResult, { body });
    yield put(putSwiftResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* SwiftFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, swiftResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteSwiftResultSaga),
    takeLatest(SEARCH_RESULT, swiftSearchBankSaga),
    takeLatest(REQUEST_TAC, swiftAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, swiftConfirmationFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteSwiftTacSaga),
    takeLatest(DELETE_CONFIRMATION, deleteSwiftFavouriteSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, swiftEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, swiftEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, swiftEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, swiftEditResultFavouriteSaga),
  ]);
}
