export const COUNTRIES = formatMessage => [
  {
    key: 'AF',
    label: formatMessage({ id: 'country.AF' }),
  },
  {
    key: 'AX',
    label: formatMessage({ id: 'country.AX' }),
  },
  {
    key: 'AL',
    label: formatMessage({ id: 'country.AL' }),
  },
  {
    key: 'DZ',
    label: formatMessage({ id: 'country.DZ' }),
  },
  {
    key: 'AS',
    label: formatMessage({ id: 'country.AS' }),
  },
  {
    key: 'AD',
    label: formatMessage({ id: 'country.AD' }),
  },
  {
    key: 'AO',
    label: formatMessage({ id: 'country.AO' }),
  },
  {
    key: 'AI',
    label: formatMessage({ id: 'country.AI' }),
  },
  {
    key: 'AI',
    label: formatMessage({ id: 'country.AI' }),
  },
  {
    key: 'AG',
    label: formatMessage({ id: 'country.AG' }),
  },
  {
    key: 'AR',
    label: formatMessage({ id: 'country.AR' }),
  },
  {
    key: 'AM',
    label: formatMessage({ id: 'country.AM' }),
  },
  {
    key: 'AW',
    label: formatMessage({ id: 'country.AW' }),
  },
  {
    key: 'AU',
    label: formatMessage({ id: 'country.AU' }),
  },
  {
    key: 'AT',
    label: formatMessage({ id: 'country.AT' }),
  },
  {
    key: 'AZ',
    label: formatMessage({ id: 'country.AZ' }),
  },
  {
    key: 'BS',
    label: formatMessage({ id: 'country.BS' }),
  },
  {
    key: 'BH',
    label: formatMessage({ id: 'country.BH' }),
  },
  {
    key: 'BD',
    label: formatMessage({ id: 'country.BD' }),
  },
  {
    key: 'BB',
    label: formatMessage({ id: 'country.BB' }),
  },
  {
    key: 'BY',
    label: formatMessage({ id: 'country.BY' }),
  },
  {
    key: 'BE',
    label: formatMessage({ id: 'country.BE' }),
  },
  {
    key: 'BZ',
    label: formatMessage({ id: 'country.BZ' }),
  },
  {
    key: 'BJ',
    label: formatMessage({ id: 'country.BJ' }),
  },
  {
    key: 'BM',
    label: formatMessage({ id: 'country.BM' }),
  },
  {
    key: 'BT',
    label: formatMessage({ id: 'country.BT' }),
  },
  {
    key: 'BO',
    label: formatMessage({ id: 'country.BO' }),
  },
  {
    key: 'BQ',
    label: formatMessage({ id: 'country.BQ' }),
  },
  {
    key: 'BA',
    label: formatMessage({ id: 'country.BA' }),
  },
  {
    key: 'BW',
    label: formatMessage({ id: 'country.BW' }),
  },
  {
    key: 'BV',
    label: formatMessage({ id: 'country.BV' }),
  },
  {
    key: 'BR',
    label: formatMessage({ id: 'country.BR' }),
  },
  {
    key: 'IO',
    label: formatMessage({ id: 'country.IO' }),
  },
  {
    key: 'BN',
    label: formatMessage({ id: 'country.BN' }),
  },
  {
    key: 'BG',
    label: formatMessage({ id: 'country.BG' }),
  },
  {
    key: 'BF',
    label: formatMessage({ id: 'country.BF' }),
  },
  {
    key: 'BI',
    label: formatMessage({ id: 'country.BI' }),
  },
  {
    key: 'KH',
    label: formatMessage({ id: 'country.KH' }),
  },
  {
    key: 'CM',
    label: formatMessage({ id: 'country.CM' }),
  },
  {
    key: 'CA',
    label: formatMessage({ id: 'country.CA' }),
  },
  {
    key: 'CV',
    label: formatMessage({ id: 'country.CV' }),
  },
  {
    key: 'KY',
    label: formatMessage({ id: 'country.KY' }),
  },
  {
    key: 'CF',
    label: formatMessage({ id: 'country.CF' }),
  },
  {
    key: 'TD',
    label: formatMessage({ id: 'country.TD' }),
  },
  {
    key: 'CL',
    label: formatMessage({ id: 'country.CL' }),
  },
  {
    key: 'CN',
    label: formatMessage({ id: 'country.CN' }),
  },
  {
    key: 'CX',
    label: formatMessage({ id: 'country.CX' }),
  },
  {
    key: 'CC',
    label: formatMessage({ id: 'country.CC' }),
  },
  {
    key: 'CO',
    label: formatMessage({ id: 'country.CO' }),
  },
  {
    key: 'KM',
    label: formatMessage({ id: 'country.KM' }),
  },
  {
    key: 'CG',
    label: formatMessage({ id: 'country.CG' }),
  },
  {
    key: 'CD',
    label: formatMessage({ id: 'country.CD' }),
  },
  {
    key: 'CK',
    label: formatMessage({ id: 'country.CK' }),
  },
  {
    key: 'CR',
    label: formatMessage({ id: 'country.CR' }),
  },
  {
    key: 'HR',
    label: formatMessage({ id: 'country.HR' }),
  },
  {
    key: 'CU',
    label: formatMessage({ id: 'country.CU' }),
  },
  {
    key: 'CW',
    label: formatMessage({ id: 'country.CW' }),
  },
  {
    key: 'CY',
    label: formatMessage({ id: 'country.CY' }),
  },
  {
    key: 'CZ',
    label: formatMessage({ id: 'country.CZ' }),
  },
  {
    key: 'CI',
    label: formatMessage({ id: 'country.CI' }),
  },
  {
    key: 'DK',
    label: formatMessage({ id: 'country.DK' }),
  },
  {
    key: 'DJ',
    label: formatMessage({ id: 'country.DJ' }),
  },
  {
    key: 'DM',
    label: formatMessage({ id: 'country.DM' }),
  },
  {
    key: 'DO',
    label: formatMessage({ id: 'country.DO' }),
  },
  {
    key: 'EC',
    label: formatMessage({ id: 'country.EC' }),
  },
  {
    key: 'EG',
    label: formatMessage({ id: 'country.EG' }),
  },
  {
    key: 'SV',
    label: formatMessage({ id: 'country.SV' }),
  },
  {
    key: 'GQ',
    label: formatMessage({ id: 'country.GQ' }),
  },
  {
    key: 'ER',
    label: formatMessage({ id: 'country.ER' }),
  },
  {
    key: 'EE',
    label: formatMessage({ id: 'country.EE' }),
  },
  {
    key: 'ET',
    label: formatMessage({ id: 'country.ET' }),
  },
  {
    key: 'FK',
    label: formatMessage({ id: 'country.FK' }),
  },
  {
    key: 'FO',
    label: formatMessage({ id: 'country.FO' }),
  },
  {
    key: 'FJ',
    label: formatMessage({ id: 'country.FJ' }),
  },
  {
    key: 'FI',
    label: formatMessage({ id: 'country.FI' }),
  },
  {
    key: 'FR',
    label: formatMessage({ id: 'country.FR' }),
  },
  {
    key: 'GF',
    label: formatMessage({ id: 'country.GF' }),
  },
  {
    key: 'PF',
    label: formatMessage({ id: 'country.PF' }),
  },
  {
    key: 'TF',
    label: formatMessage({ id: 'country.TF' }),
  },
  {
    key: 'GA',
    label: formatMessage({ id: 'country.GA' }),
  },
  {
    key: 'GM',
    label: formatMessage({ id: 'country.GM' }),
  },
  {
    key: 'GE',
    label: formatMessage({ id: 'country.GE' }),
  },
  {
    key: 'DE',
    label: formatMessage({ id: 'country.DE' }),
  },
  {
    key: 'GH',
    label: formatMessage({ id: 'country.GH' }),
  },
  {
    key: 'GI',
    label: formatMessage({ id: 'country.GI' }),
  },
  {
    key: 'GR',
    label: formatMessage({ id: 'country.GR' }),
  },
  {
    key: 'GL',
    label: formatMessage({ id: 'country.GL' }),
  },
  {
    key: 'GD',
    label: formatMessage({ id: 'country.GD' }),
  },
  {
    key: 'GP',
    label: formatMessage({ id: 'country.GP' }),
  },
  {
    key: 'GU',
    label: formatMessage({ id: 'country.GU' }),
  },
  {
    key: 'GT',
    label: formatMessage({ id: 'country.GT' }),
  },
  {
    key: 'GG',
    label: formatMessage({ id: 'country.GG' }),
  },
  {
    key: 'GN',
    label: formatMessage({ id: 'country.GN' }),
  },
  {
    key: 'GW',
    label: formatMessage({ id: 'country.GW' }),
  },
  {
    key: 'GY',
    label: formatMessage({ id: 'country.GY' }),
  },
  {
    key: 'HT',
    label: formatMessage({ id: 'country.HT' }),
  },
  {
    key: 'HM',
    label: formatMessage({ id: 'country.HM' }),
  },
  {
    key: 'VA',
    label: formatMessage({ id: 'country.VA' }),
  },
  {
    key: 'HN',
    label: formatMessage({ id: 'country.HN' }),
  },
  {
    key: 'HK',
    label: formatMessage({ id: 'country.HK' }),
  },
  {
    key: 'HU',
    label: formatMessage({ id: 'country.HU' }),
  },
  {
    key: 'IS',
    label: formatMessage({ id: 'country.IS' }),
  },
  {
    key: 'IN',
    label: formatMessage({ id: 'country.IN' }),
  },
  {
    key: 'ID',
    label: formatMessage({ id: 'country.ID' }),
  },
  {
    key: 'IR',
    label: formatMessage({ id: 'country.IR' }),
  },
  {
    key: 'IQ',
    label: formatMessage({ id: 'country.IQ' }),
  },
  {
    key: 'IE',
    label: formatMessage({ id: 'country.IE' }),
  },
  {
    key: 'IM',
    label: formatMessage({ id: 'country.IM' }),
  },
  {
    key: 'IL',
    label: formatMessage({ id: 'country.IL' }),
  },
  {
    key: 'IT',
    label: formatMessage({ id: 'country.IT' }),
  },
  {
    key: 'JM',
    label: formatMessage({ id: 'country.JM' }),
  },
  {
    key: 'JP',
    label: formatMessage({ id: 'country.JP' }),
  },
  {
    key: 'JE',
    label: formatMessage({ id: 'country.JE' }),
  },
  {
    key: 'JO',
    label: formatMessage({ id: 'country.JO' }),
  },
  {
    key: 'KZ',
    label: formatMessage({ id: 'country.KZ' }),
  },
  {
    key: 'KE',
    label: formatMessage({ id: 'country.KE' }),
  },
  {
    key: 'KI',
    label: formatMessage({ id: 'country.KI' }),
  },
  {
    key: 'KP',
    label: formatMessage({ id: 'country.KP' }),
  },
  {
    key: 'KR',
    label: formatMessage({ id: 'country.KR' }),
  },
  {
    key: 'KW',
    label: formatMessage({ id: 'country.KW' }),
  },
  {
    key: 'KG',
    label: formatMessage({ id: 'country.KG' }),
  },
  {
    key: 'LA',
    label: formatMessage({ id: 'country.LA' }),
  },
  {
    key: 'LV',
    label: formatMessage({ id: 'country.LV' }),
  },
  {
    key: 'LB',
    label: formatMessage({ id: 'country.LB' }),
  },
  {
    key: 'LS',
    label: formatMessage({ id: 'country.LS' }),
  },
  {
    key: 'LR',
    label: formatMessage({ id: 'country.LR' }),
  },
  {
    key: 'LY',
    label: formatMessage({ id: 'country.LY' }),
  },
  {
    key: 'LI',
    label: formatMessage({ id: 'country.LI' }),
  },
  {
    key: 'LT',
    label: formatMessage({ id: 'country.LT' }),
  },
  {
    key: 'LU',
    label: formatMessage({ id: 'country.LU' }),
  },
  {
    key: 'MO',
    label: formatMessage({ id: 'country.MO' }),
  },
  {
    key: 'MK',
    label: formatMessage({ id: 'country.MK' }),
  },
  {
    key: 'MG',
    label: formatMessage({ id: 'country.MG' }),
  },
  {
    key: 'MW',
    label: formatMessage({ id: 'country.MW' }),
  },
  {
    key: 'MY',
    label: formatMessage({ id: 'country.MY' }),
  },
  {
    key: 'MV',
    label: formatMessage({ id: 'country.MV' }),
  },
  {
    key: 'ML',
    label: formatMessage({ id: 'country.ML' }),
  },
  {
    key: 'MT',
    label: formatMessage({ id: 'country.MT' }),
  },
  {
    key: 'MH',
    label: formatMessage({ id: 'country.MH' }),
  },
  {
    key: 'MQ',
    label: formatMessage({ id: 'country.MQ' }),
  },
  {
    key: 'MR',
    label: formatMessage({ id: 'country.MR' }),
  },
  {
    key: 'MU',
    label: formatMessage({ id: 'country.MU' }),
  },
  {
    key: 'YT',
    label: formatMessage({ id: 'country.YT' }),
  },
  {
    key: 'MX',
    label: formatMessage({ id: 'country.MX' }),
  },
  {
    key: 'FM',
    label: formatMessage({ id: 'country.FM' }),
  },
  {
    key: 'MD',
    label: formatMessage({ id: 'country.MD' }),
  },
  {
    key: 'MC',
    label: formatMessage({ id: 'country.MC' }),
  },
  {
    key: 'MN',
    label: formatMessage({ id: 'country.MN' }),
  },
  {
    key: 'ME',
    label: formatMessage({ id: 'country.ME' }),
  },
  {
    key: 'MS',
    label: formatMessage({ id: 'country.MS' }),
  },
  {
    key: 'MA',
    label: formatMessage({ id: 'country.MA' }),
  },
  {
    key: 'MZ',
    label: formatMessage({ id: 'country.MZ' }),
  },
  {
    key: 'MM',
    label: formatMessage({ id: 'country.MM' }),
  },
  {
    key: 'NA',
    label: formatMessage({ id: 'country.NA' }),
  },
  {
    key: 'NR',
    label: formatMessage({ id: 'country.NR' }),
  },
  {
    key: 'NP',
    label: formatMessage({ id: 'country.NP' }),
  },
  {
    key: 'NL',
    label: formatMessage({ id: 'country.NL' }),
  },
  {
    key: 'NC',
    label: formatMessage({ id: 'country.NC' }),
  },
  {
    key: 'NZ',
    label: formatMessage({ id: 'country.NZ' }),
  },
  {
    key: 'NI',
    label: formatMessage({ id: 'country.NI' }),
  },
  {
    key: 'NE',
    label: formatMessage({ id: 'country.NE' }),
  },
  {
    key: 'NG',
    label: formatMessage({ id: 'country.NG' }),
  },
  {
    key: 'NU',
    label: formatMessage({ id: 'country.NU' }),
  },
  {
    key: 'NF',
    label: formatMessage({ id: 'country.NF' }),
  },
  {
    key: 'MP',
    label: formatMessage({ id: 'country.MP' }),
  },
  {
    key: 'NO',
    label: formatMessage({ id: 'country.NO' }),
  },
  {
    key: 'OM',
    label: formatMessage({ id: 'country.OM' }),
  },
  {
    key: 'PK',
    label: formatMessage({ id: 'country.PK' }),
  },
  {
    key: 'PW',
    label: formatMessage({ id: 'country.PW' }),
  },
  {
    key: 'PS',
    label: formatMessage({ id: 'country.PS' }),
  },
  {
    key: 'PA',
    label: formatMessage({ id: 'country.PA' }),
  },
  {
    key: 'PG',
    label: formatMessage({ id: 'country.PG' }),
  },
  {
    key: 'PY',
    label: formatMessage({ id: 'country.PY' }),
  },
  {
    key: 'PE',
    label: formatMessage({ id: 'country.PE' }),
  },
  {
    key: 'PH',
    label: formatMessage({ id: 'country.PH' }),
  },
  {
    key: 'PN',
    label: formatMessage({ id: 'country.PN' }),
  },
  {
    key: 'PL',
    label: formatMessage({ id: 'country.PL' }),
  },
  {
    key: 'PT',
    label: formatMessage({ id: 'country.PT' }),
  },
  {
    key: 'PR',
    label: formatMessage({ id: 'country.PR' }),
  },
  {
    key: 'QA',
    label: formatMessage({ id: 'country.QA' }),
  },
  {
    key: 'RE',
    label: formatMessage({ id: 'country.RE' }),
  },
  {
    key: 'RO',
    label: formatMessage({ id: 'country.RO' }),
  },
  {
    key: 'RU',
    label: formatMessage({ id: 'country.RU' }),
  },
  {
    key: 'RW',
    label: formatMessage({ id: 'country.RW' }),
  },
  {
    key: 'BL',
    label: formatMessage({ id: 'country.BL' }),
  },
  {
    key: 'SH',
    label: formatMessage({ id: 'country.SH' }),
  },
  {
    key: 'KN',
    label: formatMessage({ id: 'country.KN' }),
  },
  {
    key: 'LC',
    label: formatMessage({ id: 'country.LC' }),
  },
  {
    key: 'MF',
    label: formatMessage({ id: 'country.MF' }),
  },
  {
    key: 'PM',
    label: formatMessage({ id: 'country.PM' }),
  },
  {
    key: 'VC',
    label: formatMessage({ id: 'country.VC' }),
  },
  {
    key: 'WS',
    label: formatMessage({ id: 'country.WS' }),
  },
  {
    key: 'SM',
    label: formatMessage({ id: 'country.SM' }),
  },
  {
    key: 'ST',
    label: formatMessage({ id: 'country.ST' }),
  },
  {
    key: 'SA',
    label: formatMessage({ id: 'country.SA' }),
  },
  {
    key: 'SN',
    label: formatMessage({ id: 'country.SN' }),
  },
  {
    key: 'RS',
    label: formatMessage({ id: 'country.RS' }),
  },
  {
    key: 'SC',
    label: formatMessage({ id: 'country.SC' }),
  },
  {
    key: 'SL',
    label: formatMessage({ id: 'country.SL' }),
  },
  {
    key: 'SG',
    label: formatMessage({ id: 'country.SG' }),
  },
  {
    key: 'SX',
    label: formatMessage({ id: 'country.SX' }),
  },
  {
    key: 'SK',
    label: formatMessage({ id: 'country.SK' }),
  },
  {
    key: 'SI',
    label: formatMessage({ id: 'country.SI' }),
  },
  {
    key: 'SB',
    label: formatMessage({ id: 'country.SB' }),
  },
  {
    key: 'SO',
    label: formatMessage({ id: 'country.SO' }),
  },
  {
    key: 'ZA',
    label: formatMessage({ id: 'country.ZA' }),
  },
  {
    key: 'GS',
    label: formatMessage({ id: 'country.GS' }),
  },
  {
    key: 'SS',
    label: formatMessage({ id: 'country.SS' }),
  },
  {
    key: 'ES',
    label: formatMessage({ id: 'country.ES' }),
  },
  {
    key: 'LK',
    label: formatMessage({ id: 'country.LK' }),
  },
  {
    key: 'SD',
    label: formatMessage({ id: 'country.SD' }),
  },
  {
    key: 'SR',
    label: formatMessage({ id: 'country.SR' }),
  },
  {
    key: 'SJ',
    label: formatMessage({ id: 'country.SJ' }),
  },
  {
    key: 'SZ',
    label: formatMessage({ id: 'country.SZ' }),
  },
  {
    key: 'SE',
    label: formatMessage({ id: 'country.SE' }),
  },
  {
    key: 'CH',
    label: formatMessage({ id: 'country.CH' }),
  },
  {
    key: 'SY',
    label: formatMessage({ id: 'country.SY' }),
  },
  {
    key: 'TW',
    label: formatMessage({ id: 'country.TW' }),
  },
  {
    key: 'TJ',
    label: formatMessage({ id: 'country.TJ' }),
  },
  {
    key: 'TZ',
    label: formatMessage({ id: 'country.TZ' }),
  },
  {
    key: 'TH',
    label: formatMessage({ id: 'country.TH' }),
  },
  {
    key: 'TL',
    label: formatMessage({ id: 'country.TL' }),
  },
  {
    key: 'TG',
    label: formatMessage({ id: 'country.TG' }),
  },
  {
    key: 'TK',
    label: formatMessage({ id: 'country.TK' }),
  },
  {
    key: 'TO',
    label: formatMessage({ id: 'country.TO' }),
  },
  {
    key: 'TT',
    label: formatMessage({ id: 'country.TT' }),
  },
  {
    key: 'TN',
    label: formatMessage({ id: 'country.TN' }),
  },
  {
    key: 'TR',
    label: formatMessage({ id: 'country.TR' }),
  },
  {
    key: 'TM',
    label: formatMessage({ id: 'country.TM' }),
  },
  {
    key: 'TC',
    label: formatMessage({ id: 'country.TC' }),
  },
  {
    key: 'TV',
    label: formatMessage({ id: 'country.TV' }),
  },
  {
    key: 'UG',
    label: formatMessage({ id: 'country.UG' }),
  },
  {
    key: 'UA',
    label: formatMessage({ id: 'country.UA' }),
  },
  {
    key: 'AE',
    label: formatMessage({ id: 'country.AE' }),
  },
  {
    key: 'GB',
    label: formatMessage({ id: 'country.GB' }),
  },
  {
    key: 'US',
    label: formatMessage({ id: 'country.US' }),
  },
  {
    key: 'UM',
    label: formatMessage({ id: 'country.UM' }),
  },
  {
    key: 'UY',
    label: formatMessage({ id: 'country.UY' }),
  },
  {
    key: 'UZ',
    label: formatMessage({ id: 'country.UZ' }),
  },
  {
    key: 'VU',
    label: formatMessage({ id: 'country.VU' }),
  },
  {
    key: 'VE',
    label: formatMessage({ id: 'country.VE' }),
  },
  {
    key: 'VN',
    label: formatMessage({ id: 'country.VN' }),
  },
  {
    key: 'VG',
    label: formatMessage({ id: 'country.VG' }),
  },
  {
    key: 'VI',
    label: formatMessage({ id: 'country.VI' }),
  },
  {
    key: 'WF',
    label: formatMessage({ id: 'country.WF' }),
  },
  {
    key: 'EH',
    label: formatMessage({ id: 'country.EH' }),
  },
  {
    key: 'YE',
    label: formatMessage({ id: 'country.YE' }),
  },
  {
    key: 'ZM',
    label: formatMessage({ id: 'country.ZM' }),
  },
  {
    key: 'ZW',
    label: formatMessage({ id: 'country.ZW' }),
  },
];
