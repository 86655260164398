import PropTypes from 'prop-types';

import { get } from 'utils/lodash';

const DynoCardDetail = ({ detail }) => {
  const holderName = get(detail, 'cardHolderName', '');
  const cardType = get(detail, 'productName', '');
  const cardNumber = get(detail, 'creditCardNoDisplay', '');

  return (
    <>
      <span className="b pr4">{holderName}</span>
      <span className="pr2 f7">{cardType}</span>
      <span className="f7 light-silver">{cardNumber}</span>
    </>
  );
};

DynoCardDetail.propTypes = { detail: PropTypes.object.isRequired };

export default DynoCardDetail;
