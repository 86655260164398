import { actions } from 'middleware/actions/purchase';

const { RESET_PURCHASE, SENDER_ACCOUNT_RESPONSE, ACCESS, MOBILE_PREPAID, TICKET, PLN_RELOAD } = actions.PUT;

const initialState = {
  senderAccountResult: {},
  purchaseAccess: {},
  mobilePrepaidDetail: {},
  mobilePrepaidConfirmation: {},
  mobilePrepaidTac: {},
  mobilePrepaidResult: {},
  mobilePrepaidReceipt: {},
  ticketTac: {},
  ticketResult: {},
  ticketReceipt: {},
  ticketConfirmation: {},
  plnReloadDetail: {},
  plnReloadConfirmation: {},
  plnReloadTac: {},
  plnReloadResult: {},
  plnReloadReceipt: {},
};

const PurchaseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_PURCHASE:
      return initialState;
    case SENDER_ACCOUNT_RESPONSE:
      return { ...state, senderAccountResult: action.res };
    case ACCESS:
      return { ...state, purchaseAccess: action.res };
    case MOBILE_PREPAID.DETAIL:
      return { ...state, mobilePrepaidDetail: action.res };
    case MOBILE_PREPAID.CONFIRMATION:
      return { ...state, mobilePrepaidConfirmation: action.res };
    case MOBILE_PREPAID.REQUEST_TAC:
      return { ...state, mobilePrepaidTac: action.res };
    case MOBILE_PREPAID.RESULT:
      return { ...state, mobilePrepaidResult: action.res };
    case MOBILE_PREPAID.RECEIPT:
      return { ...state, mobilePrepaidReceipt: action.res };
    case TICKET.TAC:
      return { ...state, ticketTac: action.res };
    case TICKET.RESULT:
      return { ...state, ticketResult: action.res };
    case TICKET.RECEIPT:
      return { ...state, ticketReceipt: action.res };
    case TICKET.CONFIRM:
      return { ...state, ticketConfirmation: action.res };
    case PLN_RELOAD.DETAIL:
      return { ...state, plnReloadDetail: action.res };
    case PLN_RELOAD.CONFIRMATION:
      return { ...state, plnReloadConfirmation: action.res };
    case PLN_RELOAD.TAC:
      return { ...state, plnReloadTac: action.res };
    case PLN_RELOAD.RESULT:
      return { ...state, plnReloadResult: action.res };
    case PLN_RELOAD.RECEIPT:
      return { ...state, plnReloadReceipt: action.res };
    default:
      return state;
  }
};

export default PurchaseReducer;
