// TODO: English translation from business
export const lldDescription = {
  production: {
    2701: 'Interest, yield, margin and bonus - promissory notes, commercial paper and bonds up to 1 year',
    2702: 'Interest, yield, margin and bonus - promissory notes, commercial paper and bonds up to 1 year',
    2705: 'Interest/mudharabah of current/saving account and term deposit',
    2710: "Loan interest (incl. financial leasing, murabahah, musyarakah, salam paralel, istishna' paralel, dan ijarah)",
    2716: 'Interest/yield from securities issued by citizen (such as promissory notes, commercial papers, bonds, and SIMA) up to 1 year',
    2717: 'Interest/yield from securities issued by citizen (such as promissory notes, commercial papers, bonds, and SIMA) more than 1 year',
    2720: 'Interest, yield, gold swap margin',
    2725: 'Dividend and shared profit, including profit from mutual funds',
    2730: 'Securities owner transaction fee for securities lending transaction',
    2731: 'Gold owner transaction fee for gold loans or gold swaps',
    2150: 'Salary transfer',
    2163: 'Salary',
    2741: 'Land rental',
    2742: 'Office space/apartment/house rent',
    2743: 'Land rent for office space/apartment/house',
    2751: 'Grant (in cash) which is not related with fixed asset purchase',
    2752: 'Grant (in cash) which is  related with fixed asset purchase',
    2760: 'Penalty/fine',
    2765: 'Value added tax, payroll tax, land and building tax, and business license',
    2766: 'Witholding tax',
    2767: 'Other tax',
    2770: 'Rights for the use of natural resource',
  },
  goods: {
    2012: 'Goods import',
    2803: 'Import refunds',
    2018: 'Full advance payment',
    2019: 'Partial advance payment',
    2015: 'Bunkers and stores (from/to transportation providers)',
    2097: 'Domestic goods trading (supporting documents are mandatory)',
    2098: 'Overseas goods sale (non domestic origin)',
    2804: 'Goods refund',
  },
  services: {
    2400: 'Goods processing service',
    2405: 'Goods service and maintenance',
    2411: 'Sea passengers transportation',
    2412: 'Air passengers transportation',
    2413: 'Other passenger transportation',
    2421: 'Sea freight transportation (export/import)',
    2422: 'Air freight transportation (export/import)',
    2423: 'Other freight transportation (export/import)',
    2431: 'Sea freight transportation (non export/import)',
    2432: 'Air freight transportation (non export/import)',
    2433: 'Other freight transportation (non export/import)',
    2441: 'Sea transportation support',
    2442: 'Air transportation support',
    2443: 'Other transportation support',
    2450: 'Post and courier',
    2461: 'Business trip',
    2462: 'Personal trip',
    2466: 'Domestic health service',
    2467: 'Overseas health service',
    2468: 'Domestic training/education',
    2469: 'Overseas training/education',
    2480: 'Telecomunication service',
    2490: 'Computer service',
    2495: 'Information service',
    2501: 'Domestic construction - up to 1 year',
    2502: 'Domestic construction - more than 1 year',
    2511: 'Overseas construction - up to 1 year',
    2512: 'Overseas construction - more than 1 year',
    2521: 'Life insurance premium',
    2522: 'Life insurance benefit',
    2523: 'Freight insurance premium',
    2524: 'Life insurance claim',
    2525: 'Other insurance premium',
    2526: 'Other insurance claim',
    2531: 'Reinsurance premium',
    2532: 'Reinsurance claim',
    2533: 'Reinsurance commission',
    2541: 'Auxiliary insurance services',
    2546: 'Pension fund - contribution',
    2547: 'Pension fund - benefit',
    2560: 'Intellectual property use',
    2570: 'Research and development',
    2580: 'Law, accounting, management consultant, and public relations',
    2590: 'Advertising, market research, and public survey',
    2600: 'Architecture and other engineering',
    2610: 'Mine, farm, waste and pollution processing',
    2615: 'Goods operational lease',
    2616: 'Non passenger transportation operational lease',
    2630: 'Trading related service',
    2640: 'Other services',
    2651: 'Domestic art, culture, and leisure services',
    2652: 'Overseas art, culture, and leisure services',
    2660: 'Government service services',
    2129: 'Non export account balance netting',
  },
  modal: {
    2193: 'Non financial asset trading (marketing assets such as franchise, trademark, brand name, logo, and domain name)',
    2194: 'Non financial asset trading (contract, lease, trading license for marketable operating lease)',
    2197: 'Land trading',
    2198: 'Building trading',
    2203: 'Domestic investment',
    2204: 'Overseas investment',
    2206: 'Domestic divestment',
    2207: 'Overseas divestment',
    2221: 'Loan disbursement with term up to 1 year',
    2222: 'Loan disbursement with term more than 1 year',
    2231: 'Loan payment with term up to 1 year',
    2232: 'Loan payment with term more than 1 year',
    2233: 'Financial leasing with option to purchase',
    2383: 'Factoring - the sale of receivables with domestic bank debtors with term up to 1 year',
    2384: 'Factoring - the sale of receivables with domestic bank debtors with term more than 1 year',
    2385: 'Factoring - the sale of receivables with domestic companies with term up to 1 year',
    2386: 'Factoring - the sale of receivables with domestic debtor companies with term more than 1 year',
    2387: 'Factoring - the sale of receivables with overseas bank debtors with term up to 1 year',
    2388: 'Factoring - the sale of receivables with overseas bank debtors with term more than 1 year',
    2389: 'Factoring - the sale of receivables with overseas debtor companies with term up to 1 year',
    2390: 'Factoring - the sale of receivables with overseas debtor companies with term more than 1 year',
    2391: 'Fund transfer by cash providers in gold swap transactions with term up to 1 year',
    2392: 'Fund transfer by cash providers in gold swap transactions with term more than 1 year',
    2393: 'Refund by cash providers in gold swap transactions with term up to 1 year',
    2394: 'Refund by cash providers in gold swap transactions with term more than 1 year',
    2395: 'Fund acceptance by cash providers in gold swap transactions with term up to 1 year',
    2396: 'Fund acceptance by cash providers in gold swap transactions with term more than 1 year',
    2397: 'Fund acceptance by gold owner in gold swap transactions with term up to 1 year',
    2398: 'Fund acceptance by gold owner in gold swap transactions with term more than 1 year',
    2808: 'Working capital/cashcall -  to purchase raw materials and pay oil/gas wages',
    2809: 'Working capital/cashcall -  to purchase raw materials and pay non oil/gas wages',
    2811: 'Advance payment other than export/import',
    2812: 'Insurance advance payment',
    2813: 'Other advance payment',
    2814: 'Order acceptance/refund (based on IPO and private placement)',
    2815: 'Bailout reimbursement',
    2240: 'Domestic term deposit placement - up to 3 months',
    2241: 'Domestic current account and on-call deposit placement - up to 3 months',
    2242: 'Domestic deposit placement - 3 to 6 months',
    2243: 'Domestic deposit placement - more than 6 months',
    2244: 'Domestic term deposit withdrawal - up to 3 months',
    2245: 'Domestic current account and on-call deposit withdrawal - up to 3 months',
    2246: 'Domestic deposit withdrawal - 3 to 6 months',
    2247: 'Domestic deposit withdrawal - more than 6 months',
    2251: 'Overseas deposit placement incl current account and on-call deposit - up to 3 months',
    2252: 'Overseas deposit placement - more than 3 months',
    2255: 'Overseas deposit withdrawal incl current account and on-call deposit - more than 3 months',
    2256: 'Overseas term deposit withdrawal - more than 3 months',
    2257: 'Withdrawal of foreign exchange earnings from exports from overseas accounts',
    2261: 'Foreign exchange trading - spot foreign exchange transactions against IDR',
    2262: 'Foreign exchange trading - spot transactions between foreign exchange',
    2263: 'Foreign exchange trading - forward foreign exchange transactions against IDR',
    2264: 'Foreign exchange trading - forward transactions between foreign exchange',
  },
  unrequitted: {
    2271: 'Gain on forward or similar transactions (such as swaps and futures)',
    2272: 'Acceptance forward rate agreement (FRA)',
    2273: 'Acceptance for interest swap or currency swap',
    2274: 'Acceptance for initial/repayable margin such as initial margin in securities repo transactions, securities lending',
    2275: 'Acceptance for variation/non repayable margin',
    2276: 'Premium from option transaction',
    2277: 'Principal acceptance in currency swap transactions at the beginning of the transaction (first leg)',
    2278: 'Principal acceptance in currency swap transactions at the end of the transaction (final leg)',
    2279: 'Premium from default swap credit transaction',
    2280: 'Deposit/refund for guarantee',
    2282: 'Refund for other than export/import transactions and other transactions',
    2311: 'Equity',
    2321: 'Bonds with more than 1 year term',
    2322: 'Medium term note with more than 1 year term',
    2323: 'Other long term securities',
    2331: 'Treasury bills with more than 1 year term',
    2332: 'Promissory Notes',
    2333: 'Other short term debt securities',
    2341: 'Equity',
    2342: 'Other equities such as warrant and right',
    2351: 'Indonesia government bonds with more than 1 year term',
    2352: 'Corporate bonds with more than 1 year term',
    2353: 'Medium term note with more than 1 year tem ',
    2354: 'Other long-term debt securities',
    2361: 'Bank Indonesia Certificate and Bank Indonesia Wadiah Certificate',
    2362: 'Letter of the state Treasury',
    2363: 'Promissory Notes',
    2364: 'Negotiable Certificate of Deposit (NCD)',
    2365: 'Other short term debt securities',
    2366: 'Bank Indonesia deposit certificate',
    2371: 'Mutual funds',
    2372: 'Traded mutual funds',
    2375: 'Sale of securities, issued by resident, under repo by securities owner with term up to 1 year ',
    2376: 'Sale of securities, issued by resident, under repo by securities owner with term more than 1 year ',
    2377: 'Re-sale of securities, issued by resident, under repo by securities buyer with term up to 1 year',
    2378: 'Re-sale of securities, issued by resident, under repo by securities buyer with term more than 1 year',
    2379: 'Securities buyback, issued by resident, sold by repo by securities owner with term up to 1 year',
    2380: 'Securities buyback, issued by resident, sold by repo by securities owner with term more than 1 year ',
    2381: 'Securities purchase, issued by resident, sold by repo with term up to 1 year',
    2382: 'Securities purchase, issued by resident, sold by repo with term more than 1 year',
    2821: 'Sale of securities, issued by non resident, under repo by securities owner with term up to 1 year',
    2822: 'Sale of securities, issued by non resident, under repo by securities owner with term more than 1 year',
    2823: 'Re-sale of securities, issued by non resident, under repo by securities buyer with term up to 1 year ',
    2824: 'Re-sale of securities, issued by non resident, under repo by securities buyer with term more than 1 year',
    2825: 'Securities buyback, issued by non resident, sold by repo by securities owner with term up to 1 year',
    2826: 'Securities buyback, issued by non resident, sold by repo by securities owner with term more than 1 year',
    2827: 'Repo securities purchase issued by non resident with term less than 1 year',
    2828: 'Repo securities purchase issued by non resident  with term more than 1 year',
  },
};
