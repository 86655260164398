export const purchaseEndpoint = {
  get: {
    senderAccount: 'purchase/v1/source/accounts',
    biller: 'purchase/v1/billers',
    favouriteAccount: 'purchase/v1/biller/fav/source/accounts',
    maintenances: 'purchase/v1/biller/fav/dest/list',
  },
  mobilePrepaid: {
    detail: 'purchase/v1/biller/mobile/details',
    confirm: 'purchase/v1/biller/mobile/initiate',
    requestTac: 'notification/v1/tac/purchase/mobile/request',
    result: 'purchase/v1/biller/mobile/confirm',
    receipt: 'purchase/v1/biller/mobile/receipt',
  },
  ticket: {
    getResult: 'purchase/v1/biller/ticket/confirm',
    getReceipt: 'purchase/v1/biller/ticket/receipt',
    getConfirmation: 'purchase/v1/biller/ticket/initiate',
    requestTAC: 'notification/v1/tac/purchase/ticket/request',
  },
  plnReload: {
    getDetail: 'purchase/v1/biller/pln/details',
    getConfirm: 'purchase/v1/biller/pln/initiate',
    getRequestTac: 'notification/v1/tac/purchase/pln/request',
    getResult: 'purchase/v1/biller/pln/confirm',
    getReceipt: 'purchase/v1/biller/pln/receipt',
  },
  favourite: {
    add: {
      getDetail: 'purchase/v1/biller/fav/add/mobile/details',
      getDetailFromPurchase: 'purchase/v1/biller/fav/add/txn/mobile/details',
      getConfirmation: 'purchase/v1/biller/fav/add/mobile/initiate',
      getTac: 'notification/v1/tac/purchase/fav/add/mobile/request',
      getResult: 'purchase/v1/biller/fav/add/mobile/confirm',
    },
    edit: {
      getDetail: 'purchase/v1/biller/fav/edit/mobile/details',
      getConfirmation: 'purchase/v1/biller/fav/edit/mobile/initiate',
      getTac: 'notification/v1/tac/purchase/fav/edit/mobile/request',
      getResult: 'purchase/v1/biller/fav/edit/mobile/confirm',
    },
    delete: {
      getConfirmation: 'purchase/v1/biller/fav/del/mobile/initiate',
      getResult: 'purchase/v1/biller/fav/del/mobile/confirm',
      getTac: 'notification/v1/tac/purchase/fav/del/mobile/request',
    },
    purchase: {
      getMobileDetail: 'purchase/v1/biller/fav/mobile/details',
      getPlnDetail: '/purchase/v1/biller/fav/pln/details',
    },
  },
};
