// responseCode mapping from API
export const errorMessages = {
  SYSTEM: 'Service is currently unavailable.',
  ERR_00: 'Success',
  ERR_01: 'Invalid username/password. Click "Forgot Login" to reset your password',
  ERR_02: 'Invalid Reference No and Registration No.',
  ERR_03: 'User Account Locked.',
  ERR_04: 'Unactivated.',
  ERR_05: 'TAC Locked.',
  ERR_06: 'Invalid username/password. Click "Forgot Login" to reset your password',
  ERR_07: 'You have reached the maximum login attempts. Click "Having Problem Logging In?" to unlock.',
  ERR_08:
    'Your User ID has been locked. Please unlock through nearest Maybank Branch or call Maybank Call Center 1500611 / +622178869811 (from overseas).',
  ERR_09:
    'You have reached maximum TAC attempts. For your security, your M2U ID user has been locked. You can unlock through "Having Problem Logging In?".',
  ERR_10: 'User State Dormant call customer care.',
  ERR_11: 'Unknown Error.',
  ERR_12: 'You cannot use the same 5 previous password. Please try another password',
  ERR_13: 'Your new password is the same with your User ID. Please try another password',
  ERR_14: 'Username already exist.',
  ERR_15: 'Username already Register.',
  ERR_16: 'Invalid Input.',
  ERR_17: 'Security Answer Invalid.',
  ERR_18: 'User State Tac Locked.',
  ERR_19: 'Your current password is wrong. Please try again',
  ERR_20: 'Mobile No Not Match.',
  ERR_21: 'Invalid Account Number',
  ERR_22: 'Currency Not Valid.',
  ERR_23: 'You do not have any transactions.',
  ERR_24: 'You do not have any transactions.',
  ERR_25: 'You do not have any credit card statement.',
  ERR_26: 'Please use option "Own Account" for fund transfer between your accounts.',
  ERR_27: 'Error not casa loan account.',
  ERR_28: 'Error exceed fav limit.',
  ERR_29: 'Invalid Account Number.',
  ERR_30: 'Exceed per transaction limit for Real Time Transfer.',
  ERR_31: 'Invalid TAC.',
  ERR_32:
    'You have reached maximum TAC attempts. For your security, your M2U ID user has been locked. You can unlock through "Having Problem Logging In?".',
  ERR_33: 'TAC Expired.',
  ERR_34: 'TAC delivery failed. Please click "Request TAC" again.',
  ERR_35: 'TAC Exceed Max Use.',
  ERR_36: 'No Available Account to Unlink.',
  ERR_37_OPEN_PAYMENT: 'Invalid Credit Card Number',
  ERR_37: 'Invalid Card Number',
  ERR_38: 'Payee Account Number not Exist.',
  ERR_39: 'Test Question is More Than 4 Words.',
  ERR_40: 'Nickname is Already Used.',
  ERR_41: 'Mobile Number is Already Registered.',
  ERR_42: 'Foreign telegraphic transfer swift not registered.',
  ERR_43: 'Beneficiary Bank is Required.',
  ERR_44: 'Account Number already registered',
  ERR_44_EWALLET: 'Customer Number already registered',
  ERR_45: 'No Subject Available to Compose New Message.',
  ERR_46: 'Account/Reference Number Already Exist.',
  ERR_47: 'Account Not Found.',
  ERR_48: 'TD Placement Must be Equal or Greater Than Defined Minimum Amount.',
  ERR_49: 'Account Already Exist.',
  ERR_50: 'Termination Date Must be After Todays Date and Start Date.',
  ERR_51: 'Insufficient Fund',
  ERR_52: 'Invalid Start Date for Recurring Payment.',
  ERR_53: 'Invalid Start Date for Recurring Payment.',
  ERR_54: 'Bank Standing Instruction Maximum Transaction Limit for the Account has Reached.',
  ERR_55: 'Invalid Payment Frequency Month.',
  ERR_56: 'Payment Frequency Not within Effective Date Range.',
  ERR_57: 'Invalid Effective Date Range.',
  ERR_58: 'Invalid User Session.',
  ERR_59: 'Transaction exceeds daily banking operational hours. Please select other date',
  ERR_60: 'Communication time out. Please check your balance.',
  ERR_61: 'Invalid Account Number.',
  ERR_62: 'You do not have valid accounts to perform this transaction.',
  ERR_63: 'Transaction amount should not be less than allowed amount for RTGS',
  ERR_64: 'TAC is required.',
  ERR_65: 'Invalid Bill Account Number',
  ERR_66: 'Invalid Customer Number/ID',
  ERR_68: 'Your Transaction Is Time Out',
  ERR_70: 'Your new password is same with your User ID. Please try another password',
  ERR_73: 'Invalid STP Account to perform this transaction.',
  ERR_75: 'Inputted account number is not registered',
  ERR_76: 'Invalid Account Number details, please try again in the next 24 hours',
  ERR_77: 'Invalid Account Number details, please try again in the next 24 hours',
  ERR_78: 'Invalid Credit Card details, please try again in the next 24 hours',
  ERR_79: 'Invalid Credit Card details',
  ERR_80: 'Invalid Account Number or PIN',
  ERR_81: 'Invalid account details, visit the nearest Branch to proceed with the registration',
  ERR_82: "Your 'Self Unlocked' process using Credit Card has failed. {contactNo}",
  ERR_83: 'Invalid account details, please try again in the next 24 hours',
  ERR_87: 'Send Email Failed.',
  ERR_89: 'The system is temporary unavailable to process this request. Please try again later.',
  ERR_90: 'The system is temporary unavailable to process this request. Please try again later.',
  ERR_91: 'Transaction cannot be processsed.',
  ERR_92: 'System Operation Timeout',
  ERR_93: 'Error System Not Available',
  ERR_94: 'Transaction cant be processed',
  ERR_95: 'System Error please relogin.',
  ERR_96: 'Logger Error.',
  ERR_97: 'OpenAM Error.',
  ERR_98: 'The service is temporary unavailable to process this request. Please try again later',
  ERR_99: 'The service is temporary unavailable to process this request. Please try again later',
  ERR_100: 'The date you selected is holiday date  please select other date.',
  ERR_101: 'LLD information not required',
  ERR_102: 'Invalid Credit Card details, please try again in the next 24 hours',
  ERR_103: 'Reset using credit card is locked, please try again after 24 hours.',
  ERR_104: 'Invalid beneficiary account.',
  ERR_105: 'Invalid Mobile Number',
  ERR_106: 'Invalid Customer Number',
  ERR_107: 'SWIFT exceed operation hours',
  ERR_108: 'Invalid Customer Number',
  ERR_109: 'Invalid Payment Code Number',
  ERR_110: 'Beneficiary Nickname already exist',
  ERR_115: 'Card Number already registered',
  ERR_116: 'Mobile Number already registered',
  ERR_117: 'Customer Number already registered',
  ERR_118: '{accountType} already registered',
  ERR_119: 'Virtual Account Number already registered',
  ERR_120: 'Your Credit Card Number is Invalid',
  ERR_121: 'Your card is not registered. Please try again later.',
  ERR_122: 'Below Minimum E-Wallet Transaction Amount.',
  ERR_123: 'Exceed E-Wallet Maximum Transaction Amount.',
  ERR_124: 'Exceed E-Wallet Daily Transaction Limit.',
  ERR_125: 'Bill Already Paid.',

  // System errors
  ERR_201: 'System Error (01).',
  ERR_202: 'System Error (02).',
  ERR_203: 'System Error (03).',
  ERR_204: 'System Error (04).',
  ERR_205: 'Transaction cannot be process.',
  ERR_206: 'System Error (06).',
  ERR_207: 'System Error (07).',
  ERR_208: 'System Error (08).',
  ERR_209: 'System Error (09).',
  ERR_210: 'Bill must be paid through counter.',
  ERR_211: 'System Error (11).',
  ERR_212: 'Invalid transaction not sign-on.',
  ERR_213: 'Invalid amount.',
  ERR_214: 'Invalid subscriber number.',
  ERR_215: 'System Error (15).',
  ERR_216: 'System Error (16).',
  ERR_217: 'System Error (17).',
  ERR_218: 'System Error (18).',
  ERR_219: 'System Error (19).',
  ERR_220: 'System Error (20).',
  ERR_221: 'System Error (21).',
  ERR_222: 'System Error (22).',
  ERR_223: 'System Error (23).',
  ERR_224: 'System Error (24).',
  ERR_225: 'No original transaction.',
  ERR_226: 'System Error (26).',
  ERR_227: 'System Error (27).',
  ERR_228: 'System Error (28).',
  ERR_229: 'System Error (29).',
  ERR_230: 'Format error.',
  ERR_231: 'Invalid bank code.',
  ERR_232: 'System Error (32).',
  ERR_233: 'System Error (33).',
  ERR_234: 'System Error (34).',
  ERR_235: 'System Error (35).',
  ERR_236: 'System Error (36).',
  ERR_237: 'System Error (37).',
  ERR_238: 'System Error (38).',
  ERR_239: 'System Error (39).',
  ERR_240: 'Request function not supported.',
  ERR_241: 'System Error (41).',
  ERR_242: 'System Error (42).',
  ERR_243: 'System Error (43).',
  ERR_244: 'System Error (44).',
  ERR_245: 'System Error (45).',
  ERR_246: 'System Error (46).',
  ERR_247: 'System Error (47).',
  ERR_248: 'System Error (48).',
  ERR_249: 'System Error (49).',
  ERR_250: 'System Error (50).',
  ERR_251: 'Insufficient Fund',
  ERR_252: 'No checking account.',
  ERR_253: 'No saving account.',
  ERR_254: 'Expired card.',
  ERR_255: 'Incorrect PIN.',
  ERR_256: 'System Error (56).',
  ERR_257: 'Transaction not permitted.',
  ERR_258: 'System Error (58).',
  ERR_259: 'System Error (59).',
  ERR_260: 'System Error (60).',
  ERR_261: 'Exceed amount limit.',
  ERR_262: 'Restricted card.',
  ERR_263: 'System Error (63).',
  ERR_264: 'System Error (64).',
  ERR_265: 'Exceed frequency limit.',
  ERR_266: 'System Error (66).',
  ERR_267: 'System Error (67).',
  ERR_268: 'Transaction cannot be processed.',
  ERR_269: 'System Error (69).',
  ERR_270: 'Voucher is not available.',
  ERR_271: 'System Error (71).',
  ERR_272: 'System Error (72).',
  ERR_273: 'System Error (73).',
  ERR_274: 'System Error (74).',
  ERR_275: 'Max PIN tries exceeded.',
  ERR_276: 'Invalid account customer name not same within bills.',
  ERR_277: 'More than 3 bills.',
  ERR_278: '2 bills in one month.',
  ERR_279: 'Phone Number is Block.',
  ERR_280: 'Invalid reference number.',
  ERR_281: 'Phone/Card Number is Expired.',
  ERR_282: 'System Error (82).',
  ERR_283: 'System Error (83).',
  ERR_284: 'System Error (84).',
  ERR_285: 'System Error (85).',
  ERR_286: 'System Error (86).',
  ERR_287: 'System Error (87).',
  ERR_288: 'Bill already paid.',
  ERR_289: 'Transaction cannot be process.',
  ERR_290: 'Cut off in process.',
  ERR_291: 'Transaction cannot be process.',
  ERR_292: 'Transaction cannot be process.',
  ERR_293: 'System Error (93).',
  ERR_294: 'Duplicate transaction.',
  ERR_295: 'System Error (95).',
  ERR_296: 'System malfunction.',
  ERR_297: 'System Error (97).',
  ERR_298: 'Response is never received because link is down or process aborted.',
  ERR_299: 'PIN block error.',
  ERR_500: 'Service is currently unavailable.',
  ERR_800: '{clickHere} to obtain a credit card facility from Maybank that suits your need and enjoy various attractive promos',
  ERR_1300: 'Below Minimum Amount',
  ERR_1304: 'You have exceeded transaction limit for Real Time Transfer',

  // For transaction result (statusCode)
  ERR_ESB_01: 'Amount can not be empty',
  ERR_ESB_02: 'Insufficient funds',
  ERR_ESB_03: 'Destination account not found',
  ERR_ESB_04: 'System under maintenance, please try again later',
  ERR_ESB_05: 'Transaction failed, invalid beneficiary account',
  ERR_ESB_06: 'Transaction is more than max limit or less than min limit',
  ERR_ESB_07: 'Insufficient balance',
  ERR_ESB_08: 'Account can not be transacted online',
  ERR_ESB_09: 'Forex transaction is more than equivalent USD 25,000/month',
  ERR_ESB_10: 'Insufficient balance',
  ERR_ESB_11: 'Insufficient balance',
  ERR_ESB_23: 'Transaction cannot be processed',
  ERR_ESB_51: 'Insufficient balance',
  ERR_ESB_107: 'Invalid username and password',

  // custom errors
  ERR_FAV_01: 'No favourite list available.',
  ERR_CC_01: 'Credit Card Number is required.',
  ERR_INVALID_SWIFT_BANK_SEARCH: 'Invalid Bank Name or SWIFT Code',
  ERR_INVALID_SWIFT_SEARCH: 'Invalid SWIFT Code',
  ERR_INVALID_BANK_SEARCH: 'Beneficiary Bank is not found',
  ERR_PROCEDURE_ERROR: 'This service is temporarily unavailable.',
  ERR_REQUEST_TIMEOUT: 'Your request cannot be processed at the moment. Please try again.',
  ERR_DEFAULT_ERROR: 'Please check your Internet connection and please try again.',
  ERR_UNRESPONSIVE_HOST: 'Please check your Internet connection and please try again.',
  ERR_PWD_COMPLEXITY: 'Password is not a valid password. Password must be a mixture of alphabets and numbers.',
  ERR_PWD_LENGTH: 'Password cannot be less than 6 characters',
  ERR_NO_ACCOUNT: 'You do not have any accounts at this moment',
  ERR_NO_CC: 'You do not have any credit cards at this moment.',
  ERR_MAX_REGISTERED_DEVICE: 'Sorry you have already registered for 3 devices.',
  ERR_ACCOUNT_NOT_ALLOWED: 'The account you selected can not be used for this transaction',
  ERR_AMOUNT_LIMIT_EXCEEDED: 'Jumlah harus dalam kisaran 0.01 sampai 9999999999.99',
  ERR_INVALID_INPUT: 'Invalid parameter input',
  ERR_INVALID_ACCT: 'The account you selected can not be used for this transaction',
  ERR_NO_SENDER_ACCOUNT: "You don't have account allowed for this transaction",
  ERR_NON_IDR: 'Deposit placement is currently only for IDR account.',
  ERR_FAV_EXISTS: 'Already registered in favorite.',
  ERR_QR00: 'Maximum Limit per QR Transaction IDR 2.000.000',
  ERR_NONFIN: 'You do not have access to this feature. Please visit our nearest branch to change your transaction mode to the Financial.',
  ERR_LIMIT: 'The transaction amount exceed per transfer limit of {0}',
  ERR_TRANSACTION_SERVICE_OFFLINE: 'The service is temporary unavailable to process this request. Please try again later',
  ERR_REQUIRED: 'Please ensure you fill all information required for this transaction',
  ERR_SERVER_ERROR: 'The service is temporary unavailable to process this request. Please try again later.',
  ERR_59_8:
    "Transaction can't be processed after SWIFT operating hours. <br/>SWIFT is available {swiftStartTime} - {swiftEndTime} GMT +7 on working days.",
  ERR_107_8:
    "Transaction can't be processed after SWIFT operating hours. <br/>SWIFT is available {swiftStartTime} - {swiftEndTime} GMT +7 on working days.",
  ERR_HALF_KYC_NON_FINANCIAL: "You currently don't have access to perform this transaction. Please contact Maybank Call Center 1500611.",
  ERR_MAINTENANCE_PAGE: 'Website is Under Maintenance',
  ERR_MAINTENANCE_PAGE_WITH_SORRY: 'We apologize that M2U Web is currently undergoing scheduled maintenance. We should be back shortly.',
};
