import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { COUNT_IDLE_IN_MODAL } from 'settings/constants/common';
import { DynoAlertModal } from 'components/dyno-template/modals';

import CountdownTimer from '../CountdownTimer';

const ExpiredSessionModal = ({ idle, onCancel, onSubmit, isCompleted }) => {
  const { formatMessage } = useIntl();

  return (
    <DynoAlertModal
      isOpen={idle}
      hasCancelButton
      onCancel={onCancel}
      onSubmit={onSubmit}
      title={formatMessage({ id: 'dashboard.sessionExpired.title' })}
      description={formatMessage(
        { id: 'dashboard.sessionExpired.description' },
        {
          br: <br />,
          counting: (
            <span>
              <CountdownTimer duration={COUNT_IDLE_IN_MODAL} modalCount isStart onComplete={isCompleted} />
            </span>
          ),
        }
      )}
    />
  );
};

ExpiredSessionModal.defaultProps = {
  idle: false,
  onCancel: null,
  onSubmit: null,
  isCompleted: null,
};

ExpiredSessionModal.propTypes = {
  idle: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isCompleted: PropTypes.func,
};

export default ExpiredSessionModal;
