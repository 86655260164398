import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';

import PDFViewer from 'components/common/PDFViewer';

import { BasicDrawer } from 'components/common/drawers';
import { ActionButton, BasicButton } from 'components/common/buttons';

import { get } from 'utils/lodash';
import { resultDrawerButtonHandler } from 'utils/transaction';

import { DASHBOARD_TIME_DEPOSIT } from 'settings/constants/dashboard';
import { PDF_PREFIX } from 'settings/constants/common';

const DynoResultDrawer = ({ isOpen, receipt, onClose, onDownload, resultTitle, resultDetail, transactionType }) => {
  const { formatMessage } = useIntl();

  const [isPdfOpen, setPdfOpen] = useState(false);

  const handlePdfClose = () => setPdfOpen(false);

  const handleDownloadClick = () => {
    onDownload();
    setPdfOpen(true);
  };

  const handleDoneClick = () => {
    onClose();
    setPdfOpen(false);
  };

  const getResultMessageDrawer = isTransactionSuccess => {
    if (!isTransactionSuccess) {
      return formatMessage({ id: 'transaction.failed' });
    }

    return get(resultDetail, 'transactionStatus', '').toLowerCase();
  };

  const filename = get(resultDetail, 'transactionID', '');
  const isBeforeOperationHour = get(resultDetail, 'isBeforeOperationHour', false);
  const isTransactionSuccess = get(resultDetail, 'isTransactionSuccessful', false);
  const status = isBeforeOperationHour ? formatMessage({ id: 'transaction.accepted' }) : getResultMessageDrawer(isTransactionSuccess);

  const buttonTitle = resultDrawerButtonHandler({ transactionType, formatMessage, isTransactionSuccess });

  const hasPdf = isTransactionSuccess && receipt;
  // append PDF mime prefix to receipt content
  const content = hasPdf && PDF_PREFIX + receipt;

  return (
    <>
      <BasicDrawer variant="persistent" className="result--drawer" isOpen={isOpen} onClose={onClose}>
        <Grid container justify="center">
          <Grid container sm={11} md={9} item>
            <Grid item sm={5} xs={12}>
              <h1 className="f4 lh-copy">
                {isEmpty(resultTitle) ? <FormattedMessage id="transaction.transfer.resultTitle" values={{ status }} /> : resultTitle}
              </h1>
            </Grid>

            <Grid item sm={7} xs={12} container alignContent="center" justify="flex-end">
              {isTransactionSuccess && onDownload !== null && (
                <ActionButton
                  onClick={handleDownloadClick}
                  isAssetIcon={false}
                  title={formatMessage({ id: 'buttonText.downloadReceipt' })}
                  icon="download"
                  className="transparent-bb db"
                />
              )}

              {isTransactionSuccess && transactionType.toLowerCase() !== DASHBOARD_TIME_DEPOSIT.toLowerCase() ? (
                <ActionButton icon="add" className="db btn--done" isAssetIcon={false} title={buttonTitle} onClick={handleDoneClick} />
              ) : (
                <BasicButton title={buttonTitle} onClick={handleDoneClick} className="db btn--done" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </BasicDrawer>

      {isPdfOpen && <PDFViewer filename={filename} isOpen={isPdfOpen} onClose={handlePdfClose} content={content} />}
    </>
  );
};

DynoResultDrawer.defaultProps = {
  isOpen: true,
  onClose: null,
  onDownload: null,
  resultDetail: {},
  receipt: '',
  resultTitle: '',
  transactionType: '',
};

DynoResultDrawer.propTypes = {
  isOpen: PropTypes.bool,
  receipt: PropTypes.string,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  resultTitle: PropTypes.string,
  resultDetail: PropTypes.object,
  transactionType: PropTypes.string,
};

export default DynoResultDrawer;
