import isNull from 'lodash/isNull';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { findArrayEntry } from 'utils/array';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatEWalletDetail } from 'utils/transfer/e-wallet/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import {
  actions,
  putEWalletDetail,
  putEWalletConfirmation,
  putEWalletTac,
  putEWalletResult,
  putEWalletReceipt,
} from 'middleware/actions/transfer';

import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';
import { RECEIPT_KEYMAP, TRANSFER_KEYMAP } from 'settings/constants/keymap';

const getClientIP = () => remoteIpInstance.get();

const getDetail = ({ body }) => Instance.post(endpoint.eWallet.getDetail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.eWallet.getConfirmation, body);
const requestTac = ({ body }) => Instance.post(endpoint.eWallet.requestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.eWallet.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.eWallet.getReceipt, body);
const getFavouriteDetail = ({ body }) => Instance.post(endpoint.eWallet.favourite.getTransferDetail, body);

const getLocale = state => state.LanguageReducer.locale;
const getEWallet = state => state.TransferReducer.eWalletDetail.products;

const { TRANSFER_CONFIRMATION, TRANSFER_DETAIL, TRANSFER_RECEIPT, TRANSFER_RESULT, REQUEST_TAC, TRANSFER_FROM_FAVOURITE_DETAIL } =
  actions.GET.E_WALLET;

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getDetail, { body });

    const res = formatEWalletDetail(data);

    yield put(putEWalletDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getFavouriteDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getFavouriteDetail, { body });

    const res = formatEWalletDetail(data);

    yield put(putEWalletDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const renamedBody = formatKeys(payload, TRANSFER_KEYMAP);

    const body = { locale, remoteIPAddress: ip, ...renamedBody };

    const { data } = yield call(getConfirmation, { body });

    const res = formatTransferConfirmation(data);

    yield put(putEWalletConfirmation(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTacSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(requestTac, { body });

    yield put(putEWalletTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const allEWallets = yield select(getEWallet);
    const payeeName = get(payload, 'payeeName', '');

    // manual get since confirmationDetail api doesnt return payeeCode
    const findEWallet = findArrayEntry(allEWallets, 'payeeName', payeeName);
    const payeeCode = get(findEWallet, 'payeeCode', '');

    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, payeeCode, ...payload };

    const { data } = yield call(getResult, { body });

    yield put(putEWalletResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };

    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    const responseCode = get(data, 'responseCode', '');

    yield put(putEWalletReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferEWallet() {
  yield all([
    takeLatest(TRANSFER_DETAIL, getDetailSaga),
    takeLatest(TRANSFER_CONFIRMATION, getConfirmationSaga),
    takeLatest(REQUEST_TAC, requestTacSaga),
    takeLatest(TRANSFER_RESULT, getResultSaga),
    takeLatest(TRANSFER_RECEIPT, getReceiptSaga),
    takeLatest(TRANSFER_FROM_FAVOURITE_DETAIL, getFavouriteDetailSaga),
  ]);
}
