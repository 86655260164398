import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import { formatKeys } from 'utils/formatter';
import { formatTransferConfirmation } from 'utils/transfer/formatter';

import Instance from 'providers/instance';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { settingsEndpoint as endpoint } from 'providers/endpoints/settings';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putSenderAccountResult,
  putSiDeleteConfirmation,
  putSiDeleteResult,
  putSiDeleteTac,
  putSiMaintenanceList,
} from 'middleware/actions/settings';
import { get } from 'utils/lodash';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';
import { formatSourceAccounts } from 'utils/transaction';

const { SI_MAINTENANCE_LIST, SI_DELETE_CONFIRMATION, SI_REQUEST_TAC, SI_DELETE_RESULT, SI_SENDER_ACCOUNT } = actions.GET.MANAGE_TRANSACTION;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const getSenderAccount = ({ body }) => Instance.post(endpoint.manageTransaction.senderAccount, body);

const getSiMaintenanceList = ({ body }) => Instance.post(endpoint.manageTransaction.siMaintenanceList, body);
const getSiMaintenanceDeleteConfirm = ({ body }) => Instance.post(endpoint.manageTransaction.siMaintenanceDeleteConfirm, body);
const getTacRequest = ({ body }) => Instance.post(endpoint.manageTransaction.executeTacRequest, body);
const getSiMaintenanceDeleteResult = ({ body }) => Instance.post(endpoint.manageTransaction.siMaintenanceDeleteResult, body);

function* getSiMaintenanceListSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getSiMaintenanceList, { body });
    yield put(putSiMaintenanceList(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getSenderAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const userId = get(payload, 'userId', '');

    const body = { userId, locale };

    const { data } = yield call(getSenderAccount, { body });

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const senderAccounts = formatSourceAccounts(get(data, 'fromAccountList', []));

    yield put(
      putSenderAccountResult({
        senderAccounts,
        responseCode,
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getSiMaintenanceDeleteConfirmSaga({ payload }) {
  try {
    const locale = yield select(getLocale);

    const body = { locale, ...payload };

    const { data } = yield call(getSiMaintenanceDeleteConfirm, { body });

    yield put(putSiDeleteConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getTacRequestSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getTacRequest, { body });

    yield put(putSiDeleteTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getSiMaintenanceDeleteResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getSiMaintenanceDeleteResult, { body });

    yield put(putSiDeleteResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Transfer() {
  yield all([
    takeLatest(SI_MAINTENANCE_LIST, getSiMaintenanceListSaga),
    takeLatest(SI_SENDER_ACCOUNT, getSenderAccountSaga),
    takeLatest(SI_DELETE_CONFIRMATION, getSiMaintenanceDeleteConfirmSaga),
    takeLatest(SI_REQUEST_TAC, getTacRequestSaga),
    takeLatest(SI_DELETE_RESULT, getSiMaintenanceDeleteResultSaga),
  ]);
}
