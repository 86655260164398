import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';

import Icon from './Icon';

import './style/index.scss';

const TextLabel = ({ id, label, className, hasIcon, isAssetIcon, tooltipTitle }) => (
  <Grid item container xs={12} alignItems="center" className={className}>
    <InputLabel className="common textlabel" htmlFor={id}>
      {label}
    </InputLabel>
    {hasIcon && (
      <div className={className}>
        <Icon hasTooltip isAssetIcon={isAssetIcon} title={tooltipTitle} />
      </div>
    )}
  </Grid>
);

TextLabel.defaultProps = {
  id: '',
  className: '',
  hasIcon: false,
  isAssetIcon: true,
  tooltipTitle: '',
};

TextLabel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  isAssetIcon: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default TextLabel;
