import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { formatKeys } from 'utils/formatter';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putInterbankTac,
  putInterbankDetail,
  putInterbankResult,
  putInterbankConfirmation,
} from 'middleware/actions/favourite/transfer';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';

import { FAVOURITE_KEYMAP } from 'settings/constants/keymap';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.INTERBANK;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.INTERBANK;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const deleteInterbankFavourite = ({ body }) => Instance.post(endpoint.interbank.favourite.delete, body);
const newFavouriteDetail = ({ body }) => Instance.post(endpoint.interbank.favourite.addNewDetail, body);
const interbankResultFavourite = ({ body }) => Instance.post(endpoint.interbank.favourite.result, body);
const deleteInterbankResult = ({ body }) => Instance.post(endpoint.interbank.favourite.deleteResult, body);
const requestDeleteInterbankTac = ({ body }) => Instance.post(endpoint.interbank.favourite.deleteRequestTac, body);
const interbankConfirmationFavourite = ({ body }) => Instance.post(endpoint.interbank.favourite.confirmation, body);
const interbankAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.interbank.favourite.addNewRequestTac, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.interbank.favourite.addNewDetailFromTransfer, body);
const interbankEditFormDetail = ({ body }) => Instance.post(endpoint.interbank.favourite.editDetail, body);
const interbankEditConfirmDetail = ({ body }) => Instance.post(endpoint.interbank.favourite.editConfirm, body);
const interbankEditTac = ({ body }) => Instance.post(endpoint.interbank.favourite.editRequestTac, body);
const interbankEditResult = ({ body }) => Instance.post(endpoint.interbank.favourite.editResult, body);

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });

    yield put(putInterbankDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });

    yield put(putInterbankDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(interbankConfirmationFavourite, { body });
    yield put(putInterbankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(interbankAddNewRequestTacFavourite, { body });

    yield put(putInterbankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(interbankResultFavourite, { body });

    yield put(putInterbankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteInterbankFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteInterbankFavourite, { body });
    yield put(putInterbankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteInterbankTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteInterbankTac, { body });
    yield put(putInterbankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteInterbankResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteInterbankResult, { body });
    yield put(putInterbankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(interbankEditFormDetail, { body });
    const formattedData = formatKeys(data, FAVOURITE_KEYMAP);
    yield put(putInterbankDetail(formatTransferConfirmation({ ...formattedData, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(interbankEditConfirmDetail, { body });
    yield put(putInterbankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(interbankEditTac, { body });
    yield put(putInterbankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* interbankEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(interbankEditResult, { body });
    yield put(putInterbankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* InterbankFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, interbankResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteInterbankResultSaga),
    takeLatest(REQUEST_TAC, interbankAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, interbankConfirmationFavouriteSaga),
    takeLatest(DELETE_CONFIRMATION, deleteInterbankFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteInterbankTacSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, interbankEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, interbankEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, interbankEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, interbankEditResultFavouriteSaga),
  ]);
}
