export const actions = {
  PUT: {
    CUSTOMER_PROFILE: 'AUTH_PUT_CUSTOMER_PROFILE',
    UPDATE_CUSTOMER_PROFILE: 'AUTH_PUT_UPDATE_CUSTOMER_PROFILE',
    CAPTCHA_IMAGE: 'AUTH_PUT_CAPTCHA_IMAGE',
    WELCOME_MESSAGE: 'WELCOME_MESSAGE',
    RENEW_PASSWORD: 'RENEW_PASSWORD',
  },
};

export const putCustomerProfile = res => ({
  type: actions.PUT.CUSTOMER_PROFILE,
  payload: res,
});

export const putUpdateCustomerProfile = res => ({
  type: actions.PUT.UPDATE_CUSTOMER_PROFILE,
  payload: res,
});

export const putCaptchaImage = imageLink => ({
  type: actions.PUT.CAPTCHA_IMAGE,
  imageLink,
});

export const putWelcomeMessage = welcomeMessage => ({
  type: actions.PUT.WELCOME_MESSAGE,
  welcomeMessage,
});

export const putIsRenewPassword = res => ({
  type: actions.PUT.RENEW_PASSWORD,
  payload: res,
});
