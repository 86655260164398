import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_AUTOCOMPLETE,
  FORMAT_FAVOURITE_NICKNAME,
  FORMAT_BENEFICIARY_MESSAGE,
  FAVOURITE_NICKNAME_MAX_LENGTH,
  FORMAT_ALPHANUMERIC_WITHOUT_SPACE,
} from 'settings/constants/transaction';
import { OTHER_BILL_ACCOUNT_MAX_LENGTH, OTHER_BILL_REFERENCE_NUMBER_MAX_LENGTH } from 'settings/constants/common';

export const OTHER_BILL_FORM_FIELDS = [
  {
    name: 'customerNumber',
    label: 'transaction.payment.otherBill.vaNumber', // default label as Virtual Account
    type: FIELD_TYPE_TEXT,
    format: FORMAT_ALPHANUMERIC_WITHOUT_SPACE,
    maxLength: OTHER_BILL_ACCOUNT_MAX_LENGTH,
    isHidden: true,
  },
  {
    name: 'referenceNumber',
    label: 'transaction.payment.otherBill.billRef',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_ALPHANUMERIC_WITHOUT_SPACE,
    maxLength: OTHER_BILL_REFERENCE_NUMBER_MAX_LENGTH,
    isHidden: true,
  },
  {
    name: 'paymentAmount',
    label: 'transaction.payment.modal.amount',
    type: FIELD_TYPE_TEXT,
    isHidden: true,
  },
  {
    name: 'message',
    label: 'transaction.payment.otherBill.message',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_BENEFICIARY_MESSAGE,
    isHidden: true,
  },
];

export const OTHER_BILL_NEW_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    name: 'billerType',
    label: 'transaction.payment.modal.biller',
    placeholder: 'transaction.payment.placeHolder.pleaseSelect',
    type: FIELD_TYPE_AUTOCOMPLETE,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'payeeType',
    label: 'transaction.payment.modal.payee',
    placeholder: 'transaction.payment.placeHolder.pleaseSelect',
    type: FIELD_TYPE_AUTOCOMPLETE,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    // will be changed once payeetype is selected
    name: 'accountNo',
    label: 'transaction.payment.otherBill.vaNumber', // default label as Virtual Account
    type: FIELD_TYPE_TEXT,
    format: FORMAT_ALPHANUMERIC_WITHOUT_SPACE,
    maxLength: OTHER_BILL_ACCOUNT_MAX_LENGTH,
  },
];

export const OTHER_BILL_EDIT_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'billerName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.biller',
  },
  {
    readOnly: true,
    name: 'payeeName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.payee',
  },
  {
    readOnly: true,
    name: 'accountNo',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.otherBill.vaNumber',
  },
];
