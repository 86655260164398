export const actions = {
  CHANGE: {
    PASSWORD: 'SETTINGS_CHANGE_PASSWORD',
    PERSONAL_INFO: 'SETTINGS_CHANGE_PERSONAL_INFO',
    SECURITY_IMAGE: 'SETTINGS_CHANGE_SECURITY_IMAGES',
    MANAGE_DEVICE: 'SETTING_MANAGE_DEVICE',
  },
  CONFIRM: {
    MANAGE_DEVICE: 'CONFIRM_SETTING_MANAGE_DEVICE',
  },
  GET: {
    SECURITY_IMAGE: {
      TAC: 'SETTINGS_SECURITY_IMAGE_GET_TAC',
      IMAGES: 'SETTINGS_SECURITY_IMAGE_GET_IMAGES',
      RESULT: 'SETTINGS_SECURITY_IMAGE_GET_RESULT',
    },
    THEME: { ACCESS: 'SETTINGS_THEME_GET_ACCESS', RESULT: 'SETTINGS_THEME_GET_RESULT' },
    PERSONAL_INFO: { RESULT: 'SETTINGS_PERSONAL_INFO_GET_RESULT' },
    PASSWORD: { TAC: 'SETTINGS_PASSWORD_GET_TAC', RESULT: 'SETTINGS_PASSWORD_GET_RESULT' },
    MANAGE_TRANSACTION: {
      SI_MAINTENANCE_LIST: 'SETTINGS_MANAGE_TRANSACTION_GET_SI_MAINTENANCE_LIST',
      SI_REQUEST_TAC: 'SETTINGS_MANAGE_TRANSACTION_GET_SI_REQUEST_TAC',
      SI_DELETE_RESULT: 'SETTINGS_MANAGE_TRANSACTION_GET_SI_DELETE_RESULT',
      SI_DELETE_CONFIRMATION: 'SETTINGS_MANAGE_TRANSACTION_GET_SI_DELETE_CONFIRMATION',
      SI_SENDER_ACCOUNT: 'SETTINGS_MANAGE_TRANSACTION_GET_SI_SENDER_ACCOUNT',
      // RECEIVER_ACCOUNT: 'TRANSACTION_GET_RECEIVER_ACCOUNT',
    },
    MANAGE_DEVICE: {
      ACCESS_REMOVE_DEVICE: 'SETTINGS_MANAGE_DEVICE_ACCESS_REMOVE_DEVICE',
      REMOVE_DEVICE_TAC: 'SETTINGS_MANAGE_DEVICE_GET_REMOVE_DEVICE_TAC',
      REMOVE_DEVICE_RESULT: 'SETTINGS_MANAGE_DEVICE_GET_REMOVE_DEVICE_RESULT',
    },
  },
  PUT: {
    SECURITY_IMAGE: {
      TAC: 'SETTINGS_SECURITY_IMAGE_PUT_TAC',
      IMAGES: 'SETTINGS_SECURITY_IMAGE_PUT_IMAGES',
      RESULT: 'SETTINGS_SECURITY_IMAGE_PUT_RESULT',
      ACCESS: 'SETTINGS_SECURITY_IMAGES_PUT_ACCESS',
    },
    THEME: { ACCESS: 'SETTINGS_THEME_PUT_ACCESS', RESULT: 'SETTINGS_THEME_PUT_RESULT' },
    PERSONAL_INFO: { ACCESS: 'SETTINGS_PERSONAL_INFO_PUT_ACCESS', RESULT: 'SETTINGS_PERSONAL_INFO_PUT_RESULT' },
    PASSWORD: { ACCESS: 'SETTINGS_PASSWORD_PUT_ACCESS', TAC: 'SETTINGS_PASSWORD_PUT_TAC', RESULT: 'SETTINGS_PASSWORD_PUT_RESULT' },
    MANAGE_TRANSACTION: {
      SI_MAINTENANCE_LIST: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_MAINTENANCE_LIST',
      SI_REQUEST_TAC: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_REQUEST_TAC',
      SI_DELETE_RESULT: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_DELETE_RESULT',
      SI_DELETE_CONFIRMATION: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_DELETE_CONFIRMATION',
      SI_SENDER_ACCOUNT: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_SENDER_ACCOUNT',
      SI_RESET_ALL: 'SETTINGS_MANAGE_TRANSACTION_PUT_SI_RESET_ALL',
    },
    MANAGE_DEVICE: {
      ACCESS_REMOVE_DEVICE: 'SETTINGS_MANAGE_DEVICE_ACCESS_REMOVE_DEVICE',
      CONFIRM_ACCESS_REMOVE_DEVICE: 'SETTINGS_MANAGE_DEVICE_CONFIRM_REMOVE_DEVICE',
      REMOVE_DEVICE_TAC: 'SETTINGS_MANAGE_DEVICE_PUT_REMOVE_DEVICE_TAC',
      REMOVE_DEVICE_RESULT: 'SETTINGS_MANAGE_DEVICE_PUT_REMOVE_DEVICE_RESULT',
    },
  },
};

// Change password
export const changePassword = payload => ({ type: actions.CHANGE.PASSWORD, payload });
export const getPasswordTac = payload => ({ type: actions.GET.PASSWORD.TAC, payload });
export const getPasswordResult = payload => ({ type: actions.GET.PASSWORD.RESULT, payload });

export const putPasswordTac = res => ({ type: actions.PUT.PASSWORD.TAC, res });
export const putPasswordAccess = res => ({ type: actions.PUT.PASSWORD.ACCESS, res });
export const putPasswordResult = res => ({ type: actions.PUT.PASSWORD.RESULT, res });

// Change theme
export const getThemeAccess = payload => ({ type: actions.GET.THEME.ACCESS, payload });
export const getThemeResult = payload => ({ type: actions.GET.THEME.RESULT, payload });

export const putThemeAccess = res => ({ type: actions.PUT.THEME.ACCESS, res });
export const putThemeResult = res => ({ type: actions.PUT.THEME.RESULT, res });

// Change personal info
export const changePersonalInfo = payload => ({ type: actions.CHANGE.PERSONAL_INFO, payload });
export const getPersonalInfoResult = payload => ({ type: actions.GET.PERSONAL_INFO.RESULT, payload });

export const putPersonalInfoAccess = res => ({ type: actions.PUT.PERSONAL_INFO.ACCESS, res });
export const putPersonalInfoResult = res => ({ type: actions.PUT.PERSONAL_INFO.RESULT, res });

// Change security image
export const changeSecurityImage = payload => ({ type: actions.CHANGE.SECURITY_IMAGE, payload });
export const getSecurityImageTac = payload => ({ type: actions.GET.SECURITY_IMAGE.TAC, payload });
export const getSecurityImages = payload => ({ type: actions.GET.SECURITY_IMAGE.IMAGES, payload });
export const getSecurityImageResult = payload => ({ type: actions.GET.SECURITY_IMAGE.RESULT, payload });

export const putSecurityImageTac = res => ({ type: actions.PUT.SECURITY_IMAGE.TAC, res });
export const putSecurityImages = res => ({ type: actions.PUT.SECURITY_IMAGE.IMAGES, res });
export const putSecurityImageAccess = res => ({ type: actions.PUT.SECURITY_IMAGE.ACCESS, res });
export const putSecurityImageResult = res => ({ type: actions.PUT.SECURITY_IMAGE.RESULT, res });

// Manage Transaction
export const getSiMaintenanceList = payload => ({ type: actions.GET.MANAGE_TRANSACTION.SI_MAINTENANCE_LIST, payload });
export const getSiDeleteTac = body => ({ type: actions.GET.MANAGE_TRANSACTION.SI_REQUEST_TAC, payload: body });
export const getSiDeleteResult = body => ({ type: actions.GET.MANAGE_TRANSACTION.SI_DELETE_RESULT, payload: body });
export const getSiDeleteConfirmation = body => ({ type: actions.GET.MANAGE_TRANSACTION.SI_DELETE_CONFIRMATION, payload: body });
export const getSenderAccount = payload => ({ type: actions.GET.MANAGE_TRANSACTION.SI_SENDER_ACCOUNT, payload });

export const putSiMaintenanceList = res => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_MAINTENANCE_LIST, res });
export const putSiDeleteTac = res => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_REQUEST_TAC, payload: res });
export const putSiDeleteResult = res => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_DELETE_RESULT, payload: res });
export const putSiDeleteConfirmation = res => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_DELETE_CONFIRMATION, payload: res });
export const putSenderAccountResult = res => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_SENDER_ACCOUNT, res });
export const putSiResetAll = () => ({ type: actions.PUT.MANAGE_TRANSACTION.SI_RESET_ALL });

// SENDER & RECEIVER ACCOUNT
// export const getReceiverAccount = payload => ({ type: actions.GET.RECEIVER_ACCOUNT, payload });

// Manage Device
export const getAccessRemoveDevice = payload => ({ type: actions.CHANGE.MANAGE_DEVICE, payload });
export const confirmRemoveDevice = payload => ({ type: actions.CONFIRM.MANAGE_DEVICE, payload });
export const getRemoveDeviceTac = payload => ({ type: actions.GET.MANAGE_DEVICE.REMOVE_DEVICE_TAC, payload });
export const getRemoveDeviceResult = payload => ({ type: actions.GET.MANAGE_DEVICE.REMOVE_DEVICE_RESULT, payload });

export const putAccessRemoveDevice = res => ({ type: actions.PUT.MANAGE_DEVICE.ACCESS_REMOVE_DEVICE, res });
export const putConfirmRemoveDevice = res => ({ type: actions.PUT.MANAGE_DEVICE.CONFIRM_ACCESS_REMOVE_DEVICE, res });
export const putRemoveDeviceTac = res => ({ type: actions.PUT.MANAGE_DEVICE.REMOVE_DEVICE_TAC, res });
export const putRemoveDeviceResult = res => ({ type: actions.PUT.MANAGE_DEVICE.REMOVE_DEVICE_RESULT, res });
