export const logout = {
  header: {
    personal: 'Personal',
    business: 'Business',
    login: 'LOGIN',
    table: {
      date: 'Date',
      activity: 'Activity Details',
      amount: 'Amount',
      status: 'Status & Reference',
      print: 'Print',
    },
  },
  message: {
    logoutSuccess: 'You have logged out. {br} Thank you for using M2U ID Web',
    logoutTransaction: 'Thank you for using maybank2u for your financial transaction',
    multiSession:
      'You have logged out. {br} {br} You are currently doing a login session simultaneously on another device. {br} For safety and the convenience of your transaction, we will end this session.',
    activity: 'You do not have any activities for today.',
    summaryMessage: 'Here is a summary of your transactions: ',
  },
};

export const sessionExpired = {
  header: {
    personal: 'Personal',
    business: 'Business',
  },
  message: {
    expired: 'Your session has expired! Your browser was idle for more than 5 minutes {br} Please login again to M2U ID Web',
  },
};
