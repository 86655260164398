import { useReducer } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { BasicButton } from 'components/common/buttons';

import { isLengthValid } from 'utils/validation';
import { isValidSwiftCode } from 'utils/transfer/swift/validation';

import { DEFAULT_INT_ZERO, INT_TWO } from 'settings/constants/common';
import { BANK_NAME_MIN_LENGTH, NO_OPTION_GRID_SIZE, SWIFT_SEARCH_GRID_SIZE } from 'settings/constants/transaction';

import SwiftTextfields from './Textfields';

const SwiftSearchSection = ({ onSearchBank, hasModalStyle }) => {
  const { formatMessage } = useIntl();

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), { bankName: '', bankSwiftCode: '' });

  const { bankName, bankSwiftCode } = state;

  const isSearchDisabled = !isLengthValid(bankName, BANK_NAME_MIN_LENGTH) && !isValidSwiftCode(bankSwiftCode);

  return (
    <Grid container spacing={hasModalStyle ? DEFAULT_INT_ZERO : INT_TWO} direction={hasModalStyle ? 'row' : 'row-reverse'}>
      <Grid item xs={NO_OPTION_GRID_SIZE} md={hasModalStyle ? NO_OPTION_GRID_SIZE : SWIFT_SEARCH_GRID_SIZE}>
        <SwiftTextfields bankName={bankName} bankSwiftCode={bankSwiftCode} onChange={setState} />
        <div className={hasModalStyle ? 'mt3 tr' : 'w-100 mt3 tr'}>
          <BasicButton
            title={formatMessage({ id: 'buttonText.search' })}
            onClick={() => onSearchBank({ bankName, bankSwiftCode })}
            disabled={isSearchDisabled}
          />
        </div>
      </Grid>
    </Grid>
  );
};

SwiftSearchSection.defaultProps = { hasModalStyle: false };

SwiftSearchSection.propTypes = { hasModalStyle: PropTypes.bool, onSearchBank: PropTypes.func.isRequired };

export default SwiftSearchSection;
