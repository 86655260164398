import PropTypes from 'prop-types';

import { Card as MaterialCard, Typography, CardContent } from '@material-ui/core';

import Icon from '../Icon';
import Link from '../Link';

import './style.scss';

const IconLabelCard = ({ label, isAssetIcon, icon, onClick }) => (
  <MaterialCard className="card card--icon-label" onClick={onClick}>
    <CardContent className="content">
      <div className="content--icon mb3">
        <Icon isAssetIcon={isAssetIcon} type={icon} />
      </div>

      <Typography variant="h6">{label}</Typography>

      <Link hasRightIcon label="more info" icon="arrowForward" className="content--link" />
    </CardContent>
  </MaterialCard>
);

IconLabelCard.defaultProps = { label: '', icon: '', isAssetIcon: false, onClick: null };

IconLabelCard.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isAssetIcon: PropTypes.bool,
};

export default IconLabelCard;
