import { actions } from 'middleware/actions/favourite/transfer';

const {
  RESET_FAVOURITE,
  RESET_ADD_FAVOURITE,
  FAVOURITE_ACCOUNT,
  MAINTENANCES,
  INTRABANK,
  VIRTUAL_ACCOUNT,
  SKN,
  RTGS,
  EWALLET,
  INTERBANK,
  SWIFT,
  RESET_TAC,
} = actions.PUT;

const initialState = {
  favouriteAccount: {},
  maintenanceTransfers: {},
  intrabankFormDetail: {},
  virtualAccountFavouriteDetail: {},
  sknFavouriteDetail: {},
  rtgsFavouriteDetail: {},
  eWalletFavouriteDetail: {},
  interbankFavouriteDetail: {},
  intrabankEditDetail: {},
  intrabankConfirmation: {},
  intrabankTac: {},
  intrabankResult: {},
  virtualAccountConfirmation: {},
  virtualAccountTac: {},
  virtualAccountResult: {},
  sknConfirmation: {},
  sknTac: {},
  sknResult: {},
  rtgsConfirmation: {},
  rtgsTac: {},
  rtgsResult: {},
  eWalletConfirmation: {},
  eWalletTac: {},
  eWalletResult: {},
  interbankConfirmation: {},
  interbankTac: {},
  interbankResult: {},
  swiftSearchResult: {},
  swiftFavouriteDetail: {},
  swiftConfirmation: {},
  swiftTac: {},
  swiftResult: {},
};

const addNewInitialState = {
  maintenanceTransfers: {},
  intrabankFormDetail: {},
  virtualAccountFavouriteDetail: {},
  sknFavouriteDetail: {},
  rtgsFavouriteDetail: {},
  eWalletFavouriteDetail: {},
  interbankFavouriteDetail: {},
  swiftSearchResult: {},
  swiftFavouriteDetail: {},
  rtgsConfirmation: {},
  intrabankConfirmation: {},
  virtualAccountConfirmation: {},
  sknConfirmation: {},
  eWalletConfirmation: {},
  interbankConfirmation: {},
  swiftConfirmation: {},
};

const initialTac = {
  intrabankTac: {},
  virtualAccountTac: {},
  sknTac: {},
  rtgsTac: {},
  eWalletTac: {},
  interbankTac: {},
  swiftTac: {},
};

const FavouriteTransferReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_FAVOURITE:
      return { ...state, ...initialState };
    // Reset transfer types and transfer details
    case RESET_ADD_FAVOURITE:
      return { ...state, ...addNewInitialState };
    case RESET_TAC:
      return { ...state, ...initialTac };
    case FAVOURITE_ACCOUNT:
      return { ...state, favouriteAccount: action.res };
    case MAINTENANCES:
      return { ...state, maintenanceTransfers: action.res };
    case INTRABANK.DETAIL:
      return { ...state, intrabankFormDetail: action.res };
    case INTRABANK.CONFIRMATION:
      return { ...state, intrabankConfirmation: action.res };
    case INTRABANK.TAC:
      return { ...state, intrabankTac: action.res };
    case INTRABANK.RESULT:
      return { ...state, intrabankResult: action.res };
    case VIRTUAL_ACCOUNT.DETAIL:
      return { ...state, virtualAccountFavouriteDetail: action.res };
    case VIRTUAL_ACCOUNT.CONFIRMATION:
      return { ...state, virtualAccountConfirmation: action.res };
    case VIRTUAL_ACCOUNT.TAC:
      return { ...state, virtualAccountTac: action.res };
    case VIRTUAL_ACCOUNT.RESULT:
      return { ...state, virtualAccountResult: action.res };
    case SKN.DETAIL:
      return { ...state, sknFavouriteDetail: action.res };
    case SKN.CONFIRMATION:
      return { ...state, sknConfirmation: action.res };
    case SKN.TAC:
      return { ...state, sknTac: action.res };
    case SKN.RESULT:
      return { ...state, sknResult: action.res };
    case RTGS.DETAIL:
      return { ...state, rtgsFavouriteDetail: action.res };
    case RTGS.CONFIRMATION:
      return { ...state, rtgsConfirmation: action.res };
    case RTGS.TAC:
      return { ...state, rtgsTac: action.res };
    case RTGS.RESULT:
      return { ...state, rtgsResult: action.res };
    case EWALLET.DETAIL:
      return { ...state, eWalletFavouriteDetail: action.res };
    case EWALLET.CONFIRMATION:
      return { ...state, eWalletConfirmation: action.res };
    case EWALLET.TAC:
      return { ...state, eWalletTac: action.res };
    case EWALLET.RESULT:
      return { ...state, eWalletResult: action.res };
    case INTERBANK.DETAIL:
      return { ...state, interbankFavouriteDetail: action.res };
    case INTERBANK.CONFIRMATION:
      return { ...state, interbankConfirmation: action.res };
    case INTERBANK.TAC:
      return { ...state, interbankTac: action.res };
    case INTERBANK.RESULT:
      return { ...state, interbankResult: action.res };
    case SWIFT.SEARCH_RESULT:
      return { ...state, swiftSearchResult: action.res };
    case SWIFT.DETAIL:
      return { ...state, swiftFavouriteDetail: action.res };
    case SWIFT.CONFIRMATION:
      return { ...state, swiftConfirmation: action.res };
    case SWIFT.TAC:
      return { ...state, swiftTac: action.res };
    case SWIFT.RESULT:
      return { ...state, swiftResult: action.res };
    default:
      return state;
  }
};

export default FavouriteTransferReducer;
