export const settingsEndpoint = {
  password: {
    change: 'settings/v1/password/update',
    result: 'settings/v1/password/confirm',
    requestTac: 'notification/v1/tac/settings/password/request',
  },
  personalInfo: {
    change: 'settings/v1/info/personal',
    result: 'settings/v1/info/personal/confirm',
  },
  theme: {
    access: 'settings/v1/theme/details',
    result: 'settings/v1/theme/confirm',
  },
  securityImage: {
    change: 'settings/v1/security/image/details',
    result: 'settings/v1/security/image/confirm',
    getImages: 'settings/v1/security/image/list',
    requestTac: 'notification/v1/tac/settings/security/image/request',
  },
  manageTransaction: {
    siMaintenanceList: 'settings/v1/managetxn/si',
    siMaintenanceDeleteConfirm: 'settings/v1/managetxn/si/delete/initiate',
    executeTacRequest: 'notification/v1/tac/settings/managetxn/si/request',
    siMaintenanceDeleteResult: 'settings/v1/managetxn/si/delete/confirm',
    senderAccount: 'transfer/v1/funds/source/accounts',
  },
  manageDevice: {
    accessRemoveDevice: 'v2/settings/removeDevice',
    confirmRemoveDevice: '/v2/settings/removeDeviceConfirm',
    requestTac: '/v2/commonServices/executeTacRequest',
    result: '/v2/settings/removeDeviceResult',
  },
};
