import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatArrayKeys } from 'utils/formatter';
import { putErrorResponse } from 'middleware/actions/error';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { formatFavouriteAccountResult } from 'utils/favourite/transfer/formatter';
import { actions, putFavouriteAccount, putMaintenanceTransfers } from 'middleware/actions/favourite/transfer';

const getClientIP = () => remoteIpInstance.get();

const getMaintenanceTransfers = ({ body }) => Instance.post(endpoint.get.maintenances, body);
const getFavouriteAccount = ({ body }) => Instance.post(endpoint.get.favouriteAccount, body);

const getLocale = state => state.LanguageReducer.locale;

const { FAVOURITE_ACCOUNT, MAINTENANCES } = actions.GET;

function* getFavouriteAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getFavouriteAccount, { body });
    yield put(putFavouriteAccount(formatFavouriteAccountResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMaintenanceTransfersSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getMaintenanceTransfers, { body });
    const initialMaps = get(data, 'transferToMap', []);
    yield put(putMaintenanceTransfers(formatArrayKeys(initialMaps)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferFavourite() {
  yield all([takeLatest(FAVOURITE_ACCOUNT, getFavouriteAccountSaga), takeLatest(MAINTENANCES, getMaintenanceTransfersSaga)]);
}
