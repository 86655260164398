import { actions } from 'middleware/actions/favourite/purchase';

const { RESET_FAVOURITE, MAINTENANCE_LIST, FAVOURITE_ACCOUNT, MOBILE_RELOAD, PLN, RESET_TAC, RESET_ADD_FAVOURITE } = actions.PUT;

const initialState = {
  maintenancePurchase: {},
  favouriteAccount: {},
  mobileReloadDetail: {},
  mobileReloadConfirm: {},
  mobileReloadTac: {},
  mobileReloadResult: {},
  plnDetail: {},
  plnConfirm: {},
  plnTac: {},
  plnResult: {},
};

const addNewInitialState = {
  mobileReloadDetail: {},
  mobileReloadConfirm: {},
  plnDetail: {},
  plnConfirm: {},
};

const initialTac = {
  plnTac: {},
  mobileReloadTac: {},
};

const FavouritePurchaseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_FAVOURITE:
      return { ...state, ...initialState };
    case RESET_TAC:
      return { ...state, ...initialTac };
    case RESET_ADD_FAVOURITE:
      return { ...state, ...addNewInitialState };
    case FAVOURITE_ACCOUNT:
      return { ...state, favouriteAccount: action.res };
    case MAINTENANCE_LIST:
      return { ...state, maintenancePurchase: action.res };
    case MOBILE_RELOAD.FAVOURITE_ACTION.DETAIL:
      return { ...state, mobileReloadDetail: action.res };
    case MOBILE_RELOAD.FAVOURITE_ACTION.CONFIRM:
      return { ...state, mobileReloadConfirm: action.res };
    case MOBILE_RELOAD.FAVOURITE_ACTION.TAC:
      return { ...state, mobileReloadTac: action.res };
    case MOBILE_RELOAD.FAVOURITE_ACTION.RESULT:
      return { ...state, mobileReloadResult: action.res };
    case PLN.FAVOURITE_ACTION.DETAIL:
      return { ...state, plnDetail: action.res };
    case PLN.FAVOURITE_ACTION.CONFIRM:
      return { ...state, plnConfirm: action.res };
    case PLN.FAVOURITE_ACTION.TAC:
      return { ...state, plnTac: action.res };
    case PLN.FAVOURITE_ACTION.RESULT:
      return { ...state, plnResult: action.res };
    default:
      return state;
  }
};

export default FavouritePurchaseReducer;
