import { useIntl } from 'react-intl';
import { useState } from 'react';

import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { checkFormFormat, validate, validResult } from 'utils/form-validation';

import { DEFAULT_TEXTFIELD_MAX_CHARS, FIELD_TYPE_TEXT, FIELD_TYPE_PASSWORD } from 'settings/constants/transaction';
import { GRID_SIZE_HORIZONTAL, GRID_SIZE_VERTICAL, GRID_VARIANT_DEFAULT } from 'settings/constants/ui-control';

import { Textfield, TextLabel } from 'components/common';

const DynoPasswordField = ({
  value,
  name,
  label,
  variant,
  readOnly,
  rightLabel,
  hasRightLabel,
  validationRules,
  onChange,
  getValue,
  hasPeekButton,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const [isPeekOpen, setPeek] = useState(false);
  const [validationResult, setValidationResult] = useState(validResult);

  const className = get(props, 'className', '');
  const leftLabel = get(props, 'leftLabel', '');
  const bottomLabel = get(props, 'bottomLabel', '');
  const isHeader = get(props, 'isHeader', false);
  const format = get(props, 'format', false);
  const placeholderId = get(props, 'placeholder', '');
  const isThinPadding = get(props, 'isThinPadding', false);

  const readOnlyClass = readOnly ? 'readonly' : '';
  const textFieldClass = `textfield--password ${className} ${readOnlyClass}`;
  const headerClassname = isHeader ? 'pv3 ph4 bg-light-gray' : `pb0 pt3 ${isThinPadding ? 'ph3' : 'ph4'}`;

  const validField = getValue('validField', {});
  const maxLength = get(props, 'maxLength', DEFAULT_TEXTFIELD_MAX_CHARS);

  const hasTooltip = get(props, 'hasToolTip', false);
  const tooltipTitleID = get(props, 'toolTipTitle', '');
  const toolTipTitle = hasTooltip ? formatMessage({ id: tooltipTitleID }) : '';

  const handleOnBlur = event => {
    const { isValid, messageId, param } = validate(validationRules, event);
    setValidationResult({ isValid, messageId, param });
    onChange({ validField: { ...validField, [name]: isValid } });
  };

  const { isValid, messageId, param } = validationResult;
  const errorMessage = !readOnly && !isValid && formatMessage({ id: messageId, defaultMessage: messageId }, param);
  const placeholder = placeholderId ? formatMessage({ id: placeholderId }) : '';

  const gridSize = variant === GRID_VARIANT_DEFAULT ? GRID_SIZE_VERTICAL : GRID_SIZE_HORIZONTAL;

  return (
    <Grid container alignItems="center" className={headerClassname} spacing={2}>
      <Grid item xs={gridSize.label.xs} sm={gridSize.label.sm} className="password-label">
        {label && (
          <TextLabel label={formatMessage({ id: label, defaultMessage: label })} hasIcon={hasTooltip} tooltipTitle={toolTipTitle} />
        )}
      </Grid>
      <Grid item sm={gridSize.input.sm} md={gridSize.input.sm} className="w-100">
        <Textfield
          id={name}
          name={name}
          value={value}
          type={isPeekOpen ? FIELD_TYPE_TEXT : FIELD_TYPE_PASSWORD}
          readOnly={readOnly}
          hasError={!isValid && !readOnly}
          errorMessage={errorMessage}
          rightLabel={rightLabel}
          hasRightLabel={hasRightLabel}
          hasLeftLabel={!isEmpty(leftLabel)}
          leftLabel={leftLabel}
          className={textFieldClass}
          onBlur={e => handleOnBlur(e)}
          onPaste={e => e.preventDefault()}
          placeholder={placeholder}
          maxLength={maxLength}
          hasRightIcon={hasPeekButton && !readOnly}
          rightIcon={isPeekOpen ? 'eyeClose' : 'eyeOpen'}
          isRightAsset
          onIconClick={() => setPeek(!isPeekOpen)}
          onChange={e => onChange({ [name]: checkFormFormat(e.target.value, format) })}
        />
        {!isEmpty(bottomLabel) && <TextLabel label={formatMessage({ id: bottomLabel })} className="pt2" />}
      </Grid>
    </Grid>
  );
};

DynoPasswordField.defaultProps = {
  value: '',
  label: '',
  rightLabel: '',
  readOnly: false,
  hasRightLabel: false,
  validationRules: [],
  variant: GRID_VARIANT_DEFAULT,
  hasPeekButton: true,
};

DynoPasswordField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  rightLabel: PropTypes.string,
  hasRightLabel: PropTypes.bool,
  hasPeekButton: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  validationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

export default DynoPasswordField;
