export const actions = {
  PUT: {
    LOCALE: 'LANGUAGE_PUT_LOCALE',
  },
};

export const putLocale = locale => ({
  type: actions.PUT.LOCALE,
  locale,
});
