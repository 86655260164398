import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import NumberFormat from 'react-number-format';

import { memo } from 'utils/react';
import { isLeadingDot, isLeadingZero, isLeadingZeroValid } from 'utils/validation';

import './style/index.scss';
import { DEFAULT_LEADING_ZERO_REGEX } from 'settings/constants/ui-control';

const NumberField = ({
  id,
  name,
  value,
  prefix,
  onBlur,
  format,
  onChange,
  readOnly,
  disabled,
  className,
  leftLabel,
  maxLength,
  placeholder,
  defaultValue,
  errorMessage,
  decimalScale,
  allowNegative,
  allowLeadingZero,
  leadingZeroRegex,
  thousandSeparator,
  allowEmptyFormatting,
}) => {
  const borderStyle = !isEmpty(errorMessage) ? 'error' : '';

  // Disable/enable leading zero for input. example: 012345666
  const handleLeadingZero = number => (allowLeadingZero ? isLeadingZeroValid(number, leadingZeroRegex) : !isLeadingZero(number));

  // isEmpty were to allow clear all value
  // isLeading dot to disallow `.` as first value
  const isValid = number => !isLeadingDot(number) && (handleLeadingZero(number) || isEmpty(number));

  return (
    <div className="common number--container">
      <div className={`common number ${className} ${borderStyle}`}>
        <>
          {!isEmpty(leftLabel) && <span className="left--label">{leftLabel}</span>}
          <NumberFormat
            id={id}
            name={name}
            value={value}
            defaultValue={defaultValue}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            allowLeadingZeros={allowLeadingZero}
            allowNegative={allowNegative}
            onValueChange={values => onChange(values.value)}
            isAllowed={values => isValid(values.value)}
            placeholder={placeholder}
            onBlur={onBlur}
            prefix={prefix}
            allowEmptyFormatting={allowEmptyFormatting}
            maxLength={maxLength}
            readOnly={readOnly}
            disabled={disabled}
            format={format}
          />
        </>
      </div>

      {!isEmpty(errorMessage) && <p className="inline--error">{errorMessage}</p>}
    </div>
  );
};

NumberField.defaultProps = {
  id: '',
  name: '',
  value: '',
  prefix: '',
  onBlur: null,
  format: null, // cant use '', package will clear value input
  className: '',
  leftLabel: '',
  onChange: null,
  placeholder: '',
  decimalScale: 0,
  maxLength: null,
  readOnly: false,
  disabled: false,
  errorMessage: '',
  defaultValue: '',
  allowNegative: false,
  allowLeadingZero: false,
  thousandSeparator: false,
  allowEmptyFormatting: false,
  leadingZeroRegex: DEFAULT_LEADING_ZERO_REGEX,
};

NumberField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  leftLabel: PropTypes.string,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.node,
  allowNegative: PropTypes.bool,
  placeholder: PropTypes.string,
  decimalScale: PropTypes.number,
  allowLeadingZero: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leadingZeroRegex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(NumberField);
