import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { putErrorResponse } from 'middleware/actions/error';
import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';
import {
  actions,
  putPlnFavouriteConfirm,
  putPlnFavouriteDetail,
  putPlnFavouriteResult,
  putPlnFavouriteTac,
} from 'middleware/actions/favourite/purchase';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';
import { putPlnReloadDetail } from 'middleware/actions/purchase';

const getClientIP = () => remoteIpInstance.get();

const newFavouriteConfirmation = ({ body }) => Instance.post(endpoint.favourite.add.getConfirmation, body);
const newFavouriteAfterPurchaseDetail = ({ body }) => Instance.post(endpoint.favourite.add.getDetailFromPurchase, body);
const newFavouriteTac = ({ body }) => Instance.post(endpoint.favourite.add.getTac, body);
const newFavouriteResult = ({ body }) => Instance.post(endpoint.favourite.add.getResult, body);

const getEditDetail = ({ body }) => Instance.post(endpoint.favourite.edit.getDetail, body);
const getEditConfirmation = ({ body }) => Instance.post(endpoint.favourite.edit.getConfirmation, body);
const getEditTac = ({ body }) => Instance.post(endpoint.favourite.delete.getTac, body);
const getEditResult = ({ body }) => Instance.post(endpoint.favourite.edit.getResult, body);

const getDeleteConfirm = ({ body }) => Instance.post(endpoint.favourite.delete.getConfirmation, body);
const getDeleteTac = ({ body }) => Instance.post(endpoint.favourite.delete.getTac, body);
const getDeleteResult = ({ body }) => Instance.post(endpoint.favourite.delete.getResult, body);
const getPurchaseDetail = ({ body }) => Instance.post(endpoint.favourite.purchase.getPlnDetail, body);

const getLocale = state => state.LanguageReducer.locale;

const { CONFIRM: DELETE_CONFIRM, TAC: DELETE_TAC, RESULT: DELETE_RESULT } = actions.GET.PLN.DELETE_FAVOURITE;
const { DETAIL: EDIT_DETAIL, CONFIRM: EDIT_CONFIRM, TAC: EDIT_TAC, RESULT: EDIT_RESULT } = actions.GET.PLN.EDIT_FAVOURITE;
const { DETAIL: ADD_DETAIL, DETAIL_FROM_PURCHASE, CONFIRM: ADD_CONFIRM, TAC: ADD_TAC, RESULT: ADD_RESULT } = actions.GET.PLN.ADD_FAVOURITE;
const { DETAIL: PURCHASE_DETAIL } = actions.GET.PLN.PURCHASE_FROM_FAVOURITE;

function* getFavouritePlnDetailAfterPurchaseSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteAfterPurchaseDetail, { body });
    yield put(putPlnFavouriteDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPurchaseDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(getPurchaseDetail, { body });
    yield put(putPlnReloadDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonDetailFavouriteSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_DETAIL]: () => '',
      [EDIT_DETAIL]: () => getEditDetail,
      default: () => null,
    };

    const detailEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(detailEndpoint, { body });
    yield put(putPlnFavouriteDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonConfirmationFavouriteSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_CONFIRM]: () => newFavouriteConfirmation,
      [DELETE_CONFIRM]: () => getDeleteConfirm,
      [EDIT_CONFIRM]: () => getEditConfirmation,
      default: () => null,
    };

    const confirmEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(confirmEndpoint, { body });
    yield put(putPlnFavouriteConfirm(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonTacFavouriteSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_TAC]: () => newFavouriteTac,
      [DELETE_TAC]: () => getDeleteTac,
      [EDIT_TAC]: () => getEditTac,
      default: () => null,
    };

    const tacEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(tacEndpoint, { body });
    yield put(putPlnFavouriteTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonResultFavouriteSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_RESULT]: () => newFavouriteResult,
      [DELETE_RESULT]: () => getDeleteResult,
      [EDIT_RESULT]: () => getEditResult,
      default: () => null,
    };

    const resultEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(resultEndpoint, { body });
    yield put(putPlnFavouriteResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PlnFavourite() {
  yield all([
    takeLatest(DETAIL_FROM_PURCHASE, getFavouritePlnDetailAfterPurchaseSaga),
    takeLatest(ADD_CONFIRM, getCommonConfirmationFavouriteSaga),
    takeLatest(ADD_TAC, getCommonTacFavouriteSaga),
    takeLatest(ADD_RESULT, getCommonResultFavouriteSaga),
    takeLatest(EDIT_DETAIL, getCommonDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRM, getCommonConfirmationFavouriteSaga),
    takeLatest(EDIT_TAC, getCommonTacFavouriteSaga),
    takeLatest(EDIT_RESULT, getCommonResultFavouriteSaga),
    takeLatest(DELETE_CONFIRM, getCommonConfirmationFavouriteSaga),
    takeLatest(DELETE_TAC, getCommonTacFavouriteSaga),
    takeLatest(DELETE_RESULT, getCommonResultFavouriteSaga),
    takeLatest(PURCHASE_DETAIL, getPurchaseDetailSaga),
  ]);
}
