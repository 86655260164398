export const selfUnlock = {
  accountNumber: 'Nomor Rekening',
  creditCard: 'Nomor Kartu Kredit',
  modal: {
    title: 'Atur ulang/unlock akun Anda',
    footerButton: 'Lanjut',
    backButton: 'Kembali',
    header: {
      title: 'Tidak Dapat Mengingat Detail Login Anda?',
      subtitle: 'Izinkan kami membantu anda!',
      description:
        'Atur ulang/Unlock detil Anda menggunakan nomor rekening/nomor kartu kredit yang anda gunakan untuk registrasi Maybank2u',
      activityDescription: 'Silahkan pilih satu aktivitas yang akan anda lakukan',
      resetPasswordDescription: 'Silahkan masukkan kata sandi baru anda',
    },
    container: {
      resetUsing: 'Atur ulang/Unlock menggunakan',
      account: {
        accountNumberTooltip: 'Nomor Rekening adalah nomor rekening Maybank anda',
        accountNumberPlaceholder: 'Contoh: 1122334455',
        pin: 'PIN',
        pinTooltip: 'PIN adalah PIN Kartu Debet anda',
        pinPlaceholder: 'Masukkan 6-digit nomor PIN',
      },
      cc: {
        ccNumber: 'Nomor Kartu Kredit',
        ccNumberTooltip: 'Nomor Kartu Kredit adalah nomor Kartu Kredit Maybank anda',
        ccNumberPlaceholder: 'Contoh: 1111222233334444',
        pin: 'PIN',
        pinTooltip: 'PIN adalah PIN Kartu Kredit anda',
        pinPlaceholder: 'Masukkan nomor PIN 6 digit',
        ccExpiry: 'Tanggal Kadaluwarsa Kartu Kredit',
        mm: 'BB',
        yy: 'TT',
      },
      resetPassword: {
        password: 'Kata Sandi',
        confirmPassword: 'Konfirmasi Password',
        passwordPlaceholder: 'Masukkan Kata Sandi anda',
        confirmPasswordPlaceholder: 'Masukkan kembali Kata Sandi anda',
        confirmPasswordTooltip: 'Harus sama dengan Kata Sandi',
        passwordTooltip:
          'Harus terdapat huruf dan angka. Panjang maksimal terdiri dari 8 sampai 20 karakter, huruf kapital dan huruf kecil',
      },
    },
    unlock: {
      status: 'Status',
      action: 'Aktivitas',
      normal: 'Normal dan dapat digunakan.',
      locked: 'Terkunci',
      disabled: 'Dinonaktifkan',
      disabledWording: 'Mohon hubungi {contactNo} atau kunjungi cabang Maybank terdekat',
      unlockUserIdOrTac: 'Buka User ID/TAC',
      forgotUserId: 'Lupa User ID',
      resetPassword: 'Atur ulang Kata Sandi',
    },
  },
  errorMessage: {
    accountLength: 'Nomor Akun harus 10 digit',
    ccLength: 'Nomor Kartu Kredit harus 16 digit',
    ccMmLength: 'Bulan Kedaluwarsa Kartu Kredit harus 2 digit',
    ccYyLength: 'Tahun Berakhirnya Kartu Kredit harus 2 digit',
    pinLength: 'PIN harus 6 digit',
    currency: 'Silakan pilih mata uang',
    noPassword: 'Masukkan kata sandi baru anda',
    passwordNotMatch: 'Kata Sandi dan Kata Sandi Konfirmasi tidak cocok',
    invalidPassword: 'Harus mengandung Alfa Numerik. Panjang maksimum antara 8 hingga 20 karakter. Huruf Besar Kecil.',
    tacInvalid: 'TAC tidak valid',
    tacExceedMaxTries:
      'TAC yang Anda masukkan salah. User ID Anda dalam keadaan terkunci. Silakan akses "Mengalami Kendala Saat Masuk" di halaman utama.',
    tacExpired: 'TAC kedaluwarsa',
    tacNotGenerate: 'TAC tidak menghasilkan',
    tacExceedMaxUse: 'TAC melebihi penggunaan maksimum',
  },
  resetPassword: {
    tacTitle: 'Mohon konfirmasi detail instruksi anda.',
    tacDescription: 'Untuk melanjutkan instruksi ini, silakan minta dan masukkan nomor TAC Anda.',
    transaction: {
      successful: 'Permintaan Anda berhasil!',
      failed: 'Permintaan Anda gagal!',
    },
  },
};
