import { Children, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';

import { isEmpty } from 'lodash';
import Icon from '../Icon';

const YearPicker = ({ date, years, onChange, hasYearList, hasMonthList, localeUtils }) => {
  const { formatMessage } = useIntl();
  const [isYearOpen, setYearOpen] = useState(false);
  const [isMonthOpen, setMonthOpen] = useState(false);

  const months = localeUtils.getMonths();
  const currentYear = moment(date).year();
  const currentMonth = moment(date).month();
  const monthName = formatMessage({ id: `calendar.months.${currentMonth}` });
  const className = isYearOpen ? 'opened' : '';
  const selectedYear = document.getElementsByClassName('tc year selected');

  const toggleYear = () => {
    if (!hasYearList) return;
    setYearOpen(!isYearOpen);
    setMonthOpen(false);
  };

  const toggleMonth = () => {
    if (!hasMonthList) return;
    setMonthOpen(!isMonthOpen);
    setYearOpen(false);
  };

  // auto scroll to selected year when select year picker
  useEffect(() => {
    if (!isEmpty(selectedYear)) {
      selectedYear[0].scrollIntoView();
    }
  }, [selectedYear]);

  return (
    <div className={`DayPicker-Caption ${className}`}>
      {hasMonthList && !isYearOpen && (
        <button type="button" className="btn-picker" onClick={toggleMonth}>
          <span>{monthName}</span>
          {hasMonthList && <Icon isAssetIcon={false} type={isMonthOpen ? 'expandLess' : 'expandMore'} />}
        </button>
      )}
      {hasYearList && !hasMonthList ? (
        <button type="button" className="btn-picker" onClick={toggleYear}>
          <span>{monthName}</span>
          <span>{currentYear}</span>
          {hasYearList && <Icon isAssetIcon={false} type={isYearOpen ? 'expandLess' : 'expandMore'} />}
        </button>
      ) : (
        <>
          {!isMonthOpen && (
            <button type="button" className="btn-picker" onClick={toggleYear}>
              {!hasMonthList && <span>{monthName}</span>}
              <span>{currentYear}</span>
              {hasYearList && <Icon isAssetIcon={false} type={isYearOpen ? 'expandLess' : 'expandMore'} />}
            </button>
          )}
        </>
      )}

      <Collapse in={isYearOpen}>
        <Grid container className="year-picker">
          {hasYearList &&
            Children.toArray(
              years.map(year => {
                const selected = year === String(currentYear) ? 'selected' : '';
                return (
                  <Grid item xs={4} className={`tc year ${selected}`} onClick={() => onChange(year, currentMonth)}>
                    <button type="button">{year}</button>
                  </Grid>
                );
              })
            )}
        </Grid>
      </Collapse>
      <Collapse in={isMonthOpen}>
        <Grid container className="month-picker">
          {hasMonthList &&
            Children.toArray(
              months.map((month, index) => {
                const selected = index === currentMonth ? 'selected' : '';
                return (
                  <Grid item xs={4} className={`tc month ${selected}`} onClick={() => onChange(currentYear, index)}>
                    <button type="button">{month}</button>
                  </Grid>
                );
              })
            )}
        </Grid>
      </Collapse>
    </div>
  );
};

YearPicker.defaultProps = {
  hasMonthList: false,
};

YearPicker.propTypes = {
  years: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  hasMonthList: PropTypes.bool,
  hasYearList: PropTypes.bool.isRequired,
  localeUtils: PropTypes.object.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default YearPicker;
