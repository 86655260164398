import PropTypes from 'prop-types';

import { Card as MaterialCard, CardActions, CardContent, Typography, Paper } from '@material-ui/core';

import Icon from '../Icon';
import Link from '../Link';
import BasicButton from '../buttons/BasicButton';

import './style.scss';

const BannerInfoCard = ({ title, desc, buttonTitle, icon, hasLink, hasIcon, hasFooterButton, onClick, onLinkClick }) => (
  <Paper className="card card--banner-info">
    <MaterialCard className="card--container">
      <CardContent>
        <div className="card--title-wrapper">
          <Typography variant="h6" className="card--title">
            {title}
          </Typography>
          {hasIcon && <Icon type={icon} />}
        </div>

        <div className="content--banner" />

        <div className="content--wrapper">
          <Typography variant="body2" className="content--desc" component="p">
            {desc}
            {hasLink && <Link hasRightIcon label="more info" onClick={onLinkClick} icon="arrowForward" className="ttc link--icon right" />}
          </Typography>
        </div>
      </CardContent>

      {hasFooterButton && (
        <CardActions>
          <BasicButton fullWidth title={buttonTitle} onClick={onClick} className="" />
        </CardActions>
      )}
    </MaterialCard>
  </Paper>
);

BannerInfoCard.defaultProps = {
  desc: '',
  title: '',
  icon: '',
  hasLink: false,
  hasIcon: false,
  onLinkClick: null,
  hasFooterButton: false,
  buttonTitle: 'Apply now',
};

BannerInfoCard.propTypes = {
  icon: PropTypes.string,
  desc: PropTypes.string,
  title: PropTypes.string,
  hasIcon: PropTypes.bool,
  hasLink: PropTypes.bool,
  onLinkClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  hasFooterButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default BannerInfoCard;
