import { Children } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { formatFieldProp } from 'utils/dynamic-field';

import { Accordion } from 'components/common';
import { DYNAMIC_FIELD } from 'settings/constants/dynamic-field';

const DynoAccordion = ({ isRecurring, ...preparedProps }) => {
  const { formatMessage } = useIntl();

  const defaultExpanded = get(preparedProps, 'defaultExpanded', false);
  const accordionFields = get(preparedProps, 'fields', []);
  const accordionTitle = get(preparedProps, 'title', '');
  const defaultField = get(DYNAMIC_FIELD, 'default', null);

  const title = isString(accordionTitle) ? formatMessage({ id: accordionTitle }) : accordionTitle;

  // the concept is to use the dynamic form function and wrap it around accordion
  // the function is almost same on rendering the field except the value need to be taken by using getValue
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Accordion title={title} defaultExpanded={defaultExpanded}>
          {Children.toArray(
            accordionFields.map(accordionField => {
              const type = get(accordionField, 'type', '');
              const isDisplay = get(accordionField, 'isDisplay', true);

              const accordionProps = formatFieldProp(preparedProps, accordionField);

              if (!isDisplay) return defaultField;
              return (DYNAMIC_FIELD[type] || defaultField)(accordionProps, isRecurring);
            })
          )}
        </Accordion>
      </Grid>
    </Grid>
  );
};

DynoAccordion.propTypes = { isRecurring: PropTypes.bool.isRequired };

export default DynoAccordion;
