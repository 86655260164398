import PropTypes from 'prop-types';
import { DynoAlertModal } from 'components/dyno-template/modals';

const CommonModal = ({
  open,
  onCancel,
  onSubmit,
  onClose,
  title,
  description,
  hasCancelButton,
  actionButtonTitle,
  classNameDesc,
  className,
  secondaryActionButtonTitle,
}) => {
  return (
    <DynoAlertModal
      isOpen={open}
      hasCancelButton={hasCancelButton}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onClose={onClose}
      title={title}
      description={description}
      actionButtonTitle={actionButtonTitle}
      classNameDesc={classNameDesc}
      className={className}
      hasCloseButton
      secondaryActionButtonTitle={secondaryActionButtonTitle}
    />
  );
};

CommonModal.defaultProps = {
  open: false,
  onCancel: null,
  onSubmit: null,
  onClose: null,
  title: '',
  description: '',
  hasCancelButton: true,
  actionButtonTitle: '',
  classNameDesc: '',
  className: '',
  secondaryActionButtonTitle: '',
};

CommonModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  hasCancelButton: PropTypes.bool,
  actionButtonTitle: PropTypes.string,
  classNameDesc: PropTypes.string,
  className: PropTypes.string,
  secondaryActionButtonTitle: PropTypes.string,
};

export default CommonModal;
