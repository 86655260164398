import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { BasicDrawer } from 'components/common/drawers';
import { BasicButton } from 'components/common/buttons';

const DynoFavouriteResultDrawer = ({
  isOpen,
  onClose,
  isEditFavourite,
  isDeleteFavourite,
  isTransactionSuccessful,
  isFromTransferResult,
}) => {
  const { formatMessage } = useIntl();

  const buttonTitle = !isFromTransferResult ? 'buttonText.done' : 'buttonText.confirm';
  return (
    <BasicDrawer variant="persistent" className="result--drawer" isOpen={isOpen} onClose={onClose}>
      <Grid container justify="center">
        <Grid container sm={11} md={9} item>
          <Grid item sm={5} xs={12}>
            <h1 className="f4 lh-copy">
              {/* Add New Result message */}
              {!isFromTransferResult &&
                !isEditFavourite &&
                !isDeleteFavourite &&
                (isTransactionSuccessful
                  ? formatMessage({ id: 'transaction.favourite.drawer.addSuccess' })
                  : formatMessage({ id: 'transaction.favourite.drawer.addFailed' }))}
              {/* Edit Result message */}
              {!isFromTransferResult &&
                isEditFavourite &&
                (isTransactionSuccessful
                  ? formatMessage({ id: 'transaction.favourite.drawer.editSuccess' })
                  : formatMessage({ id: 'transaction.favourite.drawer.editFailed' }))}

              {!isFromTransferResult &&
                isDeleteFavourite &&
                (isTransactionSuccessful
                  ? formatMessage({ id: 'transaction.favourite.drawer.deleteSuccess' })
                  : formatMessage({ id: 'transaction.favourite.drawer.deleteFailed' }))}

              {isFromTransferResult && formatMessage({ id: 'transaction.transfer.tacTitle' })}
            </h1>
          </Grid>

          <Grid item sm={7} xs={12} container alignContent="center" justify="flex-end">
            <BasicButton title={formatMessage({ id: buttonTitle })} onClick={onClose} className="db btn--done" />
          </Grid>
        </Grid>
      </Grid>
    </BasicDrawer>
  );
};

DynoFavouriteResultDrawer.defaultProps = { isFromTransferResult: false };

DynoFavouriteResultDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFromTransferResult: PropTypes.bool,
  isEditFavourite: PropTypes.bool.isRequired,
  isDeleteFavourite: PropTypes.bool.isRequired,
  isTransactionSuccessful: PropTypes.bool.isRequired,
};

export default DynoFavouriteResultDrawer;
