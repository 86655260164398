export const transfer = {
  or: 'OR',
  label: 'Transfer',
  from: 'Rekening Asal',
  to: 'Rekening Tujuan',
  message: 'Pesan',
  myFavorites: 'Favorit Saya',
  transactionFee: 'Biaya transaksi',
  transactionAmount: 'Jumlah transaksi',
  transactionCharges: 'Biaya transaksi',
  availableBalance: 'Saldo Tersedia',
  accountNumber: 'Nomor Rekening',
  amount: 'Jumlah',
  effectiveDate: 'Tanggal Efektif',
  setRecurring: 'Transfer Berulang',
  frequency: 'Frekuensi',
  monthly: 'Bulanan',
  weekly: 'Mingguan',
  start: 'Mulai',
  end: 'Berakhir',
  selectDate: 'Pilih tanggal',
  debitDate: 'Tanggal Pendebetan',
  debitDay: 'Hari Pendebetan',
  note: 'Catatan:',
  notes: 'Catatan:',
  selectBank: 'Pilih Bank',
  identityNumber: 'Nomor ID',
  sourceOfFund: 'Sumber Dana',
  selectCurrency: 'Pilih mata uang',
  transactionPurpose: 'Tujuan Transaksi',
  beneficiary: {
    type: 'Jenis Penerima',
    bank: 'Bank Penerima',
    name: 'Nama Penerima',
    info: 'Informasi Penerima',
    email: 'Email Penerima',
    address: 'Alamat Penerima',
    message: 'Pesan (Opsional)',
    category: 'Kategori Penerima',
    citizenship: 'Kewarganegaraan Penerima',
    bankAddress: 'Alamat Bank Penerima',
    bankCountry: 'Negara Bank Penerima',
    swiftCode: 'Kode SWIFT Bank Penerima',
    emailOptional: 'Email Penerima (Opsional)',
    citizenStatus: 'Status Kewarganegaraan Penerima',
  },
  localDebitedAmount: 'Jumlah yang akan didebet dalam IDR',
  recurringMonthly: 'Transfer Berulang - Bulanan',
  recurringWeekly: 'Transfer Berulang - Mingguan',
  startDate: 'Tanggal Mulai',
  endDate: 'Tanggal Berakhir',
  monetaryInfo: 'Informasi Moneter',
  totalDebit: 'Jumlah yang akan didebet',
  equivalentDebit: 'Jumlah yang akan didebet dalam IDR',
  exchangeRate: 'Nilai Penukaran',
  referenceNumber: 'Nomor Referensi',
  foreignExchange: 'Pertukaran Asing',
  foreignExchangeRate: 'Kurs Valuta Asing',
  ownAccount: {
    label: 'Rekening Sendiri',
    title: 'Transfer Antar Rekening Sendiri',
  },
  sknTransfer: {
    name: 'SKN',
    label: {
      transferToSkn: 'Transfer ke LLG (Lalu Lintas Giro)/SKN (Sistem Kliring Nasional)',
      message: 'Pesan (Opsional)',
      beneficiaryEmail: 'Email Penerima (Opsional)',
      messagePlaceholder: 'Maks 15 karakter',
    },
  },
  intrabank: {
    title: 'Transfer Antar Rekening Maybank',
    messagePlaceholder: 'Maks 120 karakter',
    message: 'Pesan (Opsional)',
  },
  interbank: {
    title: 'Transfer to Antarbank',
    noOptionsText: 'Bank Penerima tidak ditemukan',
    message: 'Pesan (Opsional)',
    messagePlaceholder: 'Max 40 karakter',
    beneficiaryBank: 'Bank Penerima',
    beneficiaryEmail: 'Email Penerima (Opsional)',
  },
  rtgs: {
    name: 'Transfer ke RTGS (Real Time Gross Settlement)',
  },
  virtualAccount: {
    name: 'Akun Virtual Maybank',
    title: 'Transfer ke Akun Virtual Maybank',
  },
  eWallet: {
    name: 'E-Wallet',
    title: 'Transfer ke E-Wallet',
    selectEWallet: 'Pilih E-Wallet',
    customerNumber: 'Nomor Pelanggan',
    eWalletName: 'Nama E-Wallet',
    adminFee: 'Biaya Admin',
  },
  swift: {
    table: {
      swiftCode: 'Kode SWIFT',
      bankName: 'Nama Bank',
      firstAddress: 'Alamat 1',
      secondAddress: 'Alamat 2',
      thirdAddress: 'Alamat 3',
    },
    modal: {
      accountTitle: 'Nomor Rekening/IBAN',
      name: 'Transfer ke Transfer Valuta Asing SWIFT',
      tooltip:
        'IBAN adalah kode unik yang digunakan beberapa negara untuk mengidentifikasi no rekening bank dalam pembayaran antar negara.',
      beneficiaryEmail: 'Email Penerima Manfaat (Opsional)',
      idNumber: 'Nomor Identitas',
      dateOfBirth: 'Tanggal lahir',
      jpyBottomLabel: 'Silakan masukkan jumlah tanpa desimal',
      limitTitle: 'Batas Transaksi',
      limitMessage:
        'Transaksi melebihi limit valuta asing. Silakan gunakan aplikasi M2U ID untuk transaksi transfer valuta asing SWIFT dengan jumlah lebih dari ekuivalen USD 100.000.',
      notes:
        'Limit transaksi valuta asing dihitung berdasarkan nilai tukar saat transaksi dibukukan, yang mungkin berbeda dengan nilai tukar transaksi Anda.',
    },
    placeholder: {
      bankName: 'Masukkan nama bank',
      swiftCode: 'Masukkan kode SWIFT',
      select: 'Silakan Pilih',
      street: 'Jalan',
      other: 'Lain',
      country: 'Negara',
      message: 'Max 120 karakter',
    },
    lld: {
      label: 'Lalu Lintas Devisa (LLD)',
      identityStatus: {
        label: 'Status Identik',
        no: 'Pengirim tidak sama dengan Penerima',
        yes: 'Pengirim sama dengan Penerima',
      },
      card: {
        description: 'Deskripsi',
        identicalStatus: 'Status Identik',
        transDescription: 'Deskripsi Transaksi',
        remitterRelationship: 'Remitter Relationship',
      },
      citizenship: {
        label: 'Kewarganegaraan Penerima',
      },
      beneficiary: {
        label: 'Kategori Penerima',
        individual: 'Perorangan',
        financial: 'Lembaga Keuangan Non Bank',
        company: 'Perusahaan',
      },
      relationship: {
        label: 'Hubungan dengan Pengirim',
        nonAffiliate: 'Non Afiliasi',
        shareHolders: 'Pemegang Saham',
        subsidiary: 'Anak Perusahaan',
        group: 'Grup',
      },
      purpose: {
        label: 'Kategori Tujuan Transaksi',
        production: 'Penghasilan/Pengeluaran',
        goods: 'Transaksi Barang',
        services: 'Transaksi Jasa',
        modal: 'Transaksi Modal/Keuangan',
        unrequitted: 'Transfer Sukarela',
      },
      description: {
        label: 'Deskripsi',
      },
      message: {
        label: 'Deskripsi Transaksi (Opsional)',
        placeholder: 'Max 120 karakter',
      },
    },
  },
  errorMessage: {
    required: 'Pastikan Anda mengisi semua informasi yang diperlukan untuk transaksi ini',
    accountLength: 'Nomor Akun minimal 10 digit',
    currency: 'Silakan pilih mata uang',
    amount: 'Jumlah tidak sesuai.',
    accountNumber: 'Rekening Penerima tidak valid.',
    customerNumber: 'Nomor Pelanggan tidak valid.',
    sameAccount: 'Silakan pilih opsi "Own Account" untuk transaksi transfer antar rekening Anda.',
    sameEndDate: 'Tanggal Akhir harus lebih lambat dari Tanggal Mulai.',
    endDate: 'Tanggal Berakhir tidak valid.',
    minAmount: 'Nominal minimum yang diperbolehkan untuk transaksi ini adalah Rp {amount}',
    maxAmount: 'Nominal maksimum yang diperbolehkan untuk transaksi ini adalah Rp {amount}',
    beneficiaryBankNotFound: 'Bank penerima tidak ditemukan',
    insufficientFund: 'Saldo tidak mencukupi',
    beneficiaryAmount: 'Jumlah Penerima tidak valid',
    beneficiaryName: 'Nama Penerima tidak valid',
    sourceOfFund: 'Sumber Dana tidak valid',
    transactionPurpose: 'Tujuan Transaksi tidak valid',
    beneficiaryType: 'Jenis Penerima tidak valid',
    beneficiaryCitizenStatus: 'Status Kewarganegaraan Penerima tidak valid',
    startDate: 'Tanggal Efektif tidak valid',
    frequency: 'Frekuensi tidak valid',
    sknAmountLimit: 'Jumlah transaksi tidak boleh kurang atau lebih besar dari jumlah yang diperbolehkan untuk LLG/SKN',
    beneficiaryEmail: 'Email Penerima tidak valid',
    exceedBalance: 'Jumlah transaksi Anda tidak boleh lebih besar dari saldo yang tersedia',
    localCurrencyTransaction: 'Transaksi hanya dapat dilakukan dalam mata uang lokal. Silakan pilih sumber akun dalam IDR',
    invalidIban: 'Nomor Rekening/IBAN tidak valid',
    noEligibleAccount: 'Anda tidak memiliki akun yang memenuhi syarat untuk melakukan transaksi ini',
  },
  tacTitle: 'Silakan mengkonfirmasi detail instruksi transaksi Anda.',
  tacTitleFc: 'Silakan mengkonfirmasi kata sandi baru Anda.',
  tacDescription: 'Untuk melanjutkan, silakan mengajukan permintaan TAC dan masukkan nomor <b>TAC</b> Anda.',
  resultTitle: 'Transaksi {status}!',
  noOptionFound: 'Tidak Ada Pilihan yang Ditemukan',
  changeAdminFee: 'Pengenaan biaya admin mengacu pada ketentuan biaya yang berlaku.',
};
