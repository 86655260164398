import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import PropTypes from 'prop-types';
import flatten from 'flat';

import { IntlProvider } from 'react-intl';

import { putLocale } from 'middleware/actions/language';

import English from 'settings/languages/en';
import Indonesian from 'settings/languages/id';

import { LocaleContext } from 'providers/context';

const TranslationProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { locale } = useSelector(
    state => ({
      locale: state.LanguageReducer.locale,
    }),
    shallowEqual
  );

  const translations = {
    en: English,
    id: Indonesian,
  };

  const [messages, setMessages] = useState(translations[locale]);

  const setLocale = language => {
    setMessages(translations[language]);
    dispatch(putLocale(language));
  };

  useEffect(() => {
    setMessages(translations[locale]);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={flatten(messages)} locale={locale}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

TranslationProvider.propTypes = { children: PropTypes.node.isRequired };

export default TranslationProvider;
