import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { get } from 'utils/lodash';
import { formatArrayKeys, formatKeys } from 'utils/formatter';
import { formatBody, encryptPayload } from 'utils/self-unlock';
import { selfUnlockEndpoint as endpoint } from 'providers/endpoints/self-unlock';

import { SELF_UNLOCK_KEYMAP } from 'settings/constants/keymap';
import { UNRESPONSIVE_HOST, SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

import { putErrorResponse } from 'middleware/actions/error';
import {
  actions,
  putEmailStatus,
  putSelfUnlockAccess,
  putUnlockUserResponse,
  putVerifiedSelfUnlockDetails,
  putResetPasswordTacRequestResponse,
  putResetPasswordTacSubmitResponse,
} from 'middleware/actions/self-unlock';

const { USERNAME_TO_EMAIL, RESET_PASSWORD_TAC_REQUEST, RESET_PASSWORD_TAC_SUBMIT, UNLOCK_USER } = actions.POST;
const { SELF_UNLOCK_ACCESS, VERIFY_SELF_UNLOCK_DETAILS, REQUEST_USERNAME_SEND_TO_EMAIL } = actions.GET;

const getClientIP = () => remoteIpInstance.get();
const getRegisterAccess = ({ body }) => Instance.post(endpoint.get.access, body);
const getVerifyPin = ({ body }) => Instance.post(endpoint.get.verifyPin, body);
const postUsernameToEmail = ({ body }) => Instance.post(endpoint.post.email, body);
const postResetPasswordTacRequest = ({ body }) => Instance.post(endpoint.post.resetPasswordTacRequest, body);
const postResetPasswordTacSubmit = ({ body }) => Instance.post(endpoint.post.resetPasswordTacSubmit, body);
const postUnlockUser = ({ body }) => Instance.post(endpoint.post.unlockUser, body);

const getLocale = state => state.LanguageReducer.locale;
const getVerifiedPinDetail = state => state.SelfUnlockReducer.verifiedDetail;

function* getSelfUnlockSaga() {
  try {
    const locale = yield select(getLocale);

    const body = { locale };

    const { data } = yield call(getRegisterAccess, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const currencies = get(data, 'currencyMap', []);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      const mutatedCurrencies = yield call(formatArrayKeys, currencies);
      yield put(putSelfUnlockAccess(mutatedCurrencies));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getVerifySelfUnlockDetailsSaga({ payload }) {
  try {
    const { ip } = yield call(getClientIP);
    const locale = yield select(getLocale);

    const accountBody = encryptPayload(payload);

    const initialBody = { locale, remoteIPAddress: ip, accountBody };
    const formattedBody = formatBody(initialBody, payload);

    const body = formatKeys(formattedBody, SELF_UNLOCK_KEYMAP);

    const { data } = yield call(getVerifyPin, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
    yield put(putVerifiedSelfUnlockDetails(data, isSuccess));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* postUsernameToEmailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, encryptedUserId: payload };

    const { data } = yield call(postUsernameToEmail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
    yield put(putEmailStatus(data, isSuccess));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* resetPasswordTacRequestSaga() {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const { encryptedUserId, tacBean } = yield select(getVerifiedPinDetail);

    const body = { locale, remoteIPAddress, userAgent, encryptedUserId, tacBean };

    const { data } = yield call(postResetPasswordTacRequest, { body });
    const responseCode = get(data, 'responseCode', '');
    const tacSentTime = get(data, 'tacSentDateTime', '');
    const restSMSService = get(data, 'tacViewBean.restSMSService', {});
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    const res = { isSuccess, responseCode, tacSentTime, restSMSService };

    yield put(putResetPasswordTacRequestResponse(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* resetPasswordTacSubmitSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, ...payload };

    const { data } = yield call(postResetPasswordTacSubmit, { body });
    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    const res = { responseCode, isSuccess };

    yield put(putResetPasswordTacSubmitResponse(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* unlockUserSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { ...payload, locale, remoteIPAddress };

    const { data } = yield call(postUnlockUser, { body });
    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    const response = { responseCode, isSuccess };
    yield put(putUnlockUserResponse(response));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* SelfUnlock() {
  yield all([takeLatest(SELF_UNLOCK_ACCESS, getSelfUnlockSaga)]);
  yield all([takeLatest(VERIFY_SELF_UNLOCK_DETAILS, getVerifySelfUnlockDetailsSaga)]);
  yield all([takeLatest(USERNAME_TO_EMAIL, postUsernameToEmailSaga)]);
  yield all([takeLatest(RESET_PASSWORD_TAC_REQUEST, resetPasswordTacRequestSaga)]);
  yield all([takeLatest(RESET_PASSWORD_TAC_SUBMIT, resetPasswordTacSubmitSaga)]);
  yield all([takeLatest(REQUEST_USERNAME_SEND_TO_EMAIL, postUsernameToEmailSaga)]);
  yield all([takeLatest(UNLOCK_USER, unlockUserSaga)]);
}
