import PropTypes from 'prop-types';

import { Card as MaterialCard, CardActions, CardContent, CardMedia, Typography, Paper, CardActionArea } from '@material-ui/core';

import { get } from 'utils/lodash';

import { COMPONENT_USED_IN_TIME_DEPOSIT } from 'settings/constants/common';

import Link from '../Link';
import ActionButton from '../buttons/ActionButton';

import './style.scss';

const InteractiveMediaCard2 = ({ index, item, buttonTitle, coverImg, onClick, onLinkClick }) => {
  const desc = get(item, 'desc', '');
  const title = get(item, 'title', '');

  return (
    <Paper className="card card--interactive-media2">
      <MaterialCard className="card--container">
        <div className="content--img">
          <CardMedia component="img" alt={title} title={title} image={coverImg} />
          <Typography variant="h5" className="img--title pa3">
            {title}
          </Typography>
        </div>

        <CardContent>
          <div className="ph3 content--wrapper">
            <Typography variant="body2" className="content--desc" component="p">
              {desc}
            </Typography>
          </div>
        </CardContent>

        <CardActions>
          <ActionButton
            fullWidth
            hasLeftIcon={false}
            hasRightIcon
            isAssetIcon={false}
            icon="arrowRightAlt"
            title={buttonTitle}
            onClick={onClick}
            className="hover--default pb3"
            usedIn={COMPONENT_USED_IN_TIME_DEPOSIT}
          />
        </CardActions>
      </MaterialCard>
    </Paper>
  );
};

InteractiveMediaCard2.defaultProps = {
  item: {},
  index: '',
  onLinkClick: null,
  buttonTitle: 'Apply now',
};

InteractiveMediaCard2.propTypes = {
  item: PropTypes.object,
  onLinkClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  coverImg: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InteractiveMediaCard2;
