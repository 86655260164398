export const selfUnlockEndpoint = {
  get: {
    access: 'registration/v1/unlock/home',
    verifyPin: 'registration/v1/unlock/pin',
  },
  post: {
    email: 'notify/v1/unlock/email',
    resetPasswordTacRequest: 'notify/v1/tac/unlock/request',
    resetPasswordTacSubmit: 'registration/v1/tac/unlock/confirm',
    unlockUser: 'registration/v1/unlock/userid',
  },
};
