import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { getCustomerInfoSaga } from 'middleware/sagas/login';
import { formatSettingsResult } from 'utils/settings/formatter';
import { settingsEndpoint as endpoint } from 'providers/endpoints/settings';
import { actions, putPersonalInfoAccess, putPersonalInfoResult, putThemeAccess, putThemeResult } from 'middleware/actions/settings';

const getClientIP = () => remoteIpInstance.get();

const getThemeAccess = ({ body }) => Instance.post(endpoint.theme.access, body);
const getThemeResult = ({ body }) => Instance.post(endpoint.theme.result, body);

const changePersonalInfo = ({ body }) => Instance.post(endpoint.personalInfo.change, body);
const getPersonalInfoResult = ({ body }) => Instance.post(endpoint.personalInfo.result, body);

const getLocale = state => state.LanguageReducer.locale;

function* getThemeAccessSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getThemeAccess, { body });
    yield put(putThemeAccess(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getThemeResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(getThemeResult, { body });

    const userId = get(payload, 'userId', '');
    const formattedResult = formatSettingsResult(data);
    const isSuccess = get(formattedResult, 'isSuccess', false);

    // Update data disable the save button upon success
    if (isSuccess) {
      yield all([call(getThemeAccessSaga, { payload: { userId } }), call(getCustomerInfoSaga, { payload: { userId } })]);
    }

    yield put(putThemeResult(formattedResult));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* changePersonalInfoSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(changePersonalInfo, { body });
    yield put(putPersonalInfoAccess(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPersonalInfoResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const timezoneOffset = new Date().getTimezoneOffset();
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, timezoneOffset, ...payload };
    const { data } = yield call(getPersonalInfoResult, { body });
    const formattedResult = formatSettingsResult(data);

    const userId = get(payload, 'userId', '');
    const isSuccess = get(formattedResult, 'isSuccess', false);
    const welcomeMessage = get(formattedResult, 'welcomeMessage', '');

    // success and update the personalInfo data to disable the save button
    // run and wait this first to avoid drawer display wrong language
    if (isSuccess) {
      yield all([
        call(changePersonalInfoSaga, { payload: { userId } }),
        call(getCustomerInfoSaga, { payload: { userId }, welcomeMessage }),
      ]);
    }

    yield put(putPersonalInfoResult(formattedResult));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* SettingsPersonal() {
  yield all([
    takeLatest(actions.GET.THEME.ACCESS, getThemeAccessSaga),
    takeLatest(actions.GET.THEME.RESULT, getThemeResultSaga),
    takeLatest(actions.CHANGE.PERSONAL_INFO, changePersonalInfoSaga),
    takeLatest(actions.GET.PERSONAL_INFO.RESULT, getPersonalInfoResultSaga),
  ]);
}
