import { INITIAL_NOTIFICATION_COUNT } from 'settings/constants/common';

import { actions } from '../actions/auth';
import { actions as registerActions } from '../actions/registration';

const { CUSTOMER_PROFILE, CAPTCHA_IMAGE, UPDATE_CUSTOMER_PROFILE, WELCOME_MESSAGE, AUTH_TOKEN } = actions.PUT;
const { REGISTER_ACCESS } = registerActions.PUT;

const initialState = {
  unreadMsgCount: INITIAL_NOTIFICATION_COUNT,
  welcomeMessage: '',
  lastLoginDate: '',
  imageLink: '',
  isAuthenticated: false,
  customerProfile: {},
  registerAccess: {},
  authToken: '',
  isRenewPassword: false,
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CAPTCHA_IMAGE:
      return { ...state, imageLink: action.imageLink };
    case WELCOME_MESSAGE:
      return { ...state, welcomeMessage: action.welcomeMessage };

    case CUSTOMER_PROFILE:
      return {
        ...state,
        cifNo: action.payload.cifNo,
        lastLoginDate: action.payload.lastLoginDate,
        unreadMsgCount: action.payload.unreadMsgCount,
        welcomeMessage: action.payload.welcomeMessage,
        customerProfile: action.payload.customerProfile,
        isAuthenticated: action.payload.isSuccess,
        isRenewPassword: action.payload.isRenewPassword,
      };
    case REGISTER_ACCESS:
      return { ...state, registerAccess: action.payload };
    case UPDATE_CUSTOMER_PROFILE:
      return { ...state, customerProfile: action.payload };
    case 'LOGIN_PUT_AUTH_TOKEN':
      return { ...state, authToken: action.payload.jwt };
    case 'RENEW_PASSWORD':
      return { ...state, isRenewPassword: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
