import PropTypes from 'prop-types';

import { Tooltip as MaterialTooltip } from '@material-ui/core';

import { useTooltipStyle, useTooltipStyleNoMaxWidth } from './style/material';

const Tooltip = ({ className, title, placement, children, noMaxWidth }) => {
  const classesClassic = useTooltipStyle();
  const classesNoMaxWidth = useTooltipStyleNoMaxWidth();

  return (
    <MaterialTooltip
      className={className}
      title={title}
      placement={placement}
      classes={noMaxWidth ? classesNoMaxWidth : classesClassic}
      arrow
    >
      {children}
    </MaterialTooltip>
  );
};

Tooltip.defaultProps = { className: '', placement: 'top', noMaxWidth: false };

Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  noMaxWidth: PropTypes.bool,
};

export default Tooltip;
