import { useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';

import { RadioGroup, FormControl, FormControlLabel, Radio as MaterialRadio } from '@material-ui/core';

import Icon from 'components/common/Icon';

import { useRadioStyle } from './style/material';

// Radio is used for multiple choices. It will return the currently selected choice
const Radio = ({ row, name, value, options, onChange, preserveValue, variant, className, fullWidth, clearValue, isNumberValue }) => {
  const classes = useRadioStyle();
  const iconMode = className.includes('radio-light') ? 'Light' : ''; // light theme vs dark (default)

  const [selected, setSelected] = useState(value);

  const handleAction = e => {
    // by default, event value will return a string type. for favourite, index is in integer type
    const currentChoice = isNumberValue ? Number(e.target.value) : e.target.value;
    // to allow multiple click on radio and trigger onChange callback
    if (preserveValue) setSelected(currentChoice);
    return onChange(currentChoice);
  };

  const icon = variant === 'tick' ? 'radioUnselectedTick' : 'radioUnselected';
  const checkedIcon = variant === 'tick' ? 'radioSelectedTick' : 'radioSelected';

  useEffect(() => {
    if (clearValue) setSelected('');
  }, [clearValue]);

  const StyledRadio = props => (
    <MaterialRadio
      icon={<Icon isAssetIcon type={`${icon}${iconMode}`} />}
      checkedIcon={<Icon isAssetIcon type={`${checkedIcon}${iconMode}`} />}
      {...props}
    />
  );

  return (
    <FormControl fullWidth={fullWidth} className={classes.root}>
      <RadioGroup className={className} row={row} name={name} value={selected} onChange={handleAction}>
        {Children.toArray(
          options.map(option => (
            <FormControlLabel value={option.value} disabled={option.disabled} control={<StyledRadio />} label={option.label} />
          ))
        )}
      </RadioGroup>
    </FormControl>
  );
};

Radio.defaultProps = {
  variant: 'round', // or tick
  className: '',
  value: '',
  name: '',
  preserveValue: true,
  onChange: null,
  row: false,
  fullWidth: false,
  clearValue: false,
  isNumberValue: false,
};

Radio.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  row: PropTypes.bool,
  fullWidth: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  preserveValue: PropTypes.bool,
  clearValue: PropTypes.bool,
  isNumberValue: PropTypes.bool,
  variant: PropTypes.oneOf(['round', 'tick']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Radio;
