export const actions = {
  GET: {
    SENDER_ACCOUNT: 'PAYMENT_GET_SENDER_ACCOUNT',
    MOBILE_POSTPAID: {
      ACCESS: 'PAYMENT_GET_MOBILE_POSTPAID_ACCESS',
      DETAIL: 'PAYMENT_GET_MOBILE_POSTPAID_DETAILS',
      CONFIRM: 'PAYMENT_GET_MOBILE_POSTPAID_CONFIRM',
      TAC: 'PAYMENT_GET_MOBILE_POSTPAID_TAC',
      RESULT: 'PAYMENT_GET_MOBILE_POSTPAID_RESULT',
      RECEIPT: 'PAYMENT_GET_MOBILE_POSTPAID_RECEIPT',
      DETAIL_FROM_FAVOURITE: 'PAYMENT_GET_MOBILE_POSTPAID_DETAIL_FROM_FAVOURITE',
    },
    UTILITY: {
      RESULT: 'PAYMENT_UTILITY_GET_RESULT',
      RECEIPT: 'PAYMENT_UTILITY_GET_RECEIPT',
      ACCESS: 'PAYMENT_UTILITY_GET_ACCESS',
      REQUEST_TAC: 'PAYMENT_UTILITY_REQUEST_TAC',
      CONFIRMATION: 'PAYMENT_UTILITY_GET_CONFIRMATION',
      BILLER_DETAIL: 'PAYMENT_UTILITY_GET_BILLER_DETAIL',
      DETAIL_FROM_FAVOURITE: 'PAYMENT_GET_UTILITY_DETAIL_FROM_FAVOURITE',
    },
    CREDIT_CARD: {
      ACCESS: 'PAYMENT_GET_CREDIT_CARD_ACCESS',
      MAYBANK_ACCESS: 'PAYMENT_GET_CREDIT_CARD_MAYBANK_ACCESS',
      OWN_MAYBANK_CARDS: 'PAYMENT_GET_CREDIT_CARD_OWN_MAYBANK_CARDS',
      PAYMENT_MODES: 'PAYMENT_CREDIT_CARD_GET_PAYMENT_MODES',
      MAYBANK_CONFIRMATION: 'PAYMENT_CREDIT_CARD_GET_MAYBANK_CONFIRMATION',
      OTHER_BANK_CONFIRMATION: 'PAYMENT_CREDIT_CARD_GET_OTHER_BANK_CONFIRMATION',
      REQUEST_MAYBANK_TAC: 'PAYMENT_CREDIT_CARD_REQUEST_MAYBANK_TAC',
      REQUEST_OTHER_BANK_TAC: 'PAYMENT_CREDIT_CARD_REQUEST_OTHER_BANK_TAC',
      MAYBANK_RESULT: 'PAYMENT_CREDIT_CARD_GET_MAYBANK_RESULT',
      OTHER_BANK_RESULT: 'PAYMENT_CREDIT_CARD_GET_OTHER_BANK_RESULT',
      MAYBANK_RECEIPT: 'PAYMENT_CREDIT_CARD_GET_MAYBANK_RECEIPT',
      OTHER_BANK_RECEIPT: 'PAYMENT_CREDIT_CARD_GET_OTHER_BANK_RECEIPT',
      FAVOURITE: {
        MAYBANK_DETAIL: 'PAYMENT_GET_CREDIT_CARD_FAVOURITE_MAYBANK_DETAIL',
        OTHER_DETAIL: 'PAYMENT_GET_CREDIT_CARD_FAVOURITE_OTHER_DETAIL',
      },
    },
    OTHER_BILL: {
      ACCESS: 'PAYMENT_OTHER_BILL_GET_ACCESS',
      PAYEES: 'PAYMENT_OTHER_BILL_GET_PAYEES',
      DETAIL: 'PAYMENT_OTHER_BILL_GET_DETAIL',
      CONFIRMATION: 'PAYMENT_OTHER_BILL_GET_CONFIRMATION',
      REQUEST_TAC: 'PAYMENT_OTHER_BILL_REQUEST_TAC',
      RESULT: 'PAYMENT_OTHER_BILL_GET_RESULT',
      RECEIPT: 'PAYMENT_OTHER_BILL_GET_RECEIPT',
      DETAIL_FROM_FAVOURITE: 'PAYMENT_GET_OTHER_BILL_DETAIL_FROM_FAVOURITE',
    },
  },
  PUT: {
    RESET_PAYMENT: 'TRANSACTION_PUT_RESET_PAYMENT',
    RESET_CONFIRM_PAYMENT: 'TRANSACTION_PUT_RESET_CONFIRM_PAYMENT',
    SENDER_ACCOUNT_RESPONSE: 'PAYMENT_PUT_SENDER_ACCOUNT_RESPONSE',
    MOBILE_POSTPAID: {
      ACCESS: 'PAYMENT_PUT_MOBILE_POSTPAID_ACCESS',
      BILLERS: 'PAYMENT_PUT_MOBILE_POSTPAID_BILLERS',
      DETAIL: 'PAYMENT_PUT_MOBILE_POSTPAID_DETAILS',
      CONFIRM: 'PAYMENT_PUT_MOBILE_POSTPAID_CONFIRM',
      TAC: 'PAYMENT_PUT_MOBILE_POSTPAID_TAC',
      RESULT: 'PAYMENT_PUT_MOBILE_POSTPAID_RESULT',
      RECEIPT: 'PAYMENT_PUT_MOBILE_POSTPAID_RECEIPT',
    },
    UTILITY: {
      ACCESS: 'PAYMENT_UTILITY_PUT_ACCESS',
      RESULT: 'PAYMENT_UTILITY_PUT_RESULT',
      RECEIPT: 'PAYMENT_UTILITY_PUT_RECEIPT',
      BILLERS: 'PAYMENT_UTILITY_PUT_BILLERS',
      TAC_RESULT: 'PAYMENT_UTILITY_PUT_TAC_RESULT',
      CONFIRMATION: 'PAYMENT_UTILITY_PUT_CONFIRMATION',
      BILLER_DETAIL: 'PAYMENT_UTILITY_PUT_BILLER_DETAIL',
    },
    CREDIT_CARD: {
      ACCESS: 'PAYMENT_CREDIT_CARD_PUT_ACCESS',
      TYPES: 'PAYMENT_CREDIT_CARD_PUT_TYPES',
      MAYBANK_ACCESS: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_ACCESS',
      MAYBANK_HOLDERS: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_HOLDERS',
      OWN_MAYBANK_CARDS: 'PAYMENT_CREDIT_CARD_PUT_OWN_MAYBANK_CARDS',
      PAYMENT_MODES: 'PAYMENT_CREDIT_CARD_PUT_PAYMENT_MODES',
      MAYBANK_CONFIRMATION: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_CONFIRMATION',
      OTHER_BANK_CONFIRMATION: 'PAYMENT_CREDIT_CARD_PUT_OTHER_BANK_CONFIRMATION',
      REQUEST_MAYBANK_TAC: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_TAC_RESULT',
      REQUEST_OTHER_BANK_TAC: 'PAYMENT_CREDIT_CARD_PUT_OTHER_BANK_TAC_RESULT',
      MAYBANK_RESULT: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_RESULT',
      OTHER_BANK_RESULT: 'PAYMENT_CREDIT_CARD_PUT_OTHER_BANK_RESULT',
      MAYBANK_RECEIPT: 'PAYMENT_CREDIT_CARD_PUT_MAYBANK_RECEIPT',
      OTHER_BANK_RECEIPT: 'PAYMENT_CREDIT_CARD_PUT_OTHER_BANK_RECEIPT',
    },
    OTHER_BILL: {
      ACCESS: 'PAYMENT_OTHER_BILL_PUT_ACCESS',
      BILLERS: 'PAYMENT_OTHER_BILL_PUT_BILLERS',
      PAYEES: 'PAYMENT_OTHER_BILL_PUT_PAYEES',
      DETAIL: 'PAYMENT_OTHER_BILL_PUT_DETAIL',
      CONFIRMATION: 'PAYMENT_OTHER_BILL_PUT_CONFIRMATION',
      TAC: 'PAYMENT_OTHER_BILL_PUT_TAC',
      RESULT: 'PAYMENT_OTHER_BILL_PUT_RESULT',
      RECEIPT: 'PAYMENT_OTHER_BILL_PUT_RECEIPT',
    },
  },
};

export const putResetPayment = () => ({ type: actions.PUT.RESET_PAYMENT });
export const putResetConfirmPayment = () => ({ type: actions.PUT.RESET_CONFIRM_PAYMENT });

// SENDER ACCOUNT
export const getPaymentSenderAccount = payload => ({ type: actions.GET.SENDER_ACCOUNT, payload });
export const putSenderAccountResult = res => ({ type: actions.PUT.SENDER_ACCOUNT_RESPONSE, res });

// Utility
export const getUtilityResult = payload => ({ type: actions.GET.UTILITY.RESULT, payload });
export const getUtilityAccess = payload => ({ type: actions.GET.UTILITY.ACCESS, payload });
export const getUtilityReceipt = payload => ({ type: actions.GET.UTILITY.RECEIPT, payload });
export const requestUtilityTac = payload => ({ type: actions.GET.UTILITY.REQUEST_TAC, payload });
export const getUtlityConfirmation = body => ({ type: actions.GET.UTILITY.CONFIRMATION, payload: body });
export const getUtilityBillerDetail = payload => ({ type: actions.GET.UTILITY.BILLER_DETAIL, payload });
export const getUtilityFavouriteDetail = payload => ({ type: actions.GET.UTILITY.DETAIL_FROM_FAVOURITE, payload });

export const putUtilityAccess = res => ({ type: actions.PUT.UTILITY.ACCESS, res });
export const putUtilityResult = res => ({ type: actions.PUT.UTILITY.RESULT, res });
export const putUtilityTac = res => ({ type: actions.PUT.UTILITY.TAC_RESULT, res });
export const putUtilityBillers = res => ({ type: actions.PUT.UTILITY.BILLERS, res });
export const putUtilityReceipt = res => ({ type: actions.PUT.UTILITY.RECEIPT, res });
export const putUtilityConfirmation = res => ({ type: actions.PUT.UTILITY.CONFIRMATION, res });
export const putUtilityBillerDetail = res => ({ type: actions.PUT.UTILITY.BILLER_DETAIL, res });

// CREDIT CARD
export const getCreditCardAccess = payload => ({ type: actions.GET.CREDIT_CARD.ACCESS, payload });
export const getCreditCardMaybankAccess = payload => ({ type: actions.GET.CREDIT_CARD.MAYBANK_ACCESS, payload });
export const getCreditCardOwnCards = payload => ({ type: actions.GET.CREDIT_CARD.OWN_MAYBANK_CARDS, payload });
export const getCreditCardPaymentModes = payload => ({ type: actions.GET.CREDIT_CARD.PAYMENT_MODES, payload });
export const getMaybankCardConfirmation = payload => ({ type: actions.GET.CREDIT_CARD.MAYBANK_CONFIRMATION, payload });
export const getOtherBankConfirmation = payload => ({ type: actions.GET.CREDIT_CARD.OTHER_BANK_CONFIRMATION, payload });
export const requestMaybankCardTac = payload => ({ type: actions.GET.CREDIT_CARD.REQUEST_MAYBANK_TAC, payload });
export const requestOtherBankCardTac = payload => ({ type: actions.GET.CREDIT_CARD.REQUEST_OTHER_BANK_TAC, payload });
export const getMaybankCardResult = payload => ({ type: actions.GET.CREDIT_CARD.MAYBANK_RESULT, payload });
export const getOtherBankCardResult = payload => ({ type: actions.GET.CREDIT_CARD.OTHER_BANK_RESULT, payload });
export const getMaybankCardReceipt = payload => ({ type: actions.GET.CREDIT_CARD.MAYBANK_RECEIPT, payload });
export const getOtherBankCardReceipt = payload => ({ type: actions.GET.CREDIT_CARD.OTHER_BANK_RECEIPT, payload });
export const getFavouriteMaybankCardDetail = payload => ({ type: actions.GET.CREDIT_CARD.FAVOURITE.MAYBANK_DETAIL, payload });
export const getFavouriteOtherCardDetail = payload => ({ type: actions.GET.CREDIT_CARD.FAVOURITE.OTHER_DETAIL, payload });

export const putCreditCardAccess = res => ({ type: actions.PUT.CREDIT_CARD.ACCESS, res });
export const putCreditCardTypes = res => ({ type: actions.PUT.CREDIT_CARD.TYPES, res });
export const putCreditCardMaybankAccess = res => ({ type: actions.PUT.CREDIT_CARD.MAYBANK_ACCESS, res });
export const putCreditCardMaybankHolders = res => ({ type: actions.PUT.CREDIT_CARD.MAYBANK_HOLDERS, res });
export const putCreditCardOwnCards = res => ({ type: actions.PUT.CREDIT_CARD.OWN_MAYBANK_CARDS, res });
export const putCreditCardPaymentModes = res => ({ type: actions.PUT.CREDIT_CARD.PAYMENT_MODES, res });
export const putMaybankCardConfirmation = res => ({ type: actions.PUT.CREDIT_CARD.MAYBANK_CONFIRMATION, res });
export const putOtherBankCardConfirmation = res => ({ type: actions.PUT.CREDIT_CARD.OTHER_BANK_CONFIRMATION, res });
export const putMaybankCardTac = res => ({ type: actions.PUT.CREDIT_CARD.REQUEST_MAYBANK_TAC, res });
export const putOtherBankCardTac = res => ({ type: actions.PUT.CREDIT_CARD.REQUEST_OTHER_BANK_TAC, res });
export const putMaybankCardResult = res => ({ type: actions.PUT.CREDIT_CARD.MAYBANK_RESULT, res });
export const putOtherBankCardResult = res => ({ type: actions.PUT.CREDIT_CARD.OTHER_BANK_RESULT, res });
export const putMaybankCardReceipt = res => ({ type: actions.PUT.CREDIT_CARD.MAYBANK_RECEIPT, res });
export const putOtherBankCardReceipt = res => ({ type: actions.PUT.CREDIT_CARD.OTHER_BANK_RECEIPT, res });

// Mobile Postpaid
export const getMobilePostpaidAccess = payload => ({ type: actions.GET.MOBILE_POSTPAID.ACCESS, payload });
export const getMobilePostpaidDetail = payload => ({ type: actions.GET.MOBILE_POSTPAID.DETAIL, payload });
export const getMobilePostpaidConfirmation = payload => ({ type: actions.GET.MOBILE_POSTPAID.CONFIRM, payload });
export const getMobilePostpaidTac = payload => ({ type: actions.GET.MOBILE_POSTPAID.TAC, payload });
export const getMobilePostpaidResult = payload => ({ type: actions.GET.MOBILE_POSTPAID.RESULT, payload });
export const getMobilePostpaidReceipt = payload => ({ type: actions.GET.MOBILE_POSTPAID.RECEIPT, payload });
export const getMobilePostpaidFavouriteDetail = payload => ({ type: actions.GET.MOBILE_POSTPAID.DETAIL_FROM_FAVOURITE, payload });

export const putMobilePostpaidAccess = res => ({ type: actions.PUT.MOBILE_POSTPAID.ACCESS, res });
export const putMobilePostpaidBillers = res => ({ type: actions.PUT.MOBILE_POSTPAID.BILLERS, res });
export const putMobilePostpaidDetail = res => ({ type: actions.PUT.MOBILE_POSTPAID.DETAIL, res });
export const putMobilePostpaidConfirmation = res => ({ type: actions.PUT.MOBILE_POSTPAID.CONFIRM, res });
export const putMobilePostpaidTac = res => ({ type: actions.PUT.MOBILE_POSTPAID.TAC, res });
export const putMobilePostpaidResult = res => ({ type: actions.PUT.MOBILE_POSTPAID.RESULT, res });
export const putMobilePostpaidReceipt = res => ({ type: actions.PUT.MOBILE_POSTPAID.RECEIPT, res });

// Other Bill
export const getOtherBillAccess = payload => ({ type: actions.GET.OTHER_BILL.ACCESS, payload });
export const getOtherBillPayees = payload => ({ type: actions.GET.OTHER_BILL.PAYEES, payload });
export const getOtherBillDetail = payload => ({ type: actions.GET.OTHER_BILL.DETAIL, payload });
export const getOtherBillConfirmation = payload => ({ type: actions.GET.OTHER_BILL.CONFIRMATION, payload });
export const getOtherBillTac = payload => ({ type: actions.GET.OTHER_BILL.REQUEST_TAC, payload });
export const getOtherBillResult = payload => ({ type: actions.GET.OTHER_BILL.RESULT, payload });
export const getOtherBillReceipt = payload => ({ type: actions.GET.OTHER_BILL.RECEIPT, payload });
export const getOtherBillFavouriteDetail = payload => ({ type: actions.GET.OTHER_BILL.DETAIL_FROM_FAVOURITE, payload });

export const putOtherBillAccess = res => ({ type: actions.PUT.OTHER_BILL.ACCESS, res });
export const putOtherBillBillers = res => ({ type: actions.PUT.OTHER_BILL.BILLERS, res });
export const putOtherBillPayees = res => ({ type: actions.PUT.OTHER_BILL.PAYEES, res });
export const putOtherBillDetail = res => ({ type: actions.PUT.OTHER_BILL.DETAIL, res });
export const putOtherBillConfirmation = res => ({ type: actions.PUT.OTHER_BILL.CONFIRMATION, res });
export const putOtherBillTac = res => ({ type: actions.PUT.OTHER_BILL.TAC, res });
export const putOtherBillResult = res => ({ type: actions.PUT.OTHER_BILL.RESULT, res });
export const putOtherBillReceipt = res => ({ type: actions.PUT.OTHER_BILL.RECEIPT, res });
