import { DEFAULT_LOCALE } from 'settings/constants/language';
import { actions } from '../actions/language';

const { LOCALE } = actions.PUT;

const initialState = {
  locale: DEFAULT_LOCALE,
};

const LanguageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOCALE:
      return { ...state, locale: action.locale };

    default:
      return state;
  }
};

export default LanguageReducer;
