import PropTypes from 'prop-types';

import { Accordion as MaterialAccordion, AccordionSummary, Typography } from '@material-ui/core';

import { ACCORDION_SHADOW_ELEVATION } from 'settings/constants/ui-control';

import Icon from './Icon';

import './style/index.scss';

const Accordion = ({ className, title, titleClassName, children, expandIcon, disabled, defaultExpanded }) => {
  return (
    <MaterialAccordion
      className={`common accordion ${className}`}
      elevation={ACCORDION_SHADOW_ELEVATION}
      disabled={disabled}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary className={titleClassName} expandIcon={<Icon type={expandIcon ?? 'expandMore'} />}>
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      {children}
    </MaterialAccordion>
  );
};

Accordion.defaultProps = {
  className: '',
  title: '',
  titleClassName: '',
  children: null,
  expandIcon: null,
  disabled: false,
  defaultExpanded: false,
};

Accordion.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.node,
  expandIcon: PropTypes.node,
};

export default Accordion;
