export const favourite = {
  label: 'Favorit Saya',
  placeholder: {
    searchPurchase: 'Cari berdasarkan Nama Paggilan, Provider/Nomor Handphone/Nomor Pelanggan',
    searchPayment: 'Cari berdasarkan Nama Panggilan, Kartu/Handphone/Nomor Pelanggan, Jenis Tagihan/Nama',
    searchTransfer: 'Cari berdasarkan Nama Panggilan, Nomor Rekening, Jenis Transfer, Bank',
    searchEmpty: 'Penerima tidak ditemukan',
  },
  form: {
    currency: 'Mata uang',
    beneficiaryNickname: 'Nama Panggilan Penerima',
    dailyTransactionLimit: 'Batas Transaksi Harian',
    title: {
      to: 'kepada',
      addNew: 'Tambahkan Favorit Baru',
      edit: 'Ubah Favorit',
      delete: 'Hapus Favorit',
      virtualAccount: 'Tambahkan Favorit Baru Transfer Maybank Virtual Account',
      intrabank: 'Tambahkan Favorit Baru Transfer Antar Rekening Maybank',
      eWallet: 'Tambahkan Favorit Baru Transfer E-Wallet',
      skn: 'Tambahkan Favorit Baru Transfer LLG/SKN',
      interbank: 'Tambahkan Favorit Baru Transfer Antarbank Real Time',
      rtgs: 'Tambahkan Favorit Baru Transfer RTGS',
      swift: 'Tambahkan Favorit Baru Transfer SWIFT Transfer Valuta Asing',
      virtualAccountEdit: 'Ubah Favorit Transfer Maybank Virtual Account',
      intrabankEdit: 'Ubah Favorit Transfer Antar Rekening Maybank',
      eWalletEdit: 'Ubah Favorit Transfer E-Wallet',
      sknEdit: 'Ubah Favorit Transfer LLG/SKN',
      interbankEdit: 'Ubah Favorit Transfer Antarbank Real Time',
      rtgsEdit: 'Ubah Favorit Transfer RTGS',
      swiftEdit: 'Ubah Favorit Transfer SWIFT Transfer Valuta Asing',
    },
    content: { delete: 'Apakah Anda yakin untuk menghapus Favorit Anda?' },
  },
  drawer: {
    addSuccess: 'Tambahkan Favorit Baru Berhasil!',
    addFailed: 'Gagal Tambah Favorit Baru!',
    editSuccess: 'Ubah Favorit Berhasil!',
    editFailed: 'Gagal Ubah Favorit!',
    deleteSuccess: 'Hapus Favorit Berhasil!',
    deleteFailed: 'Hapus Favorit Gagal!',
    mainPageAddSuccess: 'Favorit Baru Berhasil Ditambah',
    mainPageDeleteSuccess: 'Favorit Berhasil Dihapus',
    mainPageEditSuccess: 'Favorit Berhasil Diubah',
    mainPageAddError: 'Gagal Tambah Favorit Baru!',
    mainPageEditError: 'Gagal Ubah Favorit!',
    mainPageDeleteError: 'Hapus Favorit Gagal!',
  },
  payment: {
    for: 'Pembayaran untuk',
    bankName: 'Nama Bank',
    title: {
      creditCard: 'Tambahkan Favorit Baru untuk Pembayaran Kartu Kredit',
      mobilePostpaid: 'Tambahkan Favorit Baru untuk Pembayaran Pascabayar Seluler',
      utility: 'Tambahkan Favorit Baru untuk Pembayaran Utilitas',
      otherBill: 'Tambahkan Favorit Baru untuk Pembayaran Tagihan Lainnya',
      creditCardEdit: 'Ubah Favorit untuk Pembayaran Kartu Kredit Favorit',
      mobilePostpaidEdit: 'Ubah Favorit untuk Pembayaran Pascabayar Seluler Favorit',
      utilityEdit: 'Ubah Favorit untuk Pembayaran Utilitas Favorit',
      otherBillEdit: 'Ubah Favorit untuk Pembayaran Tagihan Lainnya',
    },
  },
  purchase: {
    for: 'Pembelian untuk',
    operatorName: 'Operator Seluler',
    title: {
      ticket: 'Tambahkan Favorit Baru untuk Pembelian Tiket',
      pln: 'Tambah Favorit Baru Pembelian Pulsa PLN',
      mobile: 'Tambah Favorit Baru untuk Pembelian Pulsa Prabayar dan Paket Data',
      ticketEdit: 'Ubah Pembelian Tiket Favorit',
      plnEdit: 'Ubah Pembelian Pulsa PLN Favorit',
      mobileEdit: 'Ubah Pembelian Isi Ulang Seluler Prabayar dan Paket Data Favorit',
    },
  },
  errorMessage: {
    invalidAccountNickname: 'Panjang maksimum antara 3 hingga 50 karakter',
  },
};
