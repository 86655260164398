import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { Switch } from 'components/common';

import Grid from '@material-ui/core/Grid';

const DynoSwitch = ({ name, label, onChange, value }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container alignItems="center" className="pb0 pt3 ph4">
      <Grid item xs={12} sm={4} className="pb2" />
      <Grid item xs={12} sm={8}>
        <Switch label={label && formatMessage({ id: label })} isChecked={value} onChange={e => onChange({ [name]: e.target.checked })} />
      </Grid>
    </Grid>
  );
};

DynoSwitch.defaultProps = { value: false };

DynoSwitch.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DynoSwitch;
