export const dashboardEndpoint = {
  get: {
    // Get account balances
    allAccountBalance: 'dashboard/v1/summary/all/balances',
    totalWealth: 'wealth/v1/summary/totalWealth',
    casaAccountBalance: 'dashboard/v1/summary/casa/balances',
    loanAccountBalance: 'dashboard/v1/summary/loan/balances',
    timeDepositAccountBalance: 'dashboard/v1/summary/td/balances',
    creditCardAccountBalance: 'dashboard/v1/summary/cc/balances',

    // Wealth Endpoint
    wealthAccountBalance: 'wealth/v1/summary/portfolio',
    mutualFundSummary: 'wealth/v1/list/mf',
    mutualFundHistory: 'wealth/v1/history/mf',
    mutualFundDetail: 'wealth/v1/details/mf',
    bondSummary: 'wealth/v1/list/bonds',
    bondDetail: 'wealth/v1/details/bonds',
    bondHistory: 'wealth/v1/history/bonds',
    bancaSummary: 'wealth/v1/list/banca',
    bancaDetail: 'wealth/v1/details/banca',

    // Get account details
    creditCardDetail: 'dashboard/v1/details/cc',
    // Note: Same endpoints for saving & current account detail
    casaAccountDetail: 'dashboard/v1/details/casa',
    loanAccountDetail: 'dashboard/v1/details/loans',
    unitTrustDetail: 'dashboard/v1/details/wealth/insurance/ut',
    timeDepositAccountDetail: 'dashboard/v1/details/td',

    // Get transaction type
    casaTransactionType: '/dashboard/v1/txnhist/m2utxn',

    // Get casa transaction m2u receipt
    casaTransactionM2UReceipt: '/dashboard/v1/txnhist/download/receipt',

    // Get transaction history
    savingTransactionHistory: 'dashboard/v1/txnhist/casa/savings',
    currentTransactionHistory: 'dashboard/v1/txnhist/casa/current',
    creditCardTransactionHistory: 'dashboard/v1/txhist/cc',

    // Time Deposit Stop ARO APIs
    initiateStopAro: 'dashboard/v1/tac/td/aro/initiate',
    requestTac: 'notification/v1/tac/request',
    confirmStopAro: 'dashboard/v1/tac/td/aro/confirm',
    stopAroReceipt: 'notification/v1/tac/reciept/pdf',

    // Time Deposit Opening
    products: 'deposito/v1/td/product',
    requestTDTAC: 'deposito/v1/td/request',
    interestRate: 'deposito/v1/td/interest/rate',
    doOpenAccount: 'deposito/v1/td/opening/account',
    validateTD: 'deposito/v1/td/validation',
  },
};
