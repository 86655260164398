import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions, putDeleteSent, putSentList } from 'middleware/actions/inboxs';
import { inboxsEndpoint as endpoint } from 'providers/endpoints/inboxs';
import { putErrorResponse } from 'middleware/actions/error';

import Instance from 'providers/instance';
import { autoSelectFirstInbox } from 'utils/inbox/formatter';

const getLocale = state => state.LanguageReducer.locale;
const getSentList = ({ body }) => Instance.post(endpoint.sent.sentList, body);
const deleteSent = ({ body }) => Instance.post(endpoint.sent.deleteSent, body);

function* getSentListSaga({ payload = {} }) {
  try {
    const locale = 'en';
    const body = { locale, ...payload };

    const { data } = yield call(getSentList, { body });
    yield put(putSentList(data));

    // set auto focus first inbox when get data inbox list
    autoSelectFirstInbox();
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteSentSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(deleteSent, { body });

    yield put(putDeleteSent(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Sent() {
  yield all([
    takeLatest(actions.GET.INBOX.INBOXS_SENT_LIST, getSentListSaga),
    takeLatest(actions.GET.INBOX.INBOXS_DELETE_SENT, deleteSentSaga),
  ]);
}
