export const actions = {
  GET: {
    GBI: {
      PENSION_GOAL: 'DIGITAL_WEALTH_GET_GBI_PENSION_GOAL',
      EDUCATION_GOAL: 'DIGITAL_WEALTH_GET_GBI_EDUCATION_GOAL',
      MAX_INVESTMENT_GOAL: 'DIGITAL_WEALTH_GET_GBI_MAX_INVESTMENT_GOAL',
      GOAL_PLAN: 'DIGITAL_WEALTH_GET_GBI_GOAL_PLAN',
      EXPANDED_PLAN: 'DIGITAL_WEALTH_GET_GBI_EXPANDED_PLAN',
      PLAN_DETAIL: 'DIGITAL_WEALTH_GET_GBI_PLAN_DETAIL',
    },
    PORTFOLIO: {
      OVERVIEW: 'DIGITAL_WEALTH_GET_PORTFOLIO_OVERVIEW',
      DASHBOARD: 'DIGITAL_WEALTH_GET_PORTFOLIO_DASHBOARD',
      BONDS: 'DIGITAL_WEALTH_GET_PORTFOLIO_BONDS',
      BOND_DETAIL: 'DIGITAL_WEALTH_GET_PORTFOLIO_BOND_DETAIL',
      BANCAS: 'DIGITAL_WEALTH_GET_PORTFOLIO_BANCAS',
      BANCA_DETAIL: 'DIGITAL_WEALTH_GET_PORTFOLIO_BANCA_DETAIL',
      MUTUAL_FUNDS: 'DIGITAL_WEALTH_GET_PORTFOLIO_MUTUAL_FUNDS',
      MUTUAL_FUND_DETAIL: 'DIGITAL_WEALTH_GET_PORTFOLIO_MUTUAL_FUND_DETAIL',
    },
  },
  PUT: {
    RESET_GOAL: 'DIGITAL_WEALTH_PUT_RESET_GOAL',
    GBI: {
      PENSION_GOAL: 'DIGITAL_WEALTH_PUT_GBI_PENSION_GOAL',
      EDUCATION_GOAL: 'DIGITAL_WEALTH_PUT_GBI_EDUCATION_GOAL',
      MAX_INVESTMENT_GOAL: 'DIGITAL_WEALTH_PUT_GBI_MAX_INVESTMENT_GOAL',
      GOAL_PLAN: 'DIGITAL_WEALTH_PUT_GBI_GOAL_PLAN',
      EXPANDED_PLAN: 'DIGITAL_WEALTH_PUT_GBI_EXPANDED_PLAN',
      PLAN_DETAIL: 'DIGITAL_WEALTH_PUT_GBI_PLAN_DETAIL',
    },
    PORTFOLIO: {
      OVERVIEW: 'DIGITAL_WEALTH_PUT_PORTFOLIO_OVERVIEW',
      DASHBOARD: 'DIGITAL_WEALTH_PUT_PORTFOLIO_DASHBOARD',
      BONDS: 'DIGITAL_WEALTH_PUT_PORTFOLIO_BONDS',
      BOND_DETAIL: 'DIGITAL_WEALTH_PUT_PORTFOLIO_BOND_DETAIL',
      BANCAS: 'DIGITAL_WEALTH_PUT_PORTFOLIO_BANCAS',
      BANCA_DETAIL: 'DIGITAL_WEALTH_PUT_PORTFOLIO_BANCA_DETAIL',
      MUTUAL_FUNDS: 'DIGITAL_WEALTH_PUT_PORTFOLIO_MUTUAL_FUNDS',
      MUTUAL_FUND_DETAIL: 'DIGITAL_WEALTH_PUT_PORTFOLIO_MUTUAL_FUND_DETAIL',
    },
  },
};

export const putResetGoal = () => ({ type: actions.PUT.RESET_GOAL });

// GBI
export const getPensionGoal = payload => ({ type: actions.GET.GBI.PENSION_GOAL, payload });
export const getEducationGoal = payload => ({ type: actions.GET.GBI.EDUCATION_GOAL, payload });
export const getMaxInvestmentGoal = payload => ({ type: actions.GET.GBI.MAX_INVESTMENT_GOAL, payload });
export const getGoalPlan = payload => ({ type: actions.GET.GBI.GOAL_PLAN, payload });
export const getExpandedPlan = payload => ({ type: actions.GET.GBI.EXPANDED_PLAN, payload });
export const getPlanDetail = payload => ({ type: actions.GET.GBI.PLAN_DETAIL, payload });

export const putPensionGoal = res => ({ type: actions.PUT.GBI.PENSION_GOAL, res });
export const putEducationGoal = res => ({ type: actions.PUT.GBI.EDUCATION_GOAL, res });
export const putMaxInvestmentGoal = res => ({ type: actions.PUT.GBI.MAX_INVESTMENT_GOAL, res });
export const putGoalPlan = res => ({ type: actions.PUT.GBI.GOAL_PLAN, res });
export const putExpandedPlan = res => ({ type: actions.PUT.GBI.EXPANDED_PLAN, res });
export const putPlanDetail = res => ({ type: actions.PUT.GBI.PLAN_DETAIL, res });

// Portfolio 360
export const getPortfolio = payload => ({ type: actions.GET.PORTFOLIO.OVERVIEW, payload });
export const getBancas = payload => ({ type: actions.GET.PORTFOLIO.BANCAS, payload });
export const getBancaDetail = payload => ({ type: actions.GET.PORTFOLIO.BANCA_DETAIL, payload });
export const getBonds = payload => ({ type: actions.GET.PORTFOLIO.BONDS, payload });
export const getBondDetail = payload => ({ type: actions.GET.PORTFOLIO.BOND_DETAIL, payload });
export const getMutualFunds = payload => ({ type: actions.GET.PORTFOLIO.MUTUAL_FUNDS, payload });
export const getMutualFundDetail = payload => ({ type: actions.GET.PORTFOLIO.MUTUAL_FUND_DETAIL, payload });
// to be used in My Account
export const getWealthDashboard = payload => ({ type: actions.GET.PORTFOLIO.DASHBOARD, payload });

export const putPortfolio = res => ({ type: actions.PUT.PORTFOLIO.OVERVIEW, res });
export const putBonds = res => ({ type: actions.PUT.PORTFOLIO.BONDS, res });
export const putBondDetail = res => ({ type: actions.PUT.PORTFOLIO.BOND_DETAIL, res });
export const putBancas = res => ({ type: actions.PUT.PORTFOLIO.BANCAS, res });
export const putBancaDetail = res => ({ type: actions.PUT.PORTFOLIO.BANCA_DETAIL, res });
export const putMutualFunds = res => ({ type: actions.PUT.PORTFOLIO.MUTUAL_FUNDS, res });
export const putMutualFundDetail = res => ({ type: actions.PUT.PORTFOLIO.MUTUAL_FUND_DETAIL, res });
// to be used in My Account
export const putWealthDashboard = res => ({ type: actions.PUT.PORTFOLIO.DASHBOARD, res });
