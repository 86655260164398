import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatArrayKeys } from 'utils/formatter';
import { putErrorResponse } from 'middleware/actions/error';
import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';
import { formatFavouriteAccountResult } from 'utils/favourite/transfer/formatter';
import { actions, putFavouriteAccounts, putPurchaseTypes } from 'middleware/actions/favourite/purchase';

const getClientIP = () => remoteIpInstance.get();

// TODO: set to microservice path
const getMaintenancePurchase = ({ body }) => Instance.post(endpoint.get.maintenances, body);
const getFavouriteAccount = ({ body }) => Instance.post(endpoint.get.favouriteAccount, body);

const getLocale = state => state.LanguageReducer.locale;

const { FAVOURITE_ACCOUNT, MAINTENANCE_LIST } = actions.GET;

function* getFavouriteAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getFavouriteAccount, { body });
    yield put(putFavouriteAccounts(formatFavouriteAccountResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMaintenanceListSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getMaintenancePurchase, { body });
    const initialMaps = get(data, 'purchaseToMap', []);
    yield put(putPurchaseTypes(formatArrayKeys(initialMaps)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PurchaseFavourite() {
  yield all([takeLatest(FAVOURITE_ACCOUNT, getFavouriteAccountSaga), takeLatest(MAINTENANCE_LIST, getMaintenanceListSaga)]);
}
