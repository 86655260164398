import PropTypes from 'prop-types';

import isNull from 'lodash/isNull';

import { Card as MaterialCard, CardActions, CardContent, Typography, Paper } from '@material-ui/core';

import { usePanelCardMenuStyle } from './materialStyle';

import Menu from '../Menu';

import './style.scss';

const PanelCard = ({
  index,
  title,
  desc,
  hasMenu,
  onClick,
  hasImage,
  cardImage,
  menuItems,
  onMenuClick,
  footerTitle,
  customFooter,
  hasFooterTitle,
  footerSubtitle,
  hasCustomFooter,
  footerSubtitleNote,
  footerSubtitleClassname,
}) => {
  const classes = usePanelCardMenuStyle();
  const hasFooterSubtitleNote = !isNull(footerSubtitleNote);

  return (
    <Paper className="card card--panel" onClick={onClick}>
      <MaterialCard className="card--panel">
        <CardContent className="flex flex-row items-start justify-between">
          <div className="flex flex-row items-start">
            {hasImage && <img alt="" src={cardImage} className="content--img" />}
            <div>
              <Typography gutterBottom className="content--title" component="h1">
                {title}
              </Typography>
              <Typography variant="body2" className="content--desc" component="p">
                {desc}
              </Typography>
            </div>
          </div>
          {hasMenu && <Menu className="card--menu" menuClassname={classes} menuItems={menuItems} onItemClick={onMenuClick} />}
        </CardContent>

        <CardActions className="panel--footer">
          {hasCustomFooter ? (
            customFooter
          ) : (
            <div className="flex flex-column">
              {hasFooterTitle && <span className="footer--title">{footerTitle}</span>}
              <span className={`footer--subtitle ${footerSubtitleClassname}`}>{footerSubtitle}</span>
              {hasFooterSubtitleNote && <span className="footer--subtitle-note">{footerSubtitleNote}</span>}
            </div>
          )}
        </CardActions>
      </MaterialCard>
    </Paper>
  );
};

PanelCard.defaultProps = {
  desc: '',
  index: '',
  title: '',
  cardImage: '',
  footerTitle: '',
  footerSubtitle: '',
  footerSubtitleNote: null,
  menuItems: [],
  onClick: null,
  onMenuClick: null,
  hasMenu: false,
  hasImage: false,
  customFooter: null,
  hasFooterTitle: false,
  hasCustomFooter: false,
  footerSubtitleClassname: 'black',
};

PanelCard.propTypes = {
  desc: PropTypes.node,
  title: PropTypes.string,
  hasMenu: PropTypes.bool,
  onClick: PropTypes.func,
  hasImage: PropTypes.bool,
  menuItems: PropTypes.array,
  onMenuClick: PropTypes.func,
  cardImage: PropTypes.string,
  customFooter: PropTypes.node,
  footerTitle: PropTypes.string,
  hasFooterTitle: PropTypes.bool,
  hasCustomFooter: PropTypes.bool,
  footerSubtitleClassname: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  footerSubtitle: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  footerSubtitleNote: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PanelCard;
