// responseCode mapping from API
export const errorMessages = {
  SYSTEM: 'Saat ini layanan kami sedang tidak dapat digunakan.',
  ERR_00: 'Berjaya',
  ERR_01: 'User Name/Password salah. Klik "Lupa Login" untuk atur ulang password Anda',
  ERR_02: 'Nomor referensi dan atau nomor registrasi salah.',
  ERR_03: 'Username terblokir.',
  ERR_04: 'Tidak aktif.',
  ERR_05: 'TAC terkunci.',
  ERR_06: 'User Name/Password salah. Klik "Lupa Login" untuk atur ulang password Anda',
  ERR_07: 'Anda telah mencapai jumlah maksimal untuk login. Klik "Tidak bisa masuk ke akun anda?" untuk membuka kunci.',
  ERR_08:
    'User ID Anda terblokir. Silakan melepas blokir melalui Cabang Maybank terdekat atau hubungi Call Center Maybank 1500611 / +622178869811 (dari luar negeri).',
  ERR_09: 'User ID Anda terblokir. Silakan melepas blokir melalui menu "Unlock User ID/TAC"',
  ERR_10: 'Username Dormant. Hubungi customer care.',
  ERR_11: 'Unknown Error.',
  ERR_12: 'Anda tidak dapat menggunakan 5 kata sandi sebelumnya yang sama. Silakan coba kata sandi lain',
  ERR_13: 'Password baru Anda sama dengan User ID Anda. Silakan coba kata sandi lain',
  ERR_14: 'Username sudah ada.',
  ERR_15: 'Username sudah terdaftar.',
  ERR_16: 'Salah input.',
  ERR_17: 'Pertanyaan keamanan salah.',
  ERR_18: 'TAC terkunci.',
  ERR_19: 'Kata sandi Anda saat ini salah. Silakan coba lagi',
  ERR_20: 'Nomor ponsel salah.',
  ERR_21: 'Nomor Rekening Tidak Valid',
  ERR_22: 'Kode mata uang salah atau kode mata uang tidak valid.',
  ERR_23: 'Tidak ada transaksi.',
  ERR_24: 'Tidak ada transaksi.',
  ERR_25: 'Tidak ada transaksi kartu kredit.',
  ERR_26: 'Silakan pilih opsi "Own Account" untuk transaksi transfer antar rekening Anda.',
  ERR_27: 'Bukan rekening pinjaman.',
  ERR_28: 'Melebihi limit favorit.',
  ERR_29: 'Nomor rekening salah.',
  ERR_30: 'Melebihi limit per transaksi Real Time Transfer.',
  ERR_31: 'TAC salah.',
  ERR_32: 'User ID Anda terblokir. Silakan melepas blokir melalui menu "Unlock User ID/TAC"',
  ERR_33: 'TAC kadaluarsa.',
  ERR_34: 'Pengiriman TAC gagal. Silakan klik "Minta TAC" lagi.',
  ERR_35: 'TAC telah melebihi maksimum input.',
  ERR_36: 'Tidak ada rekening yang tersedia.',
  ERR_37_OPEN_PAYMENT: 'Nomor Kartu Kredit Tidak Valid',
  ERR_37: 'Nomor Kartu Tidak Valid',
  ERR_38: 'Nomor pelanggan tidak ada.',
  ERR_39: 'Pertanyaan test lebih dari 4 kalimat.',
  ERR_40: 'Nickname sudah ada yang menggunakan.',
  ERR_41: 'NO ponsel sudah terdaftar.',
  ERR_42: 'Foreign telegraphic transfer swift not registered.',
  ERR_43: 'Bank tujuan dibutuhkan.',
  ERR_44: 'Nomor Rekening sudah terdaftar',
  ERR_44_EWALLET: 'Nomor Pelanggan sudah terdaftar',
  ERR_45: 'No Subject Available to Compose New Message.',
  ERR_46: 'Nomor rekening/nomor referensi sudah ada.',
  ERR_47: 'Rekening tidak ada.',
  ERR_48: 'Penempatan Deposito minimal IDR 10.000.000 atau equivalen.',
  ERR_49: 'Nomor rekening sudah ada.',
  ERR_50: 'Tanggal berakhir harus lebih besar dari hari ini.',
  ERR_51: 'Saldo Tidak Mencukupi',
  ERR_52: 'Tanggal mulai recurring salah.',
  ERR_53: 'Invalid Start Date for Recurring Payment.',
  ERR_54: 'Telah melebihi limit transaksi .',
  ERR_55: 'Bulan Frekuensi Pembayaran Tidak Valid.',
  ERR_56: 'Frekuensi Transfer tidak dalam rentang tanggal efektif.',
  ERR_57: 'Tanggal efektif salah.',
  ERR_58: 'Invalis user session.',
  ERR_59: 'Transaksi melewati jam operational harian bank. Silahkan pilih tanggal yang lain',
  ERR_60: 'Transkasi sedang diproses silakan cek saldo anda.',
  ERR_61: 'Nomor rekening salah.',
  ERR_62: 'Tidak ada nomor rekening.',
  ERR_63: 'Jumlah transaksi tidak boleh kurang dari jumlah yang diizinkan untuk RTGS',
  ERR_64: 'TAC dibutuhkan.',
  ERR_65: 'Nomor Rekening Tagihan Tidak Valid',
  ERR_66: 'Nomor/ID Pelanggan yang Anda masukkan salah',
  ERR_68: 'Transfer Anda Time Out',
  ERR_70: 'Password baru Anda sama dengan User ID Anda. Silakan coba kata sandi lain',
  ERR_73: 'Akun STP salah',
  ERR_75: 'No rekening yang Anda masukkan tidak terdaftar.',
  ERR_76: 'Detail No rekening salah, silakan coba lagi setelah 24 jam',
  ERR_77: 'Detail No rekening salah, silakan coba lagi setelah 24 jam',
  ERR_78: 'Detail Kartu Kredit salah, silakan coba lagi setelah 24 jam',
  ERR_79: 'Detail Kartu Kredit salah',
  ERR_80: 'Nomor Rekening atau PIN yang Anda masukkan salah.',
  ERR_81: 'Detail akun tidak valid, kunjungi Cabang terdekat untuk melanjutkan pendaftaran',
  ERR_82: 'Proses lepas blokir User ID dengan Kartu Kredit tidak berhasil. {contactNo}',
  ERR_83: 'Detail akun tidak valid. Silakan coba lagi setelah 24 jam.',
  ERR_87: 'Send Email Failed.',
  ERR_89: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_90: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_91: 'Transaksi tidak dapat diproses.',
  ERR_92: 'Batas waktu operasi sistem',
  ERR_93: 'Ralat, sistem tidak tersedia',
  ERR_94: 'Transaksi tidak dapat diproses',
  ERR_95: 'Terjadi gangguan pada sistem harap login kembali.',
  ERR_96: 'Logger Error.',
  ERR_97: 'OpenAM Error.',
  ERR_98: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_99: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_100: 'Tanggal yang Anda pilih jatuh pada hari libur silahkan pilih tanggal yang lain.',
  ERR_101: 'LLD information not required',
  ERR_102: 'Detail Kartu Kredit salah, silakan coba lagi setelah 24 jam',
  ERR_103: 'Proses setel ulang User ID dengan Kartu Kredit tidak berhasil. Silakan coba lagi setelah 24 jam.',
  ERR_104: 'Rekening Penerima tidak valid',
  ERR_105: 'Nomor Ponsel tidak valid',
  ERR_106: 'No Pelanggan Tidak Sesuai',
  ERR_107: 'Melebihi Jam Operasi SWIFT',
  ERR_108: 'No Pelanggan Tidak Sesuai',
  ERR_109: 'Kode Pembayaran Tidak Sesuai',
  ERR_110: 'Nama Panggilan Penerima sudah ada',
  ERR_115: 'Nomor Kartu sudah terdaftar',
  ERR_116: 'Nomor Ponsel sudah terdaftar',
  ERR_117: 'Nomor Pelanggan sudah terdaftar',
  ERR_118: '{accountType} sudah terdaftar',
  ERR_119: 'Nomor Akun Virtual sudah terdaftar',
  ERR_120: 'No Kartu Kredit yang Anda masukkan salah.',
  ERR_121: 'Kartu Anda tidak terdaftar. Harap coba kembali beberapa saat lagi.',
  ERR_122: 'Di Bawah Jumlah Minimum Transaksi E-Wallet',
  ERR_123: 'Melebihi Jumlah Maksimum Transaksi E-Wallet.',
  ERR_124: 'Melebihi Batas Transaksi Harian E-Wallet.',
  ERR_125: 'Tagihan Sudah Dibayar.',

  // System errors
  ERR_201: 'System Error (01).',
  ERR_202: 'System Error (02).',
  ERR_203: 'System Error (03).',
  ERR_204: 'System Error (04).',
  ERR_205: 'Transaksi tidak dapat diproses.',
  ERR_206: 'System Error (06).',
  ERR_207: 'System Error (07).',
  ERR_208: 'System Error (08).',
  ERR_209: 'System Error (09).',
  ERR_210: 'Tagihan harus dibayarkan melalui counter.',
  ERR_211: 'System Error (11).',
  ERR_212: 'Transaksi tidak valid tidak sign-on.',
  ERR_213: 'Jumlah tidak valid.',
  ERR_214: 'Nomor pelanggan salah.',
  ERR_215: 'System Error (15).',
  ERR_216: 'System Error (16).',
  ERR_217: 'System Error (17).',
  ERR_218: 'System Error (18).',
  ERR_219: 'System Error (19).',
  ERR_220: 'System Error (20).',
  ERR_221: 'System Error (21).',
  ERR_222: 'System Error (22).',
  ERR_223: 'System Error (23).',
  ERR_224: 'System Error (24).',
  ERR_225: 'Tidak ada transaksi original.',
  ERR_226: 'System Error (26).',
  ERR_227: 'System Error (27).',
  ERR_228: 'System Error (28).',
  ERR_229: 'System Error (29).',
  ERR_230: 'Format error.',
  ERR_231: 'Kode bank salah.',
  ERR_232: 'System Error (32).',
  ERR_233: 'System Error (33).',
  ERR_234: 'System Error (34).',
  ERR_235: 'System Error (35).',
  ERR_236: 'System Error (36).',
  ERR_237: 'System Error (37).',
  ERR_238: 'System Error (38).',
  ERR_239: 'System Error (39).',
  ERR_240: 'Request function not supported.',
  ERR_241: 'System Error (41).',
  ERR_242: 'System Error (42).',
  ERR_243: 'System Error (43).',
  ERR_244: 'System Error (44).',
  ERR_245: 'System Error (45).',
  ERR_246: 'System Error (46).',
  ERR_247: 'System Error (47).',
  ERR_248: 'System Error (48).',
  ERR_249: 'System Error (49).',
  ERR_250: 'System Error (50).',
  ERR_251: 'Saldo Tidak Mencukupi',
  ERR_252: 'Tidak ada pengecekan rekening.',
  ERR_253: 'Tidak ada rekening tabungan.',
  ERR_254: 'Kartu kadaluarsa.',
  ERR_255: 'PIN salah.',
  ERR_256: 'System Error (56).',
  ERR_257: 'Transaksi tidak diijinkan.',
  ERR_258: 'System Error (58).',
  ERR_259: 'System Error (59).',
  ERR_260: 'System Error (60).',
  ERR_261: 'Limit nominal terlampaui.',
  ERR_262: 'Kartu tidak dapat digunakan.',
  ERR_263: 'System Error (63).',
  ERR_264: 'System Error (64).',
  ERR_265: 'Limit terlampaui.',
  ERR_266: 'System Error (66).',
  ERR_267: 'System Error (67).',
  ERR_268: 'Transaksi tidak dapat diproses.',
  ERR_269: 'System Error (69).',
  ERR_270: 'Voucher tidak tersedia.',
  ERR_271: 'System Error (71).',
  ERR_272: 'System Error (72).',
  ERR_273: 'System Error (73).',
  ERR_274: 'System Error (74).',
  ERR_275: 'Maximum percobaan PIN terlampaui.',
  ERR_276: 'Nomor rekening salah nama pelanggan tidak sama dengan di tagihan.',
  ERR_277: 'Labih dari 3 tagihan.',
  ERR_278: '2 tagihan dalam 1 bulan.',
  ERR_279: 'Nomor telepon terblokir.',
  ERR_280: 'Nomor referensi salah.',
  ERR_281: 'Telepon/kartu sudah kadaluarsa.',
  ERR_282: 'System Error (82).',
  ERR_283: 'System Error (83).',
  ERR_284: 'System Error (84).',
  ERR_285: 'System Error (85).',
  ERR_286: 'System Error (86).',
  ERR_287: 'System Error (87).',
  ERR_288: 'Tagihan telah terbayar.',
  ERR_289: 'Transaksi tidak dapat diproses.',
  ERR_290: 'Sedang proses cut off.',
  ERR_291: 'Transaksi tidak dapat diproses.',
  ERR_292: 'Transaksi tidak dapat diproses.',
  ERR_293: 'System Error (93).',
  ERR_294: 'Transaksi duplikat.',
  ERR_295: 'System Error (95).',
  ERR_296: 'System Malfunction.',
  ERR_297: 'System Error (97).',
  ERR_298: 'Terjadi gangguan komunikasi. Silakan periksa saldo Anda.',
  ERR_299: 'PIN Blok.',
  ERR_500: 'Saat ini layanan kami sedang tidak dapat digunakan.',
  ERR_800:
    '{clickHere} untuk mendapatkan fasilitas kartu kredit dari Maybank yang sesuai dengan kebutuhan Anda dan nikmati berbagai promo menariknya',
  ERR_1300: 'Di bawah jumlah minimum',
  ERR_1304: 'Anda telah melebihi limit transaksi untuk Real Time Transfer',

  // For transaction result (statusCode)
  ERR_ESB_01: 'Nominal tidak boleh kosong',
  ERR_ESB_02: 'Dana Anda tidak mencukupi',
  ERR_ESB_03: 'Rekening tujuan tidak ditemukan',
  ERR_ESB_04: 'Pemeliharaan Sistem, coba beberapa saat lagi',
  ERR_ESB_05: 'Transaksi gagal, status rekening tujuan invalid',
  ERR_ESB_06: 'Transaksi lebih dari limit maks atau kurang dari limit min',
  ERR_ESB_07: 'Saldo Anda tidak mencukupi',
  ERR_ESB_08: 'Rekening sumber atau tujuan tidak dapat untuk transaksi online',
  ERR_ESB_09: 'Transaksi valas ekuivalen lebih USD 25,000/bulan',
  ERR_ESB_10: 'Saldo Anda tidak mencukupi',
  ERR_ESB_11: 'Saldo Anda tidak mencukupi',
  ERR_ESB_23: 'Transaksi tidak dapat diproses.',
  ERR_ESB_51: 'Saldo Anda tidak mencukupi',
  ERR_ESB_107: 'Nama pengguna dan kata sandi yang tidak valid',

  // Custom errors
  ERR_FAV_01: 'Tidak ada daftar favorit.',
  ERR_CC_01: 'Nomor kartu kredit dibutuhkan.',
  ERR_PROCEDURE_ERROR: 'Untuk sementara layanan ini tidak tersedia.',
  ERR_INVALID_SWIFT_BANK_SEARCH: 'Nama Bank atau Kode SWIFT tidak sesuai',
  ERR_INVALID_SWIFT_SEARCH: 'Kode SWIFT tidak sesuai',
  ERR_INVALID_BANK_SEARCH: 'Bank penerima tidak ditemukan',
  ERR_REQUEST_TIMEOUT: 'Permintaan Anda tidak dapat diproses saat ini. Silakan coba beberapa saat lagi.',
  ERR_DEFAULT_ERROR: 'Silakan periksa koneksi internet Anda dan silakan coba kembali.',
  ERR_UNRESPONSIVE_HOST: 'Silakan periksa koneksi internet Anda dan silakan coba kembali.',
  ERR_PWD_COMPLEXITY: 'Password harus menggunakan huruf dan angka.',
  ERR_PWD_LENGTH: 'Password tidak dapat kurang dari 6 karakter',
  ERR_NO_ACCOUNT: 'Anda tidak memiliki rekening yang terhubung.',
  ERR_NO_CC: 'Anda tidak memiliki CC yang terhubung.',
  ERR_MAX_REGISTERED_DEVICE: 'Maaf Anda sudah mendaftarkan 3 perangkat',
  ERR_ACCOUNT_NOT_ALLOWED: 'Rekening yang Anda pilih tidak bisa digunakan untuk transaksi ini',
  ERR_AMOUNT_LIMIT_EXCEEDED: 'Jumlah harus dalam kisaran 0.01 sampai 9999999999.99',
  ERR_INVALID_INPUT: 'Parameter transaksi tidak valid',
  ERR_INVALID_ACCT: 'Rekening yang Anda pilih tidak bisa digunakan untuk transaksi ini',
  ERR_NO_SENDER_ACCOUNT: 'Anda tidak memiliki akun yang diizinkan untuk transaksi ini',
  ERR_NON_IDR: 'Pembukaan deposito saat ini hanya untuk rekening IDR',
  ERR_FAV_EXISTS: 'Sudah terdaftar di favorit.',
  ERR_QR00: 'Limit Maksimum per Transaksi QR IDR 2.000.000',
  ERR_NONFIN:
    'Anda tidak memiliki akses untuk fitur ini. Silakan kunjungi kantor cabang terdekat untuk mengganti mode transaksi Anda ke Finansial.',
  ERR_LIMIT: 'Transaksi melebihi limit per transfer {0}',
  ERR_TRANSACTION_SERVICE_OFFLINE: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_REQUIRED: 'Pastikan Anda mengisi semua informasi yang diperlukan untuk transaksi ini',
  ERR_SERVER_ERROR: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ERR_59_8:
    'Transaksi tidak dapat diproses setelah jam operasional SWIFT. <br/>SWIFT tersedia {swiftStartTime} - {swiftEndTime} GMT +7 pada hari kerja.',
  ERR_107_8:
    'Transaksi tidak dapat diproses setelah jam operasional SWIFT. <br/>SWIFT tersedia {swiftStartTime} - {swiftEndTime} GMT +7 pada hari kerja.',
  ERR_HALF_KYC_NON_FINANCIAL: 'Anda tidak memiliki akses untuk melakukan transaksi ini. Silahkan hubungi Call Center Maybank 1500611.',
  ERR_MAINTENANCE_PAGE: 'Situs web Sedang Dalam Pemeliharaan',
  ERR_MAINTENANCE_PAGE_WITH_SORRY:
    'Kami mohon maaf karena M2U Web saat ini sedang menjalani pemeliharaan terjadwal. Kita harus segera kembali.',
};
