import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';

import LoadingIcon from 'assets/icons/loading.gif';

const Loader = ({ className, isOpen }) => (
  <Backdrop className={`common loader ${className}`} open={isOpen}>
    <img src={LoadingIcon} alt="Loading..." />
  </Backdrop>
);

Loader.defaultProps = { className: '', isOpen: false };

Loader.propTypes = { className: PropTypes.string, isOpen: PropTypes.bool };

export default Loader;
