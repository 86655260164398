import { get } from 'utils/lodash';

export const formatPostpaidAddFavouriteResultPayload = payload => {
  const tacValue = get(payload, 'tacValue', '');
  const isFromTransferResult = get(payload, 'confirmationDetail.isFromTransferResult', false);

  return {
    isFromTransferResult,
    ...(!isFromTransferResult ? { tacValue } : {}),
    userId: get(payload, 'userId', ''),
    restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', {}),
    nickname: get(payload, 'confirmationDetail.nickname', ''),
    mobileNo: get(payload, 'confirmationDetail.mobileNo', ''),
    payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
    toAccountHolderName: get(payload, 'confirmationDetail.toAccountHolderName', ''),
  };
};

export const formatPostpaidEditFavouritePayload = payload => ({
  userId: get(payload, 'userId', ''),
  nickname: get(payload, 'nickname', ''),
  mobileNo: get(payload, 'mobileNumber', ''),
  payeeCode: get(payload, 'payeeCode', ''),
});

export const formatPostpaidEditFavouriteResultPayload = payload => ({
  userId: get(payload, 'userId', ''),
  tacValue: get(payload, 'tacValue', ''),
  restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', ''),
  nickname: get(payload, 'confirmationDetail.nickname', ''),
  mobileNo: get(payload, 'confirmationDetail.mobileNo', ''),
  payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
});
