export const actions = {
  GET: {
    FAVOURITE_ACCOUNT: 'FAVOURITE_PURCHASE_GET_FAVOURITE_ACCOUNT',
    MAINTENANCE_LIST: 'FAVOURITE_PURCHASE_GET_MAINTENANCE_LIST',
    MOBILE_RELOAD: {
      ADD_FAVOURITE: {
        DETAIL: 'ADD_FAVOURITE_GET_MOBILE_RELOAD_DETAIL',
        DETAIL_AFTER_PURCHASE: 'ADD_FAVOURITE_GET_MOBILE_RELOAD_DETAIL_FROM_PURCHASE',
        CONFIRM: 'ADD_FAVOURITE_GET_MOBILE_RELOAD_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_MOBILE_RELOAD_TAC',
        RESULT: 'ADD_FAVOURITE_GET_MOBILE_RELOAD_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_MOBILE_RELOAD_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_MOBILE_RELOAD_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_MOBILE_RELOAD_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_MOBILE_RELOAD_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_MOBILE_RELOAD_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_MOBILE_RELOAD_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_MOBILE_RELOAD_RESULT',
      },
      PURCHASE_FROM_FAVOURITE: {
        DETAIL: 'PURCHASE_FROM_FAVOURITE_GET_MOBILE_RELOAD_DETAIL',
        CONFIRM: 'PURCHASE_FROM_FAVOURITE_GET_MOBILE_RELOAD_CONFIRM',
        TAC: 'PURCHASE_FROM_FAVOURITE_GET_MOBILE_RELOAD_TAC',
        RESULT: 'PURCHASE_FROM_FAVOURITE_GET_MOBILE_RELOAD_RESULT',
        RECEIPT: 'PURCHASE_FROM_FAVOURITE_GET_MOBILE_RELOAD_RECEIPT',
      },
    },
    PLN: {
      ADD_FAVOURITE: {
        DETAIL_FROM_PURCHASE: 'ADD_FAVOURITE_GET_PLN_DETAIL_FROM_PURCHASE',
        CONFIRM: 'ADD_FAVOURITE_GET_PLN_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_PLN_TAC',
        RESULT: 'ADD_FAVOURITE_GET_PLN_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_PLN_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_PLN_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_PLN_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_PLN_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_PLN_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_PLN_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_PLN_RESULT',
      },
      PURCHASE_FROM_FAVOURITE: {
        DETAIL: 'PURCHASE_FROM_FAVOURITE_GET_PLN_DETAIL',
        CONFIRM: 'PURCHASE_FROM_FAVOURITE_GET_PLN_CONFIRM',
        TAC: 'PURCHASE_FROM_FAVOURITE_GET_PLN_TAC',
        RESULT: 'PURCHASE_FROM_FAVOURITE_GET_PLN_RESULT',
        RECEIPT: 'PURCHASE_FROM_FAVOURITE_GET_PLN_RECEIPT',
      },
    },
  },
  PUT: {
    MAINTENANCE_LIST: 'FAVOURITE_PURCHASE_PUT_MAINTENANCE_LIST',
    RESET_FAVOURITE: 'FAVOURITE_PURCHASE_PUT_RESET_FAVOURITE',
    RESET_ADD_FAVOURITE: 'FAVOURITE_PURCHASE_PUT_RESET_ADD_FAVOURITE',
    FAVOURITE_ACCOUNT: 'FAVOURITE_PURCHASE_PUT_FAVOURITE_ACCOUNT',
    RESET_TAC: 'FAVOURITE_PURCHASE_PUT_RESET_TAC',
    MOBILE_RELOAD: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_MOBILE_RELOAD_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_MOBILE_RELOAD_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_MOBILE_RELOAD_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_MOBILE_RELOAD_RESULT',
      },
      PURCHASE_FROM_FAVOURITE: {
        DETAIL: 'PURCHASE_FROM_FAVOURITE_PUT_MOBILE_RELOAD_DETAIL',
        CONFIRM: 'PURCHASE_FROM_FAVOURITE_PUT_MOBILE_RELOAD_CONFIRM',
        TAC: 'PURCHASE_FROM_FAVOURITE_PUT_MOBILE_RELOAD_TAC',
        RESULT: 'PURCHASE_FROM_FAVOURITE_PUT_MOBILE_RELOAD_RESULT',
        RECEIPT: 'PURCHASE_FROM_FAVOURITE_PUT_MOBILE_RELOAD_RECEIPT',
      },
    },
    PLN: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_PLN_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_PLN_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_PLN_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_PLN_RESULT',
      },
      PURCHASE_FROM_FAVOURITE: {
        DETAIL: 'PURCHASE_FROM_FAVOURITE_PUT_PLN_DETAIL',
        CONFIRM: 'PURCHASE_FROM_FAVOURITE_PUT_PLN_CONFIRM',
        TAC: 'PURCHASE_FROM_FAVOURITE_PUT_PLN_TAC',
        RESULT: 'PURCHASE_FROM_FAVOURITE_PUT_PLN_RESULT',
        RECEIPT: 'PURCHASE_FROM_FAVOURITE_PUT_PLN_RECEIPT',
      },
    },
  },
};

// Common
export const getFavouriteAccounts = payload => ({ type: actions.GET.FAVOURITE_ACCOUNT, payload });
export const getPurchaseTypes = payload => ({ type: actions.GET.MAINTENANCE_LIST, payload });

export const putPurchaseFavouriteReset = res => ({ type: actions.PUT.RESET_FAVOURITE, res });
export const putFavouriteAccounts = res => ({ type: actions.PUT.FAVOURITE_ACCOUNT, res });
export const putPurchaseTypes = res => ({ type: actions.PUT.MAINTENANCE_LIST, res });
export const putResetPurchaseTac = () => ({ type: actions.PUT.RESET_TAC });
export const putResetAddFavourite = () => ({ type: actions.PUT.RESET_ADD_FAVOURITE });

/** ****** MOBILE RELOAD ********* */
// Mobile Reload - Add Favourite
export const getReloadAddFavouriteDetail = payload => ({ type: actions.GET.MOBILE_RELOAD.ADD_FAVOURITE.DETAIL, payload });
export const getReloadAddFavouriteDetailAfterPurchase = payload => ({
  type: actions.GET.MOBILE_RELOAD.ADD_FAVOURITE.DETAIL_AFTER_PURCHASE,
  payload,
});
export const getReloadAddFavouriteConfirmation = payload => ({ type: actions.GET.MOBILE_RELOAD.ADD_FAVOURITE.CONFIRM, payload });
export const getReloadAddFavouriteTac = payload => ({ type: actions.GET.MOBILE_RELOAD.ADD_FAVOURITE.TAC, payload });
export const getReloadAddFavouriteResult = payload => ({ type: actions.GET.MOBILE_RELOAD.ADD_FAVOURITE.RESULT, payload });

// Mobile Reload - Edit Favourite
export const getReloadEditFavouriteDetail = payload => ({ type: actions.GET.MOBILE_RELOAD.EDIT_FAVOURITE.DETAIL, payload });
export const getReloadEditFavouriteConfirm = payload => ({ type: actions.GET.MOBILE_RELOAD.EDIT_FAVOURITE.CONFIRM, payload });
export const getReloadEditFavouriteTac = payload => ({ type: actions.GET.MOBILE_RELOAD.EDIT_FAVOURITE.TAC, payload });
export const getReloadEditFavouriteResult = payload => ({ type: actions.GET.MOBILE_RELOAD.EDIT_FAVOURITE.RESULT, payload });

// Mobile Reload - Delete Favourite
export const getReloadDeleteFavouriteConfirm = payload => ({ type: actions.GET.MOBILE_RELOAD.DELETE_FAVOURITE.CONFIRM, payload });
export const getReloadDeleteFavouriteTac = payload => ({ type: actions.GET.MOBILE_RELOAD.DELETE_FAVOURITE.TAC, payload });
export const getReloadDeleteFavouriteResult = payload => ({ type: actions.GET.MOBILE_RELOAD.DELETE_FAVOURITE.RESULT, payload });

// Mobile Reload - Purchase from Favourite
export const getReloadPurchaseDetailFromFavourite = payload => ({
  type: actions.GET.MOBILE_RELOAD.PURCHASE_FROM_FAVOURITE.DETAIL,
  payload,
});
export const getReloadPurchaseConfirmFromFavourite = payload => ({
  type: actions.GET.MOBILE_RELOAD.PURCHASE_FROM_FAVOURITE.CONFIRM,
  payload,
});
export const getReloadPurchaseTacFromFavourite = payload => ({ type: actions.GET.MOBILE_RELOAD.PURCHASE_FROM_FAVOURITE.TAC, payload });
export const getReloadPurchaseResultFromFavourite = payload => ({
  type: actions.GET.MOBILE_RELOAD.PURCHASE_FROM_FAVOURITE.RESULT,
  payload,
});
export const getReloadPurchaseReceiptFromFavourite = payload => ({
  type: actions.GET.MOBILE_RELOAD.PURCHASE_FROM_FAVOURITE.RESULT,
  payload,
});

// Mobile Reload - Add/Edit/Delete
export const putReloadFavouriteDetail = res => ({ type: actions.PUT.MOBILE_RELOAD.FAVOURITE_ACTION.DETAIL, res });
export const putReloadFavouriteConfirm = res => ({ type: actions.PUT.MOBILE_RELOAD.FAVOURITE_ACTION.CONFIRM, res });
export const putReloadFavouriteTac = res => ({ type: actions.PUT.MOBILE_RELOAD.FAVOURITE_ACTION.TAC, res });
export const putReloadFavouriteResult = res => ({ type: actions.PUT.MOBILE_RELOAD.FAVOURITE_ACTION.RESULT, res });
/** ****** MOBILE RELOAD ********* */

/** ****** PLN ********* */
// PLN - Add Favourite
export const getPlnAddFavouriteDetailFromPurchase = payload => ({
  type: actions.GET.PLN.ADD_FAVOURITE.DETAIL_FROM_PURCHASE,
  payload,
});
export const getPlnAddFavouriteConfirmation = payload => ({ type: actions.GET.PLN.ADD_FAVOURITE.CONFIRM, payload });
export const getPlnAddFavouriteTac = payload => ({ type: actions.GET.PLN.ADD_FAVOURITE.TAC, payload });
export const getPlnAddFavouriteResult = payload => ({ type: actions.GET.PLN.ADD_FAVOURITE.RESULT, payload });

// PLN - Edit Favourite
export const getPlnEditFavouriteDetail = payload => ({ type: actions.GET.PLN.EDIT_FAVOURITE.DETAIL, payload });
export const getPlnEditFavouriteConfirm = payload => ({ type: actions.GET.PLN.EDIT_FAVOURITE.CONFIRM, payload });
export const getPlnEditFavouriteTac = payload => ({ type: actions.GET.PLN.EDIT_FAVOURITE.TAC, payload });
export const getPlnEditFavouriteResult = payload => ({ type: actions.GET.PLN.EDIT_FAVOURITE.RESULT, payload });

// PLN - Delete Favourite
export const getPlnDeleteFavouriteConfirm = payload => ({ type: actions.GET.PLN.DELETE_FAVOURITE.CONFIRM, payload });
export const getPlnDeleteFavouriteTac = payload => ({ type: actions.GET.PLN.DELETE_FAVOURITE.TAC, payload });
export const getPlnDeleteFavouriteResult = payload => ({ type: actions.GET.PLN.DELETE_FAVOURITE.RESULT, payload });

// PLN - Purchase from Favourite
export const getPlnPurchaseDetailFromFavourite = payload => ({
  type: actions.GET.PLN.PURCHASE_FROM_FAVOURITE.DETAIL,
  payload,
});
export const getPlnPurchaseConfirmFromFavourite = payload => ({
  type: actions.GET.PLN.PURCHASE_FROM_FAVOURITE.CONFIRM,
  payload,
});
export const getPlnPurchaseTacFromFavourite = payload => ({ type: actions.GET.PLN.PURCHASE_FROM_FAVOURITE.TAC, payload });
export const getPlnPurchaseResultFromFavourite = payload => ({
  type: actions.GET.PLN.PURCHASE_FROM_FAVOURITE.RESULT,
  payload,
});
export const getPlnPurchaseReceiptFromFavourite = payload => ({
  type: actions.GET.PLN.PURCHASE_FROM_FAVOURITE.RESULT,
  payload,
});

// PLN - Add/Edit/Delete
export const putPlnFavouriteDetail = res => ({ type: actions.PUT.PLN.FAVOURITE_ACTION.DETAIL, res });
export const putPlnFavouriteConfirm = res => ({ type: actions.PUT.PLN.FAVOURITE_ACTION.CONFIRM, res });
export const putPlnFavouriteTac = res => ({ type: actions.PUT.PLN.FAVOURITE_ACTION.TAC, res });
export const putPlnFavouriteResult = res => ({ type: actions.PUT.PLN.FAVOURITE_ACTION.RESULT, res });
/** ****** PLN ********* */
