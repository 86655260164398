import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putOtherBillTac,
  putOtherBillAccess,
  putOtherBillDetail,
  putOtherBillPayees,
  putOtherBillResult,
  putOtherBillBillers,
  putOtherBillReceipt,
  putOtherBillConfirmation,
} from 'middleware/actions/payment';

import { get } from 'utils/lodash';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatPaymentAccess } from 'utils/payment/formatter';
import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

const { ACCESS, PAYEES, DETAIL, CONFIRMATION, REQUEST_TAC, RESULT, RECEIPT } = actions.GET.OTHER_BILL;

const getClientIP = () => remoteIpInstance.get();

const getAccess = ({ body }) => Instance.post(endpoint.otherBill.getBiller, body);
const getPayee = ({ body }) => Instance.post(endpoint.otherBill.getPayee, body);
const getDetail = ({ body }) => Instance.post(endpoint.otherBill.getDetail, body);
const getConfirm = ({ body }) => Instance.post(endpoint.otherBill.getConfirm, body);
const requestTAC = ({ body }) => Instance.post(endpoint.otherBill.requestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.otherBill.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.otherBill.getReceipt, body);

const getLocale = state => state.LanguageReducer.locale;

function* getAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getAccess, { body });

    const [access, billers] = formatPaymentAccess(data);

    yield all([put(putOtherBillAccess(access)), put(putOtherBillBillers(billers))]);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPayeeSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getPayee, { body });

    yield put(putOtherBillPayees(get(data, 'paymentPayeeList', [])));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });

    yield put(putOtherBillDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getConfirm, { body });

    yield put(putOtherBillConfirmation(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });

    yield put(putOtherBillTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getResult, { body });

    yield put(putOtherBillResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getReceipt, { body });

    yield put(putOtherBillReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PaymentOtherBill() {
  yield all([
    takeLatest(ACCESS, getAccessSaga),
    takeLatest(PAYEES, getPayeeSaga),
    takeLatest(DETAIL, getDetailSaga),
    takeLatest(CONFIRMATION, getConfirmationSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(RESULT, getResultSaga),
    takeLatest(RECEIPT, getReceiptSaga),
  ]);
}
