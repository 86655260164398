export const actions = {
  VALIDATE_USERNAME: 'LOGIN_VALIDATE_USERNAME',
  VALIDATE_PASSWORD: 'LOGIN_VALIDATE_PASSWORD',
  GET: {
    LOGIN_ACCESS: 'LOGIN_GET_LOGIN_ACCESS',
    CUSTOMER_INFO: 'LOGIN_GET_CUSTOMER_INFO',
    LOGOUT: 'GET_LOGOUT',
    LOGOUT_TRANSACTION_SUMMARY: 'GET_LOGOUT_TRANSACTION_SUMMARY',
    LOGOUT_EXPIRED_SESSION: 'GET_LOGOUT_EXPIRED_SESSION',
    EXTEND_SESSION: 'GET_EXTEND_SESSION',
    CAPTCHA_RESET_CREDENTIAL: 'GET_CAPTCHA_RESET_CREDENTIAL',
    VERIFY_CAPTCHA_RESET_CREDENTIAL: 'GET_VERIFY_CAPTCHA_RESET_CREDENTIAL',
    TAC_RESET_CREDENTIAL: 'GET_TAC_RESET_CREDENTIAL',
    VERIFY_TAC_RESET_CREDENTIAL: 'GET_VERIFY_TAC_RESET_CREDENTIAL',
    UPDATE_PASSWORD_RESET_CREDENTIAL: 'GET_UPDATE_PASSWORD_RESET_CREDENTIAL',
    CHECK_USER_STATE_FORCE_CHANGE_PASSWORD: 'GET_CHECK_USER_STATE_FORCE_CHANGE_PASSWORD',
    GENERATE_TAC_FORCE_CHANGE_PASSWORD: 'GET_GENERATE_TAC_FORCE_CHANGE_PASSWORD',
    VERIFY_TAC_FORCE_CHANGE_PASSWORD: 'GET_VERIFY_TAC_FORCE_CHANGE_PASSWORD',
    CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER: 'GET_CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER',
    UPDATE_PHONE_NUMBER: 'GET_UPDATE_PHONE_NUMBER',
  },
  PUT: {
    USER_ALIAS: 'LOGIN_PUT_USER_ALIAS',
    CUSTOMER_INFO: 'LOGIN_PUT_CUSTOMER_INFO',
    LOGIN_ACCESS_STATUS: 'LOGIN_PUT_LOGIN_ACCESS_STATUS',
    USER_VALIDATION_RESPONSE: 'LOGIN_PUT_USER_VALIDATION_RESPONSE',
    PASSWORD_VALIDATION_RESPONSE: 'LOGIN_PUT_PASSWORD_VALIDATION_RESPONSE',
    AUTH_TOKEN: 'LOGIN_PUT_AUTH_TOKEN',
    LOGOUT: 'PUT_LOGOUT',
    LOGOUT_TRANSACTION_SUMMARY: 'PUT_LOGOUT_TRANSACTION_SUMMARY',
    LOGOUT_EXPIRED_SESSION: 'PUT_LOGOUT_EXPIRED_SESSION',
    EXTEND_SESSION: 'PUT_EXTEND_SESSION',
    CAPTCHA_RESET_CREDENTIAL: 'PUT_CAPTCHA_RESET_CREDENTIAL',
    VERIFY_CAPTCHA_RESET_CREDENTIAL: 'PUT_VERIFY_CAPTCHA_RESET_CREDENTIAL',
    TAC_RESET_CREDENTIAL: 'PUT_TAC_RESET_CREDENTIAL',
    VERIFY_TAC_RESET_CREDENTIAL: 'PUT_VERIFY_TAC_RESET_CREDENTIAL',
    UPDATE_PASSWORD_RESET_CREDENTIAL: 'PUT_UPDATE_PASSWORD_RESET_CREDENTIAL',
    CHECK_USER_STATE_FORCE_CHANGE_PASSWORD: 'PUT_CHECK_USER_STATE_FORCE_CHANGE_PASSWORD',
    GENERATE_TAC_FORCE_CHANGE_PASSWORD: 'PUT_GENERATE_TAC_FORCE_CHANGE_PASSWORD',
    VERIFY_TAC_FORCE_CHANGE_PASSWORD: 'PUT_VERIFY_TAC_FORCE_CHANGE_PASSWORD',
    CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER: 'PUT_CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER',
    UPDATE_PHONE_NUMBER: 'PUT_UPDATE_PHONE_NUMBER',
  },
};

export const getLogout = (history, stayLanding, isMultiSession = false) => ({
  type: actions.GET.LOGOUT,
  history,
  stayLanding,
  isMultiSession,
});
export const putLogout = () => ({ type: actions.PUT.LOGOUT });
export const getLogoutTransactionSummary = body => ({ type: actions.GET.LOGOUT_TRANSACTION_SUMMARY, payload: body });
export const putLogoutTransactionSummary = res => ({ type: actions.PUT.LOGOUT_TRANSACTION_SUMMARY, payload: res });

export const getLogoutExpiredSession = (history, stayLanding) => ({ type: actions.GET.LOGOUT_EXPIRED_SESSION, history, stayLanding });
export const putLogoutExpiredSession = () => ({ type: actions.PUT.LOGOUT_EXPIRED_SESSION });

export const getExtendSession = () => ({ type: actions.GET.EXTEND_SESSION });
export const putExtendSession = () => ({ type: actions.PUT.EXTEND_SESSION });

export const getLoginAccess = () => ({ type: actions.GET.LOGIN_ACCESS });
export const getCustomerInfo = payload => ({ type: actions.GET.CUSTOMER_INFO, payload });

export const validateUsername = username => ({ type: actions.VALIDATE_USERNAME, payload: username });
export const validatePassword = password => ({ type: actions.VALIDATE_PASSWORD, payload: password });

export const putCustomerInfo = res => ({ type: actions.PUT.CUSTOMER_INFO, res });
export const putUserAlias = alias => ({ type: actions.PUT.USER_ALIAS, payload: alias });
export const putLoginAccessStatus = status => ({ type: actions.PUT.LOGIN_ACCESS_STATUS, payload: status });
export const putUserValidationResponse = res => ({ type: actions.PUT.USER_VALIDATION_RESPONSE, payload: res });
export const putValidatePasswordResponse = res => ({ type: actions.PUT.PASSWORD_VALIDATION_RESPONSE, payload: res });

export const putAuthToken = res => ({ type: actions.PUT.AUTH_TOKEN, payload: res });

export const getCaptchaResetCredential = payload => ({ type: actions.GET.CAPTCHA_RESET_CREDENTIAL, payload });
export const putCaptchaResetCredential = res => ({ type: actions.PUT.CAPTCHA_RESET_CREDENTIAL, payload: res });

export const getVerifyCaptchaResetCredential = payload => ({ type: actions.GET.VERIFY_CAPTCHA_RESET_CREDENTIAL, payload });
export const putVerifyCaptchaResetCredential = res => ({ type: actions.PUT.VERIFY_CAPTCHA_RESET_CREDENTIAL, payload: res });

export const getTacResetCredential = payload => ({ type: actions.GET.TAC_RESET_CREDENTIAL, payload });
export const putTacResetCredential = res => ({ type: actions.PUT.TAC_RESET_CREDENTIAL, payload: res });

export const getVerifyTacResetCredential = payload => ({ type: actions.GET.VERIFY_TAC_RESET_CREDENTIAL, payload });
export const putVerifyTacResetCredential = res => ({ type: actions.PUT.VERIFY_TAC_RESET_CREDENTIAL, payload: res });

export const getUpdatePasswordResetCredential = payload => ({ type: actions.GET.UPDATE_PASSWORD_RESET_CREDENTIAL, payload });
export const putUpdatePasswordResetCredential = res => ({ type: actions.PUT.UPDATE_PASSWORD_RESET_CREDENTIAL, payload: res });

export const getCheckUserStateForceChangePassword = payload => ({ type: actions.GET.CHECK_USER_STATE_FORCE_CHANGE_PASSWORD, payload });
export const putCheckUserStateForceChangePassword = res => ({ type: actions.PUT.CHECK_USER_STATE_FORCE_CHANGE_PASSWORD, payload: res });

export const getGenerateTacForceChangePassword = payload => ({ type: actions.GET.GENERATE_TAC_FORCE_CHANGE_PASSWORD, payload });
export const putGenerateTacForceChangePassword = res => ({ type: actions.PUT.GENERATE_TAC_FORCE_CHANGE_PASSWORD, payload: res });

export const getVerifyTacForceChangePassword = payload => ({ type: actions.GET.VERIFY_TAC_FORCE_CHANGE_PASSWORD, payload });
export const putVerifyTacForceChangePassword = res => ({ type: actions.PUT.VERIFY_TAC_FORCE_CHANGE_PASSWORD, payload: res });

export const getCheckUserStateForceChangePhoneNumber = payload => ({
  type: actions.GET.CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER,
  payload,
});
export const putCheckUserStateForceChangePhoneNumber = res => ({
  type: actions.PUT.CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER,
  payload: res,
});

export const getUpdatePhoneNumber = payload => ({ type: actions.GET.UPDATE_PHONE_NUMBER, payload });
export const putUpdatePhoneNumber = res => ({ type: actions.PUT.UPDATE_PHONE_NUMBER, payload: res });
