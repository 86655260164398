import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions, putDeleteInbox, putInboxList, putReplyInbox, putUpdateReadInbox } from 'middleware/actions/inboxs';
import { inboxsEndpoint as endpoint } from 'providers/endpoints/inboxs';
import { putErrorResponse } from 'middleware/actions/error';

import Instance from 'providers/instance';
import { autoSelectFirstInbox } from 'utils/inbox/formatter';

const getLocale = state => state.LanguageReducer.locale;
const getInboxList = ({ body }) => Instance.post(endpoint.inbox.inboxList, body);
const deleteInbox = ({ body }) => Instance.post(endpoint.inbox.deleteInbox, body);
const updateReadInbox = ({ body }) => Instance.post(endpoint.inbox.updateReadInbox, body);
const replyInbox = ({ body }) => Instance.post(endpoint.inbox.replyInbox, body);

function* getInboxListSaga({ payload = {} }) {
  try {
    const locale = 'en';
    const body = { locale, ...payload };

    const { data } = yield call(getInboxList, { body });
    yield put(putInboxList(data));

    // set auto focus first inbox when get data inbox list
    autoSelectFirstInbox();
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteInboxSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(deleteInbox, { body });

    yield put(putDeleteInbox(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* updateReadInboxSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(updateReadInbox, { body });

    yield put(putUpdateReadInbox(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* replyInboxSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(replyInbox, { body });

    yield put(putReplyInbox(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Inboxs() {
  yield all([
    takeLatest(actions.GET.INBOX.INBOXS_INBOX_LIST, getInboxListSaga),
    takeLatest(actions.GET.INBOX.INBOXS_DELETE_INBOX, deleteInboxSaga),
    takeLatest(actions.GET.INBOX.INBOXS_UPDATE_READ_INBOX, updateReadInboxSaga),
    takeLatest(actions.GET.INBOX.INBOXS_REPLY, replyInboxSaga),
  ]);
}
