import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Box, Grid } from '@material-ui/core';

import { Checkbox } from 'components/common';
import { BasicButton } from 'components/common/buttons';
import { FormModal } from 'components/common/modals';

import './style.scss';

const DynoAlertModal = ({
  isOpen,
  title,
  description,
  hasCancelButton,
  onClose,
  onCancel,
  actionButtonTitle,
  secondaryActionButtonTitle,
  onSubmit,
  hasCheckbox,
  hasRemember,
  handleCheckboxClicked,
  checklistLabel,
  className,
  classNameDesc,
  hasCloseButton,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormModal
      fullWidth
      maxWidth="sm"
      className={`transaction--modal ${className}`}
      hasCloseButton={hasCloseButton}
      isOpen={isOpen}
      title={title}
      description={description}
      onClose={onClose}
      classNameDesc={classNameDesc}
      footer={
        <Box display="flex">
          {hasCheckbox && (
            <Checkbox
              isChecked={hasRemember}
              onClick={handleCheckboxClicked}
              label={checklistLabel}
              className="form--radio-label"
              onChange={e => handleCheckboxClicked(e.target.checked)}
            />
          )}
          <Grid container xs={12} justify="flex-end" spacing={3}>
            {hasCancelButton && (
              <Grid item>
                <BasicButton
                  className="transparent-bb db"
                  onClick={onCancel}
                  title={secondaryActionButtonTitle || formatMessage({ id: 'buttonText.cancel' })}
                />
              </Grid>
            )}
            <Grid item className="ml3">
              <BasicButton
                className="db ph4"
                onClick={hasCancelButton ? onSubmit : onClose}
                title={actionButtonTitle || formatMessage({ id: 'buttonText.ok' })}
              />
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

DynoAlertModal.defaultProps = {
  isOpen: false,
  title: '',
  description: '',
  hasCancelButton: false,
  onClose: null,
  onCancel: null,
  onSubmit: null,
  actionButtonTitle: '',
  secondaryActionButtonTitle: '',
  hasCheckbox: false,
  hasRemember: false,
  handleCheckboxClicked: null,
  checklistLabel: '',
  className: '',
  classNameDesc: '',
  hasCloseButton: false,
};

DynoAlertModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  hasCancelButton: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  actionButtonTitle: PropTypes.string,
  secondaryActionButtonTitle: PropTypes.string,
  hasCheckbox: PropTypes.bool,
  hasRemember: PropTypes.bool,
  handleCheckboxClicked: PropTypes.func,
  checklistLabel: PropTypes.string,
  className: PropTypes.string,
  classNameDesc: PropTypes.string,
  hasCloseButton: PropTypes.bool,
};

export default DynoAlertModal;
