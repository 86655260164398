import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useTheme } from '@material-ui/core/styles';

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography, useMediaQuery, Box } from '@material-ui/core';

import Icon from 'components/common/Icon';
import BackgroundImg from 'assets/images/banners/nature.jpg';

import { BasicButton, IconButton } from '../buttons';

const FLEX_SPACING = 2;

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const ActionModal = ({
  id,
  title,
  children,
  description,
  isOpen,
  className,
  onClose,
  hasCloseButton,
  primaryButtonTitle,
  onPrimaryBtnClick,
  secondaryButtonTitle,
  onSecondaryBtnClick,
}) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const hasPrimaryTitle = !isEmpty(primaryButtonTitle);
  const hasSecondaryTitle = !isEmpty(secondaryButtonTitle);
  const hasBothTitle = hasPrimaryTitle && hasSecondaryTitle;
  const isDialogDisplay = hasBothTitle || hasPrimaryTitle || hasSecondaryTitle;

  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby={id}
      disableEscapeKeyDown
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      className={`small-form-modal ${className}`}
    >
      <DialogTitle disableTypography id={id} className="" style={{ backgroundImage: `url('${BackgroundImg}')` }}>
        {hasCloseButton && (
          <div className="flex justify-end">
            <IconButton onClick={onClose} className="close-btn">
              <Icon isAssetIcon={false} type="close" />
            </IconButton>
          </div>
        )}
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Box fontSize={18}>{title}</Box>
          {description && (
            <div className="w-100 mt4 mb2 tl">
              <Typography className="tc">{description}</Typography>
            </div>
          )}
        </Grid>
      </DialogContent>

      {children && <DialogContent>{children}</DialogContent>}

      {isDialogDisplay && (
        <DialogActions>
          <Grid container spacing={FLEX_SPACING} justify="center">
            {hasSecondaryTitle && (
              <Grid container item xs={12} sm={6} justify="center">
                <BasicButton
                  title={secondaryButtonTitle}
                  className="transparent-bb db modal-action"
                  fullWidth
                  onClick={onSecondaryBtnClick}
                />
              </Grid>
            )}

            {hasPrimaryTitle && (
              <Grid container item xs={12} sm={6} justify="center">
                <BasicButton title={primaryButtonTitle} className="db modal-action" fullWidth onClick={onPrimaryBtnClick} />
              </Grid>
            )}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

ActionModal.defaultProps = {
  id: '',
  title: '',
  description: null,
  children: null,
  className: '',
  primaryButtonTitle: '',
  hasCloseButton: false,
  onPrimaryBtnClick: null,
  secondaryButtonTitle: '',
  onSecondaryBtnClick: null,
};

ActionModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasCloseButton: PropTypes.bool,
  primaryButtonTitle: PropTypes.node,
  onPrimaryBtnClick: PropTypes.func,
  secondaryButtonTitle: PropTypes.node,
  onSecondaryBtnClick: PropTypes.func,
};

export default ActionModal;
