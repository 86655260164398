import PropTypes from 'prop-types';

import { Card as MaterialCard, CardActions, CardContent, CardMedia, Typography, Paper } from '@material-ui/core';

import { get } from 'utils/lodash';

import Link from '../Link';
import ActionButton from '../buttons/ActionButton';

import './style.scss';

const PrimaryMediaCard = ({ index, item, buttonTitle, coverImg, hasLink, isContentCentered, onClick, onLinkClick }) => {
  const id = get(item, 'id', '');
  const desc = get(item, 'desc', '');
  const title = get(item, 'title', '');

  const contentStyle = isContentCentered ? 'flex flex-column justify-between' : '';

  return (
    <Paper className="card container--primary-media">
      <MaterialCard className="card--primary-media">
        <CardMedia component="img" alt={title} height="120" title={title} image={coverImg} />

        <CardContent className={contentStyle}>
          {isContentCentered ? (
            <>
              {' '}
              <Typography gutterBottom className="content--title" component="h1">
                {title}
              </Typography>
              <Typography variant="body2" className="content--desc" component="p">
                {desc}
              </Typography>
            </>
          ) : (
            <div>
              <Typography gutterBottom className="content--title" component="h1">
                {title}
              </Typography>
              <Typography variant="body2" className="content--desc" component="p">
                {desc}
              </Typography>
            </div>
          )}
          {hasLink && <Link label="more benefits" className="content--link" onClick={onLinkClick} />}
        </CardContent>

        <CardActions>
          <ActionButton
            fullWidth
            isAssetIcon={false}
            icon="arrowRightAlt"
            title={buttonTitle}
            onClick={onClick}
            className="hover--default"
          />
        </CardActions>
      </MaterialCard>
    </Paper>
  );
};

PrimaryMediaCard.defaultProps = {
  item: {},
  index: '',
  hasLink: false,
  onLinkClick: null,
  isContentCentered: false,
  buttonTitle: 'Apply now',
};

PrimaryMediaCard.propTypes = {
  item: PropTypes.object,
  hasLink: PropTypes.bool,
  onLinkClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  isContentCentered: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  coverImg: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PrimaryMediaCard;
