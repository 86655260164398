import { get } from 'utils/lodash';
import { removeStringComma } from 'utils/formatter';

import { CURRENCY_IDR } from 'settings/constants/common';
import { DEFAULT_CURRENCY_EXCHANGE_RATE } from 'settings/constants/transaction';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';
import { PAYEE_CODE_DANA_HIDDEN, PAYEE_CODE_SHOPEEPAY_HIDDEN } from 'settings/constants/transfer/e-wallet/form';

export const formatEWallet = data =>
  data.reduce((acc, { payeeName, payeeCode, ...props }) => [...acc, { label: payeeName, value: payeeCode, ...props }], []);

export const formatEWalletDetail = data => {
  const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
  const initialProducts = get(data, 'productList', []);
  const products = initialProducts.filter(pro => pro.payeeCode !== PAYEE_CODE_DANA_HIDDEN && pro.payeeCode !== PAYEE_CODE_SHOPEEPAY_HIDDEN);

  // FAVOURITE
  const toAccountNo = get(data, 'toAccountNo', '');
  const payeeName = get(data, 'payeeName', '');
  const payeeCode = get(data, 'payeeCode', '');
  const minLimit = get(data, 'minLimit', '');
  const maxLimit = get(data, 'maxLimit', '');

  return {
    products,
    minLimit,
    maxLimit,
    responseCode,
    isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
    serviceInfoBean: get(data, 'serviceInfoBean', {}),
    transferBanks: formatEWallet(products),
    ...(toAccountNo ? { toAccountNo, payeeName, payeeCode } : {}),
  };
};
export const formatEWalletConfirmationBody = data => ({
  userId: get(data, 'userId', ''),
  senderAccountNumber: get(data, 'senderAccount.accountNumber', ''),
  senderCurrencyCode: get(data, 'senderAccount.currencyCode', ''),
  senderCurrency: get(data, 'senderAccount.currency', CURRENCY_IDR),
  payeeCode: get(data, 'beneficiaryBank.value', '') || get(data, 'payeeCode', ''),
  receiverAccountNumber: get(data, 'accountNumber', ''),
  transferAmount: removeStringComma(get(data, 'transferAmount', '')),
  favTransaction: get(data, 'favouriteTransaction', false),
});
export const formatEWalletResultBody = ({
  userId,
  tacValue,
  transferTacDetail,
  confirmationDetail,
  senderAccount,
  formDetail,
  isFavouriteTransfer,
}) => {
  const tacViewBean = get(transferTacDetail, 'tacViewBean', {});

  return {
    userId,
    tacValue,
    favTransaction: isFavouriteTransfer,
    adminFee: get(confirmationDetail, 'adminFee', ''),
    adminFeeDisplay: get(confirmationDetail, 'adminFeeDisplay', ''),
    de004AmountTransaction: get(confirmationDetail, 'de004AmountTransaction', ''),
    de048AdditionalData: get(confirmationDetail, 'de048AdditionalData', ''),
    equivalentIdrAmount: get(confirmationDetail, 'equivalentIdrAmount', ''),
    equivalentIdrAmountDisplay: get(confirmationDetail, 'equivalentIdrAmountDisplay', ''),
    fromAccountNumber: get(confirmationDetail, 'fromAccountNumber', ''),
    fromAmount: get(confirmationDetail, 'fromAmount', ''),
    fromCurrencyCode: get(senderAccount, 'currencyCode', ''),
    fromCurrency: get(senderAccount, 'currency', CURRENCY_IDR),
    fromCurrencyRate: get(confirmationDetail, 'fromCurrencyRate', DEFAULT_CURRENCY_EXCHANGE_RATE),
    name: get(confirmationDetail, 'name', ''),
    payeeName: get(confirmationDetail, 'payeeName', ''),
    payeeCode: get(formDetail, 'beneficiaryBank.value', '') || get(formDetail, 'payeeCode', ''),
    toAccountNumber: get(confirmationDetail, 'toAccountNumber', ''),
    toAmount: get(confirmationDetail, 'toAmount', ''),
    toAmountDisplay: get(confirmationDetail, 'toAmountDisplay', ''),
    toCurrencyRate: get(confirmationDetail, 'toCurrencyRate', DEFAULT_CURRENCY_EXCHANGE_RATE),
    totalPayment: get(confirmationDetail, 'totalPayment', ''),
    totalPaymentDisplay: get(confirmationDetail, 'totalPaymentDisplay', ''),
    restSMSService: get(tacViewBean, 'restSMSService', {}),
  };
};

export const formatEWalletEditDetailPayload = detail => ({
  userId: get(detail, 'userId', ''),
  accountNo: get(detail, 'accountNumber', ''),
  payeeCode: get(detail, 'bankCodePayeeCode', ''),
});

export const formatEWalletEditFavouriteConfirm = detail => ({
  userId: get(detail, 'userId', ''),
  accountNickname: get(detail, 'accountNickname', ''),
  accountHolderName: get(detail, 'accountHolderName', ''),
  toAccountNo: get(detail, 'toAccountNo', ''),
  accountLimit: get(detail, 'accountLimit'),
  payeeCode: get(detail, 'payeeCode', ''),
  payeeName: get(detail, 'payeeName', ''),
});

export const formatEWalletAddFavouriteResultPayload = detail => {
  const isFromTransferResult = get(detail, 'confirmationDetail.isFromTransferResult', false);
  const tacValue = get(detail, 'tacValue', '');

  return {
    isFromTransferResult,
    userId: get(detail, 'userId', ''),
    restSMSService: get(detail, 'tacDetail.tacViewBean.restSMSService', null),
    accountNickname: get(detail, 'confirmationDetail.accountNickname', ''),
    toAccountNo: get(detail, 'confirmationDetail.toAccountNo', ''),
    accountLimit: get(detail, 'confirmationDetail.accountLimit', ''),
    accountHolderName: get(detail, 'confirmationDetail.accountHolderName', ''),
    payeeCode: get(detail, 'confirmationDetail.payeeCode', ''),
    payeeName: get(detail, 'confirmationDetail.payeeName', ''),
    ...(!isFromTransferResult ? { tacValue } : {}),
  };
};
