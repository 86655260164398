export const inboxsEndpoint = {
  inbox: {
    inboxList: '/settings/v1/inbox/message/list',
    deleteInbox: '/settings/v1/inbox/message/delete',
    updateReadInbox: '/settings/v1/inbox/message/read',
    replyInbox: '/settings/v1/inbox/reply/message',
  },
  sent: {
    sentList: '/settings/v1/inbox/sent/message/list',
    deleteSent: '/settings/v1/inbox/sent/message/delete',
  },
  compose: {
    access: '/settings/v1/inbox/compose',
    send: '/settings/v1/inbox/compose/send',
  },
};
