export const gbi = {
  title: 'Simulasi Tujuan',
  stepper: {
    chooseFinancialPlan: 'Pilih Tujuan Keuangan',
    planning: 'Perencanaan',
    simulationResult: 'Hasil Simulasi',
  },
  pensionFund: {
    type: 'Dana Pensiun',
    title: 'Persiapkan Dana Pensiun',
    description: 'Saya ingin menyiapkan dana pensiun agar saya dapat menikmati masa pensiun saya dengan tenang dan nyaman',
    now: 'Sekarang',
    retireAt: 'Pensiun Pada Umur {targetAge}',
    lastExpense: 'Harapan Usia Hidup',
    planningTitle: 'Simulasi Dana Pensiun',
    resultHeader: 'Tujuan Pensiun Saya',
    resultTitle: 'Simulasi Dana Pensiun',
    targetTitle: 'Target dana yang dibutuhkan di saat pensiun',
    investmentTitle: 'Untuk mencapai target maka dibutuhkan investasi bulanan sebesar:',
  },
  maxInvestment: {
    type: 'Maksimalkan Investasi',
    title: 'Maksimalkan Investasi',
    description: 'Saya ingin mengembangkan dana saya semaksimal mungkin',
    now: 'Sekarang',
    targetAchieve: 'Target dicapai pada tahun ke {targetAge}',
    planningTitle: 'Simulasi Maksimalkan Investasi',
    resultHeader: 'Tujuan Investasi Saya',
    resultTitle: 'Simulasi Investasi Maksimal',
    targetTitle: 'Proyeksi',
    investmentTitle: 'Untuk mencapai target maka dibutuhkan investasi bulanan sebesar:',
  },
  educationFund: {
    type: 'Dana Pendidikan',
    title: 'Persiapkan Dana Pendidikan',
    description: 'Saya ingin memastikan dana pendidikan saya atau anak saya tersedia',
    now: 'Sekarang',
    targetAchieve: 'Target dicapai pada tahun ke ',
    planningTitle: 'Simulasi Dana Pendidikan',
    resultHeader: 'Tujuan Pendidikan Saya',
    resultTitle: 'Simulasi Dana Pendidikan',
    targetTitle: 'Target dana yang diperlukan untuk pendidikan',
    investmentTitle: 'Untuk mencapai target maka dibutuhkan investasi bulanan sebesar:',
  },
  form: {
    name: {
      label: 'Nama tujuan',
      placeholder: 'Maks 40 karakter',
    },
    years: 'Tahun',
    yearsOld: 'Tahun',
    investmentPeriod: {
      label: 'Jangka waktu investasi (tahun)',
      shortLabel: 'Periode investasi',
      placeholder: 'Minimal 2 tahun',
    },
    monthlyAmount: {
      label: 'Nominal investasi bulanan',
      shortLabel: 'Investasi bulanan',
      placeholder: 'Minimal IDR 100K kelipatan IDR 10K',
    },
    targetFund: {
      label: 'Target dana yang ingin dicapai',
      shortLabel: 'Target dana',
      placeholder: 'Minimal IDR 1M kelipatan IDR 10K',
    },
    initialDeposit: {
      label: 'Nominal penempatan dana awal (opsional)',
      shortLabel: 'Penempatan dana awal',
      placeholder: 'Minimal IDR 100K kelipatan IDR 10K',
    },
    initialFundPlacement: {
      label: 'Jumlah penempatan dana awal (opsional)',
      shortLabel: 'Penempatan dana awal',
      placeholder: 'Minimal IDR 100K kelipatan IDR 10K',
    },
    riskProfile: 'Profil risiko tujuan',
    retireAge: {
      label: 'Pensiun pada usia (tahun)',
      shortLabel: 'Usia pensiun',
      placeholder: 'Min +5 tahun dari usia sekarang',
    },
    lifeExpectancy: {
      label: 'Harapan usia hidup saya (tahun)',
      shortLabel: 'Harapan usia hidup',
      placeholder: 'Antara 60 - 100 tahun',
    },
    monthlyExpense: {
      label: 'Pengeluaran bulanan di masa pensiun (IDR)',
      shortLabel: 'Pengeluaran bulanan',
      bottomShortLabel: 'Pengeluaran di masa pensiun',
      placeholder: 'Minimal IDR 1M kelipatan IDR 10K',
    },
    fundraisingPeriod: {
      label: 'Periode pengumpulan dana (tahun)',
      shortLabel: 'Periode pengumpulan dana',
      placeholder: 'Minimal 2 tahun',
    },
    bpjsTitle: 'Ikutsertakan Dana Pensiun BPJS Saya (opsional)',
    bpjsDescription: 'Dapatkan simulasi yang lebih akurat dengan mengikutsertakan Dana Pensiun BPJS di dalam perhitungan',
    bpjsBalance: 'Saldo Rekening BPJS ',
    bpjsContribution: 'Kontribusi Bulanan BPJS',
    bpjsBalanceTooltip: 'Total Jumlah Saldo anda di Dana Pensiun BPJS Ketenagakerjaan pada saat ini',
    bpjsContributionTooltip:
      'Total kontribusi bulanan ke Dana Pensiun BPJS Ketenagakerjaan anda (termasuk kontribusi karyawan, apabila ada)',
  },
  riskProfile: {
    conservative: 'Konservatif',
    aggressive: 'Agresif',
    moderate: 'Moderat',
    conservativeMessage: 'Peningkatan dana rendah dengan risiko rendah',
    aggressiveMessage: 'Peningkatan dana tinggi dengan risiko tinggi',
    moderateMessage: 'Peningkatan dana menengah dengan risiko menengah',
  },
  chart: {
    million: 'Juta',
    newTarget: 'Target Baru',
    prevTarget: 'Target Awal',
    simulation: 'Simulasi Rencana {goalName}',
    disclaimer:
      'Penghitungan target dana tidak memperhitungkan faktor inflasi atau faktor-faktor keuangan lainnya dan hanya berdasarkan arus pengeluaran per bulan yang ditentukan. <br/><br/>Simulasi di atas merupakan perkiraan dan informasi tersebut tidak dimaksudkan untuk memberikan nasihat profesional dan tidak bisa ditafsirkan sebagai penawaran untuk menjual atau ajakan untuk membeli produk investasi atau simpanan apapun. Informasi ini bukan merupakan jaminan untuk memprediksi kinerja hasil investasi dimasa mendatang.',
    notation: {
      k: 'k=ribu',
      m: 'm=juta',
      b: 'b=miliar',
      t: 't=triliun',
    },
  },
  resultTitle: 'Rekomendasi Alokasi Investasi ',
  appTitle: 'Lanjutkan Perencanaan Keuangan Anda di M2U App',
  appDescription: 'Wujudkan tujuan {goalType} anda di M2U ID App',
  appCaption:
    'Dengan M2U ID App anda dapat mensimulasikan, pilih produk investasi dan melakukan investasi berkala untuk merealisasikan tujuan masa depan anda.',
  tutorialLink:
    'https://www.maybank.co.id/id/ebanking/how-to/Maybank2u/panduan-digital-wealth-customer-360?utm_source=WA&utm_medium=eksternal&utm_campaign=vb-21032022-panduan-digital-wealth-customer-360',
  errorMessage: {
    required: 'Ruang ini tidak boleh kosong',
    minAmount: 'Nilai yang Anda masukkan kurang dari batas minimum {amount}',
    maxAmount: 'Nilai yang Anda masukkan melebihi batas maksimum {amount}',
    goalName: 'Silakan isi Nama Tujuan',
    retireAge: 'Silakan isi Pensiun pada usia',
    lifeExpectancy: 'Silakan isi Harapan usia hidup saya',
    monthlyExpense: 'Silakan isi Pengeluaran bulanan di masa pensiun',
    investmentPeriod: 'Silakan isi Jangka waktu investasi',
    monthlyAmount: 'Silakan isi Nominal investasi bulanan',
    targetFund: 'Silakan isi Target dana yang ingin dicapai',
    fundraisingPeriod: 'Silahkan isi Periode pengumpulan dana',
    validLifeExpectancy: 'Usia Harapan Hidup harus diisi diantara 60 - 100 setidaknya 1 tahun dari Usia Pensiun',
    minMaxAmount: 'Silahkan masukan nilai minimum {min} dan batas maksimum {max}',
    minAmountForDW: 'Silahkan masukan nilai minimum {min}',
    minAmountWithMultiple: 'Silahkan masukan nilai minimum {min} dengan kelipatan {multiple}',
    minMaxAmountWithMultiple: 'Silahkan masukan nilai minimum {min} dengan kelipatan {multiple} dan batas maksimum {max}.',
    minMaxAmountWithMultipleAndZero:
      'Silahkan masukan nilai minimum {min} dengan kelipatan {multiple} dan batas maksimum {max}. Atau Anda dapat menginput IDR 0.',
    minMaxInvestmentPeriod: 'Silakan masukkan tenor lebih dari 1 tahun atau maksimal 30 tahun',
  },
};
