import { CURRENCY_IDR_PREFIX, DEFAULT_CC_FORMAT } from 'settings/constants/common';
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SELECT,
  FORMAT_FAVOURITE_NICKNAME,
  FAVOURITE_NICKNAME_MAX_LENGTH,
} from 'settings/constants/transaction';

export const OWN_CREDIT_CARD_FORM_FIELDS = [
  {
    name: 'creditCardNumber',
    label: 'transaction.payment.modal.ccNumber',
    type: FIELD_TYPE_TEXT,
    readOnly: true,
  },
  {
    name: 'paymentOption',
    label: 'transaction.payment.modal.paymentOption',
    placeholder: 'transaction.payment.placeHolder.selectPaymentOption',
    type: FIELD_TYPE_SELECT,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'paymentAmount',
    label: 'transaction.payment.modal.amount',
    type: FIELD_TYPE_NUMBER,
    prefix: CURRENCY_IDR_PREFIX,
    disabled: true,
  },
];

export const OTHER_CREDIT_CARD_FORM_FIELDS = [
  {
    name: 'creditCardNumber',
    label: 'transaction.payment.modal.ccNumber',
    type: FIELD_TYPE_NUMBER,
    format: DEFAULT_CC_FORMAT,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'validCcNumber', isValidateRequired: true },
    ],
  },
  {
    name: 'paymentAmount',
    label: 'transaction.payment.modal.amount',
    type: FIELD_TYPE_NUMBER,
    prefix: CURRENCY_IDR_PREFIX,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

// own and other bank credit card use the same form
export const CREDIT_CARD_NEW_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    name: 'cardType',
    label: 'transaction.payment.modal.cardType',
    placeholder: 'transaction.payment.placeHolder.pleaseSelect',
    type: FIELD_TYPE_SELECT,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'creditCardNumber',
    label: 'transaction.payment.modal.cardNumber',
    type: FIELD_TYPE_NUMBER,
    format: DEFAULT_CC_FORMAT,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'validCcNumber', isValidateRequired: true },
    ],
  },
];

export const CREDIT_CARD_EDIT_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'cardTypeDisplay',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.cardType',
  },
  {
    readOnly: true,
    name: 'creditCardNoDisplay',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.cardNumber',
  },
  {
    isHidden: true,
    readOnly: true,
    name: 'cardHolderName',
    type: FIELD_TYPE_TEXT,
    label: 'dashboard.cc.ccHolderName',
  },
  {
    isHidden: true,
    readOnly: true,
    name: 'issuingBank',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.favourite.payment.bankName',
  },
];
