export const favourite = {
  label: 'My favorites',
  placeholder: {
    searchPurchase: 'Search by Nickname, Reload Type, Operator name, Customer Number',
    searchPayment: 'Search by Nickname, Card/Mobile/Customer Number, Bill Type/Name',
    searchTransfer: 'Search by Nickname, Account Number, Transfer Type, Bank',
    searchEmpty: 'Beneficiary not found',
  },
  form: {
    currency: 'Currency',
    beneficiaryNickname: 'Beneficiary Nickname',
    dailyTransactionLimit: 'Daily Transaction Limit',
    title: {
      to: 'to',
      addNew: 'Add New Favorite',
      edit: 'Edit Favorite',
      delete: 'Delete Favorite',
      virtualAccount: 'Add New Favorite to Virtual Account Transfer',
      intrabank: 'Add New Favorite to Within Maybank Transfer',
      eWallet: 'Add New Favorite to E-Wallet Transfer',
      skn: 'Add New Favorite to LLG/SKN Transfer',
      interbank: 'Add New Favorite to Real Time Transfer',
      rtgs: 'Add New Favorite to RTGS Transfer',
      swift: 'Add new Favorite to SWIFT Foreign Currency Transfer',
      virtualAccountEdit: 'Edit Favorite Virtual Account Transfer',
      intrabankEdit: 'Edit Favorite Within Maybank Transfer',
      eWalletEdit: 'Edit Favorite E-Wallet Transfer',
      sknEdit: 'Edit Favorite LLG/SKN Transfer',
      interbankEdit: 'Edit Favorite Real Time Transfer',
      rtgsEdit: 'Edit Favorite RTGS Transfer',
      swiftEdit: 'Edit Favorite SWIFT Foreign Currency Transfer',
    },
    content: { delete: 'Are you sure you want to delete your Favorite ?' },
  },
  drawer: {
    addSuccess: 'Add New Favorite Successful!',
    addFailed: 'Add New Favorite Failed!',
    editSuccess: 'Edit Favorite Successful!',
    editFailed: 'Edit Favorite Failed!',
    deleteSuccess: 'Delete Favorite Successful!',
    deleteFailed: 'Delete Favorite Failed!',
    mainPageAddSuccess: 'New Favorite Successfully Added',
    mainPageDeleteSuccess: 'Favorite Successfully Deleted',
    mainPageEditSuccess: 'Favorite Successfully Edited',
    mainPageAddError: 'Add New Favorite Failed!',
    mainPageEditError: 'Edit Favorite Failed!',
    mainPageDeleteError: 'Delete Favorite Failed!',
  },
  payment: {
    for: 'Payment for',
    bankName: 'Bank Name',
    title: {
      creditCard: 'Add New Favorite for Credit Card Payment',
      mobilePostpaid: 'Add New Favorite for Mobile Postpaid Payment',
      utility: 'Add New Favorite for Utility Payment',
      otherBill: 'Add New Favorite for Other Bill Payment',
      creditCardEdit: 'Edit Favorite Credit Card Payment',
      mobilePostpaidEdit: 'Edit Favorite Mobile Postpaid Payment',
      utilityEdit: 'Edit Favorite Utility Payment',
      otherBillEdit: 'Edit Favorite for Other Bill Payment',
    },
  },
  purchase: {
    for: 'Purchase for',
    operatorName: 'Mobile Operator',
    title: {
      ticket: 'Add New Favorite for Ticket Purchase',
      pln: 'Add New Favorite for PLN Reload Purchase',
      mobile: 'Add New Favorite for Mobile Prepaid and Data Package Reload Purchase',
      ticketEdit: 'Edit Favorite Ticket Purchase',
      plnEdit: 'Edit Favorite PLN Reload Purchase',
      mobileEdit: 'Edit Favorite Mobile Prepaid and Data Package Reload Purchase',
    },
  },
  errorMessage: {
    invalidAccountNickname: 'Maximum length between 3 to 50 characters',
  },
};
