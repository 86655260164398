import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import parse from 'html-react-parser';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { BasicButton } from 'components/common/buttons';
import Icon from 'components/common/Icon';

const TacStepInitialSection = ({ buttonTitle, description, onClick, title }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container sm={12} md={12} item>
      <Grid item xs={12} sm={4} md={5} container className="title" alignItems="center">
        <Typography className="tac--title">{title}</Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={7} container justify="flex-end" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <Typography className="tac--description">
            {description}
            <Icon hasTooltip isAssetIcon title={parse(formatMessage({ id: 'tac.tooltip.title' }))} tooltipClassName="tac--tooltip" />
          </Typography>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <BasicButton fullWidth size="large" onClick={onClick}>
            {buttonTitle}
          </BasicButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

TacStepInitialSection.defaultProps = {
  buttonTitle: '',
  description: '',
  title: '',
  onClick: null,
};

TacStepInitialSection.propTypes = {
  buttonTitle: PropTypes.node,
  description: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default TacStepInitialSection;
