import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ActionButton } from 'components/common/buttons';
import { CountdownTimer, Icon, Textfield, Link } from 'components/common';

import { TAC_MAX_LENGTH, RESEND_TAC_TIMER } from 'settings/constants/tac';

const TacStepFinalSection = ({ isShowResend, isShowTimer, onChange, onButtonClick, onLinkClick, onTimerComplete, value, timestamp }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container sm={12} md={12} item>
      <Grid item xs={12} sm={4} md={5} container className="title" alignItems="center">
        <Typography className="tac--title">
          <FormattedMessage id="tac.timestamp" />
          <br />
          <FormattedMessage id="tac.timestamp2" values={{ timestamp }} />
          <Icon isAssetIcon type="tacTimer" className="tac--timer" />
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={7} container alignItems="center" justify="flex-end">
        <Grid item md={5} sm={5} xs={12} container direction="column" className="tac--timestamp">
          {isShowResend && (
            <>
              <Typography>
                <FormattedMessage id="tac.notReceive" />
              </Typography>

              <button type="button" className="ttc link--dark tac--resend-btn" onClick={onLinkClick}>
                <FormattedMessage id="tac.resendTac" />
              </button>
            </>
          )}

          {isShowTimer && (
            <CountdownTimer
              isStart={isShowTimer}
              onComplete={onTimerComplete}
              duration={RESEND_TAC_TIMER}
              title={formatMessage({ id: 'tac.timerTitle' })}
            />
          )}
        </Grid>

        <Grid item md={7} sm={7} xs={12} container alignItems="center" justify="flex-end">
          <Grid item sm={6} xs={12} className="tac--input">
            <Textfield
              type="password"
              id="tac"
              autoComplete="off"
              value={value}
              maxLength={TAC_MAX_LENGTH}
              onChange={onChange}
              placeholder={formatMessage({ id: 'tac.placeholder' })}
            />
          </Grid>

          <Grid item sm={6} xs={12} className="tac--input">
            <ActionButton
              fullWidth
              isAssetIcon={false}
              title={formatMessage({ id: 'tac.confirm' })}
              icon="arrowForward"
              className="db"
              onClick={onButtonClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TacStepFinalSection.defaultProps = {
  isShowResend: false,
  isShowTimer: false,
  onChange: null,
  onButtonClick: null,
  onLinkClick: null,
  onTimerComplete: null,
  value: '',
  timestamp: '',
};

TacStepFinalSection.propTypes = {
  isShowResend: PropTypes.bool,
  isShowTimer: PropTypes.bool,
  onChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  onTimerComplete: PropTypes.func,
  value: PropTypes.string,
  timestamp: PropTypes.string,
};

export default TacStepFinalSection;
