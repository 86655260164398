import isNull from 'lodash/isNull';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putInterbankReceipt,
  putInterbankTacResult,
  putInterbankConfirmation,
  putInterbankTransferDetail,
  putInterbankTransferResult,
} from 'middleware/actions/transfer';

import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatInterbankDetail } from 'utils/transfer/interbank/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';

import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';
import { RECEIPT_KEYMAP, TRANSFER_KEYMAP } from 'settings/constants/keymap';

const { TRANSFER_CONFIRMATION, TRANSFER_DETAIL, TRANSFER_RESULT, REQUEST_TAC, RECEIPT, TRANSFER_FROM_FAVOURITE_DETAIL } =
  actions.GET.INTERBANK;

const getClientIP = () => remoteIpInstance.get();

const getResult = ({ body }) => Instance.post(endpoint.interbank.getResult, body);
const getDetail = ({ body }) => Instance.post(endpoint.interbank.getDetail, body);
const getReceipt = ({ body }) => Instance.post(endpoint.interbank.getReceipt, body);
const requestTAC = ({ body }) => Instance.post(endpoint.interbank.requestTac, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.interbank.getConfirmation, body);
const getFavouriteDetail = ({ body }) => Instance.post(endpoint.interbank.favourite.getTransferDetail, body);

const getLocale = state => state.LanguageReducer.locale;
const getFavouriteAccount = state => state.FavouriteTransferReducer.favouriteAccount;

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });
    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      const res = formatInterbankDetail(data);
      yield put(putInterbankTransferDetail({ res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getFavouriteDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getFavouriteDetail, { body });
    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      const res = formatInterbankDetail(data);
      yield put(putInterbankTransferDetail({ res }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const { ip } = yield call(getClientIP);

    const locale = yield select(getLocale);
    const favouriteAccount = yield select(getFavouriteAccount);
    const favTransaction = get(payload, 'favTransaction', false);
    const favAccountMaps = get(favouriteAccount, 'favouriteAccountMap', {});

    const initialBody = {
      ...payload,
      remoteIPAddress: ip,
      locale,
      ...(favTransaction ? { favAccountMaps } : {}),
    };
    const body = formatKeys(initialBody, TRANSFER_KEYMAP);

    const { data } = yield call(getConfirmation, { body });
    yield put(putInterbankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });
    yield put(putInterbankTacResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });
    yield put(putInterbankTransferResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    const responseCode = get(data, 'responseCode', '');
    yield put(putInterbankReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferInterbank() {
  yield all([
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(TRANSFER_RESULT, getResultSaga),
    takeLatest(TRANSFER_DETAIL, getDetailSaga),
    takeLatest(TRANSFER_CONFIRMATION, getConfirmationSaga),
    takeLatest(TRANSFER_FROM_FAVOURITE_DETAIL, getFavouriteDetailSaga),
  ]);
}
