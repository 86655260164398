import { Children } from 'react';
import PropTypes from 'prop-types';

import CardContent from '@material-ui/core/CardContent';

import { get } from 'utils/lodash';
import { MONTH_YEAR_EN, MONTH_YEAR_ID, PLN_POSTPAID, PLN_POSTPAID_SMALL } from 'settings/constants/payment/utility/common';

import CardContentItem from './Item';

import './style.scss';

const CardContentList = ({ contents, className, itemClassName, receiverName }) => (
  <CardContent className={`content--list ${className}`}>
    {Array.isArray &&
      Children.toArray(
        contents.map((item, index) => {
          const items = item;

          if (
            index !== 0 &&
            (items.title === MONTH_YEAR_EN || items.title === MONTH_YEAR_ID) &&
            (receiverName === PLN_POSTPAID || receiverName === PLN_POSTPAID_SMALL)
          ) {
            items.title = '';
          }

          const info = get(item, 'info', '');
          const itemTitle = get(item, 'title', '');
          return <CardContentItem title={itemTitle} info={info} className={itemClassName} />;
        })
      )}
  </CardContent>
);

CardContentList.defaultProps = { contents: [], className: '', itemClassName: '', receiverName: '' };

CardContentList.propTypes = {
  contents: PropTypes.array,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  receiverName: PropTypes.string,
};

export default CardContentList;
