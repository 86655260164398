import { actions } from 'middleware/actions/inboxs';

const { INBOX } = actions.PUT;

const initialState = {
  inboxList: {},
  sentList: {},
  deleteInbox: {},
  deleteSent: {},
  composeAccess: {},
  composeSend: {},
  updateReadInbox: {},
  replyInbox: {},
};

const InboxsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INBOX.INBOXS_INBOX_LIST:
      return { ...state, inboxList: action.res };
    case INBOX.INBOXS_SENT_LIST:
      return { ...state, sentList: action.res };
    case INBOX.INBOXS_DELETE_INBOX:
      return { ...state, deleteInbox: action.res };
    case INBOX.INBOXS_DELETE_SENT:
      return { ...state, deleteSent: action.res };
    case INBOX.INBOXS_COMPOSE_ACCESS:
      return { ...state, composeAccess: action.res };
    case INBOX.INBOXS_COMPOSE_SEND:
      return { ...state, composeSend: action.res };
    case INBOX.INBOXS_UPDATE_READ_INBOX:
      return { ...state, updateReadInbox: action.res };
    case INBOX.INBOXS_REPLY:
      return { ...state, replyInbox: action.res };
    default:
      return state;
  }
};

export default InboxsReducer;
