import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Grid } from '@material-ui/core';

import { DynoAlertModal } from 'components/dyno-template/modals';
import { BasicButton, IconButton } from 'components/common/buttons';
import { BasicModal } from 'components/common/modals';
import { Icon } from 'components/common';

import LoadingIcon from 'assets/icons/loading.gif';

const Coachmark = ({
  loading,
  isCoachmarkOpen,
  isCoachmarkActionOpen,
  hasRemember,
  coachmarkIndex,
  coachmarkImage,
  openCoachmark,
  handleCoachmarkPrev,
  handleCoachmarkNext,
  setLoading,
  setCoachmarkActionOpen,
  setCoachmarkOpen,
  setHasRemember,
}) => {
  const { formatMessage } = useIntl();

  const CoachmarkFooter = (
    <Box display="flex">
      <BasicButton
        className="close-btn--coachmark"
        disabledElevation
        onClick={() => setCoachmarkOpen(false)}
        title={formatMessage({ id: 'buttonText.close' })}
      />
      <Grid container xs={12} justify="flex-end" spacing={3}>
        <Grid item>
          <IconButton isDisabled={coachmarkIndex === 1} onClick={handleCoachmarkPrev} className="btn--coachmark">
            <Icon type="chevronPrev" />
          </IconButton>
        </Grid>
        <Grid item className="ml3">
          <IconButton onClick={handleCoachmarkNext} className="btn--coachmark">
            <Icon type="chevronNext" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <DynoAlertModal
        isOpen={isCoachmarkActionOpen}
        onClose={() => setCoachmarkActionOpen(false)}
        onCancel={() => setCoachmarkActionOpen(false)}
        onSubmit={openCoachmark}
        hasCancelButton
        title={formatMessage({ id: 'dashboard.coachmark.title' })}
        description={formatMessage({ id: 'dashboard.coachmark.description' })}
        actionButtonTitle={formatMessage({ id: 'buttonText.letsGo' })}
        secondaryActionButtonTitle={formatMessage({ id: 'buttonText.skip' })}
        hasCheckbox
        hasRemember={hasRemember}
        handleCheckboxClicked={setHasRemember}
        checklistLabel={<FormattedMessage id="dashboard.coachmark.checklist" />}
      />
      <BasicModal
        hasFooter
        footer={CoachmarkFooter}
        onClose={() => setCoachmarkOpen(false)}
        isOpen={isCoachmarkOpen}
        className={loading ? 'coachmark--modal on-loading' : 'coachmark--modal'}
        disableBackdropClick
      >
        <div style={{ display: loading ? 'block' : 'none' }}>
          <img src={LoadingIcon} alt="Loading..." />
        </div>
        {coachmarkImage && (
          <img
            style={{ display: loading ? 'none' : 'block' }}
            onLoad={() => setLoading(false)}
            className="w-100 h-100"
            alt={`coachmark index ${coachmarkIndex}`}
            src={coachmarkImage}
          />
        )}
      </BasicModal>
    </>
  );
};

Coachmark.defaultProps = {
  loading: true,
  isCoachmarkOpen: false,
  isCoachmarkActionOpen: true,
  hasRemember: false,
  coachmarkIndex: 0,
  coachmarkImage: '',
  setLoading: null,
  setCoachmarkActionOpen: null,
  setCoachmarkOpen: null,
  setHasRemember: null,
  openCoachmark: null,
  handleCoachmarkPrev: null,
  handleCoachmarkNext: null,
};

Coachmark.propTypes = {
  loading: PropTypes.bool,
  isCoachmarkOpen: PropTypes.bool,
  isCoachmarkActionOpen: PropTypes.bool,
  hasRemember: PropTypes.bool,
  coachmarkIndex: PropTypes.number,
  coachmarkImage: PropTypes.string,
  setLoading: PropTypes.func,
  setCoachmarkActionOpen: PropTypes.func,
  setCoachmarkOpen: PropTypes.func,
  setHasRemember: PropTypes.func,
  openCoachmark: PropTypes.func,
  handleCoachmarkPrev: PropTypes.func,
  handleCoachmarkNext: PropTypes.func,
};

export default Coachmark;
