import { isEmpty } from 'lodash';
import { get } from 'utils/lodash';

export const autoSelectFirstInbox = () => {
  const contentInbox = document.getElementById('content--inboxs');
  const inboxItems = document.getElementById('inbox--items');

  if (!isEmpty(contentInbox)) contentInbox.click();
  if (!isEmpty(inboxItems)) inboxItems.focus();
};

export const formatComposeBody = payload => {
  const body = {
    userId: get(payload, 'userId', ''),
    subjectId: get(payload, 'subjectId', ''),
    message: get(payload, 'message', ''),
  };
  return body;
};

export const formatReplyInbox = payload => {
  const body = {
    userId: get(payload, 'userId', ''),
    messageId: get(payload, 'messageId', ''),
    replyMessage: get(payload, 'message', ''),
  };
  return body;
};

export const extractContentHtml = html => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export const formatUpdateReadInboxBody = payload => {
  const body = {
    userId: get(payload, 'userId', ''),
    messageId: get(payload, 'messageId', ''),
  };
  return body;
};
