import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Drawer, Grid } from '@material-ui/core';

import { isValidTac } from 'utils/validation';

import { SIDEBAR_VARIANT } from 'settings/constants/ui-control';
import {
  TAC_STEP_INITIAL,
  TAC_INITIAL_RESEND_COUNT,
  MAX_RESEND_COUNT,
  RESEND_COUNT_INCREMENT,
  TAC_STEP_FINAL,
} from 'settings/constants/tac';

import BasicDrawer from './BasicDrawer';
import TacStepInitialSection from './content/TacStepInitialSection';
import TacStepFinalSection from './content/TacStepFinalSection';

import './style.scss';

const { PERSISTENT } = SIDEBAR_VARIANT;

const TacDrawer = ({
  className,
  variant,
  currentStep,
  description,
  title,
  timestamp,
  hideBackdrop,
  isOpen,
  onClose,
  onRequestTac,
  onSubmitTac,
}) => {
  const { formatMessage } = useIntl();

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tac: '',
    resendCount: TAC_INITIAL_RESEND_COUNT,
    isDisplay: false,
    isError: false,
    isShowResend: false,
    isShowTimer: false,
  });

  const { tac, isDisplay, isError, isShowResend, isShowTimer, resendCount } = state;

  const handleSubmit = () => {
    // inline validate TAC value before submitting back to app
    if (!isValidTac(tac)) {
      setState({
        isDisplay: false,
        isError: true,
      });
      return;
    }

    onSubmitTac(tac);
  };

  const handleResendTac = () => {
    onRequestTac();

    const updatedResendCount = resendCount + RESEND_COUNT_INCREMENT;
    setState({
      isShowResend: false,
      resendCount: updatedResendCount,
      isShowTimer: updatedResendCount < MAX_RESEND_COUNT,
    });
  };

  const handleClose = () =>
    setState({
      isError: false,
      isDisplay: isOpen,
    });

  const handleTimerComplete = () => setState({ isShowTimer: false, isShowResend: resendCount < MAX_RESEND_COUNT });

  useEffect(() => {
    setState({ isDisplay: isOpen });
  }, [isOpen]);

  useEffect(() => {
    // start timer upon TAC requested
    if (currentStep === TAC_STEP_FINAL) {
      setState({ isShowTimer: true });
    }
  }, [currentStep]);

  return (
    <>
      <Drawer
        anchor="bottom"
        variant={variant}
        open={isDisplay}
        onClose={onClose}
        className={`drawer drawer-tac ${className}`}
        hideBackdrop={hideBackdrop}
        ModalProps={{ hideBackdrop }}
      >
        <Grid container justify="center">
          <Grid container sm={12} md={10} item>
            {currentStep === TAC_STEP_INITIAL ? (
              <TacStepInitialSection
                buttonTitle={formatMessage({ id: 'buttonText.requestTac' })}
                description={description}
                onClick={onRequestTac}
                title={title}
              />
            ) : (
              <TacStepFinalSection
                isShowResend={isShowResend}
                isShowTimer={isShowTimer}
                onChange={e => setState({ tac: e.target.value })}
                onButtonClick={handleSubmit}
                onLinkClick={handleResendTac}
                onTimerComplete={handleTimerComplete}
                value={tac}
                timestamp={timestamp}
              />
            )}
          </Grid>
        </Grid>
      </Drawer>

      <BasicDrawer className="error" isOpen={isError} hideBackdrop={false} onClose={handleClose}>
        <h1 className="white f5 lh-copy mb0 tc">
          <FormattedMessage id="tac.invalid" />
        </h1>
      </BasicDrawer>
    </>
  );
};

TacDrawer.defaultProps = {
  className: '',
  title: '',
  description: '',
  onClose: null,
  onRequestTac: null,
  hideBackdrop: true,
  variant: PERSISTENT,
};

TacDrawer.propTypes = {
  title: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  onRequestTac: PropTypes.func,
  className: PropTypes.string,
  description: PropTypes.node,
  hideBackdrop: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  onSubmitTac: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
};

export default TacDrawer;
