import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { putErrorResponse } from 'middleware/actions/error';
import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

import {
  actions,
  putCCFavouriteDetail,
  putCCFavouriteConfirm,
  putCCFavouriteResult,
  putCCFavouriteTac,
  putCCPaymentDetailFromFavourite,
} from 'middleware/actions/favourite/payment';

const getClientIP = () => remoteIpInstance.get();

const newFavouriteDetail = ({ body }) => Instance.post(endpoint.creditCard.favourite.add.getDetail, body);
const newFavouriteDetailFromPayment = ({ body }) => Instance.post(endpoint.creditCard.favourite.add.getDetailFromPayment, body);
const newFavouriteConfirmation = ({ body }) => Instance.post(endpoint.creditCard.favourite.add.getConfirmation, body);
const newFavouriteTac = ({ body }) => Instance.post(endpoint.creditCard.favourite.add.getTac, body);
const newFavouriteResult = ({ body }) => Instance.post(endpoint.creditCard.favourite.add.getResult, body);
const getEditDetail = ({ body }) => Instance.post(endpoint.creditCard.favourite.edit.getDetail, body);
const getEditConfirmation = ({ body }) => Instance.post(endpoint.creditCard.favourite.edit.getConfirmation, body);
const getEditTac = ({ body }) => Instance.post(endpoint.creditCard.favourite.edit.getTac, body);
const getEditResult = ({ body }) => Instance.post(endpoint.creditCard.favourite.edit.getResult, body);
const getDeleteConfirm = ({ body }) => Instance.post(endpoint.creditCard.favourite.delete.getConfirmation, body);
const getDeleteTac = ({ body }) => Instance.post(endpoint.creditCard.favourite.delete.getTac, body);
const getDeleteResult = ({ body }) => Instance.post(endpoint.creditCard.favourite.delete.getResult, body);
const getMaybankDetail = ({ body }) => Instance.post(endpoint.creditCard.favourite.payment.getMaybankDetail, body);
const getOtherDetail = ({ body }) => Instance.post(endpoint.creditCard.favourite.payment.getOtherDetail, body);

const getLocale = state => state.LanguageReducer.locale;

const { CONFIRM: DELETE_CONFIRM, TAC: DELETE_TAC, RESULT: DELETE_RESULT } = actions.GET.CREDIT_CARD.DELETE_FAVOURITE;
const { DETAIL: EDIT_DETAIL, CONFIRM: EDIT_CONFIRM, TAC: EDIT_TAC, RESULT: EDIT_RESULT } = actions.GET.CREDIT_CARD.EDIT_FAVOURITE;
const { MAYBANK_DETAIL, OTHER_DETAIL } = actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE;
const {
  DETAIL: ADD_DETAIL,
  DETAIL_FROM_PAYMENT,
  CONFIRM: ADD_CONFIRM,
  TAC: ADD_TAC,
  RESULT: ADD_RESULT,
} = actions.GET.CREDIT_CARD.ADD_FAVOURITE;

function* getNewFavouriteCcDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });
    yield put(putCCFavouriteDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getNewFavouriteCcDetailFromPaymentSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailFromPayment, { body });
    yield put(putCCFavouriteDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getNewFavouriteConfirmationSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteConfirmation, { body });
    yield put(putCCFavouriteConfirm(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getEditDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getEditDetail, { body });
    yield put(putCCFavouriteDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getEditConfirmSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getEditConfirmation, { body });
    yield put(putCCFavouriteConfirm(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getDeleteConfirmSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getDeleteConfirm, { body });
    yield put(putCCFavouriteConfirm(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonTacSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_TAC]: () => newFavouriteTac,
      [DELETE_TAC]: () => getDeleteTac,
      [EDIT_TAC]: () => getEditTac,
      default: () => null,
    };

    const tacEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(tacEndpoint, { body });
    yield put(putCCFavouriteTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getCommonResultSaga({ payload, type }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const handler = {
      [ADD_RESULT]: () => newFavouriteResult,
      [DELETE_RESULT]: () => getDeleteResult,
      [EDIT_RESULT]: () => getEditResult,
      default: () => null,
    };

    const resultEndpoint = (handler[type] || handler.default)();

    const { data } = yield call(resultEndpoint, { body });
    yield put(putCCFavouriteResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMaybankDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getMaybankDetail, { body });
    yield put(putCCPaymentDetailFromFavourite(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOtherDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(getOtherDetail, { body });
    yield put(putCCPaymentDetailFromFavourite(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* CreditCardFavourite() {
  yield all([
    takeLatest(ADD_DETAIL, getNewFavouriteCcDetailSaga),
    takeLatest(DETAIL_FROM_PAYMENT, getNewFavouriteCcDetailFromPaymentSaga),
    takeLatest(ADD_CONFIRM, getNewFavouriteConfirmationSaga),
    takeLatest(ADD_TAC, getCommonTacSaga),
    takeLatest(ADD_RESULT, getCommonResultSaga),
    takeLatest(EDIT_DETAIL, getEditDetailSaga),
    takeLatest(EDIT_CONFIRM, getEditConfirmSaga),
    takeLatest(EDIT_TAC, getCommonTacSaga),
    takeLatest(EDIT_RESULT, getCommonResultSaga),
    takeLatest(DELETE_CONFIRM, getDeleteConfirmSaga),
    takeLatest(DELETE_TAC, getCommonTacSaga),
    takeLatest(DELETE_RESULT, getCommonResultSaga),
    takeLatest(MAYBANK_DETAIL, getMaybankDetailSaga),
    takeLatest(OTHER_DETAIL, getOtherDetailSaga),
  ]);
}
