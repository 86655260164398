// Default timezone for momentjs
export const DEFAULT_TIMEZONE = 'Asia/Jakarta';

// Login constants
export const STATUS_FAILED = 1;
export const PARSE_INT_RADIX = 10;
export const LOGIN_STEP_FINAL = 'FINAL';
export const LOGIN_STEP_INITIAL = 'INITIAL';
export const DEFAULT_CONTACT_NO = '1500611';

// Input min/max length
export const DEFAULT_MIN_LENGTH = 0;
export const DEFAULT_MAX_LENGTH = 0;
export const USERNAME_MIN_LENGTH = 6;
export const USERNAME_MAX_LENGTH = 26;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MIN_NEW_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;
export const PIN_MAX_LENGTH = 6;
export const EXPIRY_MAX_LENGTH = 2;
export const AMOUNT_MAX_LENGTH = 12;
export const CREDIT_CARD_MIN_LENGTH = 15;
export const NEW_CREDIT_CARD_MIN_LENGTH = 16;
export const CREDIT_CARD_MAX_LENGTH = 19;
export const EWALLET_NUMBER_MIN_LENGTH = 8;
export const EWALLET_NUMBER_MAX_LENGTH = 16;
export const ACCOUNT_NUMBER_MIN_LENGTH = 10;
export const ACCOUNT_NUMBER_MAX_LENGTH = 10;
export const PAYMENT_CODE_MIN_LENGTH = 10;
export const PAYMENT_CODE_MAX_LENGTH = 14;
export const TRANSFER_MESSAGE_MAX_LENGTH = 40;
export const OTHER_BILL_ACCOUNT_WATER_MIN_LENGTH = 5;
export const OTHER_BILL_ACCOUNT_MIN_LENGTH = 10;
export const OTHER_BILL_ACCOUNT_MAX_LENGTH = 16;
export const OTHER_BILL_REFERENCE_NUMBER_MIN_LENGTH = 10;
export const OTHER_BILL_REFERENCE_NUMBER_MAX_LENGTH = 16;
export const PURCHASE_PLN_MIN_LENGTH = 10;
export const PURCHASE_PLN_MAX_LENGTH = 14;
export const PERSONAL_INFO_DISPLAY_NAME_MAX_LENGTH = 64;
export const SWIFT_ACCOUNT_NUMBER_MIN_LENGTH = 5;

// Account type
export const SAVING_ACCOUNT = 'SA';
export const CURRENT_ACCOUNT = 'CA';
export const X_ACCOUNT = 'XA';

// Initial account/card's amount
export const INITIAL_AMOUNT = '0.00';

// Currency codes
export const CURRENCY_USD = 'USD';
export const CURRENCY_IDR = 'IDR';
export const CURRENCY_CHF = 'CHF';
export const CURRENCY_JPY = 'JPY';

export const CURRENCY_IDR_PREFIX = `${CURRENCY_IDR} `;

// Default date format
export const DEFAULT_DATE_FORMAT = 'D MMM YYYY h:mm A';
export const LAST_LOGIN_DATE_FORMAT = 'D MMM YYYY hh:mm:ss';

export const INITIAL_NOTIFICATION_COUNT = 0;

export const DEFAULT_LIST_SIZE = 0;
export const DEFAULT_CONVERSION_RATE = 1;

export const TIME_DEPOSIT_TRANSLATION_KEY = 'td';

// Dropdown Label Type
export const DROPDOWN_LABEL_TYPE_ACCOUNT = 'account';
export const DROPDOWN_LABEL_TYPE_TRANSFER = 'transfer';

export const MBI_CORPORATE_WEBSITE = 'https://www.maybank.co.id/';
export const M2U_CLASSIC_WEBSITE = 'https://m2u.maybank.co.id/old/common/Login.do';
export const MAYBANK_CREDIT_CARD_WEBSITE =
  'https://www.maybank.co.id/others/applyonline?categoryId={40D78E331087446C98AD1A3FAF66D13F}&categoryTitle=KARTU%20KREDIT';

// PDF mime prefix
export const PDF_PREFIX = 'data:application/pdf;base64,';

export const TRANSACTION_DATE_DEFAULT = 'today';

export const DASHBOARD_PATH = '/dashboard';
export const TRANSACTION_PATH = '/transactions';
export const DIGITAL_WEALTH_PATH = '/wealth';
export const SETTINGS_PATH = '/settings';

// for regex operation
export const MATCH_RESULT_INDEX = 0;

// zero integer for default assignment
export const DEFAULT_INT_ZERO = 0;
export const INT_ONE = 1;
export const INT_TWO = 2;

export const COMMA_POSITION = 4;
export const DEFAULT_STEPPER_POSITION = 0;

export const SELECT_PLACEHOLDER_DEFAULT_VALUE = 'none';

export const CURRENCY_MAX_FRACTION_DIGIT = 1;

export const DEFAULT_DECIMAL_SCALE = 2;
export const NO_DECIMAL_SCALE = 0;
export const DEFAULT_THOUSAND_SEPARATOR = ',';

export const DEFAULT_FALLBACK_NUMBER = 0;
export const MOBILE_NUMBER_PREFIX = '08';

export const DEFAULT_CC_FORMAT = '#### #### #### #### ###';
export const DEFAULT_EWALLET_FORMAT = '#### #### #### ####';

export const M2U_APP_APPSTORE_URL = 'https://apps.apple.com/id/app/maybank2u-id/id1457339382';
export const M2U_APP_PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=id.com.uiux.mobile';

export const DEFAULT_MESSAGE_VALUE = '-';

// favourite action indicator
export const FAVOURITE_SELECTION = 'selection';
export const FAVOURITE_ADD_NEW = 'addNew';
export const FAVOURITE_ADD_NEW_FROM_RESULT = 'addNewFromResult';
export const FAVOURITE_EDIT = 'edit';
export const FAVOURITE_DELETE = 'delete';

export const ERR_44 = '44';
export const ERR_37 = '37';

// toolip
export const COACHMARK_CHECKED = '1';
export const COACHMARK_UNCHECKED = '0';
export const TOTAL_COACHMARK = 14;

// title page
export const TITLE_PAGE_M2U = 'Maybank2u - Transaction';

// session expired
export const IDLE_TIME = 240000;
export const COUNT_IDLE_IN_MODAL = 60000;

// list product code for add note admin fee in rtol
export const PRODUCT_CODE_NOTE_ADMIN_FEE = ['850', '272', '200', '230'];

// pbb payment
export const PBB_UAT = 1181;
export const PBB_NAME = 'PBB';
export const PBB_PPC = 1268;

// components
export const CHECKBOX_POSITION_TOP = 'top';
export const COMPONENT_USED_IN_TIME_DEPOSIT = 'TD';
export const TABLE_CLASS_UPPERCASE = 'ttu';
