import isNull from 'lodash/isNull';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putSwiftTAC,
  putSwiftBank,
  putSwiftAccess,
  puttSwiftReceipt,
  putSwiftLldDetail,
  putSwiftBankDetail,
  putSwiftResult,
  putSwiftTransferConfirmation,
} from 'middleware/actions/transfer';
import { get } from 'utils/lodash';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { formatSwiftAccess, formatSwiftBank, formatSwiftBankDetail } from 'utils/transfer/swift/formatter';
import { formatKeys } from 'utils/formatter';
import { RECEIPT_KEYMAP } from 'settings/constants/keymap';

const {
  BANKS,
  BANK_DETAIL,
  LLD_DETAIL,
  TRANSFER_CONFIRMATION,
  TRANSFER_RESULT,
  REQUEST_TAC,
  RECEIPT,
  ACCESS,
  TRANSFER_FROM_FAVOURITE_DETAIL,
} = actions.GET.SWIFT;

const getClientIP = () => remoteIpInstance.get();

const getBanks = ({ body }) => Instance.post(endpoint.swift.getBanks, body);
const getAccess = ({ body }) => Instance.post(endpoint.swift.getAccess, body);
const getResult = ({ body }) => Instance.post(endpoint.swift.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.swift.getReceipt, body);
const requestTAC = ({ body }) => Instance.post(endpoint.swift.requestTac, body);
const getLldDetail = ({ body }) => Instance.post(endpoint.swift.getLldDetail, body);
const getBankDetail = ({ body }) => Instance.post(endpoint.swift.getBankDetail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.swift.getConfirmation, body);
const getFavouriteDetail = ({ body }) => Instance.post(endpoint.swift.favourite.getTransferDetail, body);

const getLocale = state => state.LanguageReducer.locale;

function* getAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, userId: get(payload, 'userId', '') };
    const { data } = yield call(getAccess, { body });
    yield put(putSwiftAccess(formatSwiftAccess(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBanksSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBanks, { body });
    yield put(putSwiftBank(formatSwiftBank(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBankDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getBankDetail, { body });
    yield put(putSwiftBankDetail(formatSwiftBankDetail(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBankFavouriteDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getFavouriteDetail, { body });
    yield put(putSwiftBankDetail(formatSwiftBankDetail(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getLldDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getLldDetail, { body });
    yield put(putSwiftLldDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getConfirmation, { body });
    yield put(putSwiftTransferConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestTAC, { body });
    yield put(putSwiftTAC(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(getResult, { body });
    yield put(putSwiftResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    const responseCode = get(data, 'responseCode', '');
    yield put(puttSwiftReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferSWIFT() {
  yield all([
    takeLatest(BANKS, getBanksSaga),
    takeLatest(ACCESS, getAccessSaga),
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(LLD_DETAIL, getLldDetailSaga),
    takeLatest(TRANSFER_RESULT, getResultSaga),
    takeLatest(BANK_DETAIL, getBankDetailSaga),
    takeLatest(TRANSFER_CONFIRMATION, getConfirmationSaga),
    takeLatest(TRANSFER_FROM_FAVOURITE_DETAIL, getBankFavouriteDetailSaga),
  ]);
}
