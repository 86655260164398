export const payment = {
  label: 'Pembayaran',
  from: 'Pembayaran dari',
  to: 'Pembayaran untuk',
  pay: 'Bayar',
  fine: 'Denda',
  resultFail: 'Permintaan Anda Gagal!',
  resultSuccess: 'Transaksi Berhasil!',
  monthYear: 'Bulan/Tahun',
  transaction: 'Transaksi',
  foreignExchange: 'Pertukaran Asing',
  placeHolder: {
    autocomplete: 'Pilih Jenis Tagihan',
    selectPayee: 'Pilih Penerima Pembayaran Anda',
    selectCc: 'Pilih Kartu Kredit Anda',
    selectMaybankCc: 'Pilih Pemegang Kartu Kredit Maybank',
    selectPaymentOption: 'Pilih Opsi Pembayaran',
    maxChar: 'Maksimal 120 karakter',
    selectAmount: 'Pilih Jumlah',
    pleaseSelect: 'Silahkan Pilih',
  },
  creditCard: {
    creditCards: 'Kartu Kredit',
    ownCreditCard: 'Kartu Kredit Sendiri',
  },
  otherBill: {
    vaNumber: 'Nomor Akun Virtual',
    billAccountNumber: 'Nomor Rekening Tagihan',
    message: 'Pesan (Opsional)',
    billRef: 'Nomor Referensi Tagihan',
    billRefOptional: 'Nomor Referensi Tagihan (Opsional)',
    beneficiaryMessage: 'Pesan',
  },
  modal: {
    title: `Pembayaran untuk {billerName}`,
    titleFavourite: `Pembayaran untuk {billerName}`,
    titleOwnCard: 'Pembayaran Kartu Kredit Maybank Milik Sendiri',
    titleOtherMaybankCard: 'Pembayaran Kartu Kredit Maybank Lainnya',
    titleOtherBankCard: 'Pembayaran ke Kartu Kredit Bank Lain',
    customerNumber: 'No Pelanggan',
    mobileNumber: 'Nomor Ponsel',
    ccNumber: 'Nomor Kartu Kredit',
    cardNumber: 'Nomor Kartu',
    paymentOption: 'Pilihan Pembayaran',
    cardType: 'Jenis Kartu',
    biller: 'Biller',
    payee: 'Payee',
    amount: 'Jumlah',
  },
  errorMessage: {
    billerNotFound: 'Jenis Tagihan tidak ditemukan',
    invalidMobileNumber: 'Nomor ponsel tidak valid',
    invalidCustomerNumber: 'No Pelanggan Tidak Sesuai',
    invalidReferenceNumber: 'Nomor Referensi Tidak Valid',
    invalidCreditCardNumber: 'Nomor Kartu Kredit salah',
    invalidBillAccountNumber: 'Nomor Rekening Tagihan Tidak Valid',
    invalidVirtualAccountNumber: 'Nomor Akun Virtual Tidak Valid',
  },
};
