import isEmpty from 'lodash/isEmpty';

import { get } from 'utils/lodash';
import { store } from 'providers/store';

import {
  getCCAddFavouriteDetail,
  getUtilityAddFavouriteDetail,
  getPostpaidAddFavouriteDetail,
  getOtherBillAddFavouriteDetail,
  getCCDeleteFavouriteConfirm,
  getCCDeleteFavouriteResult,
  getCCDeleteFavouriteTac,
  getOtherBillDeleteFavouriteConfirm,
  getOtherBillDeleteFavouriteResult,
  getOtherBillDeleteFavouriteTac,
  getPostpaidDeleteFavouriteConfirm,
  getPostpaidDeleteFavouriteResult,
  getPostpaidDeleteFavouriteTac,
  getUtilityDeleteFavouriteConfirm,
  getUtilityDeleteFavouriteResult,
  getUtilityDeleteFavouriteTac,
  getUtilityAddFavouriteConfirmation,
  getPostpaidAddFavouriteConfirmation,
  getOtherBillAddFavouriteConfirmation,
  getCCAddFavouriteConfirmation,
  putResetPaymentTac,
  getCCEditFavouriteDetail,
  getUtilityEditFavouriteDetail,
  getPostpaidEditFavouriteDetail,
  getOtherBillEditFavouriteDetail,
  getCCAddFavouriteTac,
  getPostpaidAddFavouriteTac,
  getUtilityAddFavouriteTac,
  getOtherBillAddFavouriteTac,
  getCCAddFavouriteResult,
  getPostpaidAddFavouriteResult,
  getUtilityAddFavouriteResult,
  getOtherBillAddFavouriteResult,
  getCCEditFavouriteConfirm,
  getOtherBillEditFavouriteConfirm,
  getPostpaidEditFavouriteConfirm,
  getUtilityEditFavouriteConfirm,
  getUtilityEditFavouriteTac,
  getPostpaidEditFavouriteTac,
  getOtherBillEditFavouriteTac,
  getCCEditFavouriteTac,
  getUtilityEditFavouriteResult,
  getCCEditFavouriteResult,
  getOtherBillEditFavouriteResult,
  getPostpaidEditFavouriteResult,
  getMaybankCCPaymentDetailFromFavourite,
  getOtherCCPaymentDetailFromFavourite,
  getCCAddFavouriteDetailFromPayment,
  getPostpaidAddFavouriteDetailFromPayment,
  getUtilityAddFavouriteDetailFromPayment,
  getOtherBillAddFavouriteDetailFromPayment,
  getPostpaidPaymentDetailFromFavourite,
  getUtilityPaymentDetailFromFavourite,
  getOtherBillPaymentDetailFromFavourite,
} from 'middleware/actions/favourite/payment';
import {
  getUtilityNewFavouritePayload,
  getOtherBillNewFavouritePayload,
  getCreditCardNewFavouritePayload,
  getMobilePostpaidNewFavouritePayload,
} from 'utils/favourite/payment/getter';
import { formatDeleteConfirmationPayload } from 'utils/favourite/payment/formatter';
import {
  getCCFavouritePaymentDetail,
  getDeleteCCFavouriteResultPayload,
  getCreditCardFavouriteDynamicFields,
  getCCPaymentDetailFromFavourite,
} from 'utils/payment/credit-card/getter';
import {
  getDeleteUtilityFavouriteResultPayload,
  getUtilityFavouritePaymentDetail,
  getUtilityFavouriteDynamicFields,
  getUtilityPaymentDetailFromFav,
} from 'utils/payment/utility/getter';
import {
  getDeleteOtherBillFavouriteResultPayload,
  getOtherBillFavouritePaymentDetail,
  getOtherBillFavouriteDynamicFields,
  getOtherBillPaymentDetailFromFav,
} from 'utils/payment/other-bill/getter';
import {
  getDeletePostpaidFavouriteResultPayload,
  getPostpaidFavouritePaymentDetail,
  getMobilePostpaidFavouriteDynamicFields,
  getPostpaidPaymentDetailFromFav,
} from 'utils/payment/mobile-postpaid/getter';
import {
  formatCcAddFavouriteResultPayload,
  formatCCEditFavouritePayload,
  formatCCEditFavouriteResultPayload,
} from 'utils/payment/credit-card/formatter';
import {
  formatUtilityAddFavouriteResultPayload,
  formatUtilityEditFavouritePayload,
  formatUtilityEditFavouriteResultPayload,
} from 'utils/payment/utility/formatter';
import {
  formatOtherBillAddFavouriteResultPayload,
  formatOtherBillEditFavouritePayload,
  formatOtherBillEditFavouriteResultPayload,
} from 'utils/payment/other-bill/formatter';
import {
  formatPostpaidAddFavouriteResultPayload,
  formatPostpaidEditFavouritePayload,
  formatPostpaidEditFavouriteResultPayload,
} from 'utils/payment/mobile-postpaid/formatter';

import {
  FAV_PAYMENT_MAYBANK_CC,
  FAV_PAYMENT_OTHER_BILL,
  FAV_PAYMENT_OTHER_CC,
  FAV_PAYMENT_POSTPAID,
  FAV_PAYMENT_UTILITY,
  MOBILE_POSTPAID,
  PAYMENT_CREDIT_CARD,
  PAYMENT_OTHER_BILL,
  PAYMENT_UTILITY,
} from 'settings/constants/transaction';
import { FAVOURITE_PAYMENT_TYPES } from 'settings/constants/payment/form';
import { FAVOURITE_ADD_NEW, FAVOURITE_EDIT, FAVOURITE_SELECTION } from 'settings/constants/common';
import { UTILITY_EDIT_FAVOURITE, UTILITY_NEW_FAVOURITE } from 'settings/constants/payment/utility/form';
import { OTHER_BILL_EDIT_FAVOURITE, OTHER_BILL_NEW_FAVOURITE } from 'settings/constants/payment/other-bill/form';
import { CREDIT_CARD_EDIT_FAVOURITE, CREDIT_CARD_NEW_FAVOURITE } from 'settings/constants/payment/credit-card/form';
import { MOBILE_POSTPAID_EDIT_FAVOURITE, MOBILE_POSTPAID_NEW_FAVOURITE } from 'settings/constants/payment/mobile-postpaid/form';

export const addNewDynamicFieldHandler = (type, detail, payeeDetail, payeeType, isFromResult, isEdit, isVirtualAccount, formDetail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => getCreditCardFavouriteDynamicFields(detail, isFromResult, isEdit),
    [MOBILE_POSTPAID]: () => getMobilePostpaidFavouriteDynamicFields(detail, isFromResult),
    [PAYMENT_UTILITY]: () => getUtilityFavouriteDynamicFields(detail, formDetail, isFromResult),
    [PAYMENT_OTHER_BILL]: () => getOtherBillFavouriteDynamicFields(detail, payeeDetail, payeeType, isFromResult, isVirtualAccount, isEdit),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const formTitleHandler = (isDetailSuccess, type) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: 'transaction.favourite.payment.title.creditCard',
    [MOBILE_POSTPAID]: 'transaction.favourite.payment.title.mobilePostpaid',
    [PAYMENT_UTILITY]: 'transaction.favourite.payment.title.utility',
    [PAYMENT_OTHER_BILL]: 'transaction.favourite.payment.title.otherBill',
    default: 'transaction.favourite.form.title.addNew',
  };
  return (isDetailSuccess && handler[type]) || handler.default;
};

export const addNewFormFieldHandler = (isDetailSuccess, type, paymentTypes) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => CREDIT_CARD_NEW_FAVOURITE,
    [MOBILE_POSTPAID]: () => MOBILE_POSTPAID_NEW_FAVOURITE,
    [PAYMENT_UTILITY]: () => UTILITY_NEW_FAVOURITE,
    [PAYMENT_OTHER_BILL]: () => OTHER_BILL_NEW_FAVOURITE,
    default: () => (!isEmpty(paymentTypes) && FAVOURITE_PAYMENT_TYPES(paymentTypes)) || [],
  };

  return ((isDetailSuccess && handler[type]) || handler.default)();
};

export const submitActionHandler = (isDetailSuccess, isEditFavouriteDetailSuccess) => {
  if (isDetailSuccess && !isEditFavouriteDetailSuccess) {
    return FAVOURITE_ADD_NEW;
  }

  if (isEditFavouriteDetailSuccess) {
    return FAVOURITE_EDIT;
  }

  return FAVOURITE_SELECTION;
};

export const addNewFavouritePayload = (type, state) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => getCreditCardNewFavouritePayload(state),
    [MOBILE_POSTPAID]: () => getMobilePostpaidNewFavouritePayload(state),
    [PAYMENT_UTILITY]: () => getUtilityNewFavouritePayload(state),
    [PAYMENT_OTHER_BILL]: () => getOtherBillNewFavouritePayload(state),
    default: () => {},
  };
  return (handler[type] || handler.default)();
};

export const addNewFavouriteDispatchHandler = (type, state) => {
  const payload = addNewFavouritePayload(type, state);
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => store.dispatch(getCCAddFavouriteConfirmation(payload)),
    [MOBILE_POSTPAID]: () => store.dispatch(getPostpaidAddFavouriteConfirmation(payload)),
    [PAYMENT_UTILITY]: () => store.dispatch(getUtilityAddFavouriteConfirmation(payload)),
    [PAYMENT_OTHER_BILL]: () => store.dispatch(getOtherBillAddFavouriteConfirmation(payload)),
    default: () => FAVOURITE_PAYMENT_TYPES,
  };
  return (handler[type] || handler.default)();
};

export const favouriteDetailHandler = (type, detail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => get(detail, 'creditCardDetail', {}),
    [MOBILE_POSTPAID]: () => get(detail, 'mobilePostpaidDetail', {}),
    [PAYMENT_UTILITY]: () => get(detail, 'utilityDetail', {}),
    [PAYMENT_OTHER_BILL]: () => get(detail, 'otherBillDetail', {}),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const favouriteTacDetailHandler = (type, detail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => get(detail, 'creditCardTac', {}),
    [MOBILE_POSTPAID]: () => get(detail, 'mobilePostpaidTac', {}),
    [PAYMENT_UTILITY]: () => get(detail, 'utilityTac', {}),
    [PAYMENT_OTHER_BILL]: () => get(detail, 'otherBillTac', {}),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const favouriteResultDetailHandler = (type, detail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => get(detail, 'creditCardResult', {}),
    [MOBILE_POSTPAID]: () => get(detail, 'mobilePostpaidResult', {}),
    [PAYMENT_UTILITY]: () => get(detail, 'utilityResult', {}),
    [PAYMENT_OTHER_BILL]: () => get(detail, 'otherBillResult', {}),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const favouriteFormDetailHandler = (type, userId) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => store.dispatch(getCCAddFavouriteDetail({ userId })),
    [MOBILE_POSTPAID]: () => store.dispatch(getPostpaidAddFavouriteDetail({ userId })),
    [PAYMENT_UTILITY]: () => store.dispatch(getUtilityAddFavouriteDetail({ userId })),
    [PAYMENT_OTHER_BILL]: () => store.dispatch(getOtherBillAddFavouriteDetail({ userId })),
    default: () => null,
  };
  return (handler[type] || handler.default)();
};

export const favouriteConfirmationDetailHandler = (type, detail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => get(detail, 'creditCardConfirm', {}),
    [MOBILE_POSTPAID]: () => get(detail, 'mobilePostpaidConfirm', {}),
    [PAYMENT_UTILITY]: () => get(detail, 'utilityConfirm', {}),
    [PAYMENT_OTHER_BILL]: () => get(detail, 'otherBillConfirm', {}),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const paymentFromFavouriteDetailHandler = (type, detail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => get(detail, 'creditCardFavDetail', {}),
    [MOBILE_POSTPAID]: () => get(detail, 'mobilePostpaidFavDetail', {}),
    [PAYMENT_UTILITY]: () => get(detail, 'utilityFavDetail', {}),
    [PAYMENT_OTHER_BILL]: () => get(detail, 'otherBillFavDetail', {}),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const addNewFavouriteFromPaymentDetail = (type, detail, isEditFavourite) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => getCCFavouritePaymentDetail(detail, isEditFavourite),
    [MOBILE_POSTPAID]: () => getPostpaidFavouritePaymentDetail(detail, isEditFavourite),
    [PAYMENT_UTILITY]: () => getUtilityFavouritePaymentDetail(detail, isEditFavourite),
    [PAYMENT_OTHER_BILL]: () => getOtherBillFavouritePaymentDetail(detail, isEditFavourite),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const favouriteRequestTacHandler = (type, payload) => {
  store.dispatch(putResetPaymentTac());
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => {
      store.dispatch(getCCAddFavouriteTac({}));
      store.dispatch(getCCAddFavouriteTac(payload));
    },
    [MOBILE_POSTPAID]: () => {
      store.dispatch(getPostpaidAddFavouriteTac({}));
      store.dispatch(getPostpaidAddFavouriteTac(payload));
    },
    [PAYMENT_UTILITY]: () => {
      store.dispatch(getUtilityAddFavouriteTac({}));
      store.dispatch(getUtilityAddFavouriteTac(payload));
    },
    [PAYMENT_OTHER_BILL]: () => {
      store.dispatch(getOtherBillAddFavouriteTac({}));
      store.dispatch(getOtherBillAddFavouriteTac(payload));
    },
    default: () => {},
  };
  return (handler[type] || handler.default)();
};

export const favouriteSubmitConfirmHandler = (type, resultPayload) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => store.dispatch(getCCAddFavouriteResult(formatCcAddFavouriteResultPayload(resultPayload))),
    [MOBILE_POSTPAID]: () => store.dispatch(getPostpaidAddFavouriteResult(formatPostpaidAddFavouriteResultPayload(resultPayload))),
    [PAYMENT_UTILITY]: () => store.dispatch(getUtilityAddFavouriteResult(formatUtilityAddFavouriteResultPayload(resultPayload))),
    [PAYMENT_OTHER_BILL]: () => store.dispatch(getOtherBillAddFavouriteResult(formatOtherBillAddFavouriteResultPayload(resultPayload))),
    default: () => {},
  };
  return (handler[type] || handler.default)();
};

export const paymentFromFavouriteHandler = (type, payload, dispatch) => {
  const handler = {
    [FAV_PAYMENT_MAYBANK_CC]: () => dispatch(getMaybankCCPaymentDetailFromFavourite(payload)),
    [FAV_PAYMENT_OTHER_CC]: () => dispatch(getOtherCCPaymentDetailFromFavourite(payload)),
    [FAV_PAYMENT_POSTPAID]: () => dispatch(getPostpaidPaymentDetailFromFavourite(payload)),
    [FAV_PAYMENT_UTILITY]: () => dispatch(getUtilityPaymentDetailFromFavourite(payload)),
    [FAV_PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillPaymentDetailFromFavourite(payload)),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};

export const paymentFromFavouriteFormValue = (type, ccDetail, billerDetail) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => getCCPaymentDetailFromFavourite(ccDetail),
    [MOBILE_POSTPAID]: () => getPostpaidPaymentDetailFromFav(billerDetail),
    [PAYMENT_UTILITY]: () => getUtilityPaymentDetailFromFav(billerDetail),
    [PAYMENT_OTHER_BILL]: () => getOtherBillPaymentDetailFromFav(billerDetail),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const favouriteFormAfterPaymentDetailHandler = (type, userId, addFavObject) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => store.dispatch(getCCAddFavouriteDetailFromPayment({ userId, addFavObject })),
    [MOBILE_POSTPAID]: () => store.dispatch(getPostpaidAddFavouriteDetailFromPayment({ userId, addFavObject })),
    [PAYMENT_UTILITY]: () => store.dispatch(getUtilityAddFavouriteDetailFromPayment({ userId, addFavObject })),
    [PAYMENT_OTHER_BILL]: () => store.dispatch(getOtherBillAddFavouriteDetailFromPayment({ userId, addFavObject })),
    default: () => {},
  };
  return (handler[type] || handler.default)();
};

/* *************** EDIT FAVOURITE **************** */
export const editFormFieldHandler = (isDetailSuccess, type) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: CREDIT_CARD_EDIT_FAVOURITE,
    [MOBILE_POSTPAID]: MOBILE_POSTPAID_EDIT_FAVOURITE,
    [PAYMENT_UTILITY]: UTILITY_EDIT_FAVOURITE,
    [PAYMENT_OTHER_BILL]: OTHER_BILL_EDIT_FAVOURITE,
    default: [],
  };

  return (isDetailSuccess && handler[type]) || handler.default;
};

export const editFavouriteHandler = (type, initialPayload, dispatch) => {
  const payload = formatDeleteConfirmationPayload(initialPayload);
  const handler = {
    [PAYMENT_UTILITY]: () => dispatch(getUtilityEditFavouriteDetail(payload)),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidEditFavouriteDetail(payload)),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillEditFavouriteDetail(payload)),
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCEditFavouriteDetail(payload)),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};

export const editFavouriteDispatchHandler = (type, payload, dispatch) => {
  const handler = {
    [PAYMENT_UTILITY]: () => dispatch(getUtilityEditFavouriteConfirm(formatUtilityEditFavouritePayload(payload))),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidEditFavouriteConfirm(formatPostpaidEditFavouritePayload(payload))),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillEditFavouriteConfirm(formatOtherBillEditFavouritePayload(payload))),
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCEditFavouriteConfirm(formatCCEditFavouritePayload(payload))),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};

export const editFavouriteRequestTacHandler = (type, payload, dispatch) => {
  dispatch(putResetPaymentTac());
  const handler = {
    [PAYMENT_UTILITY]: () => dispatch(getUtilityEditFavouriteTac(payload)),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidEditFavouriteTac(payload)),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillEditFavouriteTac(payload)),
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCEditFavouriteTac(payload)),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};

export const editFavouriteResultHandler = (type, payload, dispatch) => {
  const handler = {
    [PAYMENT_UTILITY]: () => dispatch(getUtilityEditFavouriteResult(formatUtilityEditFavouriteResultPayload(payload))),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidEditFavouriteResult(formatPostpaidEditFavouriteResultPayload(payload))),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillEditFavouriteResult(formatOtherBillEditFavouriteResultPayload(payload))),
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCEditFavouriteResult(formatCCEditFavouriteResultPayload(payload))),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};
/* *************** EDIT FAVOURITE **************** */

/* *************** DELETE FAVOURITE **************** */
export const deleteFavouriteHandler = (type, initialPayload, dispatch) => {
  const payload = formatDeleteConfirmationPayload(initialPayload);
  const handler = {
    [PAYMENT_UTILITY]: () => dispatch(getUtilityDeleteFavouriteConfirm(payload)),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidDeleteFavouriteConfirm(payload)),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillDeleteFavouriteConfirm(payload)),
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCDeleteFavouriteConfirm(payload)),
    default: () => {},
  };

  return (handler[type] || handler.default)();
};

export const deleteFavouriteTacRequestHandler = (type, payload, dispatch) => {
  dispatch(putResetPaymentTac());
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCDeleteFavouriteTac(payload)),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidDeleteFavouriteTac(payload)),
    [PAYMENT_UTILITY]: () => dispatch(getUtilityDeleteFavouriteTac(payload)),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillDeleteFavouriteTac(payload)),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

export const deleteFavouriteSubmitHandler = (type, payload, dispatch) => {
  const handler = {
    [PAYMENT_CREDIT_CARD]: () => dispatch(getCCDeleteFavouriteResult(getDeleteCCFavouriteResultPayload(payload))),
    [MOBILE_POSTPAID]: () => dispatch(getPostpaidDeleteFavouriteResult(getDeletePostpaidFavouriteResultPayload(payload))),
    [PAYMENT_UTILITY]: () => dispatch(getUtilityDeleteFavouriteResult(getDeleteUtilityFavouriteResultPayload(payload))),
    [PAYMENT_OTHER_BILL]: () => dispatch(getOtherBillDeleteFavouriteResult(getDeleteOtherBillFavouriteResultPayload(payload))),
    default: () => ({}),
  };
  return (handler[type] || handler.default)();
};

/* *************** end DELETE FAVOURITE **************** */
