export const actions = {
  REGISTER_RESET: 'REGISTER_RESET',
  REQUEST_TAC: 'REGISTER_REQUEST_TAC',
  VERIFY: { PIN: 'REGISTER_VERIFY_PIN', CREDENTIAL: 'REGISTER_VERIFY_CREDENTIAL' },
  GET: { REGISTER_ACCESS: 'REGISTER_GET_ACCESS', REGISTER_RESULT: 'REGISTER_GET_REGISTER_RESULT' },
  PUT: {
    TAC_RESULT: 'REGISTER_PUT_TAC_RESULT',
    PIN_RESULT: 'REGISTER_PUT_PIN_RESULT',
    REGISTER_ACCESS: 'REGISTER_PUT_ACCESS_RESULT',
    REGISTER_RESULT: 'REGISTER_PUT_REGISTER_RESULT',
    CREDENTIAL_RESULT: 'REGISTER_PUT_CREDENTIAL_RESULT',
  },
};

export const getRegisterAccess = () => ({ type: actions.GET.REGISTER_ACCESS });

export const verifyPin = body => ({
  type: actions.VERIFY.PIN,
  payload: body,
});

export const verifyCredential = body => ({
  type: actions.VERIFY.CREDENTIAL,
  payload: body,
});

export const getRegisterResult = body => ({
  type: actions.GET.REGISTER_RESULT,
  payload: body,
});

export const requestTac = body => ({
  type: actions.REQUEST_TAC,
  payload: body,
});

export const putRegisterAccess = res => ({
  type: actions.PUT.REGISTER_ACCESS,
  payload: res,
});

export const putPinResult = res => ({
  type: actions.PUT.PIN_RESULT,
  payload: res,
});

export const putCredentialResult = res => ({
  type: actions.PUT.CREDENTIAL_RESULT,
  payload: res,
});

export const putRegisterResult = res => ({
  type: actions.PUT.REGISTER_RESULT,
  payload: res,
});

export const putTacResult = res => ({
  type: actions.PUT.TAC_RESULT,
  payload: res,
});

export const resetRegisterState = () => ({ type: actions.REGISTER_RESET });
