import { get } from 'utils/lodash';

import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

export const formatSettingsResult = data => {
  const responseCode = get(data, 'responseCode', '');
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  return { ...data, isSuccess, errorCode };
};

export const formatSecurityImagesResult = images => {
  return images.map(image => {
    const value = get(image, 'value', '');
    const src = `${process.env.REACT_APP_FILE_URL}${value}`;

    return { ...image, src };
  });
};

export const formatObjectThemes = theme => {
  const formatName = /-theme.png|theme-.jpg|.jpg|.png/gi;

  return theme.reduce(
    (curr, obj) => ({
      ...curr,
      [obj.value.replace(formatName, '')]: obj.key,
    }),
    {}
  );
};

// convert array into object and swap key and value in the object: {"a": 1, "b": 2} -> {1: "a", 2: "b"}
export const loopingObjectWithSwapKeyValue = obj => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));
};

// parse object into array of array
export const arrayFromObject = obj => Object.entries(obj);
