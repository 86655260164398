import PropTypes from 'prop-types';

import { FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';

import Icon from 'components/common/Icon';
import { CHECKBOX_POSITION_TOP } from 'settings/constants/common';

import { useCheckboxStyle, useCheckboxStyleTop } from './style/material';

// this component accepts one option at a time. If you have a list of options, push them into an array
const Checkbox = ({
  checkboxPosition,
  onClick,
  isChecked,
  id,
  name,
  value,
  required,
  disabled,
  label,
  onChange,
  variant,
  className,
  size,
  color,
}) => {
  const classesClassic = useCheckboxStyle();
  const classesTop = useCheckboxStyleTop();
  const classes = checkboxPosition === CHECKBOX_POSITION_TOP ? classesTop : classesClassic;

  // Return different icon according to variant type
  const variantIcon = variant === 'square' ? 'checkboxSquare' : 'checkboxRound';
  const variantCheckedIcon = variant === 'square' ? 'checkboxSquareChecked' : 'checkboxRoundChecked';

  return (
    <FormControlLabel
      className={`${className} ${classes.root}`}
      disabled={disabled}
      control={
        <MaterialCheckbox
          id={id}
          name={name}
          value={value}
          onClick={onClick}
          required={required}
          checked={isChecked}
          onChange={onChange}
          icon={<Icon isAssetIcon type={variantIcon} />}
          checkedIcon={<Icon isAssetIcon type={variantCheckedIcon} />}
          size={size}
          color={color}
        />
      }
      label={label}
    />
  );
};

Checkbox.defaultProps = {
  checkboxPosition: 'center',
  variant: 'square', // or round
  className: '',
  isChecked: false,
  onClick: null,
  disabled: false,
  required: false,
  id: '',
  value: '',
  name: '',
  label: '',
  size: '',
  color: '',
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checkboxPosition: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['round', 'square']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Checkbox;
