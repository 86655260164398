import { actions } from 'middleware/actions/digital-wealth';
import { updateGoalData, updateGraphSet } from 'utils/digital-wealth/chart';

const { RESET_GOAL, GBI, PORTFOLIO } = actions.PUT;

const initialState = {
  pensionGoal: {},
  educationGoal: {},
  maxInvestmentGoal: {},
  goalPlan: {},
  planDetail: {},
  expandedPlan: {},
  portfolio: {},
  bonds: {},
  bancas: {},
  bondDetail: {},
  bancaDetail: {},
  mutualFunds: {},
  mutualFundDetail: {},
  wealthDashboard: {},
};

const DigitalWealthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_GOAL:
      return initialState;
    case PORTFOLIO.OVERVIEW:
      return { ...state, portfolio: action.res };
    case PORTFOLIO.DASHBOARD:
      return { ...state, wealthDashboard: action.res };
    case PORTFOLIO.BANCAS:
      return { ...state, bancas: action.res };
    case PORTFOLIO.BANCA_DETAIL:
      return { ...state, bancaDetail: action.res };
    case PORTFOLIO.BONDS:
      return { ...state, bonds: action.res };
    case PORTFOLIO.BOND_DETAIL:
      return { ...state, bondDetail: action.res };
    case PORTFOLIO.MUTUAL_FUNDS:
      return { ...state, mutualFunds: action.res };
    case PORTFOLIO.MUTUAL_FUND_DETAIL:
      return { ...state, mutualFundDetail: action.res };
    case GBI.GOAL_PLAN:
      return { ...state, goalPlan: action.res };
    case GBI.EXPANDED_PLAN:
      return { ...state, expandedPlan: action.res };
    case GBI.PLAN_DETAIL:
      return { ...state, planDetail: action.res };
    case GBI.PENSION_GOAL:
      return {
        ...state,
        pensionGoal: {
          ...action.res,
          datasets: updateGraphSet(state.pensionGoal.datasets, action.res.graphData),
          targetFund: updateGoalData(state.pensionGoal.targetFund, action.res.targetFund),
          monthlyInvestment: updateGoalData(state.pensionGoal.monthlyInvestment, action.res.monthlyInvestment),
        },
      };
    case GBI.EDUCATION_GOAL:
      return {
        ...state,
        educationGoal: {
          ...action.res,
          datasets: updateGraphSet(state.educationGoal.datasets, action.res.graphData),
          targetFund: updateGoalData(state.educationGoal.targetFund, action.res.targetFund),
          monthlyInvestment: updateGoalData(state.educationGoal.monthlyInvestment, action.res.monthlyInvestment),
        },
      };
    case GBI.MAX_INVESTMENT_GOAL:
      return {
        ...state,
        maxInvestmentGoal: {
          ...action.res,
          datasets: updateGraphSet(state.maxInvestmentGoal.datasets, action.res.graphData),
          targetFund: updateGoalData(state.maxInvestmentGoal.targetFund, action.res.targetFund),
          monthlyInvestment: updateGoalData(state.maxInvestmentGoal.monthlyInvestment, action.res.monthlyInvestment),
        },
      };

    default:
      return state;
  }
};

export default DigitalWealthReducer;
