export const actions = {
  PUT: {
    ERROR_RESPONSE: 'ERROR_PUT_ERROR_RESPONSE',
  },
};

export const putErrorResponse = payload => ({
  type: actions.PUT.ERROR_RESPONSE,
  payload,
});
