export const actions = {
  GET: {
    ALL_ACCOUNT_BALANCE: 'DASHBOARD_GET_ALL_ACCOUNT_BALANCE',
    CASA_ACCOUNT_BALANCE: 'DASHBOARD_GET_CASA_ACCOUNT_BALANCE',
    LOAN_ACCOUNT_BALANCE: 'DASHBOARD_GET_LOAN_ACCOUNT_BALANCE',
    CASA_ACCOUNT_DETAIL: 'DASHBOARD_GET_CASA_ACCOUNT_DETAIL',
    LOAN_ACCOUNT_DETAIL: 'DASHBOARD_GET_LOAN_ACCOUNT_DETAIL',
    WEALTH_ACCOUNT_BALANCE: 'DASHBOARD_GET_WEALTH_ACCOUNT_BALANCE',
    WEALTH_ACCOUNT_SUMMARY: 'DASHBOARD_GET_WEALTH_ACCOUNT_SUMMARY',
    WEALTH_ACCOUNT_DETAIL_AND_HISTORY: 'DASHBOARD_GET_WEALTH_ACCOUNT_DETAIL_AND_HISTORY',
    WEALTH_ACCOUNT_HISTORY: 'DASHBOARD_GET_WEALTH_ACCOUNT_HISTORY',
    SINGLE_ACCOUNT_BALANCES: 'DASHBOARD_GET_SINGLE_ACCOUNT_BALANCES',
    UNIT_TRUST_ACCOUNT_DETAIL: 'DASHBOARD_GET_UNIT_TRUST_ACCOUNT_DETAIL',
    CREDIT_CARD_ACCOUNT_DETAIL: 'DASHBOARD_GET_CREDIT_CARD_ACCOUNT_DETAIL',
    TIME_DEPOSIT_ACCOUNT_DETAIL: 'DASHBOARD_GET_TIME_DEPOSIT_ACCOUNT_DETAIL',
    CREDIT_CARD_ACCOUNT_BALANCE: 'DASHBOARD_GET_CREDIT_CARD_ACCOUNT_BALANCE',
    TIME_DEPOSIT_ACCOUNT_BALANCE: 'DASHBOARD_GET_TIME_DEPOSIT_ACCOUNT_BALANCE',
    CASA_TRANSACTION_TYPE: 'DASHBOARD_GET_CASA_TRANSACTION_TYPE',
    CASA_TRANSACTION_HISTORY: 'DASHBOARD_GET_CASA_TRANSACTION_HISTORY',
    CASA_TRANSACTION_M2U_RECEIPT: 'DASHBOARD_GET_CASA_TRANSACTION_M2U_RECEIPT',
    CREDIT_CARD_TRANSACTION_HISTORY: 'DASHBOARD_GET_CREDIT_CARD_TRANSACTION_HISTORY',
    TIME_DEPOSIT_INITIATE_STOP_ARO: 'DASHBOARD_GET_TIME_DEPOSIT_INITIATE_STOP_ARO',
    TIME_DEPOSIT_STOP_ARO_TAC: 'DASHBOARD_GET_TIME_DEPOSIT_STOP_ARO_TAC',
    TIME_DEPOSIT_CONFIRM_STOP_ARO: 'DASHBOARD_GET_TIME_DEPOSIT_CONFIRM_ARO',
    TIME_DEPOSIT_STOP_ARO_RECEIPT: 'DASHBOARD_GET_TIME_DEPOSIT_STOP_ARO_RECEIPT',
    TIME_DEPOSIT_PRODUCTS: 'DASHBOARD_GET_TIME_DEPOSIT_PRODUCTS',
    TIME_DEPOSIT_INTEREST_RATE: 'DASHBOARD_GET_TIME_DEPOSIT_INTEREST_RATE',
    TIME_DEPOSIT_LIST_ACCOUNTS: 'DASHBOARD_GET_TIME_DEPOSIT_LIST_ACCOUNTS',
    TIME_DEPOSIT_VALIDATE: 'DASHBOARD_GET_TIME_DEPOSIT_VALIDATE',
    TIME_DEPOSIT_REQ_TAC: 'DASHBOARD_GET_TIME_DEPOSIT_REQ_TAC',
    TIME_DEPOSIT_DO_OPEN_ACCOUNT: 'DASHBOARD_GET_TIME_DEPOSIT_OPEN_ACCOUNT',
    ACTIVE_DASHBOARD_TAB: 'DASHBOARD_GET_ACTIVE_TAB',
  },
  PUT: {
    CASA_ACCOUNT: 'DASHBOARD_PUT_CASA_ACCOUNT',
    LOAN_ACCOUNTS: 'DASHBOARD_PUT_LOAN_ACCOUNTS',
    WEALTH_ACCOUNT: 'DASHBOARD_PUT_WEALTH_ACCOUNT',
    WEALTH_ACCOUNT_SUMMARY: 'DASHBOARD_PUT_WEALTH_ACCOUNT_SUMMARY',
    WEALTH_ACCOUNT_DETAIL_AND_HISTORY: 'DASHBOARD_PUT_WEALTH_ACCOUNT_DETAIL_AND_HISTORY',
    WEALTH_ACCOUNT_HISTORY: 'DASHBOARD_PUT_WEALTH_ACCOUNT_HISTORY',
    LOAN_ACCOUNT_DETAIL: 'DASHBOARD_PUT_LOAN_ACCOUNT_DETAIL',
    ALL_ACCOUNT_BALANCE: 'DASHBOARD_PUT_ALL_ACCOUNT_BALANCE',
    CREDIT_CARD_ACCOUNTS: 'DASHBOARD_PUT_CREDIT_CARD_ACCOUNTS',
    TIME_DEPOSIT_ACCOUNTS: 'DASHBOARD_PUT_TIME_DEPOST_ACCOUNTS',
    SAVING_ACCOUNT_DETAIL: 'DASHBOARD_PUT_SAVING_ACCOUNT_DETAIL',
    CURRENT_ACCOUNT_DETAIL: 'DASHBOARD_PUT_CURRENT_ACCOUNT_DETAIL',
    UNIT_TRUST_ACCOUNT_DETAIL: 'DASHBOARD_PUT_UNIT_TRUST_ACCOUNT_DETAIL',
    CREDIT_CARD_ACCOUNT_DETAIL: 'DASHBOARD_PUT_CREDIT_CARD_ACCOUNT_DETAIL',
    TIME_DEPOSIT_ACCOUNT_DETAIL: 'DASHBOARD_PUT_TIME_DEPOSIT_ACCOUNT_DETAIL',
    DASHBOARD_RESPONSE_ERROR: 'DASHBOARD_PUT_RESPONSE_ERROR',
    DASHBOARD_MENUS_RESPONSE_ERRORS: 'DASHBOARD_PUT_RESPONSE_ERRORS',
    CASA_TRANSACTION_TYPE: 'DASHBOARD_PUT_CASA_TRANSACTION_TYPE',
    CASA_TRANSACTION_HISTORY: 'DASHBOARD_PUT_CASA_TRANSACTION_HISTORY',
    CASA_TRANSACTION_M2U_RECEIPT: 'DASHBOARD_PUT_CASA_TRANSACTION_M2U_RECEIPT',
    CREDIT_CARD_TRANSACTION_HISTORY: 'DASHBOARD_PUT_CREDIT_CARD_TRANSACTION_HISTORY',
    TIME_DEPOSIT_INITIATE_STOP_ARO: 'DASHBOARD_PUT_TIME_DEPOSIT_INITIATE_STOP_ARO',
    TIME_DEPOSIT_STOP_ARO_TAC: 'DASHBOARD_PUT_TIME_DEPOSIT_STOP_ARO_TAC',
    TIME_DEPOSIT_CONFIRM_STOP_ARO: 'DASHBOARD_PUT_TIME_DEPOSIT_CONFIRM_ARO',
    TIME_DEPOSIT_STOP_ARO_RECEIPT: 'DASHBOARD_PUT_TIME_DEPOSIT_STOP_ARO_RECEIPT',
    TIME_DEPOSIT_RESET_STOP_ARO: 'DASHBOARD_PUT_TIME_DEPOSIT_RESET_STOP_ARO',
    TIME_DEPOSIT_PRODUCTS: 'DASHBOARD_PUT_TIME_DEPOSIT_PRODUCTS',
    TIME_DEPOSIT_INTEREST_RATE: 'DASHBOARD_PUT_TIME_DEPOSIT_INTEREST_RATE',
    TIME_DEPOSIT_LIST_ACCOUNTS: 'DASHBOARD_PUT_TIME_DEPOSIT_LIST_ACCOUNTS',
    TIME_DEPOSIT_VALIDATE: 'DASHBOARD_PUT_TIME_DEPOSIT_VALIDATE',
    TIME_DEPOSIT_REQ_TAC: 'DASHBOARD_PUT_TIME_DEPOSIT_REQ_TAC',
    TIME_DEPOSIT_DO_OPEN_ACCOUNT: 'DASHBOARD_PUT_TIME_DEPOSIT_OPEN_ACCOUNT',
    TOGGLE_DASHBOARD_RIGHT_SIDEBAR_OPEN: 'DASHBOARD_PUT_RIGHT_SIDEBAR_OPEN',
    ACTIVE_DASHBOARD_TAB: 'DASHBOARD_PUT_ACTIVE_TAB',
  },
  CLEAR: {
    CASA_DETAIL: 'DASHBOARD_CLEAR_CASA_DETAIL',
  },
};
// All
export const getAllAccountBalance = body => ({ type: actions.GET.ALL_ACCOUNT_BALANCE, payload: body });

export const putAllAccountBalance = res => ({ type: actions.PUT.ALL_ACCOUNT_BALANCE, payload: res });

// Banca
export const getBancaAccountDetail = body => ({ type: actions.GET.BANCA_ACCOUNT_DETAIL, payload: body });

export const putBancaAccountDetail = res => ({ type: actions.PUT.BANCA_ACCOUNT_DETAIL, payload: res });

// Bond
export const getBondAccountDetail = body => ({ type: actions.GET.BOND_ACCOUNT_DETAIL, payload: body });

export const putBondAccountDetail = res => ({ type: actions.PUT.BOND_ACCOUNT_DETAIL, payload: res });

// CASA
export const getCasaAccountDetail = body => ({ type: actions.GET.CASA_ACCOUNT_DETAIL, payload: body });
export const getCasaAccountBalance = body => ({ type: actions.GET.CASA_ACCOUNT_BALANCE, payload: body });
export const getCasaTransactionType = body => ({ type: actions.GET.CASA_TRANSACTION_TYPE, payload: body });
export const getCasaTransactionHistory = body => ({ type: actions.GET.CASA_TRANSACTION_HISTORY, payload: body });
export const getCasaTransactionM2UReceipt = body => ({ type: actions.GET.CASA_TRANSACTION_M2U_RECEIPT, payload: body });

export const putCasaAccount = res => ({ type: actions.PUT.CASA_ACCOUNT, payload: res });
export const putCasaTransactionType = res => ({ type: actions.PUT.CASA_TRANSACTION_TYPE, payload: res });
export const putCasaTransactionHistory = res => ({ type: actions.PUT.CASA_TRANSACTION_HISTORY, payload: res });
export const putCasaTransactionM2UReceipt = res => ({ type: actions.PUT.CASA_TRANSACTION_M2U_RECEIPT, payload: res });

// Credit card
export const getCreditCardDetail = body => ({ type: actions.GET.CREDIT_CARD_ACCOUNT_DETAIL, payload: body });
export const getCreditCardAccountBalance = body => ({ type: actions.GET.CREDIT_CARD_ACCOUNT_BALANCE, payload: body });
export const getCreditCardTransactionHistory = body => ({ type: actions.GET.CREDIT_CARD_TRANSACTION_HISTORY, payload: body });

export const putCrecitCardAccounts = res => ({ type: actions.PUT.CREDIT_CARD_ACCOUNTS, payload: res });
export const putCreditCardAccountDetail = res => ({ type: actions.PUT.CREDIT_CARD_ACCOUNT_DETAIL, payload: res });
export const putCreditCardTransactionHistory = res => ({ type: actions.PUT.CREDIT_CARD_TRANSACTION_HISTORY, payload: res });

// Confirm Stop Aro
export const getConfirmStopAro = body => ({ type: actions.GET.TIME_DEPOSIT_CONFIRM_STOP_ARO, payload: body });

export const putConfirmStopAro = res => ({ type: actions.PUT.TIME_DEPOSIT_CONFIRM_STOP_ARO, payload: res });

// Current
export const putCurrentAccountDetail = res => ({ type: actions.PUT.CURRENT_ACCOUNT_DETAIL, payload: res });

// Dashboard
export const putDashboardResponseError = res => ({ type: actions.PUT.DASHBOARD_RESPONSE_ERROR, payload: res });
export const putDashboardResponseErrors = res => ({ type: actions.PUT.DASHBOARD_MENUS_RESPONSE_ERRORS, payload: res });

export const getActiveTab = body => ({ type: actions.GET.ACTIVE_DASHBOARD_TAB, payload: body });
export const putActiveTab = res => ({ type: actions.PUT.ACTIVE_DASHBOARD_TAB, payload: res });

// Loan
export const getLoanAccountDetail = body => ({ type: actions.GET.LOAN_ACCOUNT_DETAIL, payload: body });
export const getLoanAccountBalance = body => ({ type: actions.GET.LOAN_ACCOUNT_BALANCE, payload: body });

export const putLoanAccounts = res => ({ type: actions.PUT.LOAN_ACCOUNTS, payload: res });
export const putLoanAccountDetail = res => ({ type: actions.PUT.LOAN_ACCOUNT_DETAIL, payload: res });

// Saving
export const putSavingAccountDetail = res => ({ type: actions.PUT.SAVING_ACCOUNT_DETAIL, payload: res });

// Stop Aro
export const getStopAroTac = body => ({ type: actions.GET.TIME_DEPOSIT_STOP_ARO_TAC, payload: body });
export const getStopAroReceipt = body => ({ type: actions.GET.TIME_DEPOSIT_STOP_ARO_RECEIPT, payload: body });
export const getInitiateStopAro = body => ({ type: actions.GET.TIME_DEPOSIT_INITIATE_STOP_ARO, payload: body });

export const putResetStopAro = () => ({ type: actions.PUT.TIME_DEPOSIT_RESET_STOP_ARO });
export const putStopAroTac = res => ({ type: actions.PUT.TIME_DEPOSIT_STOP_ARO_TAC, payload: res });
export const putStopAroReceipt = body => ({ type: actions.PUT.TIME_DEPOSIT_STOP_ARO_RECEIPT, payload: body });
export const putInitiateStopAro = res => ({ type: actions.PUT.TIME_DEPOSIT_INITIATE_STOP_ARO, payload: res });

// Time Deposit
export const getTimeDepositAccountDetail = body => ({ type: actions.GET.TIME_DEPOSIT_ACCOUNT_DETAIL, payload: body });
export const getTimeDepositAccountBalance = body => ({ type: actions.GET.TIME_DEPOSIT_ACCOUNT_BALANCE, payload: body });
export const getTimeDepositProducts = body => ({ type: actions.GET.TIME_DEPOSIT_PRODUCTS, payload: body });
export const getTimeDepositInterestRate = body => ({ type: actions.GET.TIME_DEPOSIT_INTEREST_RATE, payload: body });
export const getTimeDepositListAccounts = body => ({ type: actions.GET.TIME_DEPOSIT_LIST_ACCOUNTS, payload: body });
export const getTimeDepositValidate = body => ({ type: actions.GET.TIME_DEPOSIT_VALIDATE, payload: body });
export const getTimeDepositReqTAC = body => ({ type: actions.GET.TIME_DEPOSIT_REQ_TAC, payload: body });
export const getTimeDepositDoOpenAccount = body => ({ type: actions.GET.TIME_DEPOSIT_DO_OPEN_ACCOUNT, payload: body });

export const putTimeDepositAccounts = res => ({ type: actions.PUT.TIME_DEPOSIT_ACCOUNTS, payload: res });
export const putTimeDepositAccountDetail = res => ({ type: actions.PUT.TIME_DEPOSIT_ACCOUNT_DETAIL, payload: res });
export const putTimeDepositProducts = res => ({ type: actions.PUT.TIME_DEPOSIT_PRODUCTS, payload: res });
export const putTimeDepositInterestRate = res => ({ type: actions.PUT.TIME_DEPOSIT_INTEREST_RATE, payload: res });
export const putTimeDepositListAccounts = res => ({ type: actions.PUT.TIME_DEPOSIT_LIST_ACCOUNTS, payload: res });
export const putTimeDepositValidate = res => ({ type: actions.PUT.TIME_DEPOSIT_VALIDATE, payload: res });
export const putTimeDepositReqTAC = res => ({ type: actions.PUT.TIME_DEPOSIT_REQ_TAC, payload: res });
export const putTimeDepositDoOpenAccount = res => ({ type: actions.PUT.TIME_DEPOSIT_DO_OPEN_ACCOUNT, payload: res });

export const toggleDashboardRightSidebarOpen = res => ({ type: actions.PUT.TOGGLE_DASHBOARD_RIGHT_SIDEBAR_OPEN, payload: res });

// Unit Trust
export const getUnitTrustAccountDetail = body => ({ type: actions.GET.UNIT_TRUST_ACCOUNT_DETAIL, payload: body });

export const putUnitTrustAccountDetail = res => ({ type: actions.PUT.UNIT_TRUST_ACCOUNT_DETAIL, payload: res });

// Wealth
export const getWealthAccountBalance = body => ({ type: actions.GET.WEALTH_ACCOUNT_BALANCE, payload: body });
export const getWealthAccountSummary = body => ({ type: actions.GET.WEALTH_ACCOUNT_SUMMARY, payload: body });
export const getWealthAccountDetailAndHistory = (body, accountType) => ({
  type: actions.GET.WEALTH_ACCOUNT_DETAIL_AND_HISTORY,
  payload: body,
  accountType,
});
export const getWealthAccountHistory = (body, accountType, day) => ({
  type: actions.GET.WEALTH_ACCOUNT_HISTORY,
  payload: body,
  accountType,
  day,
});

export const putWealthAccount = res => ({ type: actions.PUT.WEALTH_ACCOUNT, payload: res });

export const putWealthAccountSummary = res => ({
  type: actions.PUT.WEALTH_ACCOUNT_SUMMARY,
  payload: res,
});

export const putWealthAccountDetailAndHistory = res => ({
  type: actions.PUT.WEALTH_ACCOUNT_DETAIL_AND_HISTORY,
  payload: res,
});

export const putWealthAccountHistory = res => ({
  type: actions.PUT.WEALTH_ACCOUNT_HISTORY,
  payload: res,
});

export const clearCasaDetail = () => ({ type: actions.CLEAR.CASA_DETAIL });
