export const actions = {
  GET: {
    MAINTENANCE_LIST: 'FAVOURITE_PAYMENT_GET_MAINTENANCE_LIST',
    FAVOURITE_ACCOUNT: 'FAVOURITE_PAYMENT_GET_FAVOURITE_ACCOUNT',
    CREDIT_CARD: {
      ADD_FAVOURITE: {
        DETAIL: 'ADD_FAVOURITE_GET_CREDIT_CARD_DETAIL',
        DETAIL_FROM_PAYMENT: 'ADD_FAVOURITE_GET_CREDIT_CARD_DETAIL_FROM_PAYMENT',
        CONFIRM: 'ADD_FAVOURITE_GET_CREDIT_CARD_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_CREDIT_CARD_TAC',
        RESULT: 'ADD_FAVOURITE_GET_CREDIT_CARD_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_CREDIT_CARD_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_CREDIT_CARD_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_CREDIT_CARD_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_CREDIT_CARD_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_CREDIT_CARD_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_CREDIT_CARD_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_CREDIT_CARD_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        MAYBANK_DETAIL: 'PAYMENT_FROM_FAVOURITE_GET_MAYBANK_CREDIT_CARD_DETAIL',
        OTHER_DETAIL: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_CREDIT_CARD_DETAIL',
        MAYBANK_CONFIRM: 'PAYMENT_FROM_FAVOURITE_GET_MAYBANK_CREDIT_CARD_CONFIRM',
        OTHER_CONFIRM: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_CREDIT_CARD_CONFIRM',
        MAYBANK_TAC: 'PAYMENT_FROM_FAVOURITE_GET_MAYBANK_CREDIT_CARD_TAC',
        OTHER_TAC: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_CREDIT_CARD_TAC',
        MAYBANK_RECEIPT: 'PAYMENT_FROM_FAVOURITE_GET_MAYBANK_CREDIT_CARD_RECEIPT',
        OTHER_RECEIPT: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_CREDIT_CARD_RECEIPT',
      },
    },
    MOBILE_POSTPAID: {
      ADD_FAVOURITE: {
        DETAIL: 'ADD_FAVOURITE_GET_MOBILE_POSTPAID_DETAIL',
        DETAIL_FROM_PAYMENT: 'ADD_FAVOURITE_GET_MOBILE_POSTPAID_DETAIL_FROM_PAYMENT',
        CONFIRM: 'ADD_FAVOURITE_GET_MOBILE_POSTPAID_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_MOBILE_POSTPAID_TAC',
        RESULT: 'ADD_FAVOURITE_GET_MOBILE_POSTPAID_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_MOBILE_POSTPAID_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_MOBILE_POSTPAID_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_MOBILE_POSTPAID_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_MOBILE_POSTPAID_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_MOBILE_POSTPAID_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_MOBILE_POSTPAID_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_MOBILE_POSTPAID_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_GET_MOBILE_POSTPAID_DETAIL',
        TAC: 'PAYMENT_FROM_FAVOURITE_GET_MOBILE_POSTPAID_TAC',
        RECEIPT: 'PAYMENT_FROM_FAVOURITE_GET_MOBILE_POSTPAID_RECEIPT',
      },
    },
    UTILITY: {
      ADD_FAVOURITE: {
        DETAIL: 'ADD_FAVOURITE_GET_UTILITY_DETAIL',
        DETAIL_FROM_PAYMENT: 'ADD_FAVOURITE_GET_UTILITY_DETAIL_FROM_PAYMENT',
        CONFIRM: 'ADD_FAVOURITE_GET_UTILITY_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_UTILITY_TAC',
        RESULT: 'ADD_FAVOURITE_GET_UTILITY_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_UTILITY_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_UTILITY_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_UTILITY_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_UTILITY_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_UTILITY_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_UTILITY_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_UTILITY_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_GET_UTILITY_DETAIL',
        TAC: 'PAYMENT_FROM_FAVOURITE_GET_UTILITY_TAC',
        RECEIPT: 'PAYMENT_FROM_FAVOURITE_GET_UTILITY_RECEIPT',
      },
    },
    OTHER_BILL: {
      ADD_FAVOURITE: {
        DETAIL: 'ADD_FAVOURITE_GET_OTHER_BILL_DETAIL',
        PAYEE_DETAIL: 'ADD_FAVOURITE_GET_OTHER_BILL_PAYEE_DETAIL',
        DETAIL_FROM_PAYMENT: 'ADD_FAVOURITE_GET_OTHER_BILL_DETAIL_FROM_PAYMENT',
        CONFIRM: 'ADD_FAVOURITE_GET_OTHER_BILL_CONFIRM',
        TAC: 'ADD_FAVOURITE_GET_OTHER_BILL_TAC',
        RESULT: 'ADD_FAVOURITE_GET_OTHER_BILL_RESULT',
      },
      EDIT_FAVOURITE: {
        DETAIL: 'EDIT_FAVOURITE_GET_OTHER_BILL_DETAIL',
        CONFIRM: 'EDIT_FAVOURITE_GET_OTHER_BILL_CONFIRM',
        TAC: 'EDIT_FAVOURITE_GET_OTHER_BILL_TAC',
        RESULT: 'EDIT_FAVOURITE_GET_OTHER_BILL_RESULT',
      },
      DELETE_FAVOURITE: {
        CONFIRM: 'DELETE_FAVOURITE_GET_OTHER_BILL_CONFIRM',
        TAC: 'DELETE_FAVOURITE_GET_OTHER_BILL_TAC',
        RESULT: 'DELETE_FAVOURITE_GET_OTHER_BILL_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_BILL_DETAIL',
        TAC: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_BILL_TAC',
        RECEIPT: 'PAYMENT_FROM_FAVOURITE_GET_OTHER_BILL_RECEIPT',
      },
    },
  },
  PUT: {
    MAINTENANCE_LIST: 'FAVOURITE_PAYMENT_PUT_MAINTENANCE_LIST',
    RESET_FAVOURITE: 'FAVOURITE_PAYMENT_PUT_RESET_FAVOURITE',
    RESET_TAC: 'FAVOURITE_PAYMENT_PUT_RESET_TAC',
    RESET_ADD_FAVOURITE: 'FAVOURITE_PAYMENT_PUT_RESET_ADD_FAVOURITE',
    FAVOURITE_ACCOUNT: 'FAVOURITE_PAYMENT_PUT_FAVOURITE_ACCOUNT',
    CREDIT_CARD: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_CREDIT_CARD_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_CREDIT_CARD_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_CREDIT_CARD_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_CREDIT_CARD_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_PUT_CREDIT_CARD_DETAIL',
      },
    },
    MOBILE_POSTPAID: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_MOBILE_POSTPAID_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_MOBILE_POSTPAID_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_MOBILE_POSTPAID_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_MOBILE_POSTPAID_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_PUT_MOBILE_POSTPAID_DETAIL',
      },
    },
    UTILITY: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_UTILITY_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_UTILITY_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_UTILITY_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_UTILITY_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_PUT_UTILITY_DETAIL',
      },
    },
    OTHER_BILL: {
      FAVOURITE_ACTION: {
        DETAIL: 'FAVOURITE_ACTION_PUT_OTHER_BILL_DETAIL',
        PAYEE_DETAIL: 'FAVOURITE_ACTION_PUT_OTHER_BILL_PAYEE_DETAIL',
        CONFIRM: 'FAVOURITE_ACTION_PUT_OTHER_BILL_CONFIRM',
        TAC: 'FAVOURITE_ACTION_PUT_OTHER_BILL_TAC',
        RESULT: 'FAVOURITE_ACTION_PUT_OTHER_BILL_RESULT',
      },
      PAYMENT_FROM_FAVOURITE: {
        DETAIL: 'PAYMENT_FROM_FAVOURITE_PUT_OTHER_BILL_DETAIL',
      },
    },
  },
};

// Common
export const getFavouriteAccounts = payload => ({ type: actions.GET.FAVOURITE_ACCOUNT, payload });
export const getPaymentTypes = payload => ({ type: actions.GET.MAINTENANCE_LIST, payload });

export const putPaymentFavouriteReset = res => ({ type: actions.PUT.RESET_FAVOURITE, res });
export const putFavouriteAccounts = res => ({ type: actions.PUT.FAVOURITE_ACCOUNT, res });
export const putPaymentTypes = res => ({ type: actions.PUT.MAINTENANCE_LIST, res });
export const putResetPaymentTac = () => ({ type: actions.PUT.RESET_TAC });
export const putResetAddFavourite = () => ({ type: actions.PUT.RESET_ADD_FAVOURITE });

/** ****** CREDIT CARD ********* */
// Credit Card - Add Favourite
export const getCCAddFavouriteDetail = payload => ({ type: actions.GET.CREDIT_CARD.ADD_FAVOURITE.DETAIL, payload });
export const getCCAddFavouriteDetailFromPayment = payload => ({
  type: actions.GET.CREDIT_CARD.ADD_FAVOURITE.DETAIL_FROM_PAYMENT,
  payload,
});
export const getCCAddFavouriteConfirmation = payload => ({ type: actions.GET.CREDIT_CARD.ADD_FAVOURITE.CONFIRM, payload });
export const getCCAddFavouriteTac = payload => ({ type: actions.GET.CREDIT_CARD.ADD_FAVOURITE.TAC, payload });
export const getCCAddFavouriteResult = payload => ({ type: actions.GET.CREDIT_CARD.ADD_FAVOURITE.RESULT, payload });

// Credit Card - Edit Favourite
export const getCCEditFavouriteDetail = payload => ({ type: actions.GET.CREDIT_CARD.EDIT_FAVOURITE.DETAIL, payload });
export const getCCEditFavouriteConfirm = payload => ({ type: actions.GET.CREDIT_CARD.EDIT_FAVOURITE.CONFIRM, payload });
export const getCCEditFavouriteTac = payload => ({ type: actions.GET.CREDIT_CARD.EDIT_FAVOURITE.TAC, payload });
export const getCCEditFavouriteResult = payload => ({ type: actions.GET.CREDIT_CARD.EDIT_FAVOURITE.RESULT, payload });

// Credit Card - Delete Favourite
export const getCCDeleteFavouriteConfirm = payload => ({ type: actions.GET.CREDIT_CARD.DELETE_FAVOURITE.CONFIRM, payload });
export const getCCDeleteFavouriteTac = payload => ({ type: actions.GET.CREDIT_CARD.DELETE_FAVOURITE.TAC, payload });
export const getCCDeleteFavouriteResult = payload => ({ type: actions.GET.CREDIT_CARD.DELETE_FAVOURITE.RESULT, payload });

// Credit Card - Payment from Favourite
export const getMaybankCCPaymentDetailFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.MAYBANK_DETAIL,
  payload,
});
export const getOtherCCPaymentDetailFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.OTHER_DETAIL,
  payload,
});
export const getMaybankCCPaymentConfirmFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.MAYBANK_CONFIRM,
  payload,
});
export const getOtherCCPaymentConfirmFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.OTHER_CONFIRM,
  payload,
});
export const getMaybankCCPaymentTacFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.MAYBANK_TAC,
  payload,
});
export const getOtherCCPaymentTacFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.OTHER_TAC,
  payload,
});
export const getMaybankCCPaymentReceiptFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.MAYBANK_RECEIPT,
  payload,
});
export const getOtherCCPaymentReceiptFromFavourite = payload => ({
  type: actions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.OTHER_RECEIPT,
  payload,
});

// Credit Card - Add/Edit/Delete
export const putCCFavouriteDetail = res => ({ type: actions.PUT.CREDIT_CARD.FAVOURITE_ACTION.DETAIL, res });
export const putCCFavouriteConfirm = res => ({ type: actions.PUT.CREDIT_CARD.FAVOURITE_ACTION.CONFIRM, res });
export const putCCFavouriteTac = res => ({ type: actions.PUT.CREDIT_CARD.FAVOURITE_ACTION.TAC, res });
export const putCCFavouriteResult = res => ({ type: actions.PUT.CREDIT_CARD.FAVOURITE_ACTION.RESULT, res });
export const putCCPaymentDetailFromFavourite = res => ({ type: actions.PUT.CREDIT_CARD.PAYMENT_FROM_FAVOURITE.DETAIL, res });
/** ****** CREDIT CARD ********* */

/** ****** MOBILE POSTPAID ********* */
// Mobile Postpaid - Add Favourite
export const getPostpaidAddFavouriteDetail = payload => ({ type: actions.GET.MOBILE_POSTPAID.ADD_FAVOURITE.DETAIL, payload });
export const getPostpaidAddFavouriteDetailFromPayment = payload => ({
  type: actions.GET.MOBILE_POSTPAID.ADD_FAVOURITE.DETAIL_FROM_PAYMENT,
  payload,
});
export const getPostpaidAddFavouriteConfirmation = payload => ({ type: actions.GET.MOBILE_POSTPAID.ADD_FAVOURITE.CONFIRM, payload });
export const getPostpaidAddFavouriteTac = payload => ({ type: actions.GET.MOBILE_POSTPAID.ADD_FAVOURITE.TAC, payload });
export const getPostpaidAddFavouriteResult = payload => ({ type: actions.GET.MOBILE_POSTPAID.ADD_FAVOURITE.RESULT, payload });

// Mobile Postpaid - Edit Favourite
export const getPostpaidEditFavouriteDetail = payload => ({ type: actions.GET.MOBILE_POSTPAID.EDIT_FAVOURITE.DETAIL, payload });
export const getPostpaidEditFavouriteConfirm = payload => ({ type: actions.GET.MOBILE_POSTPAID.EDIT_FAVOURITE.CONFIRM, payload });
export const getPostpaidEditFavouriteTac = payload => ({ type: actions.GET.MOBILE_POSTPAID.EDIT_FAVOURITE.TAC, payload });
export const getPostpaidEditFavouriteResult = payload => ({ type: actions.GET.MOBILE_POSTPAID.EDIT_FAVOURITE.RESULT, payload });

// Mobile Postpaid - Delete Favourite
export const getPostpaidDeleteFavouriteConfirm = payload => ({ type: actions.GET.MOBILE_POSTPAID.DELETE_FAVOURITE.CONFIRM, payload });
export const getPostpaidDeleteFavouriteTac = payload => ({ type: actions.GET.MOBILE_POSTPAID.DELETE_FAVOURITE.TAC, payload });
export const getPostpaidDeleteFavouriteResult = payload => ({ type: actions.GET.MOBILE_POSTPAID.DELETE_FAVOURITE.RESULT, payload });

// Mobile Postpaid - Payment from Favourite
export const getPostpaidPaymentDetailFromFavourite = payload => ({
  type: actions.GET.MOBILE_POSTPAID.PAYMENT_FROM_FAVOURITE.DETAIL,
  payload,
});
export const getPostpaidPaymentTacFromFavourite = payload => ({
  type: actions.GET.MOBILE_POSTPAID.PAYMENT_FROM_FAVOURITE.TAC,
  payload,
});
export const getPostpaidPaymentReceiptFromFavourite = payload => ({
  type: actions.GET.MOBILE_POSTPAID.PAYMENT_FROM_FAVOURITE.RECEIPT,
  payload,
});

// Mobile Postpaid - Add/Edit/Delete
export const putPostpaidFavouriteDetail = res => ({ type: actions.PUT.MOBILE_POSTPAID.FAVOURITE_ACTION.DETAIL, res });
export const putPostpaidFavouriteConfirm = res => ({ type: actions.PUT.MOBILE_POSTPAID.FAVOURITE_ACTION.CONFIRM, res });
export const putPostpaidFavouriteTac = res => ({ type: actions.PUT.MOBILE_POSTPAID.FAVOURITE_ACTION.TAC, res });
export const putPostpaidFavouriteResult = res => ({ type: actions.PUT.MOBILE_POSTPAID.FAVOURITE_ACTION.RESULT, res });
/** ****** MOBILE POSTPAID ********* */

/** ****** UTILITY ********* */
// Utility - Add Favourite
export const getUtilityAddFavouriteDetail = payload => ({ type: actions.GET.UTILITY.ADD_FAVOURITE.DETAIL, payload });
export const getUtilityAddFavouriteDetailFromPayment = payload => ({
  type: actions.GET.UTILITY.ADD_FAVOURITE.DETAIL_FROM_PAYMENT,
  payload,
});
export const getUtilityAddFavouriteConfirmation = payload => ({ type: actions.GET.UTILITY.ADD_FAVOURITE.CONFIRM, payload });
export const getUtilityAddFavouriteTac = payload => ({ type: actions.GET.UTILITY.ADD_FAVOURITE.TAC, payload });
export const getUtilityAddFavouriteResult = payload => ({ type: actions.GET.UTILITY.ADD_FAVOURITE.RESULT, payload });

// Utility - Edit Favourite
export const getUtilityEditFavouriteDetail = payload => ({ type: actions.GET.UTILITY.EDIT_FAVOURITE.DETAIL, payload });
export const getUtilityEditFavouriteConfirm = payload => ({ type: actions.GET.UTILITY.EDIT_FAVOURITE.CONFIRM, payload });
export const getUtilityEditFavouriteTac = payload => ({ type: actions.GET.UTILITY.EDIT_FAVOURITE.TAC, payload });
export const getUtilityEditFavouriteResult = payload => ({ type: actions.GET.UTILITY.EDIT_FAVOURITE.RESULT, payload });

// Utility - Delete Favourite
export const getUtilityDeleteFavouriteConfirm = payload => ({ type: actions.GET.UTILITY.DELETE_FAVOURITE.CONFIRM, payload });
export const getUtilityDeleteFavouriteTac = payload => ({ type: actions.GET.UTILITY.DELETE_FAVOURITE.TAC, payload });
export const getUtilityDeleteFavouriteResult = payload => ({ type: actions.GET.UTILITY.DELETE_FAVOURITE.RESULT, payload });

// Utility - Payment from Favourite
export const getUtilityPaymentDetailFromFavourite = payload => ({ type: actions.GET.UTILITY.PAYMENT_FROM_FAVOURITE.DETAIL, payload });
export const getUtilityPaymentTacFromFavourite = payload => ({ type: actions.GET.UTILITY.PAYMENT_FROM_FAVOURITE.TAC, payload });
export const getUtilityPaymentReceiptFromFavourite = payload => ({ type: actions.GET.UTILITY.PAYMENT_FROM_FAVOURITE.RECEIPT, payload });

// Utility - Add/Edit/Delete
export const putUtilityFavouriteDetail = res => ({ type: actions.PUT.UTILITY.FAVOURITE_ACTION.DETAIL, res });
export const putUtilityFavouriteConfirm = res => ({ type: actions.PUT.UTILITY.FAVOURITE_ACTION.CONFIRM, res });
export const putUtilityFavouriteTac = res => ({ type: actions.PUT.UTILITY.FAVOURITE_ACTION.TAC, res });
export const putUtilityFavouriteResult = res => ({ type: actions.PUT.UTILITY.FAVOURITE_ACTION.RESULT, res });
/** ****** UTILITY ********* */

/** ****** OTHER BILLS ********* */
// Other Bill - Add Favourite
export const getOtherBillAddFavouriteDetail = payload => ({ type: actions.GET.OTHER_BILL.ADD_FAVOURITE.DETAIL, payload });
export const getOtherBillAddFavouritePayeeDetail = payload => ({ type: actions.GET.OTHER_BILL.ADD_FAVOURITE.PAYEE_DETAIL, payload });
export const getOtherBillAddFavouriteDetailFromPayment = payload => ({
  type: actions.GET.OTHER_BILL.ADD_FAVOURITE.DETAIL_FROM_PAYMENT,
  payload,
});
export const getOtherBillAddFavouriteConfirmation = payload => ({ type: actions.GET.OTHER_BILL.ADD_FAVOURITE.CONFIRM, payload });
export const getOtherBillAddFavouriteTac = payload => ({ type: actions.GET.OTHER_BILL.ADD_FAVOURITE.TAC, payload });
export const getOtherBillAddFavouriteResult = payload => ({ type: actions.GET.OTHER_BILL.ADD_FAVOURITE.RESULT, payload });

// Other Bill - Edit Favourite
export const getOtherBillEditFavouriteDetail = payload => ({ type: actions.GET.OTHER_BILL.EDIT_FAVOURITE.DETAIL, payload });
export const getOtherBillEditFavouriteConfirm = payload => ({ type: actions.GET.OTHER_BILL.EDIT_FAVOURITE.CONFIRM, payload });
export const getOtherBillEditFavouriteTac = payload => ({ type: actions.GET.OTHER_BILL.EDIT_FAVOURITE.TAC, payload });
export const getOtherBillEditFavouriteResult = payload => ({ type: actions.GET.OTHER_BILL.EDIT_FAVOURITE.RESULT, payload });

// Other Bill - Delete Favourite
export const getOtherBillDeleteFavouriteConfirm = payload => ({ type: actions.GET.OTHER_BILL.DELETE_FAVOURITE.CONFIRM, payload });
export const getOtherBillDeleteFavouriteTac = payload => ({ type: actions.GET.OTHER_BILL.DELETE_FAVOURITE.TAC, payload });
export const getOtherBillDeleteFavouriteResult = payload => ({ type: actions.GET.OTHER_BILL.DELETE_FAVOURITE.RESULT, payload });

// Other Bill - Payment from Favourite
export const getOtherBillPaymentDetailFromFavourite = payload => ({ type: actions.GET.OTHER_BILL.PAYMENT_FROM_FAVOURITE.DETAIL, payload });
export const getOtherBillPaymentTacFromFavourite = payload => ({ type: actions.GET.OTHER_BILL.PAYMENT_FROM_FAVOURITE.TAC, payload });
export const getOtherBillPaymentReceiptFromFavourite = payload => ({
  type: actions.GET.OTHER_BILL.PAYMENT_FROM_FAVOURITE.RECEIPT,
  payload,
});

// Other Bill - Add/Edit/Delete
export const putOtherBillFavouriteDetail = res => ({ type: actions.PUT.OTHER_BILL.FAVOURITE_ACTION.DETAIL, res });
export const putOtherBillFavouritePayeeDetail = res => ({ type: actions.PUT.OTHER_BILL.FAVOURITE_ACTION.PAYEE_DETAIL, res });
export const putOtherBillFavouriteConfirm = res => ({ type: actions.PUT.OTHER_BILL.FAVOURITE_ACTION.CONFIRM, res });
export const putOtherBillFavouriteTac = res => ({ type: actions.PUT.OTHER_BILL.FAVOURITE_ACTION.TAC, res });
export const putOtherBillFavouriteResult = res => ({ type: actions.PUT.OTHER_BILL.FAVOURITE_ACTION.RESULT, res });
/** ****** OTHER BILLS ********* */
