import { transfer } from './transfer';
import { payment } from './payment';
import { purchase } from './purchase';
import { favourite } from './favourite';

export const transaction = {
  transfer,
  purchase,
  payment,
  favourite,
  accepted: 'diterima',
  failed: 'gagal',
  logoutAlert: {
    title: 'Sesi Logout',
    description: 'User ID Anda dalam keadaan terkunci. Silakan akses "Mengalami Kendala Saat Masuk" di halaman utama.',
  },
  leaveAlert: {
    title: 'Keluar dari Halaman Transaksi',
    description:
      'Apakah Anda yakin untuk meninggalkan halaman ini? Klik "Batal" untuk tetap di sini atau klik "OK" untuk meninggalkan halaman ini.',
  },
  cutoffAlert: {
    title: 'Batas Waktu Transaksi',
    operationHours: 'Jam Operasional Transaksi',
    sknRtgsDescription:
      '{transferName} "Transfer Sekarang" hanya tersedia selama jam operasional harian bank dari pukul {startTime} sampai {endTime} (GMT+7).<br/> Transaksi yang dilakukan setelah batas waktu akan diproses pada hari kerja berikutnya.',
    sknRtgsTodayDescription:
      '{transferName} hanya tersedia selama jam operasional harian bank dari pukul {startTime} sampai {endTime} (GMT+7).<br/> Transaksi yang di input setelah batas waktu akan diproses saat layanan {transferName} tersedia hari ini.',
    countdownDescription: 'Anda akan mencapai batas waktu transaksi dalam {timer} menit.',
    swift: 'Layanan tersedia pada pukul {swiftStartTime} - {swiftEndTime} GMT +7 di hari kerja.',
  },
};
