export const registerEndpoint = {
  get: {
    access: 'registration/v1/signup/home',
    result: 'registration/v1/tac/signup/confirm',
  },
  requestTAC: 'notify/v1/tac/signup/request',
  verify: {
    pin: 'registration/v1/signup/pin',
    credential: 'registration/v1/signup/credentials',
  },
};
