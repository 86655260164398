export const actions = {
  GET: {
    MAINTENANCES: 'FAVOURITE_TRANSFER_GET_MAINTENANCE_LIST',
    FAVOURITE_ACCOUNT: 'FAVOURITE_TRANSFER_GET_FAVOURITE_ACCOUNT',
    INTRABANK: {
      DETAIL: 'FAVOURITE_TRANSFER_INTRABANK_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_INTRABANK_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_INTRABANK_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_INTRABANK_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTRABANK_GET_CONFIRMATION',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_INTRABANK_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_INTRABANK_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_INTRABANK_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_INTRABANK_GET_EDIT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_INTRABANK_GET_DETAIL_AFTER_TRANSFER',
    },
    INTERBANK: {
      DETAIL: 'FAVOURITE_TRANSFER_INTERBANK_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_INTERBANK_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_INTERBANK_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_INTERBANK_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTERBANK_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_INTERBANK_GET_DETAIL_AFTER_TRANSFER',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_INTERBANK_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_INTERBANK_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_INTERBANK_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_INTERBANK_GET_EDIT_CONFIRMATION',
    },
    SKN: {
      DETAIL: 'FAVOURITE_TRANSFER_SKN_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_SKN_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_SKN_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_SKN_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SKN_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_SKN_GET_DETAIL_AFTER_TRANSFER',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_SKN_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_SKN_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_SKN_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_SKN_GET_EDIT_CONFIRMATION',
    },
    RTGS: {
      DETAIL: 'FAVOURITE_TRANSFER_RTGS_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_RTGS_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_RTGS_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_RTGS_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_RTGS_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_RTGS_GET_DETAIL_AFTER_TRANSFER',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_RTGS_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_RTGS_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_RTGS_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_RTGS_GET_EDIT_CONFIRMATION',
    },
    VIRTUAL_ACCOUNT: {
      DETAIL: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_DETAIL_AFTER_TRANSFER',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_EDIT_CONFIRMATION',
    },
    EWALLET: {
      DETAIL: 'FAVOURITE_TRANSFER_EWALET_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_EWALET_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_EWALET_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_EWALET_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_EWALET_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_EWALLET_GET_DETAIL_AFTER_TRANSFER',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_EWALLET_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_EWALLET_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_EWALLET_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_EWALLET_GET_EDIT_CONFIRMATION',
    },
    SWIFT: {
      LLD: 'FAVOURITE_TRANSFER_SWIFT_TRANSFER_LLD',
      DETAIL: 'FAVOURITE_TRANSFER_SWIFT_GET_DETAIL',
      RESULT: 'FAVOURITE_TRANSFER_SWIFT_GET_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_SWIFT_GET_RECEIPT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_SWIFT_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SWIFT_GET_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_SWIFT_GET_DETAIL_AFTER_TRANSFER',
      SEARCH_RESULT: 'FAVOURITE_TRANSFER_SWIFT_GET_SEARCH_RESULT',
      EDIT_RESULT: 'FAVOURITE_TRANSFER_SWIFT_GET_EDIT_RESULT',
      EDIT_DETAILS: 'FAVOURITE_TRANSFER_SWIFT_GET_EDIT_DETAILS',
      EDIT_REQUEST_TAC: 'FAVOURITE_TRANSFER_SWIFT_GET_EDIT_REQUEST_TAC',
      EDIT_CONFIRMATION: 'FAVOURITE_TRANSFER_SWIFT_GET_EDIT_CONFIRMATION',
    },
  },
  DELETE: {
    INTRABANK: {
      RESULT: 'FAVOURITE_TRANSFER_INTRABANK_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_INTRABANK_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTRABANK_DELETE_CONFIRMATION',
    },
    INTERBANK: {
      RESULT: 'FAVOURITE_TRANSFER_INTERBANK_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_INTERBANK_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTERBANK_DELETE_CONFIRMATION',
    },
    SKN: {
      RESULT: 'FAVOURITE_TRANSFER_SKN_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_SKN_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SKN_DELETE_CONFIRMATION',
    },
    RTGS: {
      RESULT: 'FAVOURITE_TRANSFER_RTGS_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_RTGS_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_RTGS_DELETE_CONFIRMATION',
    },
    VIRTUAL_ACCOUNT: {
      RESULT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_DELETE_CONFIRMATION',
    },
    EWALLET: {
      RESULT: 'FAVOURITE_TRANSFER_EWALLET_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_EWALLET_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_EWALLET_DELETE_CONFIRMATION',
    },
    SWIFT: {
      RESULT: 'FAVOURITE_TRANSFER_SWIFT_GET_DELETE_RESULT',
      REQUEST_TAC: 'FAVOURITE_TRANSFER_SWIFT_DELETE_REQUEST_TAC',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SWIFT_DELETE_CONFIRMATION',
    },
  },
  PUT: {
    RESET_FAVOURITE: 'FAVOURITE_TRANSFER_RESET',
    RESET_ADD_FAVOURITE: 'FAVOURITE_TRANSFER_RESET_ADD',
    MAINTENANCES: 'FAVOURITE_TRANSFER_PUT_MAINTENANCE_TRANSFERS',
    FAVOURITE_ACCOUNT: 'FAVOURITE_TRANSFER_PUT_FAVOURITE_ACCOUNT',
    RESET_TAC: 'FAVOURITE_TRANSFER_PUT_RESET_TAC',
    INTRABANK: {
      RESULT: 'FAVOURITE_TRANSFER_INTRABANK_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_INTRABANK_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_INTRABANK_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_INTRABANK_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTRABANK_PUT_CONFIRMATION',
    },
    INTERBANK: {
      RESULT: 'FAVOURITE_TRANSFER_INTERBANK_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_INTERBANK_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_INTERBANK_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_INTERBANK_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_INTERBANK_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_INTERBANK_PUT_DETAIL_AFTER_TRANSFER',
    },
    SKN: {
      RESULT: 'FAVOURITE_TRANSFER_SKN_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_SKN_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_SKN_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_SKN_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SKN_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_SKN_PUT_DETAIL_AFTER_TRANSFER',
    },
    RTGS: {
      RESULT: 'FAVOURITE_TRANSFER_RTGS_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_RTGS_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_RTGS_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_RTGS_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_RTGS_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_RTGS_PUT_DETAIL_AFTER_TRANSFER',
    },
    VIRTUAL_ACCOUNT: {
      RESULT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_VIRTUAL_ACCOUNT_PUT_DETAIL_AFTER_TRANSFER',
    },
    EWALLET: {
      RESULT: 'FAVOURITE_TRANSFER_EWALLET_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_EWALLET_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_EWALLET_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_EWALLET_PUT_RECEIPT',
      CONFIRMATION: 'FAVOURITE_TRANSFER_EWALLET_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_EWALLET_PUT_DETAIL_AFTER_TRANSFER',
    },
    SWIFT: {
      RESULT: 'FAVOURITE_TRANSFER_SWIFT_PUT_RESULT',
      DETAIL: 'FAVOURITE_TRANSFER_SWIFT_PUT_DETAIL',
      TAC: 'FAVOURITE_TRANSFER_SWIFT_PUT_TAC_RESULT',
      RECEIPT: 'FAVOURITE_TRANSFER_SWIFT_PUT_RECEIPT',
      LLD_DEETAIL: 'FAVOURITE_TRANSFER_SWIFT_PUT_LLD_DETAIL',
      CONFIRMATION: 'FAVOURITE_TRANSFER_SWIFT_PUT_CONFIRMATION',
      DETAIL_AFTER_TRANSFER: 'FAVOURITE_TRANSFER_SWIFT_PUT_DETAIL_AFTER_TRANSFER',
      SEARCH_RESULT: 'FAVOURITE_TRANSFER_SWIFT_PUT_SEARCH_RESULT',
    },
  },
};

// ACCOUNTS
export const putResetFavouriteTransfer = () => ({ type: actions.PUT.RESET_FAVOURITE });
export const putResetAddFavouriteTransfer = () => ({ type: actions.PUT.RESET_ADD_FAVOURITE });
export const getMaintenanceTransfers = payload => ({ type: actions.GET.MAINTENANCES, payload });
export const getFavouriteAccounts = payload => ({ type: actions.GET.FAVOURITE_ACCOUNT, payload });

export const putMaintenanceTransfers = res => ({ type: actions.PUT.MAINTENANCES, res });
export const putFavouriteAccount = res => ({ type: actions.PUT.FAVOURITE_ACCOUNT, res });
export const putResetTransferFavTac = () => ({ type: actions.PUT.RESET_TAC });

// INTERBANK
export const getInterbankReceipt = payload => ({ type: actions.GET.INTERBANK.RECEIPT, payload });
export const getInterbankResult = payload => ({ type: actions.GET.INTERBANK.RESULT, payload });
export const getInterbankDetail = payload => ({ type: actions.GET.INTERBANK.DETAIL, payload });
export const requestInterbankTac = payload => ({ type: actions.GET.INTERBANK.REQUEST_TAC, payload });
export const getInterbankConfirmation = payload => ({ type: actions.GET.INTERBANK.CONFIRMATION, payload });
export const getInterbankDetailAfterTransfer = payload => ({ type: actions.GET.INTERBANK.DETAIL_AFTER_TRANSFER, payload });

export const putInterbankTac = res => ({ type: actions.PUT.INTERBANK.TAC, res });
export const putInterbankResult = res => ({ type: actions.PUT.INTERBANK.RESULT, res });
export const putInterbankDetail = res => ({ type: actions.PUT.INTERBANK.DETAIL, res });
export const putInterbankReceipt = res => ({ type: actions.PUT.INTERBANK.RECEIPT, res });
export const putInterbankConfirmation = res => ({ type: actions.PUT.INTERBANK.CONFIRMATION, res });
export const putInterbankDetailAfterTransfer = res => ({ type: actions.PUT.INTERBANK.DETAIL_AFTER_TRANSFER, res });

// INTERBANK - Edit
export const getInterbankEditResult = payload => ({ type: actions.GET.INTERBANK.EDIT_RESULT, payload });
export const getInterbankEditDetail = payload => ({ type: actions.GET.INTERBANK.EDIT_DETAILS, payload });
export const requestInterbankEditTac = payload => ({ type: actions.GET.INTERBANK.EDIT_REQUEST_TAC, payload });
export const getInterbankEditConfirmation = payload => ({ type: actions.GET.INTERBANK.EDIT_CONFIRMATION, payload });

export const putInterbankEditTac = res => ({ type: actions.PUT.INTERBANK.EDIT_REQUEST_TAC, res });
export const putInterbankEditResult = res => ({ type: actions.PUT.INTERBANK.EDIT_RESULT, res });
export const putInterbankEditDetail = res => ({ type: actions.PUT.INTERBANK.EDIT_DETAILS, res });
export const putInterbankEditConfirmation = res => ({ type: actions.PUT.INTERBANK.EDIT_CONFIRMATION, res });

// INTERBANK - Delete
export const getDeleteInterbankResult = payload => ({ type: actions.DELETE.INTERBANK.RESULT, payload });
export const deleteInterbankFavourite = payload => ({ type: actions.DELETE.INTERBANK.CONFIRMATION, payload });
export const requestDeleteInterbankTac = payload => ({ type: actions.DELETE.INTERBANK.REQUEST_TAC, payload });

// INTRABANK
export const getIntrabankDetail = payload => ({ type: actions.GET.INTRABANK.DETAIL, payload });
export const getIntrabankResult = payload => ({ type: actions.GET.INTRABANK.RESULT, payload });
export const getIntrabankReceipt = payload => ({ type: actions.GET.INTRABANK.RECEIPT, payload });
export const requestIntrabankTac = payload => ({ type: actions.GET.INTRABANK.REQUEST_TAC, payload });
export const getIntrabankConfirmation = payload => ({ type: actions.GET.INTRABANK.CONFIRMATION, payload });
export const getIntrabankDetailFromTransfer = payload => ({ type: actions.GET.INTRABANK.DETAIL_AFTER_TRANSFER, payload });

export const putIntrabankTac = res => ({ type: actions.PUT.INTRABANK.TAC, res });
export const putIntrabankDetail = res => ({ type: actions.PUT.INTRABANK.DETAIL, res });
export const putIntrabankResult = res => ({ type: actions.PUT.INTRABANK.RESULT, res });
export const putIntrabankReceipt = res => ({ type: actions.PUT.INTRABANK.RECEIPT, res });
export const putIntrabankConfirmation = res => ({ type: actions.PUT.INTRABANK.CONFIRMATION, res });

// INTRABANK - Edit
export const getIntrabankEditDetail = payload => ({ type: actions.GET.INTRABANK.EDIT_DETAILS, payload });
export const getIntrabankEditConfirmation = payload => ({ type: actions.GET.INTRABANK.EDIT_CONFIRMATION, payload });
export const getIntrabankEditRequestTac = payload => ({ type: actions.GET.INTRABANK.EDIT_REQUEST_TAC, payload });
export const getIntrabakEditResult = payload => ({ type: actions.GET.INTRABANK.EDIT_RESULT, payload });

export const putIntrabankEditDetail = res => ({ type: actions.PUT.INTRABANK.EDIT_DETAILS, res });
export const putIntrabankEditConfirmation = res => ({ type: actions.PUT.INTRABANK.EDIT_CONFIRMATION, res });
export const putIntrabankEditRequestTac = res => ({ type: actions.PUT.INTRABANK.EDIT_REQUEST_TAC, res });
export const putIntrabankEditResult = res => ({ type: actions.PUT.INTRABANK.EDIT_RESULT, res });

// INTRABANK - Delete
export const deleteIntrabankFavourite = payload => ({ type: actions.DELETE.INTRABANK.CONFIRMATION, payload });
export const requestDeleteIntrabankTac = payload => ({ type: actions.DELETE.INTRABANK.REQUEST_TAC, payload });
export const getDeleteIntrabankResult = payload => ({ type: actions.DELETE.INTRABANK.RESULT, payload });

// SKN
export const getSknDetail = payload => ({ type: actions.GET.SKN.DETAIL, payload });
export const getSknResult = payload => ({ type: actions.GET.SKN.RESULT, payload });
export const getSknTac = payload => ({ type: actions.GET.SKN.REQUEST_TAC, payload });
export const getSknReceipt = payload => ({ type: actions.GET.SKN.RECEIPT, payload });
export const getSknConfirmation = payload => ({ type: actions.GET.SKN.CONFIRMATION, payload });
export const getSknDetailAfterTransfer = payload => ({ type: actions.GET.SKN.DETAIL_AFTER_TRANSFER, payload });

export const putSknTac = res => ({ type: actions.PUT.SKN.TAC, res });
export const putSknDetail = res => ({ type: actions.PUT.SKN.DETAIL, res });
export const putSknResult = res => ({ type: actions.PUT.SKN.RESULT, res });
export const putSknReceipt = res => ({ type: actions.PUT.SKN.RECEIPT, res });
export const putSknConfirmation = res => ({ type: actions.PUT.SKN.CONFIRMATION, res });
export const putSknDetailAfterTransfer = res => ({ type: actions.PUT.SKN.DETAIL_AFTER_TRANSFER, res });

// SKN - Edit
export const getSknEditDetail = payload => ({ type: actions.GET.SKN.EDIT_DETAILS, payload });
export const getSknEditResult = payload => ({ type: actions.GET.SKN.EDIT_RESULT, payload });
export const getSknEditTac = payload => ({ type: actions.GET.SKN.EDIT_REQUEST_TAC, payload });
export const getSknEditConfirmation = payload => ({ type: actions.GET.SKN.EDIT_CONFIRMATION, payload });

export const putSknEditTac = res => ({ type: actions.PUT.SKN.EDIT_REQUEST_TAC, res });
export const putSknEditDetail = res => ({ type: actions.PUT.SKN.EDIT_DETAILS, res });
export const putSknEditResult = res => ({ type: actions.PUT.SKN.EDIT_RESULT, res });
export const putSknEditConfirmation = res => ({ type: actions.PUT.SKN.EDIT_CONFIRMATION, res });

// SKN - Delete
export const getDeleteSknResult = payload => ({ type: actions.DELETE.SKN.RESULT, payload });
export const deleteSknFavourite = payload => ({ type: actions.DELETE.SKN.CONFIRMATION, payload });
export const requestDeleteSknTac = payload => ({ type: actions.DELETE.SKN.REQUEST_TAC, payload });

// RTGS
export const getRtgsReceipt = payload => ({ type: actions.GET.RTGS.RECEIPT, payload });
export const requestRtgsTac = payload => ({ type: actions.GET.RTGS.REQUEST_TAC, payload });
export const getRtgsDetail = payload => ({ type: actions.GET.RTGS.DETAIL, payload });
export const getRtgsResult = payload => ({ type: actions.GET.RTGS.RESULT, payload });
export const getRtgsConfirmation = payload => ({ type: actions.GET.RTGS.CONFIRMATION, payload });
export const getRtgsDetailAfterTransfer = payload => ({ type: actions.GET.RTGS.DETAIL_AFTER_TRANSFER, payload });

export const putRtgsTac = res => ({ type: actions.PUT.RTGS.TAC, res });
export const putRtgsDetail = res => ({ type: actions.PUT.RTGS.DETAIL, res });
export const putRtgsResult = res => ({ type: actions.PUT.RTGS.RESULT, res });
export const putRtgsReceipt = res => ({ type: actions.PUT.RTGS.RECEIPT, res });
export const putRtgsConfirmation = res => ({ type: actions.PUT.RTGS.CONFIRMATION, res });
export const putRtgsDetailAfterTransfer = res => ({ type: actions.PUT.RTGS.DETAIL_AFTER_TRANSFER, res });

// RTGS - Edit
export const requestRtgsEditTac = payload => ({ type: actions.GET.RTGS.EDIT_REQUEST_TAC, payload });
export const getRtgsEditDetail = payload => ({ type: actions.GET.RTGS.EDIT_DETAILS, payload });
export const getRtgsEditResult = payload => ({ type: actions.GET.RTGS.EDIT_RESULT, payload });
export const getRtgsEditConfirmation = payload => ({ type: actions.GET.RTGS.EDIT_CONFIRMATION, payload });

export const putRtgsEditTac = res => ({ type: actions.PUT.RTGS.EDIT_REQUEST_TAC, res });
export const putRtgsEditDetail = res => ({ type: actions.PUT.RTGS.EDIT_DETAILS, res });
export const putRtgsEditResult = res => ({ type: actions.PUT.RTGS.EDIT_RESULT, res });
export const putRtgsEditConfirmation = res => ({ type: actions.PUT.RTGS.EDIT_CONFIRMATION, res });

// RTGS - Delete
export const getDeleteRtgsResult = payload => ({ type: actions.DELETE.RTGS.RESULT, payload });
export const deleteRtgsFavourite = payload => ({ type: actions.DELETE.RTGS.CONFIRMATION, payload });
export const requestDeleteRtgsTac = payload => ({ type: actions.DELETE.RTGS.REQUEST_TAC, payload });

// SWIFT
export const getSwiftResult = payload => ({ type: actions.GET.SWIFT.RESULT, payload });
export const getSwiftReceipt = payload => ({ type: actions.GET.SWIFT.RECEIPT, payload });
export const getSwiftDetail = payload => ({ type: actions.GET.SWIFT.DETAIL, payload });
export const requestSwiftTAC = payload => ({ type: actions.GET.SWIFT.REQUEST_TAC, payload });
export const getSwiftConfirmation = payload => ({ type: actions.GET.SWIFT.CONFIRMATION, payload });
export const getSwiftDetailAfterTransfer = payload => ({ type: actions.GET.SWIFT.DETAIL_AFTER_TRANSFER, payload });
export const getSwiftSearchResult = payload => ({ type: actions.GET.SWIFT.SEARCH_RESULT, payload });

export const putSwiftTAC = res => ({ type: actions.PUT.SWIFT.TAC, res });
export const puttSwiftReceipt = res => ({ type: actions.PUT.SWIFT.RECEIPT, res });
export const putSwiftDetail = res => ({ type: actions.PUT.SWIFT.DETAIL, res });
export const putSwiftResult = res => ({ type: actions.PUT.SWIFT.RESULT, res });
export const putSwiftConfirmation = res => ({ type: actions.PUT.SWIFT.CONFIRMATION, res });
export const putSwiftDetailAfterTransfer = res => ({ type: actions.PUT.SWIFT.DETAIL_AFTER_TRANSFER, res });
export const putSwiftSearchResult = res => ({ type: actions.PUT.SWIFT.SEARCH_RESULT, res });

// SWIFT - Edit
export const getSwiftEditResult = payload => ({ type: actions.GET.SWIFT.EDIT_RESULT, payload });
export const getSwiftEditDetail = payload => ({ type: actions.GET.SWIFT.EDIT_DETAILS, payload });
export const requestSwiftEditTAC = payload => ({ type: actions.GET.SWIFT.EDIT_REQUEST_TAC, payload });
export const getSwiftEditConfirmation = payload => ({ type: actions.GET.SWIFT.EDIT_CONFIRMATION, payload });

export const putSwiftEditTAC = res => ({ type: actions.PUT.SWIFT.EDIT_REQUEST_TAC, res });
export const putSwiftEditDetail = res => ({ type: actions.PUT.SWIFT.EDIT_DETAILS, res });
export const putSwiftEditResult = res => ({ type: actions.PUT.SWIFT.EDIT_RESULT, res });
export const putSwiftEditConfirmation = res => ({ type: actions.PUT.SWIFT.EDIT_CONFIRMATION, res });

// SWIFT - Delete
export const getDeleteSwiftResult = payload => ({ type: actions.DELETE.SWIFT.RESULT, payload });
export const deleteSwiftFavourite = payload => ({ type: actions.DELETE.SWIFT.CONFIRMATION, payload });
export const requestDeleteSwiftTac = payload => ({ type: actions.DELETE.SWIFT.REQUEST_TAC, payload });

// VIRTUAL ACCOUNT
export const getVirtualAccountDetail = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.DETAIL, payload });
export const getVirtualAccountResult = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.RESULT, payload });
export const getVirtualAccountReceipt = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.RECEIPT, payload });
export const requestVirtualAccountTac = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.REQUEST_TAC, payload });
export const getVirtualAccountConfirmation = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.CONFIRMATION, payload });
export const getVirtualAccountDetailAfterTransfer = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.DETAIL_AFTER_TRANSFER, payload });

export const putVirtualAccountTac = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.TAC, res });
export const putVirtualAccountDetail = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.DETAIL, res });
export const putVirtualAccountResult = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.RESULT, res });
export const putVirtualAccountReceipt = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.RECEIPT, res });
export const putVirtualAccountConfirmation = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.CONFIRMATION, res });
export const putVirtualAccountDetailAfterTransfer = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.DETAIL_AFTER_TRANSFER, res });

// VIRTUAL ACCOUNT - Edit
export const getVirtualAccountEditDetail = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.EDIT_DETAILS, payload });
export const getVirtualAccountEditResult = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.EDIT_RESULT, payload });
export const requestVirtualAccountEditTac = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.EDIT_REQUEST_TAC, payload });
export const getVirtualAccountEditConfirmation = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.EDIT_CONFIRMATION, payload });

export const putVirtualAccountEditTac = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.EDIT_REQUEST_TAC, res });
export const putVirtualAccountEditDetail = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.EDIT_DETAILS, res });
export const putVirtualAccountEditResult = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.EDIT_RESULT, res });
export const putVirtualAccountEditConfirmation = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.EDIT_CONFIRMATION, res });

// VIRTUAL ACCOUNT - Delete
export const getDeleteVirtualAccountResult = payload => ({ type: actions.DELETE.VIRTUAL_ACCOUNT.RESULT, payload });
export const deleteVirtualAccountFavourite = payload => ({ type: actions.DELETE.VIRTUAL_ACCOUNT.CONFIRMATION, payload });
export const requestDeleteVirtualAccountTac = payload => ({ type: actions.DELETE.VIRTUAL_ACCOUNT.REQUEST_TAC, payload });

// EWALLET TRANSFER
export const getEWalletDetail = payload => ({ type: actions.GET.EWALLET.DETAIL, payload });
export const getEWalletResult = payload => ({ type: actions.GET.EWALLET.RESULT, payload });
export const getEWalletReceipt = payload => ({ type: actions.GET.EWALLET.RECEIPT, payload });
export const requestEWalletTac = payload => ({ type: actions.GET.EWALLET.REQUEST_TAC, payload });
export const getEWalletConfirmation = payload => ({ type: actions.GET.EWALLET.CONFIRMATION, payload });
export const getEWalletDetailAfterTransfer = payload => ({ type: actions.GET.EWALLET.DETAIL_AFTER_TRANSFER, payload });

export const putEWalletTac = res => ({ type: actions.PUT.EWALLET.TAC, res });
export const putEWalletDetail = res => ({ type: actions.PUT.EWALLET.DETAIL, res });
export const putEWalletResult = res => ({ type: actions.PUT.EWALLET.RESULT, res });
export const putEWalletReceipt = res => ({ type: actions.PUT.EWALLET.RECEIPT, res });
export const putEWalletConfirmation = res => ({ type: actions.PUT.EWALLET.CONFIRMATION, res });
export const putEWalletDetailAfterTransfer = res => ({ type: actions.PUT.EWALLET.DETAIL_AFTER_TRANSFER, res });

// EWALLET - Edit
export const getEWalletEditDetail = payload => ({ type: actions.GET.EWALLET.EDIT_DETAILS, payload });
export const getEWalletEditResult = payload => ({ type: actions.GET.EWALLET.EDIT_RESULT, payload });
export const requestEWalletEditTac = payload => ({ type: actions.GET.EWALLET.EDIT_REQUEST_TAC, payload });
export const getEWalletEditConfirmation = payload => ({ type: actions.GET.EWALLET.EDIT_CONFIRMATION, payload });

export const putEWalletEditTac = res => ({ type: actions.PUT.EWALLET.EDIT_REQUEST_TAC, res });
export const putEWalletEditDetail = res => ({ type: actions.PUT.EWALLET.EDIT_DETAILS, res });
export const putEWalletEditResult = res => ({ type: actions.PUT.EWALLET.EDIT_RESULT, res });
export const putEWalletEditConfirmation = res => ({ type: actions.PUT.EWALLET.EDIT_CONFIRMATION, res });

// EWALLET - Delete
export const getDeleteEWalletResult = payload => ({ type: actions.DELETE.EWALLET.RESULT, payload });
export const deleteEWalletFavourite = payload => ({ type: actions.DELETE.EWALLET.CONFIRMATION, payload });
export const requestDeleteEWalletTac = payload => ({ type: actions.DELETE.EWALLET.REQUEST_TAC, payload });
