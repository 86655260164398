export const digitalWealthEndpoint = {
  get: {
    pensionGoal: 'goals/v1/pension',
    educationGoal: 'goals/v1/education',
    maxInvestmentGoal: 'goals/v1/maxinvestment',
    goalPlans: 'goals/v1/user/goals',
    expandedList: 'goals/v1/plan/expand',
    planDetail: 'goals/v1/user/goal/detail',
    portfolio: 'wealth/v1/portfolio',
    dashboard: 'wealth/v1/summary/portfolio',
    bonds: 'wealth/v1/list/bonds',
    bondDetail: 'wealth/v1/details/bonds',
    bancas: 'wealth/v1/list/banca',
    bancaDetail: 'wealth/v1/details/banca',
    mutualFunds: 'wealth/v1/list/mf',
    mutualFundDetail: 'wealth/v1/details/mf',
  },
};
