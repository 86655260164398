import { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Grid from '@material-ui/core/Grid';
import { Tooltip } from 'components/common';
import Menu from 'components/common/Menu';

import { FAVOURITE_MENU_ITEMS } from 'settings/constants/transaction';

const FavouriteCard = ({ mainText, subText1, subText2, subText3, onItemClick }) => {
  const overflowTextRef = useRef();
  const [hasTooltip, setTooltip] = useState(false);

  const isOverflowText = event => event.offsetWidth < event.scrollWidth;

  useLayoutEffect(() => {
    if (isOverflowText(overflowTextRef.current)) {
      setTooltip(true);
      return;
    }

    setTooltip(false);
  }, []);

  return (
    <Grid container alignItems="center">
      {hasTooltip ? (
        <Tooltip title={parse(`${mainText}<p>${subText1}</p><p>${subText2}</p>${subText3}`)} placement="bottom-end">
          <Grid item xs={11} className="mobile-padding">
            <div className="favourite-list-item" ref={overflowTextRef}>
              <span className="favourite-nickname">{mainText}</span>
              <span className="transfer--account-number">{subText1}</span>
              <span className="transfer--account-number">{subText2}</span>
              <span className="transfer--account-number">{subText3}</span>
            </div>
          </Grid>
        </Tooltip>
      ) : (
        <Grid item xs={11} className="mobile-padding">
          <div className="favourite-list-item" ref={overflowTextRef}>
            <span className="favourite-nickname">{mainText}</span>
            <span className="transfer--account-number">{subText1}</span>
            <span className="transfer--account-number">{subText2}</span>
            <span className="transfer--account-number">{subText3}</span>
          </div>
        </Grid>
      )}
      <Grid item xs={1}>
        <div className="favourite-menu">
          <Menu className="favourite-list--menu-btn" menuItems={FAVOURITE_MENU_ITEMS} onItemClick={onItemClick} />
        </div>
      </Grid>
    </Grid>
  );
};

FavouriteCard.defaultProps = { mainText: '', subText1: '', subText2: '', subText3: '', onItemClick: null };

FavouriteCard.propTypes = {
  mainText: PropTypes.string,
  subText1: PropTypes.string,
  subText2: PropTypes.string,
  subText3: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default FavouriteCard;
