import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FormModal } from 'components/common/modals';
import { ActionButton, BasicButton } from 'components/common/buttons';
import { DynoForm } from 'components/dyno-template';

import { get } from 'utils/lodash';
import { formatDynamicFields } from 'utils/dynamic-field';
import { formatTransferNote } from 'utils/transfer/formatter';

import { MODAL_FULLSCREEN_SIZE } from 'settings/constants/ui-control';
import TextEditor from 'components/common/TextEditor';

const DynoFormModal = ({
  title,
  serviceTitle,
  isOpen,
  onClose,
  onSubmit,
  formDetail,
  hasNoteContent,
  fields,
  dynamicFields,
  notes,
  onChange,
  actionButtonTitle,
  isBasicButton,
  className,
  hasTextEditor,
  isButtonTextEditor,
  titleTextEditor,
}) => {
  const { formatMessage } = useIntl();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(MODAL_FULLSCREEN_SIZE));

  const noteTitle = Array.isArray(notes)
    ? formatMessage({ id: 'transaction.transfer.notes' })
    : formatMessage({ id: 'transaction.transfer.note' });

  const isValid = get(formDetail, 'isValid', false);
  const colorCounter = get(formDetail, 'colorCounterTextEditor', '');
  const composeType = get(formDetail, 'composeType', '');
  const disableButtonWhenZeroCharacter = get(formDetail, 'disableButtonWhenZeroCharacter', false);

  const transferNotes = formatTransferNote(notes);

  const footerButton = isBasicButton ? (
    <BasicButton className="db" disabled={!isValid} onClick={() => onSubmit(formDetail)} title={formatMessage({ id: actionButtonTitle })} />
  ) : (
    <ActionButton
      className="db"
      disabled={!isValid}
      isAssetIcon={false}
      icon="syncArrowAlt"
      onClick={() => onSubmit(formDetail)}
      title={formatMessage({ id: actionButtonTitle })}
    />
  );

  const footerButtonEditor = (
    <ActionButton
      className="db"
      disabled={colorCounter === 'red' || !isValid || !disableButtonWhenZeroCharacter}
      isAssetIcon={false}
      icon="create"
      onClick={() => onSubmit(formDetail)}
      title={formatMessage({ id: actionButtonTitle })}
    />
  );

  const formattedFields = useMemo(() => formatDynamicFields(fields, dynamicFields), [dynamicFields]);
  const formatTextEditor = [['bold', 'italic', 'underline', { color: [] }]];

  return (
    <FormModal
      fullWidth
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={hasTextEditor ? 'xs' : 'sm'}
      fullScreen={fullScreen}
      hasContentHeader={false}
      className={`modal--transfer ${className}`}
      title={title && formatMessage({ id: title }, serviceTitle)}
      footer={isButtonTextEditor ? footerButtonEditor : footerButton}
      hasTextEditor
      composeType={composeType}
    >
      <DynoForm fields={formattedFields} formDetail={formDetail} onChange={onChange} />

      {hasTextEditor && (
        <>
          <Grid container className="mv3 ml2">
            <Grid item xs={12} sm={4} md={4}>
              <span className="title--text-editor">{formatMessage({ id: titleTextEditor })}</span>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <TextEditor onChange={onChange} formDetail={formDetail} format={formatTextEditor} style={{ width: '86%', height: '140px' }} />
            </Grid>
          </Grid>
        </>
      )}

      {hasNoteContent && (
        <Grid container alignItems="center" className="modal--note">
          <Grid item xs={12}>
            <Typography variant="body2">{noteTitle}</Typography>
            {transferNotes}
          </Grid>
        </Grid>
      )}
    </FormModal>
  );
};

DynoFormModal.defaultProps = {
  dynamicFields: [],
  notes: [],
  serviceTitle: {},
  actionButtonTitle: 'buttonText.transfer',
  isBasicButton: false,
  className: '',
  hasTextEditor: false,
  isButtonTextEditor: false,
  titleTextEditor: '',
};

DynoFormModal.propTypes = {
  isBasicButton: PropTypes.bool,
  serviceTitle: PropTypes.object,
  className: PropTypes.string,
  actionButtonTitle: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formDetail: PropTypes.object.isRequired,
  hasNoteContent: PropTypes.bool.isRequired,
  notes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  dynamicFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hasTextEditor: PropTypes.bool,
  isButtonTextEditor: PropTypes.bool,
  titleTextEditor: PropTypes.string,
};

export default DynoFormModal;
