import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import { extractContentHtml } from 'utils/inbox/formatter';
import { get, isEmpty } from 'lodash';

const TextEditor = ({ onChange, format, style, formDetail }) => {
  const [value, setValue] = useState('');
  const colorCounter = get(formDetail, 'colorCounterTextEditor', '');
  const message = get(formDetail, 'message', '');

  const formats = {
    toolbar: format,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const lengthCharacterQuill = extractContentHtml(value).length;

  useEffect(() => {
    if (isEmpty(value)) setValue(message);
  }, []);

  useEffect(() => {
    onChange({ message: value });

    if (lengthCharacterQuill > 5000) onChange({ colorCounterTextEditor: 'red' });
    else onChange({ colorCounterTextEditor: '#bcbcbc' });

    if (lengthCharacterQuill > 0) onChange({ disableButtonWhenZeroCharacter: true });
    if (lengthCharacterQuill === 0) onChange({ disableButtonWhenZeroCharacter: false });
  }, [value]);

  return (
    <>
      <ReactQuill className="react-quill" theme="snow" value={value} onChange={setValue} style={style} modules={formats} />
      <div id="counter">
        <span id="counter-text" style={{ marginRight: '5px', color: colorCounter }}>
          {`${lengthCharacterQuill}/5000`}
        </span>
      </div>
    </>
  );
};

TextEditor.defaultProps = {
  onChange: null,
  format: [],
  style: {},
  formDetail: {},
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
  format: PropTypes.array,
  style: PropTypes.object,
  formDetail: PropTypes.object,
};

export default TextEditor;
