import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { DynoFavouriteCard, DynoFavouriteSection } from 'components/dyno-template';

import { get } from 'utils/lodash';
import { formatFavouriteAccounts } from 'utils/payment/formatter';
import { getSearchableFavouriteAccounts } from 'utils/payment/getter';
import { deleteFavouriteHandler, editFavouriteHandler } from 'utils/favourite/payment/handler';

import { FAVOURITE_PAYMENT_BILLTYPE_KEYS } from 'settings/constants/array';

const PaymentFavouriteContainer = ({
  userId,
  accounts,
  isFormOpen,
  searchValue,
  transactionType,
  onKeywordChange,
  onAddNewFavourite,
  onFavouriteTypeChange,
  onPaymentFromFavourite,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleFavouriteMenuClick = payload => {
    const option = get(payload, 'option', 'default');
    const billType = get(payload, 'billType', '');
    const type = FAVOURITE_PAYMENT_BILLTYPE_KEYS.get(billType);

    const optionHandler = {
      edit: () => {
        onFavouriteTypeChange(type, { isEditFavourite: true });
        editFavouriteHandler(type, { userId, ...payload }, dispatch);
      },
      delete: () => {
        onFavouriteTypeChange(type, { isDeleteFavourite: true });
        deleteFavouriteHandler(type, { userId, ...payload }, dispatch);
      },
      default: () => null,
    };

    return (optionHandler[option] || optionHandler.default)();
  };

  const searchableAccounts = getSearchableFavouriteAccounts(accounts);
  const radioOptions = formatFavouriteAccounts(searchableAccounts, searchValue, handleFavouriteMenuClick, DynoFavouriteCard);

  const handlePurchaseFromFavourite = index => {
    const selectedFavourite = accounts.find(account => account.index === index);
    const billType = get(selectedFavourite, 'billType', '');
    const type = FAVOURITE_PAYMENT_BILLTYPE_KEYS.get(billType);
    onPaymentFromFavourite(type, selectedFavourite);
  };

  // to avoid lagging when type in input
  const FavouriteSection = useMemo(
    () => (
      <DynoFavouriteSection
        hasSearchSection
        isFormOpen={isFormOpen}
        label={formatMessage({ id: 'transaction.favourite.label' })}
        searchValue={searchValue}
        transactionType={transactionType}
        onChange={handlePurchaseFromFavourite}
        radioOptions={radioOptions}
        onKeywordChange={onKeywordChange}
        onAddFavourite={onAddNewFavourite}
        onFavouriteTypeChange={onFavouriteTypeChange}
      />
    ),
    [accounts, searchValue, isFormOpen]
  );

  return <>{FavouriteSection}</>;
};

PaymentFavouriteContainer.defaultProps = { searchValue: '' };

PaymentFavouriteContainer.propTypes = {
  searchValue: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  isFormOpen: PropTypes.bool.isRequired,
  onKeywordChange: PropTypes.func.isRequired,
  transactionType: PropTypes.string.isRequired,
  onAddNewFavourite: PropTypes.func.isRequired,
  onFavouriteTypeChange: PropTypes.func.isRequired,
  onPaymentFromFavourite: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PaymentFavouriteContainer;
