import { FormattedMessage } from 'react-intl';
import { MOBILE_POSTPAID, PAYMENT_CREDIT_CARD, PAYMENT_OTHER_BILL, PAYMENT_UTILITY } from 'settings/constants/transaction';

import { get } from 'utils/lodash';
import { formatMobileNumber } from 'utils/formatter';

export const getFavouriteBeneficiaryDetails = detail => [
  { title: <FormattedMessage id="transaction.favourite.form.beneficiaryNickname" />, info: get(detail, 'nickname', '-') },
];

// TODO: Split into each payment type getter
export const getFavouriteAccountInfo = (type, isAddNewFavourite, detail) => {
  const receiverAccount = get(detail, 'toAccountNo', '');
  const transactionType = get(detail, 'serviceName', '');
  const receiverName = get(detail, 'nickname', '');

  const defaultInfo = { receiverName, receiverAccount, transactionType };
  const postpaidInfo = {
    receiverName: get(detail, 'payeeName', ''),
    receiverAccount: get(detail, 'mobileNo', ''),
    transactionType,
  };
  const creditCardInfo = {
    receiverName: get(detail, 'cardHolderName', '') || get(detail, 'issuingBank', ''),
    receiverAccount: get(detail, 'creditCardNoDisplay', ''),
    transactionType,
  };
  const utilityInfo = {
    receiverName: get(detail, 'payeeName', ''),
    receiverAccount: get(detail, 'customerNo', ''),
    transactionType,
  };
  const otherBillInfo = {
    receiverName: get(detail, 'payeeName', ''),
    receiverAccount: get(detail, 'accountNo', ''),
    transactionType,
  };

  const handler = {
    [PAYMENT_CREDIT_CARD]: creditCardInfo,
    [MOBILE_POSTPAID]: postpaidInfo,
    [PAYMENT_UTILITY]: utilityInfo,
    [PAYMENT_OTHER_BILL]: otherBillInfo,
    default: defaultInfo,
  };
  return handler[type] || handler.default;
};

export const getCreditCardNewFavouritePayload = state => {
  const isFromTransferResult = get(state, 'isFromTransferResult', false);
  const cardHolderName = get(state, 'cardHolderName', '');
  const issuingBank = get(state, 'issuingBank', '');

  const isOtherBank = isFromTransferResult && issuingBank;
  const isMaybank = isFromTransferResult && cardHolderName;

  return {
    userId: get(state, 'userId', ''),
    nickname: get(state, 'nickname', ''),
    cardType: get(state, 'cardType', ''),
    creditCardNo: get(state, 'creditCardNumber', ''),
    ...(isOtherBank ? { issuingBank } : {}),
    ...(isMaybank ? { cardHolderName } : {}),
  };
};

export const getMobilePostpaidNewFavouritePayload = state => {
  const mobileNumber = get(state, 'mobileNumber', '');
  const isFromTransferResult = get(state, 'isFromTransferResult', false);

  return {
    userId: get(state, 'userId', ''),
    nickname: get(state, 'accountNickname', '') || get(state, 'nickname', ''),
    payeeCode: get(state, 'billerType', '') || get(state, 'payeeCode', ''),
    mobileNo: isFromTransferResult ? mobileNumber : formatMobileNumber(mobileNumber),
  };
};

export const getUtilityNewFavouritePayload = state => ({
  userId: get(state, 'userId', ''),
  nickname: get(state, 'nickname', ''),
  payeeCode: get(state, 'billerType', '') || get(state, 'payeeCode', ''),
  customerNo: get(state, 'customerNumber', ''),
});

export const getOtherBillNewFavouritePayload = state => ({
  userId: get(state, 'userId', ''),
  nickname: get(state, 'nickname', ''),
  payeeCode: get(state, 'payeeType.value', '') || get(state, 'payeeCode', ''),
  accountNo: get(state, 'accountNo', ''),
  billerId: get(state, 'billerType.value', '') || get(state, 'billerId', ''),
});
