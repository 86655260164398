import { useContext } from 'react';

import { PropTypes } from 'prop-types';

import { LocaleContext } from 'providers/context';

import { NavigationList } from 'components/common/navigation';

import { LANGUAGE_OPTIONS } from 'settings/constants/language';
import { useLocalStorage } from 'hooks';

const LanguageBar = ({ className }) => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [languagePreferance, setLanguagePreference] = useLocalStorage('language');

  const handleSelectLanguage = language => {
    setLocale(language);
    setLanguagePreference(language);
  };

  return (
    <NavigationList
      className={`common language-bar ${className}`}
      indicator="top"
      value={locale}
      navItems={LANGUAGE_OPTIONS}
      onChange={handleSelectLanguage}
    />
  );
};

LanguageBar.defaultProps = { className: '' };

LanguageBar.propTypes = { className: PropTypes.string };

export default LanguageBar;
