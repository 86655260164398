import React from 'react';
import PropTypes from 'prop-types';

import { useSelector, shallowEqual } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { routes } from './routes';

const { dashboard } = routes;

// The default route when user is authenticated is dashboard, if user not autheticated
const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isRenewPassword } = useSelector(
    state => ({ isAuthenticated: state.AuthReducer.isAuthenticated, isRenewPassword: state.AuthReducer.isRenewPassword }),
    shallowEqual
  );

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated && !isRenewPassword ? <Redirect to={dashboard.casa} /> : <Component {...props} />)}
    />
  );
};

PublicRoute.defaultProps = { component: '' };

PublicRoute.propTypes = { component: PropTypes.elementType };

export default PublicRoute;
