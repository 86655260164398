import { get } from 'utils/lodash';
import { formatArrayKeys } from 'utils/formatter';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

export const formatInterbankDetail = res => {
  const allBank = get(res, 'bankMap', {});
  const initialBanks = get(res, 'bankList', []);
  const initialCurrencies = get(res, 'currencyMap', []);
  const serviceInfoBean = get(res, 'serviceInfoBean', {});
  const transferBanks = formatArrayKeys(initialBanks);
  const currencies = formatArrayKeys(initialCurrencies);
  const responseCode = get(res, 'responseCode', '');
  const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;
  const errorCode = !isSuccess && responseCode;

  // FAVOURITE
  const toAccountNumber = get(res, 'toAccountNumber', '');
  const bankName = get(res, 'bankName', '');
  const toAccountEmailAddress = get(res, 'toAccountEmailAddress', '');

  return {
    allBank,
    transferBanks,
    currencies,
    serviceInfoBean,
    isSuccess,
    errorCode,
    ...(toAccountNumber ? { toAccountNumber, bankName, toAccountEmailAddress } : {}),
  };
};

export const formatInterbankResultBody = ({ userId, tacValue, confirmationDetail, transferTacDetail, isFavouriteTransfer }) => {
  return {
    userId,
    tacValue,
    favTransaction: isFavouriteTransfer,
    restSMSService: get(transferTacDetail, 'tacViewBean.restSMSService', {}),
    additionalData: get(confirmationDetail, 'additionalData', ''),
    accountHolderName: get(confirmationDetail, 'accountHolderName', ''),
    bankCode: get(confirmationDetail, 'bankCode', ''),
    bankName: get(confirmationDetail, 'bankName', ''),
    beneReferenceNo: get(confirmationDetail, 'beneReferenceNo', ''),
    fromAccountNumber: get(confirmationDetail, 'fromAccountNumber', ''),
    fromCurrencyCode: get(confirmationDetail, 'fromCurrencyCode', ''),
    toAmount: get(confirmationDetail, 'toAmount', ''),
    toAccountNumber: get(confirmationDetail, 'toAccountNumber', ''),
    toAccountEmailAddress: get(confirmationDetail, 'toAccountEmailAddress', ''),
  };
};

export const formatInterbankEditDetailPayload = detail => ({
  userId: get(detail, 'userId', ''),
  toAccountNo: get(detail, 'accountNumber', ''),
  bankCode: get(detail, 'bankCodePayeeCode', ''),
});

export const formatInterbankEditFavouriteConfirm = detail => ({
  userId: get(detail, 'userId', ''),
  accountNickname: get(detail, 'accountNickname', ''),
  toAccountNo: get(detail, 'toAccountNo', ''),
  bankCode: get(detail, 'bankCode', ''),
  bankName: get(detail, 'bankName', ''),
  emailAddress: get(detail, 'emailAddress', ''),
  accountHolderName: get(detail, 'accountHolderName', '') || get(detail, 'toAccountHolderName', ''),
});

export const formatInterbankAddFavouriteResultPayload = detail => {
  const isFromTransferResult = get(detail, 'confirmationDetail.isFromTransferResult', false);
  const tacValue = get(detail, 'tacValue', '');

  return {
    isFromTransferResult,
    userId: get(detail, 'userId', ''),
    restSMSService: get(detail, 'tacDetail.tacViewBean.restSMSService', null),
    accountNickname: get(detail, 'confirmationDetail.accountNickname', ''),
    toAccountNo: get(detail, 'confirmationDetail.toAccountNo', ''),
    bankCode: get(detail, 'confirmationDetail.bankCode', ''),
    emailAddress: get(detail, 'confirmationDetail.emailAddress', ''),
    ...(!isFromTransferResult ? { tacValue } : {}),
  };
};
