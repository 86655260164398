export const lldDescription = {
  production: {
    2701: 'Bunga, bagi hasil, margin dan bonus - promissory notes, comersial papers dan obligasi s.d 1 tahun ',
    2702: 'Bunga, bagi hasil, margin dan bonus - promissory notes, comersial papers dan obligasi lebih dari 1 tahun ',
    2705: 'Bunga Rekening giro dan simpanan, termasuk tabungan dan deposito mudharabah',
    2710: "Bunga Pinjaman (termasuk financial leasing, murabahah, musyarakah, salam paralel, istishna ' paralel, dan ijarah)",
    2716: 'Bunga, bagi hasil Surat-surat berharga yang diterbitkan oleh penduduk (seperti promissory notes, commercial papers, dan obligasi serta SIMA) s.d 1 tahun ',
    2717: 'Bunga, bagi hasil Surat-surat berharga yang diterbitkan oleh penduduk (seperti promissory notes,commercial papers, dan obligasi serta SIMA) lebih dari 1 tahun ',
    2720: 'Bunga, bagi hasil, margin Gold swap',
    2725: 'Dividen dan keuntungan/laba yang dibagikan, termasuk keuntungan yang berasal dari reksadana ',
    2730: 'Fee transaksi pemilik surat berharga dalam rangka transaksi securities lending ',
    2731: 'Fee transaksi pemilik emas dlm rangka transaksi gold loans atau gold swaps',
    2150: "Transfer Penghasilan (worker's remmitances)",
    2163: 'Penghasilan tenaga kerja',
    2741: 'Sewa tanah ',
    2742: 'Sewa ruang perkantoran, apartemen, rumah dan sejenisnya',
    2743: 'Sewa tanah ruang perkantoran, apartemen, rumah dan sejenisnya',
    2751: 'Hibah atau sejenisnya (dalam bentuk cash) yang tidak dikaitkan dengan kewajiban membeli fixed asset.',
    2752: 'Hibah atau sejenisnya (dalam bentuk cash) yang dikaitkan dengan kewajiban membeli fixed asset.',
    2760: 'Sanksi/denda, dan sejenisnya',
    2765: 'Pajak atas produk seperti value added tax dan pajak atas produksi seperti payroll tax, pajak bumi & bangunan, dan lisensi bisnis ',
    2766: 'Pajak atas pendapatan dan kesejahteraan misalnya pajak gaji/upah',
    2767: 'Pajak lainnya, a.l iuran keanggotaan',
    2770: 'Hak untuk penggunaan sumber daya alam',
  },
  goods: {
    2012: 'Impor barang',
    2803: 'Pengembalian dana (refunds) impor',
    2018: 'Pembayaran dimuka (advance payment) - dibayar penuh',
    2019: 'Pembayaran dimuka (advance payment) - dibayar sebagian',
    2015: 'Bunkers & Stores (oleh/kepada penyedia sarana transportasi seperti bahan bakar dan supplies)',
    2097: 'Perdagangan barang dalam wilayah Indonesia (Wajib dilengkapi Dokumen pendukung)',
    2098: 'Penjualan barang di luar Indonesia dimana barang tsb tidak berasal dari Indonesia/pembelian barang di luar Indonesia untuk kemudian dijual di luar ',
    2804: 'Pengembalian dana (refunds) barang lainnya',
  },
  services: {
    2400: 'Jasa pemrosesan barang',
    2405: 'Jasa pemeliharaan dan perbaikan barang',
    2411: 'Transportasi Penumpang - Moda Transportasi laut',
    2412: 'Transportasi Penumpang - Moda Transportasi udara',
    2413: 'Transportasi Penumpang - Moda Transportasi lainnya (a.l rel & jalan raya)',
    2421: 'Transportasi barang (Freight) dalam rangka ekspor dan impor - Moda transportasi laut',
    2422: 'Transportasi barang (Freight) dalam rangka ekspor dan impor - Moda transportasi udara',
    2423: 'Transportasi barang (Freight) dalam rangka ekspor dan impor - Moda transportasi lainnya(a.l rel, pipa, transportasi ruang & jalan raya)',
    2431: 'Transportasi barang (Freight) di luar ekspor dan impor - Moda Transportasi laut',
    2432: 'Transportasi barang (Freight) di luar ekspor dan impor - Moda Transportasi udara',
    2433: 'Transportasi barang (Freight) di luar ekspor dan impor - Moda transportasi lainnya (a.l rel, pipa, transportasi ruang & jalan raya)',
    2441: 'Penunjang transportasi - Moda Transportasi laut',
    2442: 'Penunjang transportasi - Moda Transportasi udara',
    2443: 'Penunjang transportasi - Moda Transportasi lainnya (rel, pipa, ruang dan jalan raya)',
    2450: 'Pos dan kurir',
    2461: 'Perjalanan bisnis',
    2462: 'Perjalanan personal',
    2466: 'Kesehatan - Jasa kesehatan dilakukan di Indonesia',
    2467: 'Kesehatan - Jasa kesehatan dilakukan di luar Indonesia',
    2468: 'Jasa Pendidikan/pelatihan dilakukan di Indonesia',
    2469: 'Jasa Pendidikan/pelatihan dilakukan di luar Indonesia',
    2480: 'Jasa telekomunikasi',
    2490: 'Jasa komputer',
    2495: 'Jasa informasi',
    2501: 'Jasa konstruksi di Indonesia - sampai dengan satu tahun',
    2502: 'Jasa konstruksi di Indonesia - lebih dari satu tahun',
    2511: 'Jasa konstruksi di luar negeri - sampai dengan 1 tahun',
    2512: 'Jasa konstruksi di luar negeri - lebih dari 1 tahun',
    2521: 'Jasa Asuransi langsung - Asuransi Jiwa - Premi',
    2522: 'Jasa Asuransi langsung - Asuransi Jiwa - Benefit',
    2523: 'Jasa Asuransi langsung - Asuransi Freight - Premi',
    2524: 'Jasa Asuransi langsung - Asuransi Jiwa - Klaim',
    2525: 'Jasa Asuransi langsung - Asuransi lainnya - Premi',
    2526: 'Jasa Asuransi langsung - Asuransi lainnya - Klaim',
    2531: 'Reasuransi (reinsurance) - premi',
    2532: 'Reasuransi (reinsurance) - klaim',
    2533: 'Reasuransi (reinsurance) - komisi',
    2541: 'Jasa penunjang asuransi (auxiliary insurance services)',
    2546: 'Dana pensiun - kontribusi',
    2547: 'Dana pensiun - benefit',
    2560: 'Penggunaan hak kekayaan intelektual',
    2570: 'Penelitian dan pengembangan ',
    2580: 'Hukum, akuntansi, konsultasi manajemen, dan kehumasan',
    2590: 'Periklanan, penelitian pasar, dan jajak pendapat publik',
    2600: 'Arsitektur, rekayasa, dan teknik lainnya',
    2610: 'Pertambangan, pertanian, dan pengolahan sampah dan polusi',
    2615: 'Operational leasing - barang',
    2616: 'Operational leasing - sarana transportasi tanpa awak',
    2630: 'Jasa terkait perdagangan ',
    2640: 'Jasa bisnis lainnya ',
    2651: 'Jasa seni, budaya, dan rekreasi dilakukan di Indonesia',
    2652: 'Jasa seni, budaya, dan rekreasi dilakukan di luar Indonesia',
    2660: 'Jasa pemerintah',
    2129: 'Penyelesaian saldo rekening (netting) - tidak terkait eksport',
  },
  modal: {
    2193: 'Jual beli aset non financial - Marketing assets seperti franchise, trademarks, brand names, logo dan domain names ',
    2194: 'Jual beli aset non financial - kontrak, lease dan lisensi jual/beli hak atas marketable operating lease ',
    2197: 'Jual beli tanah',
    2198: 'Jual beli gedung',
    2203: 'Penyertaan modal di Indonesia',
    2204: 'Penyertaan modal di luar Indonesia',
    2206: 'Divestasi penyertaan modal di Indonesia',
    2207: 'Divestasi penyertaan modal di luar Indonesia',
    2221: 'Pemberian/penarikan pinjaman dengan jangka waktu s.d 1 tahun',
    2222: 'Pemberian/penarikan pinjaman dengan jangka waktu lebih dari 1 tahun',
    2231: 'Pengembalian pinjaman dengan jangka waktu s.d 1 tahun',
    2232: 'Pengembalian pinjaman dengan jangka waktu lebih dari 1 tahun',
    2233: 'Financial leasing- Meliputi leasing berdasarkan kontrak sewa dengan opsi untuk membeli ',
    2383: 'Factoring - Meliputi penjualan piutang dengan debitur Bank di Indonesia dengan jangka waktu s.d 1 tahun ',
    2384: 'Factoring - Meliputi penjualan piutang dengan debitur Bank di Indonesia dengan jangka waktu lebih dari 1 tahun ',
    2385: 'Factoring - Meliputi penjualan piutang dengan perusahaan di Indonesia dengan jangka waktu s.d 1 tahun ',
    2386: 'Factoring - Meliputi penjualan piutang dengan debitur perusahaan di Indonesia dengan jangka waktu lebih dari 1 tahun ',
    2387: 'Factoring - Meliputi penjualan piutang dengan debitur Bank di luar negeri dengan jangka waktu s.d 1 tahun ',
    2388: 'Factoring - Meliputi penjualan piutang dengan debitur Bank di luar negeri dengan jangka waktu lebih dari 1 tahun ',
    2389: 'Factoring - Meliputi penjualan piutang dengan debitur perusahaan di luar negeri dengan jangka waktu s.d 1 tahun ',
    2390: 'Factoring - Meliputi penjualan piutang dengan debitur perusahaan di luar negeri dengan jangka waktu lebih dari 1 tahun ',
    2391: 'Penyerahan dana oleh cash provider dalam transaksi gold swap dengan jangka waktu perjanjian s.d 1 tahun ',
    2392: 'Penyerahan dana oleh cash provider dalam transaksi gold swap dengan jangka waktu perjanjian lebih dari 1 tahun ',
    2393: 'Pengembalian dana oleh gold owner dalam transaksi gold swap dengan jangka waktu perjanjian s.d 1 tahun ',
    2394: 'Pengembalian dana oleh gold owner dalam transaksi gold swap dengan jangka waktu perjanjian lebih dari 1 tahun ',
    2395: 'Penerimaan dana oleh cash provider dalam transaksi gold swap dengan jangka waktu perjanjian s.d 1 tahun ',
    2396: 'Penerimaan dana oleh cash provider dalam transaksi gold swap dengan jangka waktu perjanjian lebih dari 1 tahun ',
    2397: 'Penerimaan dana oleh gold owner dalam transaksi gold swap dengan jangka waktu perjanjian s.d 1 tahun ',
    2398: 'Penerimaan dana oleh gold owner dalam transaksi gold swap dengan jangka waktu perjanjian lebih dari 1 tahun ',
    2808: 'Working capital/cashcall - yang akan digunakan antara lain untuk membeli bahan baku dan membayar upah Migas ',
    2809: 'Working capital/cashcall - yang akan digunakan antara lain untuk membeli bahan baku dan membayar upah Non Migas ',
    2811: 'Advance payment/pembayaran dimuka barang di luar ekspor dan impor',
    2812: 'Advance payment/pembayaran dimuka dalam rangka asuransi',
    2813: 'Advance payment/pembayaran dimuka dalam rangka lainnya',
    2814: 'Penerimaan/pengembalian uang pemesanan (berdasarkan IPO dan private placement)',
    2815: 'Reimbursement atas dana talangan',
    2240: 'Simpanan di Indonesia - Penempatan simpanan s.d 3 bulan - term deposit',
    2241: 'Simpanan di Indonesia - Penempatan simpanan s.d 3 bulan - lainnya, termasuk rekening giro dan call money ',
    2242: 'Simpanan di Indonesia - Penempatan simpanan lebih dari 3 s.d 6 bulan',
    2243: 'Simpanan di Indonesia - Penempatan simpanan lebih dari 6 bulan',
    2244: 'Penarikan Simpanan di Indonesia - s.d 3 bulan - term deposit',
    2245: 'Penarikan Simpanan di Indonesia - s.d 3 bulan - lainnya, termasuk rekening giro dan call money ',
    2246: 'Penarikan Simpanan di Indonesia - lebih dari 3 s.d 6 bulan',
    2247: 'Penarikan Simpanan di Indonesia - lebih dari 6 bulan',
    2251: 'Simpanan di luar Indonesia - Penempatan simpanan s.d 3 bulan - termasuk rekening giro dan call money ',
    2252: 'Simpanan di luar Indonesia - Penempatan simpanan lebih dari 3 bulan ',
    2255: 'Penarikan Simpanan di luar Indonesia - s.d 3 bulan - termasuk rekening giro dan call money ',
    2256: 'Penarikan Simpanan di luar Indonesia - lebih dari 3 bulan',
    2257: 'Penarikan devisa hasil ekspor dari rekening di luar negeri',
    2261: 'Perdagangan valuta asing - transaksi spot valuta asing terhadap rupiah',
    2262: 'Perdagangan valuta asing - transaksi spot antar valuta asing ',
    2263: 'Perdagangan valuta asing - transaksi forward valuta asing terhadap rupiah',
    2264: 'Perdagangan valuta asing - transaksi forward antar valuta asing',
  },
  unrequitted: {
    2271: 'Keuntungan atas transaksi forward atau sejenisnya (seperti swaps dan futures)',
    2272: 'Penerimaan untuk forward rate agreement (FRA)',
    2273: 'Penerimaan dalam transaksi interest swap atau currency swap',
    2274: 'Penerimaan untuk initial/repayable margin seperti initial margin dalam transaksi repo surat berharga,securities lending ',
    2275: 'Penerimaan untuk variation /nonrepayable margin',
    2276: 'Penerimaan premium dalam transaksi option',
    2277: 'Penerimaan pokok (principal) dalam transaksi currency swap di awal transaksi (first leg)',
    2278: 'Penerimaan pokok (principal) dalam transaksi currency swap pada saat transaksi berakhir(final leg)',
    2279: 'Penerimaan premi dalam transaksi credit default swap',
    2280: 'Setoran/pengembalian jaminan',
    2282: 'Pengembalian dana untuk transaksi selain ekspor/impor barang dan transaksi barang lainnya ',
    2311: 'Saham',
    2321: 'Obligasi dengan jangka waktu lebih dari 1 tahun',
    2322: 'Medium Term Notes (MTNs) lebih dari 1 tahun',
    2323: 'Surat berharga jangka panjang lainnya',
    2331: 'Treasury Bills dengan jangka waktu s.d 1 tahun',
    2332: 'Promissory Notes (PNs/Promes)',
    2333: 'Surat utang jangka pendek lainnya',
    2341: 'Efek ekuitas - saham',
    2342: 'Efek ekuitas lainnya, seperti warrant dan right',
    2351: 'ORI, dengan jangka waktu lebih dari 1 tahun',
    2352: 'Obligasi perusahaan dengan jangka waktu lebih dari 1 tahun',
    2353: 'Medium Term Notes (MTNs) lebih dari 1 tahun',
    2354: 'Surat utang jangka panjang lainnya',
    2361: 'Sertifikat Bank Indonesia (SBI) dan Sertifikat Wadiah Bank Indonesia (SWBI)',
    2362: 'Surat Perbendaharaan Negara (SPN)',
    2363: 'Promissory Notes (PNs/Promes)',
    2364: 'Negotiable Certificate of Deposit (NCD)',
    2365: 'Surat utang jangka pendek lainnya.',
    2366: 'Sertifikat deposito Bank Indonesia',
    2371: 'Reksadana',
    2372: 'Reksadana yang diperdagangkan (exchange traded fund)',
    2375: 'Penjualan surat berharga secara repo oleh pemilik surat berharga dengan jangka waktu s.d 1 tahun yang diterbitkan Penduduk ',
    2376: 'Penjualan surat berharga secara repo oleh pemilik surat berharga dengan jangka waktu lebih dari 1 tahun yang diterbitkan Penduduk',
    2377: 'Penjualan kembali surat berharga secara repo oleh pembeli surat berharga dengan jangka waktu s.d 1 tahun yang diterbitkan Penduduk ',
    2378: 'Penjualan kembali surat berharga secara repo oleh pembeli surat berharga dengan jangka waktu lebih dari 1 tahun yang diterbitkan Penduduk ',
    2379: 'Pembelian kembali oleh pemilik surat berharga yang telah dijual secara repo dengan jangka waktu s.d 1 tahun yang diterbitkan Penduduk ',
    2380: 'Pembelian kembali oleh pemilik surat berharga yang telah dijual secara repo dengan jangka waktu lebih dari 1 tahun yang diterbitkan Penduduk ',
    2381: 'Pembelian surat berharga yang dijual secara repo dengan jangka waktu s.d 1 tahun yang diterbitkan Penduduk ',
    2382: 'Pembelian surat berharga yang dijual secara repo dengan jangka waktu lebih dari 1 tahun yang diterbitkan Penduduk ',
    2821: 'Penjualan surat berharga secara repo oleh pemilik surat berharga dengan jangka waktu s.d 1 tahun yang diterbitkan bukan Penduduk ',
    2822: 'Penjualan surat berharga secara repo oleh pemilik surat berharga dengan jangka waktu lebih dari 1 tahun yang diterbitkan bukan Penduduk ',
    2823: 'Penjualan kembali surat berharga secara repo oleh pembeli surat berharga dengan jangka waktu s.d 1 tahun yang diterbitkan bukan Penduduk ',
    2824: 'Penjualan kembali surat berharga secara repo oleh pembeli surat berharga dengan jangka waktu lebih dari 1 tahun yang diterbitkan bukan Penduduk ',
    2825: 'Pembelian kembali oleh pemilik surat berharga yang telah dijual secara repo dengan jangka waktu s.d 1 tahun yang diterbitkan bukan Penduduk ',
    2826: 'Pembelian kembali oleh pemilik surat berharga yang telah dijual secara repo dengan jangka waktu lebih dari 1 tahun yang diterbitkan bukan Penduduk ',
    2827: 'Pembelian surat berharga yang dijual secara repo dengan jangka waktu s.d 1 tahun yang diterbitkan bukan Penduduk ',
    2828: 'Pembelian surat berharga yang dijual secara repo dengan jangka waktu lebih dari 1 tahun yang diterbitkan bukan Penduduk ',
  },
};
