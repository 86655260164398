import { PBB_NAME } from 'settings/constants/common';
import { get } from 'utils/lodash';

export const formatOtherBillAddFavouriteResultPayload = payload => {
  const tacValue = get(payload, 'tacValue', '');
  const isFromTransferResult = get(payload, 'confirmationDetail.isFromTransferResult', false);

  return {
    isFromTransferResult,
    ...(!isFromTransferResult ? { tacValue } : {}),
    userId: get(payload, 'userId', ''),
    tacValue: get(payload, 'tacValue', ''),
    restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', {}),
    nickname: get(payload, 'confirmationDetail.nickname', ''),
    accountNo: get(payload, 'confirmationDetail.accountNo', ''),
    payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
    billerId: get(payload, 'confirmationDetail.billerId', ''),
  };
};

export const formatOtherBillEditFavouritePayload = payload => ({
  userId: get(payload, 'userId', ''),
  nickname: get(payload, 'nickname', ''),
  accountNo: get(payload, 'accountNo', ''),
  payeeCode: get(payload, 'payeeCode', ''),
  payeeName: get(payload, 'payeeName', ''),
  billerId: get(payload, 'billerId', ''),
});

export const formatOtherBillEditFavouriteResultPayload = payload => ({
  userId: get(payload, 'userId', ''),
  tacValue: get(payload, 'tacValue', ''),
  restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', ''),
  nickname: get(payload, 'confirmationDetail.nickname', ''),
  payeeCode: get(payload, 'confirmationDetail.payeeCode', ''),
  accountNo: get(payload, 'confirmationDetail.accountNo', ''),
  billerId: get(payload, 'confirmationDetail.billerId', ''),
});

export const hidePayeePBB = array => {
  // hide temporary other bill pbb
  return array
    .filter(item => {
      return item.categoryName !== PBB_NAME;
    })
    .map(item => item.fullName || item.value || item.categoryName);
};
