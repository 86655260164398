import { all } from 'redux-saga/effects';

import Login from './login';
import Payment from './payment';
import Purchase from './purchase';
import Transfer from './transfer';
import Dashboard from './dashboard';
import SelfUnlock from './self-unlock';
import Registration from './registration';
import TransferSKN from './transfer/skn';
import TransferRTGS from './transfer/rtgs';
import TransferSWIFT from './transfer/swift';
import DigitalWealth from './digital-wealth';
import PurchaseTicket from './purchase/ticket';
import PaymentUtility from './payment/utility';
import TransferEWallet from './transfer/e-wallet';
import SettingsSecurity from './settings/security';
import SettingsPersonal from './settings/personal';
import SettingsManageTransaction from './settings/manage-transaction';
import TranferIntrabank from './transfer/intrabank';
import PaymentOtherBill from './payment/other-bill';
import TransferInterbank from './transfer/interbank';
import TransferFavourite from './favourite/transfer';
import PaymentCreditCard from './payment/credit-card';
import PurchasePlnReload from './purchase/pln-reload';
import PaymentMobilePostpaid from './payment/mobile-postpaid';
import TransferVirtualAccount from './transfer/virtual-account';
import PurchaseMobilePrepaid from './purchase/mobile-prepaid';
import IntrabankFavourite from './favourite/transfer/intrabank';
import VirtualAccountFavourite from './favourite/transfer/virtual-account';
import SknFavourite from './favourite/transfer/skn';
import RtgsFavourite from './favourite/transfer/rtgs';
import EWalletFavourite from './favourite/transfer/e-wallet';
import InterbankFavourite from './favourite/transfer/interbank';
import SwiftFavourite from './favourite/transfer/swift';
import PaymentFavourite from './favourite/payment';
import PurchaseFavourite from './favourite/purchase';
import MobilePrepaidFavourite from './favourite/purchase/mobile-prepaid';
import PlnFavourite from './favourite/purchase/pln';
import CreditCardFavourite from './favourite/payment/credit-card';
import MobilePostpaidFavourite from './favourite/payment/mobile-postpaid';
import OtherBillFavourite from './favourite/payment/other-bill';
import UtilityFavourite from './favourite/payment/utility';
import Coachmark from './coachmark';
import Inboxs from './inboxs/inbox';
import Sent from './inboxs/sent';
import Compose from './inboxs/compose';

export default function* rootSaga() {
  yield all([
    Login(),
    Dashboard(),
    Registration(),
    SelfUnlock(),
    DigitalWealth(),
    Transfer(),
    TransferSKN(),
    TransferRTGS(),
    TransferSWIFT(),
    TranferIntrabank(),
    TransferInterbank(),
    TransferVirtualAccount(),
    TransferEWallet(),
    TransferFavourite(),
    Payment(),
    PaymentUtility(),
    PaymentOtherBill(),
    PaymentCreditCard(),
    PaymentMobilePostpaid(),
    Purchase(),
    PurchaseMobilePrepaid(),
    PurchaseTicket(),
    PurchasePlnReload(),
    SettingsSecurity(),
    SettingsPersonal(),
    SettingsManageTransaction(),
    IntrabankFavourite(),
    VirtualAccountFavourite(),
    SknFavourite(),
    RtgsFavourite(),
    EWalletFavourite(),
    InterbankFavourite(),
    SwiftFavourite(),
    PaymentFavourite(),
    PurchaseFavourite(),
    MobilePrepaidFavourite(),
    PlnFavourite(),
    CreditCardFavourite(),
    MobilePostpaidFavourite(),
    OtherBillFavourite(),
    UtilityFavourite(),
    Coachmark(),
    Inboxs(),
    Sent(),
    Compose(),
  ]);
}
