import PropTypes from 'prop-types';
import { Grid, Card as MaterialCard, Typography } from '@material-ui/core';

import './style.scss';

const ColourIconCard = ({ className, colour, title, caption, percentage, hasPercentage, icon }) => (
  <MaterialCard className={`card card--colour-icon ${className}`}>
    <div className="w-100 content--container">
      <Grid item xs={2} className="circle" style={{ backgroundColor: colour }} />

      <Grid item xs={10} className="pl2">
        <Typography variant="h5" className="text">
          {title}
        </Typography>
        <Typography variant="caption" className="caption">
          {caption}
        </Typography>
      </Grid>
      <div>{icon}</div>
    </div>

    {hasPercentage && <Typography className="text">{`${percentage}%`}</Typography>}
  </MaterialCard>
);

ColourIconCard.defaultProps = { colour: '', caption: '', title: '', className: '', percentage: 0, hasPercentage: true, icon: '' };

ColourIconCard.propTypes = {
  colour: PropTypes.string,
  title: PropTypes.node,
  caption: PropTypes.node,
  className: PropTypes.string,
  percentage: PropTypes.number,
  hasPercentage: PropTypes.bool,
  icon: PropTypes.node,
};

export default ColourIconCard;
