import {
  FORMAT_NUMBER,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_SELECT,
  FORMAT_FAVOURITE_NICKNAME,
  FAVOURITE_NICKNAME_MAX_LENGTH,
} from 'settings/constants/transaction';

export const UTILITY_FORM_FIELDS = [
  {
    name: 'customerNumber',
    label: 'transaction.payment.modal.customerNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    thousandSeparator: false,
  },
];

export const UTILITY_NEW_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    name: 'billerType',
    label: 'transaction.payment.modal.biller',
    placeholder: 'transaction.payment.placeHolder.pleaseSelect',
    type: FIELD_TYPE_SELECT,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'customerNumber',
    label: 'transaction.payment.modal.customerNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    thousandSeparator: false,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const UTILITY_EDIT_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'payeeName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.biller',
  },
  {
    readOnly: true,
    type: FIELD_TYPE_TEXT,
    name: 'customerNumber',
    label: 'transaction.payment.modal.customerNumber',
  },
];
