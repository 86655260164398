import { COUNTRIES } from 'settings/constants/country';

import { GOODS } from './goods';
import { MODALS } from './modal';
import { SERVICES } from './service';
import { PRODUCTIONS } from './production';
import { UNREQUITTEDS } from './unrequitted';

export const SWIFT_CODE_MAX_LENGTH = 11;
export const SWIFT_MESSAGE_INPUT_MAX_CHARS = 120;

export const SWIFT_MODAL_TITLE_EN = 'Transfer to SWIFT Foreign Currency Transfer';
export const SWIFT_MODAL_TITLE_ID = 'Transfer ke Transfer Valuta Asing SWIFT';

export const SWIFT_TABLE_HEADERS = formatMessage => [
  { field: 'swiftCode', headerName: formatMessage({ id: 'transaction.transfer.swift.table.swiftCode' }) },
  { field: 'bankName', headerName: formatMessage({ id: 'transaction.transfer.swift.table.bankName' }) },
  { field: 'firstAddress', headerName: formatMessage({ id: 'transaction.transfer.swift.table.firstAddress' }) },
  { field: 'secondAddress', headerName: formatMessage({ id: 'transaction.transfer.swift.table.secondAddress' }) },
  { field: 'thirdAddress', headerName: formatMessage({ id: 'transaction.transfer.swift.table.thirdAddress' }) },
];

export const DEFAULT_LLD_CATEGORY = 'default';
export const LLD_DESCRIPTION_MAP = new Map([
  ['1', 'productions'],
  ['2', 'goods'],
  ['3', 'services'],
  ['4', 'modals'],
  ['5', 'unrequitteds'],
  [DEFAULT_LLD_CATEGORY, ''],
]);

// this LLD info will be used instead of fetching from API, to conserve bandwidth
export const LLD_INFO = formatMessage => ({
  countries: COUNTRIES(formatMessage),
  description: {
    productions: PRODUCTIONS(formatMessage),
    goods: GOODS(formatMessage),
    services: SERVICES(formatMessage),
    modals: MODALS(formatMessage),
    unrequitteds: UNREQUITTEDS(formatMessage),
  },
  identityStatuses: [
    {
      key: 'N',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.identityStatus.no' }),
    },
    {
      key: 'Y',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.identityStatus.yes' }),
    },
  ],
  beneficiaries: [
    {
      key: 'A0',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.beneficiary.individual' }),
    },
    {
      key: 'D0',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.beneficiary.financial' }),
    },
    {
      key: 'E0',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.beneficiary.company' }),
    },
  ],
  relationships: [
    {
      key: 'N',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.relationship.nonAffiliate' }),
    },
    {
      key: 'P',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.relationship.shareHolders' }),
    },
    {
      key: 'T',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.relationship.subsidiary' }),
    },
    {
      key: 'G',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.relationship.group' }),
    },
  ],
  purposes: [
    {
      key: '1',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.purpose.production' }),
    },
    {
      key: '2',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.purpose.goods' }),
    },
    {
      key: '3',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.purpose.services' }),
    },
    {
      key: '4',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.purpose.modal' }),
    },
    {
      key: '5',
      label: formatMessage({ id: 'transaction.transfer.swift.lld.purpose.unrequitted' }),
    },
  ],
});
