export const actions = {
  GET: {
    INBOX: {
      INBOXS_INBOX_LIST: 'INBOXS_INBOX_GET_INBOXS_INBOX_LIST',
      INBOXS_SENT_LIST: 'INBOXS_SENT_GET_INBOXS_SENT_LIST',
      INBOXS_DELETE_INBOX: 'INBOXS_DELETE_INBOX_GET_DELETE_INBOX',
      INBOXS_DELETE_SENT: 'INBOXS_DELETE_SENT_GET_DELETE_SENT',
      INBOXS_COMPOSE_ACCESS: 'INBOXS_COMPOSE_ACCESS_GET_COMPOSE_ACCESS',
      INBOXS_COMPOSE_SEND: 'INBOXS_COMPOSE_SEND_GET_COMPOSE_SEND',
      INBOXS_UPDATE_READ_INBOX: 'INBOXS_UPDATE_READ_INBOX_GET_UPDATE_READ_INBOX',
      INBOXS_REPLY: 'INBOXS_REPLY_GET_REPLY',
    },
  },
  PUT: {
    INBOX: {
      INBOXS_INBOX_LIST: 'INBOXS_INBOX_PUT_INBOXS_INBOX_LIST',
      INBOXS_SENT_LIST: 'INBOXS_SENT_PUT_INBOXS_SENT_LIST',
      INBOXS_DELETE_INBOX: 'INBOXS_DELETE_INBOX_PUT_DELETE_INBOX',
      INBOXS_DELETE_SENT: 'INBOXS_DELETE_SENT_PUT_DELETE_SENT',
      INBOXS_COMPOSE_ACCESS: 'INBOXS_COMPOSE_ACCESS_PUT_COMPOSE_ACCESS',
      INBOXS_COMPOSE_SEND: 'INBOXS_COMPOSE_SEND_PUT_COMPOSE_SEND',
      INBOXS_UPDATE_READ_INBOX: 'INBOXS_UPDATE_READ_INBOX_PUT_UPDATE_READ_INBOX',
      INBOXS_REPLY: 'INBOXS_REPLY_PUT_REPLY',
    },
  },
};

// inboxs
export const getInboxList = payload => ({ type: actions.GET.INBOX.INBOXS_INBOX_LIST, payload });
export const getSentList = payload => ({ type: actions.GET.INBOX.INBOXS_SENT_LIST, payload });
export const getDeleteInbox = payload => ({ type: actions.GET.INBOX.INBOXS_DELETE_INBOX, payload });
export const getDeleteSent = payload => ({ type: actions.GET.INBOX.INBOXS_DELETE_SENT, payload });
export const getComposeAccess = payload => ({ type: actions.GET.INBOX.INBOXS_COMPOSE_ACCESS, payload });
export const getComposeSend = payload => ({ type: actions.GET.INBOX.INBOXS_COMPOSE_SEND, payload });
export const getUpdateReadInbox = payload => ({ type: actions.GET.INBOX.INBOXS_UPDATE_READ_INBOX, payload });
export const getReplyInbox = payload => ({ type: actions.GET.INBOX.INBOXS_REPLY, payload });

export const putInboxList = res => ({ type: actions.PUT.INBOX.INBOXS_INBOX_LIST, res });
export const putSentList = res => ({ type: actions.PUT.INBOX.INBOXS_SENT_LIST, res });
export const putDeleteInbox = res => ({ type: actions.PUT.INBOX.INBOXS_DELETE_INBOX, res });
export const putDeleteSent = res => ({ type: actions.PUT.INBOX.INBOXS_DELETE_SENT, res });
export const putComposeAccess = res => ({ type: actions.PUT.INBOX.INBOXS_COMPOSE_ACCESS, res });
export const putComposeSend = res => ({ type: actions.PUT.INBOX.INBOXS_COMPOSE_SEND, res });
export const putUpdateReadInbox = res => ({ type: actions.PUT.INBOX.INBOXS_UPDATE_READ_INBOX, res });
export const putReplyInbox = res => ({ type: actions.PUT.INBOX.INBOXS_REPLY, res });
