import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { BasicButton } from 'components/common/buttons';

const TransactionSection = ({ buttonTitle, description, onClick }) => {
  return (
    <Grid container sm={12} md={12} item>
      <Grid item xs={12} sm={12} md={12} container justify="center" alignItems="center">
        <Typography className="content--description mr4">{description}</Typography>

        <BasicButton size="large" className="ph4" onClick={onClick}>
          {buttonTitle}
        </BasicButton>
      </Grid>
    </Grid>
  );
};

TransactionSection.defaultProps = {
  buttonTitle: '',
  description: '',
  onClick: null,
};

TransactionSection.propTypes = {
  buttonTitle: PropTypes.node,
  description: PropTypes.node,
  onClick: PropTypes.func,
};

export default TransactionSection;
