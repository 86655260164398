import { JSEncrypt } from 'jsencrypt';
import { SHA256 } from 'crypto-js';

// encryption using public key
// returns ciphertext in base64
export const encrypt = data => {
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(publicKey);
  return jsencrypt.encrypt(data);
};

// generate hash 256
export const generateSHA256Hash = input => {
  const hash = SHA256(input);
  return hash.toString(); // Mengonversi WordArray ke string hex
};
