import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { Autocomplete, TextLabel } from 'components/common';

import { validate } from 'utils/form-validation';

const DynoAutocomplete = ({
  label,
  options,
  value,
  placeholder,
  name,
  onChange,
  variant,
  hasIcon,
  isMultiline,
  getValue,
  validationRules,
  noOptionsText,
  isTextCapitalized,
}) => {
  const { formatMessage } = useIntl();

  const validField = getValue('validField', {});

  // TODO: using useMemo
  // change due to programming. ie: resetting the form using hook
  const handleInputChange = () => {
    const event = { target: { name, value } };
    const { isValid } = validate(validationRules, event);
    onChange({ validField: { ...validField, [name]: isValid } });
  };

  // change due to user selection
  const handleChange = selected => {
    const event = { target: { name, value: selected } };
    const { isValid } = validate(validationRules, event);
    onChange({ [name]: selected, validField: { ...validField, [name]: isValid } });
  };

  return (
    <Grid container alignItems="center" className="pb0 pt3 ph4">
      <Grid item xs={12} sm={4} className="pb2">
        {label && <TextLabel label={formatMessage({ id: label })} className="pl1-ns" />}
      </Grid>
      <Grid item xs={12} sm={8}>
        <Autocomplete
          value={value}
          noOptionsText={formatMessage({ id: noOptionsText })}
          placeholder={placeholder && formatMessage({ id: placeholder })}
          variant={variant}
          hasIcon={hasIcon}
          options={options}
          isMultiline={isMultiline}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isTextCapitalized={isTextCapitalized}
        />
      </Grid>
    </Grid>
  );
};

DynoAutocomplete.defaultProps = {
  options: [],
  value: '',
  placeholder: '',
  noOptionsText: 'transaction.transfer.noOptionFound',
  hasIcon: false,
  isMultiline: true,
  variant: 'dropdown',
  validationRules: [],
  isTextCapitalized: false,
};

DynoAutocomplete.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  variant: PropTypes.string,
  hasIcon: PropTypes.bool,
  isMultiline: PropTypes.bool,
  noOptionsText: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isTextCapitalized: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  validationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

export default DynoAutocomplete;
