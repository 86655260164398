import axios from 'axios';
import { useState, useCallback, useMemo, useEffect } from 'react';

import { get } from 'utils/lodash';

const remoteIpInstance = axios.create({ baseURL: process.env.REACT_APP_IPIFY_URL });

export const useRemoteIpInterceptor = () => {
  const [counter, setCounter] = useState(0);

  const increment = useCallback(() => setCounter(count => count + 1), [setCounter]);
  const decrement = useCallback(() => setCounter(count => count - 1), [setCounter]);

  const interceptors = useMemo(
    () => ({
      request: config => {
        increment();
        return { ...config };
      },
      response: response => {
        decrement();

        // NOTE: return destructed data key from response
        return get(response, 'data', {});
      },
      error: () => {
        decrement();

        // NOTE: return empty to avoid error in saga
        return ``;
      },
    }),
    [increment, decrement]
  );

  useEffect(() => {
    remoteIpInstance.interceptors.request.use(interceptors.request, interceptors.error);
    remoteIpInstance.interceptors.response.use(interceptors.response, interceptors.error);

    return () => {
      // INFO: Remove all intercepts when done
      remoteIpInstance.interceptors.request.eject(interceptors.request);
      remoteIpInstance.interceptors.request.eject(interceptors.error);
      remoteIpInstance.interceptors.response.eject(interceptors.response);
      remoteIpInstance.interceptors.response.eject(interceptors.error);
    };
  }, [interceptors]);

  return [counter > 0];
};

export default remoteIpInstance;
