export const INBOXS_TAB_VALUE = {
  INBOX: 0,
  SENT: 1,
};

export const INBOX_TAB_VALUE = 'inbox';
export const SENT_TAB_VALUE = 'sent';
export const ADD_COMPOSE = '1';
export const REPLY_INBOX = '2';
export const NEW_INBOX_LIST_CONTENT = '0';
export const READ_INBOX_LIST_CONTENT = '1';
export const ARCHIVE_INBOX_LIST_CONTENT = '2';

export const DEFAULT_INBOXS_SUBMENU_TAB_VALUE = 0;
