import { useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { validate, validResult } from 'utils/form-validation';

import { Select, TextLabel } from 'components/common';

import { SELECT_PLACEHOLDER_DEFAULT_VALUE } from 'settings/constants/common';
import { GRID_SIZE_HORIZONTAL, GRID_SIZE_VERTICAL, GRID_VARIANT_DEFAULT } from 'settings/constants/ui-control';

const DynoSelect = ({ label, options, value, name, variant, onChange, getValue, bottomLabel, ...props }) => {
  const { formatMessage } = useIntl();

  const [validationResult, setValidationResult] = useState(validResult);

  const className = get(props, 'className', '');
  const validationRules = get(props, 'validationRules', []);
  const placeholderId = get(props, 'placeholder', '');
  const placeholder = placeholderId
    ? formatMessage({ id: placeholderId })
    : formatMessage({ id: 'transaction.payment.placeHolder.pleaseSelect' });
  const isThinPadding = get(props, 'isThinPadding', false);

  const validField = getValue('validField', {});

  const handleOnChange = event => {
    const newValue = event.target.value;
    const { isValid, messageId, param } = validate(validationRules, event);
    setValidationResult({ isValid, messageId, param });
    onChange({ validField: { ...validField, [name]: isValid }, [name]: newValue });
  };

  const handleOnClose = e => {
    // no selection made, component will return undefined, selection made component will return 0
    if (isUndefined(e.target.value) && !value) {
      const event = { target: { value, name } };

      const { isValid, messageId, param } = validate(validationRules, event);
      setValidationResult({ isValid, messageId, param });
    }
  };

  const { isValid, messageId, param } = validationResult;
  const errorMessage = !isValid && formatMessage({ id: messageId, defaultMessage: messageId }, param);

  const gridSize = variant === GRID_VARIANT_DEFAULT ? GRID_SIZE_VERTICAL : GRID_SIZE_HORIZONTAL;

  return (
    <Grid container alignItems={!isValid ? `baseline` : `center`} className={`pb0 pt3 ${isThinPadding ? 'ph3' : 'ph4'} ${className}`}>
      <Grid item xs={gridSize.label.xs} sm={gridSize.label.sm} className="pb2">
        {label && <TextLabel label={formatMessage({ id: label })} className="pl1-ns" />}
      </Grid>
      <Grid item xs={gridSize.input.xs} sm={gridSize.input.sm}>
        <Select
          fullWidth
          options={options}
          value={value || SELECT_PLACEHOLDER_DEFAULT_VALUE}
          onClose={handleOnClose}
          onChange={handleOnChange}
          placeholder={placeholder}
          hasError={!isValid}
          errorMessage={errorMessage}
        />
        {!isEmpty(bottomLabel) && <TextLabel label={formatMessage({ id: bottomLabel })} className="pt2" />}
      </Grid>
    </Grid>
  );
};

DynoSelect.defaultProps = { options: [], value: '', bottomLabel: '', variant: GRID_VARIANT_DEFAULT };

DynoSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  variant: PropTypes.string,
  bottomLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default DynoSelect;
