import { makeStyles } from '@material-ui/core/styles';

const APP_GOLD = '#FFC83D';

export const usePanelCardMenuStyle = makeStyles({
  root: {
    '& .MuiMenu-paper': {
      borderRadius: '7px',
      minWidth: '150px',
      boxShadow: '0 0 1rem 0 rgb(0 0 0 / 30%)',
    },

    '& .MuiMenu-list': {
      borderRadius: '7px',
      padding: '0.5rem 0.25rem',
      background: '#373737',

      '& .MuiMenuItem-root': {
        fontSize: '13px',
        color: '#fff',
      },

      '& .MuiMenuItem-root:hover': {
        color: APP_GOLD,
      },
    },
  },
});
