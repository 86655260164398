import invert from 'lodash/invert';

import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { putErrorResponse } from 'middleware/actions/error';
import {
  actions,
  putIntrabankConfirmation,
  putIntrabankResult,
  putIntrabankTac,
  putIntrabankDetail,
} from 'middleware/actions/favourite/transfer';

import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';

import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatKeys } from 'utils/formatter';
import { FAVOURITE_KEYMAP } from 'settings/constants/keymap';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.INTRABANK;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.INTRABANK;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const deleteIntrabankFavourite = ({ body }) => Instance.post(endpoint.intrabank.favourite.delete, body);
const intrabankResultFavourite = ({ body }) => Instance.post(endpoint.intrabank.favourite.result, body);
const deleteIntrabankResult = ({ body }) => Instance.post(endpoint.intrabank.favourite.deleteResult, body);
const addIntrabankFormDetail = ({ body }) => Instance.post(endpoint.intrabank.favourite.addNewDetail, body);
const requestDeleteIntrabankTac = ({ body }) => Instance.post(endpoint.intrabank.favourite.deleteRequestTac, body);
const intrabankConfirmationFavourite = ({ body }) => Instance.post(endpoint.intrabank.favourite.confirmation, body);
const intrabankAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.intrabank.favourite.addNewRequestTac, body);
const addInterbankFormDetailFromTransfer = ({ body }) => Instance.post(endpoint.intrabank.favourite.addNewDetailFromTransfer, body);
const intrabankEditFormDetail = ({ body }) => Instance.post(endpoint.intrabank.favourite.editDetail, body);
const intrabankEditConfirmDetail = ({ body }) => Instance.post(endpoint.intrabank.favourite.editConfirm, body);
const intrabankEditTac = ({ body }) => Instance.post(endpoint.intrabank.favourite.editRequestTac, body);
const intrabankEditResult = ({ body }) => Instance.post(endpoint.intrabank.favourite.editResult, body);

function* addIntrabankFormDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(addIntrabankFormDetail, { body });
    yield put(putIntrabankDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* addIntrabankFormDetailFromTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(addInterbankFormDetailFromTransfer, { body });
    const formattedData = formatKeys(data, FAVOURITE_KEYMAP);
    yield put(putIntrabankDetail(formatTransferConfirmation(formattedData)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(intrabankConfirmationFavourite, { body });
    yield put(putIntrabankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(intrabankAddNewRequestTacFavourite, { body });
    yield put(putIntrabankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(intrabankResultFavourite, { body });
    yield put(putIntrabankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteIntrabankFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteIntrabankFavourite, { body });
    yield put(putIntrabankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteIntrabankTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteIntrabankTac, { body });
    yield put(putIntrabankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteIntrabankResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteIntrabankResult, { body });
    yield put(putIntrabankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(intrabankEditFormDetail, { body });
    const formattedData = formatKeys(data, FAVOURITE_KEYMAP);
    yield put(putIntrabankDetail(formatTransferConfirmation({ ...formattedData, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { ...payload, locale };
    const body = formatKeys(initialBody, invert(FAVOURITE_KEYMAP));
    const { data } = yield call(intrabankEditConfirmDetail, { body });
    yield put(putIntrabankConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(intrabankEditTac, { body });
    yield put(putIntrabankTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* intrabankEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(intrabankEditResult, { body });
    yield put(putIntrabankResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* IntrabankFavourite() {
  yield all([
    takeLatest(DETAIL, addIntrabankFormDetailSaga),
    takeLatest(RESULT, intrabankResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteIntrabankResultSaga),
    takeLatest(REQUEST_TAC, intrabankAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, intrabankConfirmationFavouriteSaga),
    takeLatest(DELETE_CONFIRMATION, deleteIntrabankFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteIntrabankTacSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, addIntrabankFormDetailFromTransferSaga),
    takeLatest(EDIT_DETAILS, intrabankEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, intrabankEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, intrabankEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, intrabankEditResultFavouriteSaga),
  ]);
}
