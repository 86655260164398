export const selfUnlock = {
  accountNumber: 'Account Number',
  creditCard: 'Credit Card Number',
  modal: {
    title: 'Reset/Unlock Login Details',
    footerButton: 'Continue',
    backButton: 'Back',
    header: {
      title: 'Having problem Logging in?',
      subtitle: 'Let Us Help You!',
      description: 'Reset/Unlock login details with account number/credit card number you used to sign up for Maybank2u',
      activityDescription: 'Please select one of the activities you are going to do',
      resetPasswordDescription: 'Please input your new password',
    },
    container: {
      resetUsing: 'Reset/Unlock Using',
      account: {
        accountNumberTooltip: 'Account Number is your Maybank Account Number',
        accountNumberPlaceholder: 'Ex.1122334455',
        pin: 'PIN',
        pinTooltip: 'PIN is your Debit Card PIN',
        pinPlaceholder: 'Enter 6-digit PIN Number',
      },
      cc: {
        ccNumber: 'Credit Card Number',
        ccNumberTooltip: 'Credit Card Number is your Maybank Credit Card Number',
        ccNumberPlaceholder: 'Ex: 1223123123123213',
        pin: 'PIN',
        pinTooltip: 'PIN is your Credit Card PIN',
        pinPlaceholder: 'Enter 6 digit PIN',
        ccExpiry: 'Credit Card Expiry',
        mm: 'MM',
        yy: 'YY',
      },
      resetPassword: {
        password: 'Password',
        confirmPassword: 'Confirmation Password',
        passwordPlaceholder: 'Please input your new password',
        confirmPasswordPlaceholder: 'Re-Enter your new Password',
        confirmPasswordTooltip: 'Must be the same as Password',
        passwordTooltip: 'Must be contain Alpha Numeric. Maximum length between 8 to 20 characters, Lower Uppercase Letter',
      },
    },
    unlock: {
      status: 'Status',
      action: 'Action',
      normal: 'Normal and Enabled',
      locked: 'Locked',
      disabled: 'Disabled',
      disabledWording: 'Please call {contactNo} or visit the nearest Maybank branch',
      unlockUserIdOrTac: 'Unlock User ID/TAC',
      forgotUserId: 'Forgot User ID',
      resetPassword: 'Reset Password',
    },
  },
  errorMessage: {
    accountLength: 'Input Account Number must be 10 digit',
    ccLength: 'Input Credit Card Number must be 16 digit',
    ccMmLength: 'Input Credit Card Month of expiration must be 2 digit',
    ccYyLength: 'Input Credit Card Year of expiration must be 2 digit',
    pinLength: 'Input PIN must be 6 digit',
    currency: 'Please select a currency',
    noPassword: 'Please enter your new password',
    passwordNotMatch: 'Password and Confirmation Password not match',
    invalidPassword: 'Must contain Alpha Numeric. Maximum length between 8 to 20 characters. Lower Uppercase Letter',
    tacInvalid: 'Invalid TAC',
    tacExceedMaxTries:
      'You have reached maximum TAC attempts. For your security, your M2U ID user has been locked. You can unlock through "Having Problem Logging In?".',
    tacExpired: 'TAC Expired',
    tacNotGenerate: 'TAC Not Generate',
    tacExceedMaxUse: 'TAC Exceed Max Use',
  },
  resetPassword: {
    tacTitle: 'Please confirm your instruction details.',
    tacDescription: 'To proceed with this instruction, please request and insert your <b>TAC</b> number.',
    transaction: {
      successful: 'Your request is successful!',
      failed: 'Your request is failed!',
    },
  },
};
