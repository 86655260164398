// Note: Remember to research on Deep linking routing

export const routes = {
  home: '/home',
  login: '/login',
  selfUnlock: '/login/self-unlock',
  logout: '/logout',
  sessionExpired: '/expired',
  digitalWealth: {
    path: '/wealth',
    goalDetail: '/wealth/detail',
    financialGoal: '/wealth/financial-goal',
  },
  dashboard: {
    // Under "My Accounts" tab
    path: '/dashboard',
    default: '/dashboard/:accountType',
    details: '/dashboard/:accountType/details',
    apply: '/dashboard/:accountType/apply',
    summary: '/dashboard/:accountType/summary',
    casa: '/dashboard/casa',
    casaDetails: '/dashboard/casa/details',
    loan: '/dashboard/loan',
    loanDetails: '/dashboard/loan/details',
    wealth: '/dashboard/wealth',
    wealthSummary: '/dashboard/wealth/summary',
    wealthDetails: '/dashboard/wealth/details',
    creditCard: '/dashboard/cc',
    creditCardDetails: '/dashboard/cc/details',
    timeDeposit: '/dashboard/td',
    timeDepositDetails: '/dashboard/td/details',
    timeDepositApply: '/dashboard/td/apply',
    overview: '/dashboard/overview',
    transaction: '/dashboard/transactions',
  },
  transaction: {
    path: '/transactions',
    default: '/transactions/:transactionType',
    transfer: '/transactions/transfer',
    payment: '/transactions/payment',
    purchase: '/transactions/purchase',
  },
  registration: {
    default: '/registration',
  },
  settings: {
    path: '/settings',
    default: '/settings/:settingType',
    personal: '/settings/personal',
    security: '/settings/security',
    manageTransaction: '/settings/manage-transaction',
  },
  inboxs: {
    path: '/inboxs',
    default: '/inboxs/:settingType',
    sent: '/inboxs/sent',
    inbox: '/inboxs/inbox',
  },
};
