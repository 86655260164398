import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { getExtendSession, getLogoutExpiredSession, getLogoutTransactionSummary } from 'middleware/actions/login';
import { IdleTimer } from 'utils/idle-timer';
import ExpiredSessionModal from 'components/common/modals/ExpiredSessionModal';
import { routes } from './routes';

const { login } = routes;

// Render component when user autheticated, else go to login page
const PrivateRoute = ({ component: Component, coachmarkComponent, handleTutorial, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthhenticatedState = useRef(false);

  const { isAuthenticated, isRenewPassword } = useSelector(
    state => ({ isAuthenticated: state.AuthReducer.isAuthenticated, isRenewPassword: state.AuthReducer.isRenewPassword }),
    shallowEqual
  );

  const [isIdle, setIdle] = useState(false);
  const [idleOnModal, setIdleOnModal] = useState(false);

  const onSubmit = () => {
    setIdleOnModal(true);
    setIdle(false);
    dispatch(getExtendSession(history));
  };

  const onCancel = () => {
    isAuthhenticatedState.current = true;
    if (isAuthenticated) {
      dispatch(getLogoutTransactionSummary());
      setTimeout(() => {
        dispatch(getLogoutExpiredSession(history));
      }, 100);
    }
  };

  const onIdle = () => {
    setIdleOnModal(true);
    setIdle(true);
  };

  const completedCountDownModal = () => {
    if (idleOnModal && !isAuthhenticatedState.current) {
      dispatch(getLogoutTransactionSummary());
      setTimeout(() => {
        dispatch(getLogoutExpiredSession(history));
      }, 100);
    }
  };

  // to know user idle or not use, IdleTimer
  useEffect(() => {}, [IdleTimer(onIdle)]);

  const expiredSessionModal = (
    <ExpiredSessionModal idle={isIdle} onCancel={onCancel} onSubmit={onSubmit} isCompleted={completedCountDownModal} />
  );

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && !isRenewPassword ? (
          <Component handleTutorial={handleTutorial} {...props}>
            {isIdle ? expiredSessionModal : coachmarkComponent}
          </Component>
        ) : (
          <Redirect to={login} />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = { component: '' };

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  coachmarkComponent: PropTypes.object.isRequired,
  handleTutorial: PropTypes.func.isRequired,
};

export default PrivateRoute;
