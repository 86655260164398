import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { actions, putSknTac, putSknDetail, putSknResult, putSknConfirmation } from 'middleware/actions/favourite/transfer';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.SKN;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.SKN;

const getClientIP = () => remoteIpInstance.get();

const deleteSknFavourite = ({ body }) => Instance.post(endpoint.skn.favourite.delete, body);
const sknResultFavourite = ({ body }) => Instance.post(endpoint.skn.favourite.result, body);
const deleteSknResult = ({ body }) => Instance.post(endpoint.skn.favourite.deleteResult, body);
const newFavouriteDetail = ({ body }) => Instance.post(endpoint.skn.favourite.addNewDetail, body);
const requestDeleteSknTac = ({ body }) => Instance.post(endpoint.skn.favourite.deleteRequestTac, body);
const sknConfirmationFavourite = ({ body }) => Instance.post(endpoint.skn.favourite.confirmation, body);
const sknAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.skn.favourite.addNewRequestTac, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.skn.favourite.addNewDetailFromTransfer, body);
const sknEditFormDetail = ({ body }) => Instance.post(endpoint.skn.favourite.editDetail, body);
const sknEditConfirmDetail = ({ body }) => Instance.post(endpoint.skn.favourite.editConfirm, body);
const sknEditTac = ({ body }) => Instance.post(endpoint.skn.favourite.editRequestTac, body);
const sknEditResult = ({ body }) => Instance.post(endpoint.skn.favourite.editResult, body);

const getLocale = state => state.LanguageReducer.locale;

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });
    yield put(putSknDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });

    yield put(putSknDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(sknConfirmationFavourite, { body });
    yield put(putSknConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(sknAddNewRequestTacFavourite, { body });

    yield put(putSknTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(sknResultFavourite, { body });

    yield put(putSknResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteSknFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteSknFavourite, { body });
    yield put(putSknConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteSknTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteSknTac, { body });
    yield put(putSknTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteSknResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteSknResult, { body });
    yield put(putSknResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(sknEditFormDetail, { body });
    yield put(putSknDetail(formatTransferConfirmation({ ...data, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(sknEditConfirmDetail, { body });
    yield put(putSknConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(sknEditTac, { body });
    yield put(putSknTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sknEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(sknEditResult, { body });
    yield put(putSknResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* SknFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, sknResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteSknResultSaga),
    takeLatest(REQUEST_TAC, sknAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, sknConfirmationFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteSknTacSaga),
    takeLatest(DELETE_CONFIRMATION, deleteSknFavouriteSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, sknEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, sknEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, sknEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, sknEditResultFavouriteSaga),
  ]);
}
