import axios from 'axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import history from 'router/history';
import isEmpty from 'lodash/isEmpty';

import { get } from 'utils/lodash';
import { routes } from 'router/routes';
import {
  getLogout,
  getLogoutExpiredSession,
  getLogoutTransactionSummary,
  putLogout,
  putLogoutExpiredSession,
} from 'middleware/actions/login';
import { putDashboardResponseError } from 'middleware/actions/dashboard';
import {
  HTTP_SUCCESS,
  HTTP_UNAUTHORIZED,
  SUCCESS_RESPONSE_CODE,
  HTTP_INTERNAL_SERVER_ERROR,
  MULTIPLE_SESSION_RESPONSE_CODE,
  EXPIRED_SESSION_RESPONSE_CODE,
} from 'settings/constants/response-codes';

import { dashboardEndpoint as endpoint } from './endpoints/dashboard';

// config for all API calls. we set the baseURL for API calls by fetching from the .env file
const Instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Frame-Options': 'SAMEORIGIN',
  },
});

// INFO: implementation of global loader. will show <Loader /> component each time there are ajax/API call
export const useAxiosInterceptor = () => {
  const dispatch = useDispatch();

  // NOTE: isMounted used to check for the component is mounted before updating state
  const isMounted = useRef(true);
  const [counter, setCounter] = useState(0);

  const { authToken: jwtToken } = useSelector(state => ({ authToken: state.AuthReducer.authToken }), shallowEqual);

  const doLogout = (isMultiSession = false, isExpiredSession = false) => {
    const logoutExpiredRoutes = isExpiredSession ? routes.sessionExpired : routes.logout;

    if (isMultiSession) {
      const loader = document.getElementsByClassName('common loader');
      loader[0].style.display = 'none';

      return dispatch(getLogout(history, false, isMultiSession));
    }

    if (isExpiredSession) {
      dispatch(putLogoutExpiredSession());

      return history.push(logoutExpiredRoutes, {
        logout: true,
        isExpiredSession,
        isMultiSession,
      });
    }

    return getLogoutExpiredSession(history);
  };

  const increment = useCallback(() => setCounter(count => count + 1), [setCounter]);
  const decrement = useCallback(() => setCounter(count => count - 1), [setCounter]);

  // NOTE: intercptors moved in this function
  const interceptors = useMemo(
    () => ({
      // TODO: add token verification and check for ERR 401
      request: config => {
        if (isMounted.current) increment();
        if (jwtToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${jwtToken}`;
        }

        // NOTE: clear dashboard error response
        dispatch(putDashboardResponseError({}));

        return { ...config };
      },
      response: response => {
        const responseCode = get(response, 'data.responseCode', SUCCESS_RESPONSE_CODE);

        if (isMounted.current) decrement();

        if (responseCode === EXPIRED_SESSION_RESPONSE_CODE) doLogout(false, true);
        if (responseCode === MULTIPLE_SESSION_RESPONSE_CODE && isMounted.current === true) {
          doLogout(true);

          // force logout one times
          isMounted.current = false;
        }

        return response;
      },
      error: error => {
        if (isMounted.current) decrement();
        const httpResponse = get(error, 'response', undefined);
        const httpRequestStatus = get(error, 'status', '');
        const httpResponseStatus = get(error, 'response.status', HTTP_INTERNAL_SERVER_ERROR);
        const defaultResponseData = get(error, 'response.data', {});
        const url = get(error, 'response.config.url', '');

        // if fail get total dont make error on dashboard
        const totalWealthEndpoint = endpoint.get.totalWealth;
        const totalAllAccountEndpoint = endpoint.get.allAccountBalance;

        const isGetTotalWealthOrAccount = url === totalWealthEndpoint || url === totalAllAccountEndpoint;
        const response = { ...defaultResponseData, status: httpRequestStatus || httpResponseStatus };

        if (httpRequestStatus === HTTP_UNAUTHORIZED) {
          dispatch(putLogout());
          return history.push(routes.sessionExpired, { logout: true });
        }

        // if fail get total dont make error on dashboard
        if (
          !isEmpty(httpResponse) &&
          httpResponseStatus !== HTTP_UNAUTHORIZED &&
          httpResponseStatus !== HTTP_SUCCESS &&
          !isGetTotalWealthOrAccount
        ) {
          dispatch(putDashboardResponseError({ errorCode: httpResponseStatus, isSuccess: false }));
        }

        return Promise.reject(response);
      },
    }),
    [increment, decrement, jwtToken]
  );

  useEffect(() => {
    const requestID = Instance.interceptors.request.use(interceptors.request, interceptors.error);
    const responseID = Instance.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // INFO: Remove all intercepts when done
      // Need eject to erase previous bearer token
      Instance.interceptors.request.eject(requestID);
      Instance.interceptors.response.eject(responseID);
    };
  }, [interceptors]);

  // NOTE: reset isMounted indicator on component unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return [counter > 0];
};

export default Instance;
