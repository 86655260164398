import PropTypes from 'prop-types';

import { Card as MaterialCard } from '@material-ui/core';

import CardContentItem from './content/Item';

import './style.scss';

const PanelItemCard = ({ title, info }) => (
  <MaterialCard className="card card--panel-item">
    <CardContentItem title={title} info={info} />
  </MaterialCard>
);

PanelItemCard.defaultProps = { info: '', title: '' };

PanelItemCard.propTypes = { title: PropTypes.node, info: PropTypes.node };

export default PanelItemCard;
