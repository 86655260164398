export const inbox = {
  action: {
    delete: 'Hapus',
    print: 'Cetak',
    reply: 'Balas',
    titleModal: 'Hapus Surat',
    notification: 'Pemberitahuan',
    descNotif: 'Berhenti membuat pesan? Pesan tidak akan tersimpan',
    successSent: 'Pesan berhasil terkirim!',
    successReply: 'Balas Pesan berhasil terkirim!',
    failSent: 'Pesan Gagal terkirim!',
    failReply: 'Balas Pesan gagal terkirim!',
    ok: 'YA',
  },
  subHeader: { label: 'KOTAK MASUK' },
  label: 'Kotak Masuk',
  sent: {
    label: 'Item Terkirim',
  },
  message: {
    label: 'Pesan',
    successDelete: 'Surat berhasil dihapus.',
    errorDelete: 'Gagal menghapus surat',
    successCompose: 'Surat berhasil dibuat.',
    errorCompose: 'Gagal menulis surat',
  },
  modal: {
    description: 'Apakah Anda ingin menghapus item yang dipilih secara permanen?',
  },
  compose: 'Tulis',
  reply: 'Balas',
  subject: 'Subjek',
  messageInput: 'Pesan',
  mail: 'Surat',
  replyConversation: 'Balas Percakapan',
  placeholderSubject: 'Pilih Subjek',
};
