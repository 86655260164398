import PropTypes from 'prop-types';

import { Card as MaterialCard, CardActions, CardContent, CardMedia, Typography, Paper, CardActionArea } from '@material-ui/core';

import { get } from 'utils/lodash';

import Link from '../Link';
import ActionButton from '../buttons/ActionButton';

import './style.scss';

const InteractiveMediaCard = ({ index, item, buttonTitle, coverImg, onClick, onLinkClick }) => {
  const desc = get(item, 'desc', '');
  const title = get(item, 'title', '');

  return (
    <Paper className="card card--interactive-media">
      <MaterialCard className="card--container">
        <CardContent>
          <div className="content--img">
            <CardMedia component="img" alt={title} title={title} image={coverImg} />
            <Typography variant="h4" className="img--title pa3">
              {title}
            </Typography>
          </div>

          <div className="pa3 content--wrapper">
            <Typography variant="body2" className="content--desc" component="p">
              {desc}
            </Typography>

            <Link hasRightIcon label="more info" onClick={onLinkClick} icon="arrowForward" className="content--link" />
          </div>
        </CardContent>

        <CardActions>
          <ActionButton
            fullWidth
            isAssetIcon={false}
            icon="arrowRightAlt"
            title={buttonTitle}
            onClick={onClick}
            className="hover--default"
          />
        </CardActions>
      </MaterialCard>
    </Paper>
  );
};

InteractiveMediaCard.defaultProps = {
  item: {},
  index: '',
  onLinkClick: null,
  buttonTitle: 'Apply now',
};

InteractiveMediaCard.propTypes = {
  item: PropTypes.object,
  onLinkClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  coverImg: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InteractiveMediaCard;
