import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';
import { actions, putTicketTAC, putTicketReceipt, putTicketResult, putTicketConfirmation } from 'middleware/actions/purchase';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';

const { RECEIPT, RESULT, TAC, CONFIRM } = actions.GET.TICKET;

const getClientIP = () => remoteIpInstance.get();

const getResult = ({ body }) => Instance.post(endpoint.ticket.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.ticket.getReceipt, body);
const requestTAC = ({ body }) => Instance.post(endpoint.ticket.requestTAC, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.ticket.getConfirmation, body);

const getLocale = state => state.LanguageReducer.locale;

function* getConfirmationSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getConfirmation, { body });
    yield put(putTicketConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });
    yield put(putTicketTAC(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });
    yield put(putTicketResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    yield put(putTicketReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PurchaseTicket() {
  yield all([
    takeLatest(TAC, requestTACSaga),
    takeLatest(RESULT, getResultSaga),
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(CONFIRM, getConfirmationSaga),
  ]);
}
