export const GOODS = formatMessage => [
  {
    key: '2012',
    label: formatMessage({ id: 'lldDescription.goods.2012' }),
  },
  {
    key: '2803',
    label: formatMessage({ id: 'lldDescription.goods.2803' }),
  },
  {
    key: '2018',
    label: formatMessage({ id: 'lldDescription.goods.2018' }),
  },
  {
    key: '2019',
    label: formatMessage({ id: 'lldDescription.goods.2019' }),
  },
  {
    key: '2015',
    label: formatMessage({ id: 'lldDescription.goods.2015' }),
  },
  {
    key: '2097',
    label: formatMessage({ id: 'lldDescription.goods.2097' }),
  },
  {
    key: '2098',
    label: formatMessage({ id: 'lldDescription.goods.2098' }),
  },
  {
    key: '2804',
    label: formatMessage({ id: 'lldDescription.goods.2804' }),
  },
];
