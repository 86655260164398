import PropTypes from 'prop-types';
import { Children, useEffect } from 'react';
import { reduce, merge, isEmpty, isEqual } from 'lodash';

import { get } from 'utils/lodash';
import { addAccordionField, formatFieldProp, formatSelectAccountProp, validateAccordionField, validateField } from 'utils/dynamic-field';

import { FIELD_TYPE_ACCORDION, FIELD_TYPE_SELECT_ACCOUNT } from 'settings/constants/transaction';

import DynoAccordion from './Accordion';

const DynoForm = ({ fields, formDetail, onChange }) => {
  const { isRecurring, currency, validField } = formDetail;

  const getValue = (name, defaultValue = '') => get(formDetail, name, defaultValue);

  // when watch function is passed. it will return all the formDetail to watch function
  useEffect(() => {
    // check field is valid, If have 1 not valid will return as false
    const isValid = reduce(validField, (result, value) => value && result, true);
    // to avoid infinity rerendering
    if (isValid !== formDetail.isValid) onChange({ isValid });
  }, [formDetail]);

  // set the state using initialValue from field schema.
  // if input already has a value, use it instead
  useEffect(() => {
    fields.map(field => {
      const { name } = field;
      return onChange({ [name]: formDetail[name] });
    });
  }, []);

  // set if field that have validationRules is valid or not
  useEffect(() => {
    const allValidField = fields.reduce((result, field) => {
      const type = get(field, 'type', '');
      const optionField = get(field, 'optionField', {});

      if (type === FIELD_TYPE_ACCORDION) {
        const validatedAccordionField = validateAccordionField(field, formDetail, isRecurring);
        merge(result, { ...validatedAccordionField });
      }

      if (!isEmpty(optionField)) {
        const validatedOptionField = validateField(optionField, isRecurring, formDetail);
        merge(result, { ...validatedOptionField });
      }

      const validatedField = validateField(field, isRecurring, formDetail);
      return merge(result, { ...validatedField });
    }, {});

    // no infinity rerender since i have fixed the lldForm for Swift
    if (!isEqual(validField, allValidField)) onChange({ validField: allValidField });
  }, [isRecurring, currency, fields]);

  return Children.toArray(
    fields.map((field, index) => {
      const type = get(field, 'type', '');
      const isHidden = get(field, 'isHidden', false);
      const isRecurringDisplay = get(field, 'isRecurringDisplay', '');
      const isFirstField = index === 0;

      // to avoid dependency cycle. I passed the component from here
      const dynamicFieldHandler = addAccordionField(DynoAccordion);
      const defaultFieldHandler = get(dynamicFieldHandler, 'default', null);

      const fieldProps =
        type === FIELD_TYPE_SELECT_ACCOUNT
          ? formatSelectAccountProp({ getValue, onChange, isFirstField }, field)
          : formatFieldProp({ getValue, onChange, isFirstField }, field);

      if (isHidden) return defaultFieldHandler;
      if (isRecurringDisplay && !isRecurring) return defaultFieldHandler;
      return (dynamicFieldHandler[type] || defaultFieldHandler)(fieldProps, isRecurring);
    })
  );
};

DynoForm.propType = {
  fields: PropTypes.array.isRequired,
  formDetail: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
};

export default DynoForm;
