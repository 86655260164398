import { useState } from 'react';
import PropTypes from 'prop-types';

import { Card as MaterialCard, Collapse, Typography } from '@material-ui/core';

import { memo } from 'utils/react';

import Icon from '../Icon';

import './style.scss';

const INITIAL_PERCENTAGE = 0;

const LeftSectionIconCard = ({
  icon,
  subtitle,
  leftTitle,
  rightTitle,
  percentage,
  isAssetIcon,
  circleColour,
  hasIcon,
  onClick,
  hasCircle,
  hasSubtitle,
  leftDropdown,
  rightDropdown,
  hasPercentage,
  hasLeftDropdown,
  hasRightDropdown,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const iconType = isExpanded ? 'expandLess' : 'expandMore';

  return (
    <MaterialCard className="card card--left-icon-section" onClick={onClick}>
      <div className={`content--left-section wealth--dashboard ${iconType}`}>
        {hasCircle && (
          <div className="section--circle" style={{ borderColor: circleColour }}>
            {hasPercentage && <Typography className="percentage">{`${percentage}%`}</Typography>}
            {hasIcon && <Icon type={icon} isAssetIcon={isAssetIcon} />}
          </div>
        )}
        {hasIcon && !hasCircle && <Icon type={icon} isAssetIcon={isAssetIcon} />}

        {hasLeftDropdown && (
          <Collapse in={isExpanded} className="left-dropdown">
            {leftDropdown}
          </Collapse>
        )}
      </div>

      <div className="content--right-section">
        <div className="flex flex-column w-100">
          <div className="flex w-100 justify-between">
            <Typography variant="h5" className="title" onClick={() => setExpanded(!isExpanded)}>
              {leftTitle}
              {hasRightDropdown && <Icon isAssetIcon={false} type={iconType} />}
            </Typography>

            <Typography className="title">{rightTitle}</Typography>
          </div>

          {hasRightDropdown && (
            <Collapse in={isExpanded} className="right-dropdown">
              {rightDropdown}
            </Collapse>
          )}
        </div>

        <div className="flex flex-row justify-end w-100">{hasSubtitle && <Typography className="subtitle">{subtitle}</Typography>}</div>
      </div>
    </MaterialCard>
  );
};

LeftSectionIconCard.defaultProps = {
  icon: '',
  subtitle: '',
  leftTitle: '',
  rightTitle: '',
  circleColour: '',
  leftDropdown: '',
  rightDropdown: '',
  onClick: null,
  hasCircle: true,
  hasIcon: false,
  isAssetIcon: false,
  hasSubtitle: false,
  hasPercentage: false,
  hasLeftDropdown: false,
  hasRightDropdown: false,
  percentage: INITIAL_PERCENTAGE,
};

LeftSectionIconCard.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  hasCircle: PropTypes.bool,
  hasSubtitle: PropTypes.bool,
  isAssetIcon: PropTypes.bool,
  subtitle: PropTypes.node,
  leftTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  percentage: PropTypes.number,
  rightTitle: PropTypes.string,
  hasPercentage: PropTypes.bool,
  circleColour: PropTypes.string,
  leftDropdown: PropTypes.node,
  rightDropdown: PropTypes.node,
  hasLeftDropdown: PropTypes.bool,
  hasRightDropdown: PropTypes.bool,
};

export default memo(LeftSectionIconCard);
