export const transferEndpoint = {
  get: {
    maintenances: 'transfer/v1/funds/fav/dest/list',
    receiverAccount: 'transfer/v1/funds/own/accounts',
    senderAccount: 'transfer/v1/funds/source/accounts',
    favouriteAccount: 'transfer/v1/funds/fav/source/accounts',
  },
  ownTransfer: {
    getTransferConfirm: 'transfer/v1/funds/own/initiate',
    getTransferResult: 'transfer/v1/funds/own/confirm',
    getReceipt: 'transfer/v1/funds/own/reciept',
    requestTac: 'notification/v1/tac/transfer/own/request',
  },
  interbank: {
    getReceipt: 'transfer/v1/funds/inter/reciept',
    getResult: 'transfer/v1/funds/inter/confirm',
    getDetail: 'transfer/v1/funds/inter/accounts',
    getConfirmation: 'transfer/v1/funds/inter/initiate',
    requestTac: 'notification/v1/tac/transfer/inter/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/inter/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/inter/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/inter/request',
      addNewDetail: 'transfer/v1/funds/fav/add/inter/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/inter/details',
      confirmation: 'transfer/v1/funds/fav/add/inter/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/inter/request',
      result: 'transfer/v1/funds/fav/add/inter/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/inter/details',
      editConfirm: 'transfer/v1/funds/fav/edit/inter/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/inter/request',
      editResult: 'transfer/v1/funds/fav/edit/inter/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/inter/accounts',
    },
  },
  intrabank: {
    getDetail: 'transfer/v1/funds/intra/accounts',
    getResult: 'transfer/v1/funds/intra/confirm',
    getReceipt: 'transfer/v1/funds/intra/reciept',
    getConfirmation: 'transfer/v1/funds/intra/initiate',
    requestTac: 'notification/v1/tac/transfer/intra/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/intra/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/intra/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/intra/request',
      addNewDetail: 'transfer/v1/funds/fav/add/intra/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/intra/details',
      confirmation: 'transfer/v1/funds/fav/add/intra/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/intra/request',
      result: 'transfer/v1/funds/fav/add/intra/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/intra/details',
      editConfirm: 'transfer/v1/funds/fav/edit/intra/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/intra/request',
      editResult: 'transfer/v1/funds/fav/edit/intra/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/intra/accounts',
    },
  },
  skn: {
    getSknDetails: 'transfer/v1/funds/skn/accounts',
    getConfirmation: 'transfer/v1/funds/skn/initiate',
    requestTac: 'notification/v1/tac/transfer/skn/request',
    getResult: 'transfer/v1/funds/skn/confirm',
    getReceipt: 'transfer/v1/funds/skn/reciept',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/skn/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/skn/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/skn/request',
      addNewDetail: 'transfer/v1/funds/fav/add/skn/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/skn/details',
      confirmation: 'transfer/v1/funds/fav/add/skn/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/skn/request',
      result: 'transfer/v1/funds/fav/add/skn/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/skn/details',
      editConfirm: 'transfer/v1/funds/fav/edit/skn/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/skn/request',
      editResult: 'transfer/v1/funds/fav/edit/skn/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/skn/accounts',
    },
  },
  rtgs: {
    getDetail: 'transfer/v1/funds/rtgs/accounts',
    getConfirmation: 'transfer/v1/funds/rtgs/initiate',
    getResult: 'transfer/v1/funds/rtgs/confirm',
    getReceipt: 'transfer/v1/funds/rtgs/reciept',
    requestTac: 'notification/v1/tac/transfer/rtgs/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/rtgs/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/rtgs/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/rtgs/request',
      addNewDetail: 'transfer/v1/funds/fav/add/rtgs/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/rtgs/details',
      confirmation: 'transfer/v1/funds/fav/add/rtgs/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/rtgs/request',
      result: 'transfer/v1/funds/fav/add/rtgs/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/rtgs/details',
      editConfirm: 'transfer/v1/funds/fav/edit/rtgs/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/rtgs/request',
      editResult: 'transfer/v1/funds/fav/edit/rtgs/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/rtgs/accounts',
    },
  },
  swift: {
    getAccess: 'transfer/v1/funds/fct/search/initiate',
    getBanks: 'transfer/v1/funds/fct/search/result',
    getBankDetail: 'transfer/v1/funds/fct/accounts',
    getLldDetail: 'transfer/v1/funds/fct/accounts/lld',
    getReceipt: 'transfer/v1/funds/fct/reciept',
    getConfirmation: 'transfer/v1/funds/fct/initiate',
    getResult: 'transfer/v1/funds/fct/confirm',
    requestTac: 'notification/v1/tac/transfer/fct/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/fct/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/fct/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/fct/request',
      addNewDetail: 'transfer/v1/funds/fav/add/fct/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/fct/details',
      confirmation: 'transfer/v1/funds/fav/add/fct/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/fct/request',
      result: 'transfer/v1/funds/fav/add/fct/confirm',
      searchBank: 'transfer/v1/funds/fav/add/fct/search',
      editDetail: 'transfer/v1/funds/fav/edit/fct/details',
      editConfirm: 'transfer/v1/funds/fav/edit/fct/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/fct/request',
      editResult: 'transfer/v1/funds/fav/edit/fct/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/fct/accounts',
    },
  },
  virtualAccount: {
    getDetail: 'transfer/v1/funds/vt/accounts',
    getConfirmation: 'transfer/v1/funds/vt/initiate',
    getResult: 'transfer/v1/funds/vt/confirm',
    getReceipt: 'transfer/v1/funds/vt/reciept',
    requestTac: 'notification/v1/tac/transfer/vt/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/vt/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/vt/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/vt/request',
      addNewDetail: 'transfer/v1/funds/fav/add/vt/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/vt/details',
      confirmation: 'transfer/v1/funds/fav/add/vt/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/vt/request',
      result: 'transfer/v1/funds/fav/add/vt/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/vt/details',
      editConfirm: 'transfer/v1/funds/fav/edit/vt/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/vt/request',
      editResult: 'transfer/v1/funds/fav/edit/vt/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/vt/accounts',
    },
  },
  eWallet: {
    getDetail: 'transfer/v1/funds/emoney/accounts',
    getConfirmation: 'transfer/v1/funds/emoney/initiate',
    getResult: 'transfer/v1/funds/emoney/confirm',
    getReceipt: 'transfer/v1/funds/emoney/reciept',
    requestTac: 'notification/v1/tac/transfer/emoney/request',
    favourite: {
      delete: 'transfer/v1/funds/fav/del/emoney/initiate',
      deleteResult: 'transfer/v1/funds/fav/del/emoney/confirm',
      deleteRequestTac: 'notification/v1/tac/transfer/fav/del/emoney/request',
      addNewDetail: 'transfer/v1/funds/fav/add/emoney/details',
      addNewDetailFromTransfer: 'transfer/v1/funds/fav/add/txn/emoney/details',
      confirmation: 'transfer/v1/funds/fav/add/emoney/initiate',
      addNewRequestTac: 'notification/v1/tac/transfer/fav/add/emoney/request',
      result: 'transfer/v1/funds/fav/add/emoney/confirm',
      editDetail: 'transfer/v1/funds/fav/edit/emoney/details',
      editConfirm: 'transfer/v1/funds/fav/edit/emoney/initiate',
      editRequestTac: 'notification/v1/tac/transfer/fav/edit/emoney/request',
      editResult: 'transfer/v1/funds/fav/edit/emoney/confirm',
      getTransferDetail: 'transfer/v1/funds/fav/emoney/accounts',
    },
  },
};
