import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatSettingsResult } from 'utils/settings/formatter';

import {
  actions,
  putAccessRemoveDevice,
  putConfirmRemoveDevice,
  putPasswordAccess,
  putPasswordResult,
  putPasswordTac,
  putRemoveDeviceResult,
  putRemoveDeviceTac,
  putSecurityImageAccess,
  putSecurityImageResult,
  putSecurityImages,
  putSecurityImageTac,
} from 'middleware/actions/settings';
import { putErrorResponse } from 'middleware/actions/error';
import { getCustomerInfoSaga } from 'middleware/sagas/login';

import { userAgent } from 'providers/fingerprint';
import { settingsEndpoint as endpoint } from 'providers/endpoints/settings';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

const getClientIP = () => remoteIpInstance.get();

const changePassword = ({ body }) => Instance.post(endpoint.password.change, body);
const getPasswordResult = ({ body }) => Instance.post(endpoint.password.result, body);
const requestPasswordTAC = ({ body }) => Instance.post(endpoint.password.requestTac, body);

const changeSecurityImage = ({ body }) => Instance.post(endpoint.securityImage.change, body);
const getSecurityImages = ({ body }) => Instance.post(endpoint.securityImage.getImages, body);
const getSecurityImageResult = ({ body }) => Instance.post(endpoint.securityImage.result, body);
const requestSecurityImageTAC = ({ body }) => Instance.post(endpoint.securityImage.requestTac, body);

const getAccessRemoveDevice = ({ body }) => Instance.post(endpoint.manageDevice.accessRemoveDevice, body);
const confirmRemoveDevice = ({ body }) => Instance.post(endpoint.manageDevice.confirmRemoveDevice, body);
const removeDeviceTac = ({ body }) => Instance.post(endpoint.manageDevice.requestTac, body);
const getRemoveDeviceResult = ({ body }) => Instance.post(endpoint.manageDevice.result, body);

const getLocale = state => state.LanguageReducer.locale;

function* changePasswordSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(changePassword, { body });
    yield put(putPasswordAccess(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestPasswordTacSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(requestPasswordTAC, { body });

    yield put(putPasswordTac(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPasswordResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(getPasswordResult, { body });

    yield put(putPasswordResult(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* changeSecurityImageSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(changeSecurityImage, { body });
    yield put(putSecurityImageAccess(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getSecurityImagesSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getSecurityImages, { body });
    yield put(putSecurityImages(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestSecurityImageTacSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(requestSecurityImageTAC, { body });
    yield put(putSecurityImageTac(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getSecurityImageResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(getSecurityImageResult, { body });

    const formattedResult = formatSettingsResult(data);
    const userId = get(payload, 'userId', '');
    const isSuccess = get(formattedResult, 'isSuccess', false);

    yield put(putSecurityImageResult(formattedResult));

    if (isSuccess) {
      yield all([call(changeSecurityImageSaga, { payload: { userId } }), call(getCustomerInfoSaga, { payload: { userId } })]);
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getAccessRemoveDeviceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getAccessRemoveDevice, { body });

    const responseCode = get(data, 'responseCode', '');
    const registeredDevice = get(data, 'registeredDeviceList', []);

    yield put(
      putAccessRemoveDevice({
        responseCode,
        registeredDevice,
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* confirmRemoveDeviceSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(confirmRemoveDevice, { body });

    yield put(putConfirmRemoveDevice(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestRemoveDeviceTacSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(removeDeviceTac, { body });

    yield put(putRemoveDeviceTac(formatSettingsResult(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getRemoveDeviceResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress, ...payload };
    const { data } = yield call(getRemoveDeviceResult, { body });

    const userId = get(payload, 'userId', '');

    const responseCode = get(data, 'responseCode', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    yield put(
      putRemoveDeviceResult({
        responseCode,
        isSuccess,
      })
    );

    if (isSuccess) {
      yield call(getAccessRemoveDeviceSaga, { payload: { userId, locale } });
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* SettingsSecurity() {
  yield all([
    takeLatest(actions.CHANGE.PASSWORD, changePasswordSaga),
    takeLatest(actions.GET.PASSWORD.TAC, requestPasswordTacSaga),
    takeLatest(actions.GET.PASSWORD.RESULT, getPasswordResultSaga),
    takeLatest(actions.CHANGE.SECURITY_IMAGE, changeSecurityImageSaga),
    takeLatest(actions.GET.SECURITY_IMAGE.IMAGES, getSecurityImagesSaga),
    takeLatest(actions.GET.SECURITY_IMAGE.TAC, requestSecurityImageTacSaga),
    takeLatest(actions.GET.SECURITY_IMAGE.RESULT, getSecurityImageResultSaga),
    takeLatest(actions.CHANGE.MANAGE_DEVICE, getAccessRemoveDeviceSaga),
    takeLatest(actions.CONFIRM.MANAGE_DEVICE, confirmRemoveDeviceSaga),
    takeLatest(actions.GET.MANAGE_DEVICE.REMOVE_DEVICE_TAC, requestRemoveDeviceTacSaga),
    takeLatest(actions.GET.MANAGE_DEVICE.REMOVE_DEVICE_RESULT, getRemoveDeviceResultSaga),
  ]);
}
