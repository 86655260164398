import { FormattedMessage } from 'react-intl';

import { get } from 'utils/lodash';

import { DEFAULT_MESSAGE_VALUE } from 'settings/constants/common';
import { TAC_EXCEED_MAX_TRIES } from 'settings/constants/response-codes';
import { SI_FREQUENCY_LATER, SI_FREQUENCY_MONTHLY, SI_FREQUENCY_WEEKLY } from 'settings/constants/settings';

export const getSenderAccountDetails = (accountNumber, senderAccount, labelId, isFullScreen) => {
  const fromAccountList = get(senderAccount, 'senderAccounts', []);
  const fromAccount = fromAccountList.find(account => account.accountNo === accountNumber);
  const accountName = get(fromAccount, 'productName', '');

  return [
    {
      title: (
        <>
          <FormattedMessage id={labelId} />
          {isFullScreen && <br />}
          <strong className={!isFullScreen && `ml1`}>{accountName}</strong>
        </>
      ),
    },
    { title: accountNumber },
  ];
};

export const getRecurringDetails = ({ confirmationDetail, transferFrequency, locale }) => {
  const isMonthly = transferFrequency === SI_FREQUENCY_MONTHLY[locale];

  const debitDate = get(confirmationDetail, 'debitDayDate', '');

  const debitLabel = isMonthly ? (
    <FormattedMessage id="transaction.transfer.debitDate" />
  ) : (
    <FormattedMessage id="transaction.transfer.debitDay" />
  );

  return [
    { title: debitLabel, info: debitDate },
    {
      title: <FormattedMessage id="transaction.transfer.startDate" />,
      info: <span className="silver">{get(confirmationDetail, 'effectiveDateDisplay', '')}</span>,
    },
    {
      title: <FormattedMessage id="transaction.transfer.endDate" />,
      info: <span className="silver">{get(confirmationDetail, 'terminationDateDisplay', '')}</span>,
    },
  ];
};

export const getTransactionDetails = ({
  confirmationDetail,
  referenceNumber,
  isResultSuccess,
  isRecurring,
  isOwnTransfer,
  frequency,
  locale,
}) => {
  let details = isRecurring ? getRecurringDetails({ confirmationDetail, frequency, locale }) : [];
  if (!isOwnTransfer) {
    details = details.concat({
      title: <FormattedMessage id="transaction.transfer.message" />,
      info: get(confirmationDetail, 'message', DEFAULT_MESSAGE_VALUE),
    });
  }

  const successfulDetails = details.concat({
    title: <FormattedMessage id="transaction.transfer.referenceNumber" />,
    info: referenceNumber,
  });

  return isResultSuccess ? successfulDetails : details;
};

export const getSknRtgsBeneficiaryDetails = ({
  confirmationDetail,
  referenceNumber,
  isResultSuccess,
  isRecurring,
  isOwnTransfer,
  frequency,
  locale,
}) => {
  const details = isRecurring
    ? [
        { title: <FormattedMessage id="transaction.transfer.beneficiary.bank" />, info: get(confirmationDetail, 'bankName', '') },
        {
          title: <FormattedMessage id="transaction.transfer.beneficiary.name" />,
          info: get(confirmationDetail, 'beneficiaryName', ''),
        },
        { title: <FormattedMessage id="transaction.transfer.sourceOfFund" />, info: get(confirmationDetail, 'sourceOfFund', '') },
        {
          title: <FormattedMessage id="transaction.transfer.transactionPurpose" />,
          info: get(confirmationDetail, 'transactionPurpose', ''),
        },
        {
          title: <FormattedMessage id="transaction.transfer.beneficiary.type" />,
          info: get(confirmationDetail, 'beneficiaryType', ''),
        },
        {
          title: <FormattedMessage id="transaction.transfer.beneficiary.citizenStatus" />,
          info: get(confirmationDetail, 'beneficiaryCitizenStatus', ''),
        },
        ...getRecurringDetails({ confirmationDetail, frequency, locale }),
        { title: <FormattedMessage id="transaction.transfer.message" />, info: get(confirmationDetail, 'message', '-') },
        {
          title: <FormattedMessage id="transaction.transfer.beneficiary.email" />,
          info: get(confirmationDetail, 'beneficiaryEmail', '-'),
        },
      ].filter(value => Object.keys(value).length)
    : [];

  if (isResultSuccess)
    return [...details, { title: <FormattedMessage id="transaction.transfer.referenceNumber" />, info: referenceNumber }];
  return details;
};

export const getSiRecurringLabel = (frequency, locale) => {
  if (frequency === SI_FREQUENCY_LATER[locale]) {
    return '';
  }

  return frequency === SI_FREQUENCY_MONTHLY[locale]
    ? 'settings.manageTransaction.recurring.monthly'
    : 'settings.manageTransaction.recurring.weekly';
};

export const getReceiverDetail = detail => {
  const transactionAmount = get(detail, 'toAmount', '');
  const receiverName = get(detail, 'beneficiaryName', '');
  const transactionType = get(detail, 'trnxType', '');
  const receiverAccount = get(detail, 'toAccount', '');

  return {
    transactionAmount,
    receiverName,
    receiverAccount,
    transactionType,
  };
};

export const getConfirmationDetail = detail => {
  return {
    isConfirmSuccess: get(detail, 'isSuccess', false),
    tacBean: get(detail, 'tacBean', ''),
  };
};

export const getResultDetail = detail => {
  const errorCode = get(detail, 'errorCode', '');

  return {
    resultError: errorCode,
    isTacLocked: errorCode === TAC_EXCEED_MAX_TRIES,
    statusCode: errorCode,
    statusMessage: get(detail, 'responseMessage', ''),
    transactionId: '',
    isResultSuccess: get(detail, 'isSuccess', false),
    isTransactionSuccess: get(detail, 'isSuccess', false),
    referenceNumber: get(detail, 'referenceNumber', ''),
  };
};
