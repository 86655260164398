export const loginEndpoint = {
  login: {
    access: 'login/v1/home',
    validateUsername: 'login/v1/username',
    // To authenticate username & password
    authenticateUser: 'login/v1/credentials',
    getCustomerInfo: 'login/v1/profile',
    logout: 'dashboard/v1/logout',
    logoutTransactionSummary: 'login/v1/logout',
    logoutWithExpiredSession: 'dashboard/v1/logout/popup/session',
    extendSession: 'dashboard/v1/extend/session',
    getCaptchaResetCredential: 'resetcredential/v1/reset/captcha',
    verifyCaptchaResetCredential: 'resetcredential/v1/reset/captcha/verify',
    getTacResetCredential: 'resetcredential/v1/reset/tac/get',
    verifyTacResetCredential: 'resetcredential/v1/reset/verify',
    updatePasswordResetCredential: 'resetcredential/v1/reset/tac/verify',
    checkUserForceChangePassword: 'forcechange/v1/force/reset/checkUserState',
    generateTacForChangePassword: 'forcechange/v1/force/reset/tac/send',
    verifyTacForceChangePassword: 'forcechange/v1/force/reset/tac/submit',
    checkUserStateChangePhoneNumber: 'forcephone/v1/forcephone/checkUserState',
    updatePhoneNumher: 'forcephone/v1/forcephone/submit',
  },
};
