import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import * as moment from 'moment-timezone';
import 'moment/locale/id';

import DayPicker from 'react-day-picker';
import Menu from '@material-ui/core/Menu';

import { getBeforeAfter, getDisabledDates, getMedianDate, getYears } from 'utils/date-time';

import { calendar as calendarEn } from 'settings/languages/en/calendar';
import { calendar as calendarId } from 'settings/languages/id/calendar';

import { DEFAULT_LOCALE } from 'settings/constants/language';
import { ANCHOR_ORIGIN, TRANSFORM_ORIGIN } from 'settings/constants/ui-control';
import { CALENDAR_INPUT_FORMAT } from 'settings/constants/calendar';

import Textfield from '../Textfield';
import YearPicker from './YearPicker';

import 'react-day-picker/lib/style.css';

import './style.scss';

const label = { en: calendarEn, id: calendarId };

const CalendarPicker = ({
  className,
  value,
  leftLabel,
  onChange,
  disabledDays,
  disabledDates,
  placeholder,
  disabled,
  currentMonth,
  hasError,
  hasYearList,
  errorMessage,
  hasMonthList,
  hasMonthNavbar,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [month, setMonth] = useState(currentMonth);

  const { locale } = useIntl();
  moment.locale(locale);
  moment.localeData(locale);
  const calendarLabel = label[locale] || label[DEFAULT_LOCALE];

  const { shortDays, months } = calendarLabel;

  const { before, after } = getBeforeAfter(disabledDays);
  const years = getYears(before, after);

  const disabledClass = disabled ? 'disabled' : '';
  const hideMonthNavbarClass = hasMonthNavbar ? '' : 'hideMonthNavbar';

  const dateValue = value ? moment(value).format(CALENDAR_INPUT_FORMAT) : placeholder;

  const modifier = {
    disabled: [...disabledDays, getDisabledDates(disabledDates)],
  };

  // overwrite localeUtils for 'id' locale
  const localeUtils = { getMonths: () => months };

  const handleClick = event => !disabled && setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCaptionChange = (captionYear, captionMonth) => setMonth(new Date(captionYear, captionMonth));

  const handleDayClick = (date, modifiers) => {
    if (modifiers.disabled) {
      // Day is disabled, do nothing
      return;
    }

    onChange(date);
    handleClose();
  };

  // useEffect(() => {
  //   if (currentMonth) setMonth(currentMonth);
  // }, [currentMonth]);

  return (
    <div className={`common calendar ${className}`}>
      <Textfield
        readOnly
        hasRightIcon
        hasLeftLabel
        hasError={hasError}
        errorMessage={errorMessage}
        leftLabel={leftLabel}
        value={dateValue}
        rightIcon="calendar"
        className={`textfield--label ${disabledClass}`}
        onClick={handleClick}
        onIconClick={handleClick}
      />
      <Menu
        keepMounted
        className="calendar--menu"
        disableScrollLock
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
      >
        <DayPicker
          locale={locale}
          month={month}
          months={months}
          weekdaysShort={shortDays}
          onDayClick={handleDayClick}
          selectedDays={value || month}
          modifiers={modifier}
          className={hideMonthNavbarClass}
          captionElement={({ date }) => (
            <YearPicker
              date={date}
              years={years}
              hasYearList={hasYearList}
              hasMonthList={hasMonthList}
              onChange={handleCaptionChange}
              localeUtils={localeUtils}
            />
          )}
        />
      </Menu>
    </div>
  );
};

CalendarPicker.defaultProps = {
  leftLabel: '',
  className: '',
  placeholder: '',
  value: '',
  currentMonth: '',
  disabledDays: [], // days of the week (Sat,Sun)
  disabledDates: [], // specific dates (30th, 31st)
  disabled: false,
  hasError: false,
  hasYearList: false,
  errorMessage: '',
  hasMonthList: false,
  hasMonthNavbar: true,
};

CalendarPicker.propTypes = {
  leftLabel: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabledDates: PropTypes.array,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasYearList: PropTypes.bool,
  hasMonthList: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasMonthNavbar: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabledDays: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CalendarPicker;
