import PropTypes from 'prop-types';

import { Card as MaterialCard, Typography, CardMedia, CardActionArea } from '@material-ui/core';

import Icon from '../Icon';

import './style.scss';

const ImageCoverCard = ({ title, image, onClick }) => (
  <MaterialCard className="card card--img-cover" onClick={onClick}>
    <CardActionArea className="img--container">
      <CardMedia image={image} />
      <div className="content--container">
        <Typography variant="h4">{title}</Typography>
        <Icon type="arrowRightAlt" />
      </div>
    </CardActionArea>
  </MaterialCard>
);

ImageCoverCard.defaultProps = { title: '', image: '', onClick: null };

ImageCoverCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

export default ImageCoverCard;
