import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putMobilePostpaidAccess,
  putMobilePostpaidDetail,
  putMobilePostpaidTac,
  putMobilePostpaidResult,
  putMobilePostpaidReceipt,
  putMobilePostpaidBillers,
  putMobilePostpaidConfirmation,
} from 'middleware/actions/payment';

import { formatKeys } from 'utils/formatter';
import { formatPaymentAccess } from 'utils/payment/formatter';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';

const { CONFIRM, RESULT, TAC, RECEIPT, ACCESS, DETAIL } = actions.GET.MOBILE_POSTPAID;

const getClientIP = () => remoteIpInstance.get();

const getAccess = ({ body }) => Instance.post(endpoint.mobilePostpaid.biller, body);
const getDetail = ({ body }) => Instance.post(endpoint.mobilePostpaid.detail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.mobilePostpaid.confirm, body);
const requestTAC = ({ body }) => Instance.post(endpoint.mobilePostpaid.requestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.mobilePostpaid.result, body);
const getReceipt = ({ body }) => Instance.post(endpoint.mobilePostpaid.receipt, body);

const getLocale = state => state.LanguageReducer.locale;

function* getAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getAccess, { body });

    const [access, billers] = formatPaymentAccess(data);

    yield all([put(putMobilePostpaidAccess(access)), put(putMobilePostpaidBillers(billers))]);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });

    yield put(putMobilePostpaidDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getConfirmation, { body });

    yield put(putMobilePostpaidConfirmation(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });

    yield put(putMobilePostpaidTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });

    yield put(putMobilePostpaidResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });

    yield put(putMobilePostpaidReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PaymentMobilePostpaid() {
  yield all([
    takeLatest(ACCESS, getAccessSaga),
    takeLatest(DETAIL, getDetailSaga),
    takeLatest(CONFIRM, getConfirmationSaga),
    takeLatest(TAC, requestTACSaga),
    takeLatest(RESULT, getResultSaga),
    takeLatest(RECEIPT, getReceiptSaga),
  ]);
}
