import { FormattedMessage } from 'react-intl';
import { CHANGE_PASSWORD_MAX_LENGTH } from 'settings/constants/settings';
import {
  FIELD_TYPE_PASSWORD,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_TEXT,
  FORMAT_NUMBER,
  FORMAT_PASSWORD_CHARACTER,
} from 'settings/constants/transaction';
import { ALIGN_CENTER, ALIGN_RIGHT } from './ui-control';

export const CHANGE_PASSWORD_FORM_FIELDS = [
  {
    name: 'oldPassword',
    type: FIELD_TYPE_PASSWORD,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'settings.security.changePassword.currentPassword',
    placeholder: 'settings.security.changePassword.enterCurrentPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'newPassword',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'settings.security.changePassword.newPassword',
    placeholder: 'settings.security.changePassword.enterNewPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'confirmNewPassword',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'settings.security.changePassword.confirmNewPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const CHANGE_PASSWORD_RESET_CREDENTIAL_FORM_FIELDS = [
  {
    name: 'newPasswordResetCredential',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'resetCredential.modal.passwordForm.newPassword',
    placeholder: 'resetCredential.modal.passwordForm.placeholder.newPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'confirmNewPasswordResetCredential',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    placeholder: 'resetCredential.modal.passwordForm.placeholder.confirmNewPassword',
    label: 'resetCredential.modal.passwordForm.confirmNewPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const FORCE_CHANGE_PASSWORD_FORM_FIELDS = [
  {
    name: 'newPasswordFormChange',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'login.forceChangePassword.currentPassword',
    placeholder: 'login.forceChangePassword.placeholderCurrentPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'confirmNewPasswordFormChange',
    type: FIELD_TYPE_PASSWORD,
    format: FORMAT_PASSWORD_CHARACTER,
    maxLength: CHANGE_PASSWORD_MAX_LENGTH,
    label: 'login.forceChangePassword.newPassword',
    placeholder: 'login.forceChangePassword.placeholderNewPassword',
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const CHANGE_PHONE_NUMBER_FORM_FIELDS = [
  {
    name: 'prevPhoneNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    label: 'login.changePhoneNumber.label.prevNumber',
    placeholder: 'login.changePhoneNumber.placeholder.prevNumber',
    maxLength: 20,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'newPhoneNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    label: 'login.changePhoneNumber.label.newNumber',
    placeholder: 'login.changePhoneNumber.placeholder.newNumber',
    readOnly: true,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const LOGOUT_TRANSACTION_SUMMARY_HEADER_ITEMS = [
  { field: 'transDate', headerName: <FormattedMessage id="logout.header.table.date" /> },
  { field: 'transType', headerName: <FormattedMessage id="logout.header.table.activity" /> },
  { field: 'transAmount', headerName: <FormattedMessage id="logout.header.table.amount" /> },
  { field: 'transStatus', headerName: <FormattedMessage id="logout.header.table.status" /> },
];

export const RESET_CREDENTIAL = 'reset-credential';
export const ERR_INVALID_TAC_FORCE_CHANGE_PASS = 'Invalid TAC';
export const ERR_MAXIMUM_TAC_FORCE_CHANGE_PASS = 'Maximum TAC error limit reached.';
export const ERR_SAME_OLD_PASSWORD_FORCE_CHANGE_PASS = 'LDAP error code 19:Failed to update password';
export const MESSAGE_SUCCESS_FORCE_CHANGE_PASS = 'Success';
export const RESPONSE_CODE_SUCCESS_TAC_FORCE_CHANGE_PASS = '0';
export const ERR_USER_BLOCKED_FORCE_CHANGE_PASS = 'User ID Blocked';
export const ERR_USER_LOCKED_FORCE_CHANGE_PASS = 'User ID Locked';

export const ERR_RESPONSE_CODE_CHANGE_PHONE_NUMBER_501 = '501';
export const ERR_RESPONSE_CODE_CHANGE_PHONE_NUMBER_502 = '502';
export const ERR_RESPONSE_MESSAGE_CHANGE_PHONE_NUMBER_501 = 'Error Phone Number Not Valid';
export const ERR_RESPONSE_MESSAGE_CHANGE_PHONE_NUMBER_502 = 'Error Same Phone Number';

export const HASH_TRUE_CONDITION = 'tRueFCp';
export const HASH_FALSE_CONDITION = 'fALsEfCp';
export const HASH_CHP_TRUE_CONDITION = 'trUecHP';
export const HASH_CHP_FALSE_CONDITION = 'fAlsEcHp';
