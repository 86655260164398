import { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { validate, validResult } from 'utils/form-validation';

import { HAS_OPTION_GRID_SIZE, NO_OPTION_GRID_SIZE } from 'settings/constants/transaction';
import {
  DEFAULT_LEADING_ZERO_REGEX,
  GRID_SIZE_HORIZONTAL,
  GRID_SIZE_VERTICAL,
  GRID_VARIANT_DEFAULT,
  TABLET_SCREEN_SIZE,
  MODAL_FULLSCREEN_SIZE as MOBILE_SCREEN_SIZE,
} from 'settings/constants/ui-control';
import { DEFAULT_DECIMAL_SCALE, NO_DECIMAL_SCALE, DEFAULT_THOUSAND_SEPARATOR } from 'settings/constants/common';

import { Select, TextLabel, NumberField, Textfield } from 'components/common';

import './style.scss';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DynoNumberField = ({
  name,
  value,
  label,
  prefix,
  format,
  variant,
  readOnly,
  disabled,
  onChange,
  getValue,
  maxLength,
  defaultValue,
  allowDecimal,
  isMobileNumber,
  validationRules,
  validateOnChange,
  allowLeadingZero,
  leadingZeroRegex,
  thousandSeparator,
  ...props
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [validationResult, setValidationResult] = useState(validResult);

  const tabletScreen = useMediaQuery(theme.breakpoints.up(TABLET_SCREEN_SIZE));
  const mobileScreen = useMediaQuery(theme.breakpoints.down(MOBILE_SCREEN_SIZE));
  const className = get(props, 'className', '');
  const leftLabel = get(props, 'leftLabel', '');
  const bottomLabel = get(props, 'bottomLabel', '');
  const bottomLabelClass = get(props, 'bottomLabelClass', '');
  const readOnlyClass = readOnly || disabled ? 'number--readonly' : '';
  const numberClass = `${className} number--amount number--label ${readOnlyClass}`;
  const validField = getValue('validField', {});

  // tooltip
  const hasTooltip = get(props, 'hasToolTip', false);
  const tooltipTitleID = get(props, 'toolTipTitle', '');
  const toolTipTitle = hasTooltip ? formatMessage({ id: tooltipTitleID }) : '';

  // optionField
  const hasOption = get(props, 'hasOption', false);
  const optionField = get(props, 'optionField', {});
  const optionsFieldName = get(optionField, 'name', '');
  const isTextField = get(optionField, 'isTextField', false);
  const optionReadOnly = get(optionField, 'readOnly', false);
  const selectValue = getValue(optionsFieldName);
  const options = get(optionField, 'options', []);
  const childGridSize = hasOption ? HAS_OPTION_GRID_SIZE : NO_OPTION_GRID_SIZE;
  const decimalScale = allowDecimal ? DEFAULT_DECIMAL_SCALE : NO_DECIMAL_SCALE;

  const handleOnBlur = event => {
    const { isValid, messageId, param } = validate(validationRules, event);
    setValidationResult({ isValid, messageId, param });
    onChange({ validField: { ...validField, [name]: isValid } });
  };

  const handleOptionChange = event => {
    const { isValid } = validate(validationRules, event);
    onChange({ [optionsFieldName]: event.target.value, validField: { ...validField, [optionsFieldName]: isValid } });
  };

  const handleOnChange = newValue => {
    // note: used when value is updated programmatically and causing onBlur not invoked
    if (validateOnChange) handleOnBlur({ target: { name, value: newValue } });
    return onChange({ [name]: newValue });
  };

  const { isValid, messageId, param } = validationResult;
  const errorMessage = !isValid && formatMessage({ id: messageId, defaultMessage: messageId }, param);

  const gridSize = variant === GRID_VARIANT_DEFAULT ? GRID_SIZE_VERTICAL : GRID_SIZE_HORIZONTAL;

  return (
    <Grid container alignItems={!isValid ? `baseline` : `center`} className="pb0 pt3 ph4">
      <Grid item xs={gridSize.label.xs} sm={gridSize.label.sm} className="pb2">
        {label && (
          <TextLabel
            label={formatMessage({ id: label, defaultMessage: label })}
            hasIcon={hasTooltip}
            tooltipTitle={toolTipTitle}
            className="pl1-ns"
          />
        )}
      </Grid>

      <Grid container item xs={gridSize.input.xs} sm={gridSize.input.sm}>
        {hasOption && (
          <Grid
            item
            xs={12}
            sm={3}
            className="select-padding pb3-ns"
            style={{ paddingRight: tabletScreen ? '0.5rem' : '0', paddingBottom: mobileScreen ? '1rem' : '0' }}
          >
            {isTextField ? (
              <Textfield value={selectValue} readOnly={optionReadOnly} disabled={!optionReadOnly} className="black" />
            ) : (
              <Select name={optionsFieldName} fullWidth options={options} value={selectValue} onChange={handleOptionChange} />
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={childGridSize}>
          <NumberField
            value={value}
            format={format}
            readOnly={readOnly}
            disabled={disabled}
            className={numberClass}
            decimalScale={decimalScale}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            onBlur={e => handleOnBlur(e)}
            errorMessage={errorMessage}
            id={name}
            name={name}
            leftLabel={leftLabel}
            thousandSeparator={thousandSeparator}
            prefix={prefix}
            allowEmptyFormatting
            maxLength={maxLength}
            allowLeadingZero={allowLeadingZero}
            leadingZeroRegex={leadingZeroRegex}
          />
        </Grid>
        {!isEmpty(bottomLabel) && <TextLabel label={formatMessage({ id: bottomLabel })} className={bottomLabelClass} />}
      </Grid>
    </Grid>
  );
};

DynoNumberField.defaultProps = {
  value: '',
  label: '',
  prefix: '',
  format: null, // cant use '', package will clear value input
  readOnly: false,
  disabled: false,
  maxLength: null,
  defaultValue: '',
  allowDecimal: true,
  validationRules: [],
  isMobileNumber: false,
  validateOnChange: false,
  allowLeadingZero: false,
  variant: GRID_VARIANT_DEFAULT,
  leadingZeroRegex: DEFAULT_LEADING_ZERO_REGEX,
  thousandSeparator: DEFAULT_THOUSAND_SEPARATOR,
};

DynoNumberField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  allowDecimal: PropTypes.bool,
  defaultValue: PropTypes.string,
  isMobileNumber: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  allowLeadingZero: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  validationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leadingZeroRegex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DynoNumberField;
