import { actions } from '../actions/login';

const { LOGOUT_TRANSACTION_SUMMARY } = actions.PUT;

const initialState = {
  isSuccess: false,
  logoutTransactionSummary: {},
};

const LogoutReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT_TRANSACTION_SUMMARY:
      return { ...state, logoutTransactionSummary: action.payload };
    default:
      return state;
  }
};

export default LogoutReducer;
