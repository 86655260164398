export const actions = {
  GET: {
    SENDER_ACCOUNT: 'TRANSACTION_GET_SENDER_ACCOUNT',
    RECEIVER_ACCOUNT: 'TRANSACTION_GET_RECEIVER_ACCOUNT',
    OWN_ACCOUNT: {
      RECEIPT: 'TRANSACTION_OWN_ACCOUNT_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_OWN_ACCOUNT_REQUEST_TAC',
      TRANSFER_RESULT: 'TRANSACTION_OWN_ACCOUNT_GET_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_OWN_ACCOUNT_GET_CONFIRMATION',
    },
    INTERBANK: {
      RECEIPT: 'TRANSACTION_INTERBANK_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_INTERBANK_REQUEST_TAC',
      TRANSFER_RESULT: 'TRANSACTION_INTERBANK_GET_TRANSFER_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_INTERBANK_GET_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_GET_INTERBANK_TRANSFER_CONFIRMATION',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_INTERBANK_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    RTGS: {
      RECEIPT: 'TRANSACTION_RTGS_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_RTGS_REQUEST_TAC',
      TRANSFER_DETAIL: 'TRANSACTION_RTGS_GET_TRANSFER_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_RTGS_GET_TRANSFER_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_RTGS_GET_TRANSFER_CONFIRMATION',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_RTGS_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    INTRABANK: {
      RECEIPT: 'TRANSACTION_INTRABANK_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_INTRABANK_REQUEST_TAC',
      TRANSFER_DETAIL: 'TRANSACTION_INTRABANK_GET_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_INTRABANK_GET_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_INTRABANK_GET_CONFIRMATION',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_INTRABANK_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    SKN: {
      RECEIPT: 'TRANSACTION_SKN_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_SKN_REQUEST_TAC',
      TRANSFER_RESULT: 'TRANSACTION_SKN_GET_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_SKN_GET_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_SKN_GET_TRANSFER_CONFIRMATION',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_SKN_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    VIRTUAL_ACCOUNT: {
      TRANSFER_DETAIL: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_CONFIRMATION',
      TAC_RESULT: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_TAC',
      TRANSFER_RESULT: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_RESULT',
      RECEIPT: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_RECEIPT',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_VIRTUAL_ACCOUNT_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    SWIFT: {
      BANKS: 'TRANSACTION_SWIFT_GET_BANKS',
      ACCESS: 'TRANSACTION_SWIFT_GET_ACCESS',
      RECEIPT: 'TRANSACTION_SWIFT_GET_RECEIPT',
      REQUEST_TAC: 'TRANSACTION_SWIFT_REQUEST_TAC',
      LLD_DETAIL: 'TRANSACTION_SWIFT_GET_LLD_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_SWIFT_GET_RESULT',
      BANK_DETAIL: 'TRANSACTION_SWIFT_GET_BANK_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_SWIFT_GET_TRANSFER_CONFIRMATION',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_SWIFT_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
    E_WALLET: {
      TRANSFER_DETAIL: 'TRANSACTION_E_WALLET_GET_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_E_WALLET_GET_TRANSFER_CONFIRMATION',
      REQUEST_TAC: 'TRANSACTION_E_WALLET_REQUEST_TRANSFER_TAC',
      TRANSFER_RESULT: 'TRANSACTION_E_WALLET_GET_TRANSFER_RESULT',
      TRANSFER_RECEIPT: 'TRANSACTION_E_WALLET_GET_TRANSFER_RECEIPT',
      TRANSFER_FROM_FAVOURITE_DETAIL: 'TRANSACTION_E_WALLET_GET_TRANSFER_FROM_FAVOURITE_DETAIL',
    },
  },

  PUT: {
    RESET_TRANSFER: 'TRANSACTION_PUT_RESET_TRANSFER',
    SENDER_ACCOUNT_RESPONSE: 'TRANSACTION_PUT_SENDER_ACCOUNT_RESPONSE',
    RECEIVER_ACCOUNT_RESPONSE: 'TRANSACTION_PUT_RECEIVER_ACCOUNT_RESPONSE',
    SELECTED_RECEIVER_ACCOUNT: 'TRANSACTION_PUT_SELECTED_RECEIVER_ACCOUNT',
    OWN_ACCOUNT: {
      RECEIPT: 'TRANSACTION_OWN_ACCOUNT_PUT_RECEIPT',
      TAC_RESULT: 'TRANSACTION_OWN_ACCOUNT_PUT_TAC_RESULT',
      TRANSFER_RESULT: 'TRANSACTION_OWN_ACCOUNT_PUT_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_OWN_ACCOUNT_PUT_CONFIRMATION',
    },
    INTERBANK: {
      TAC_RESULT: 'TRANSACTION_INTERBANK_PUT_TAC_RESULT',
      RECEIPT: 'TRANSACTION_INTERBANK_PUT_TRANSACTION_RECEIPT',
      TRANSFER_RESULT: 'TRANSACTION_INTERBANK_PUT_TRANSFER_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_INTERBANK_PUT_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_INTERBANK_PUT_TRANSFER_CONFIRMATION',
    },
    RTGS: {
      RECEIPT: 'TRANSACTION_RTGS_PUT_RECEIPT',
      TAC_RESULT: 'TRANSACTION_RTGS_PUT_TAC_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_RTGS_PUT_TRANSFER_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_RTGS_PUT_TRANSFER_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_RTGS_PUT_TRANSFER_CONFIRMATION',
    },
    INTRABANK: {
      RECEIPT: 'TRANSACTION_INTERBANK_PUT_RECEIPT',
      TAC_RESULT: 'TRANSACTION_INTRABANK_PUT_TAC_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_INTRABANK_PUT_TRANSFER_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_INTRABANK_PUT_TRANSFER_RESULT',
      TRANSFER_CONFIRMATION: 'TRANSACTION_INTRABANK_PUT_TRANSFER_CONFIRMATION',
    },
    SKN: {
      RECEIPT: 'TRANSACTION_SKN_PUT_RECEIPT',
      TAC_RESULT: 'TRANSACTION_SKN_PUT_TAC_RESULT',
      TRANSFER_RESULT: 'TRANSACTION_SKN_PUT_RESULT',
      TRANSFER_DETAIL: 'TRANSACTION_SKN_PUT_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_SKN_PUT_TRANSFER_CONFIRMATION',
    },
    VIRTUAL_ACCOUNT: {
      TRANSFER_DETAIL: 'TRANSACTION_VIRTUAL_ACCOUNT_PUT_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_VIRTUAL_ACCOUNT_PUT_TRANSFER_CONFIRMATION',
      TAC_RESULT: 'TRANSACTION_VIRTUAL_ACCOUNT_PUT_TRANSFER_TAC',
      TRANSFER_RESULT: 'TRANSACTION_VIRTUAL_ACCOUNT_PUT_TRANSFER_RESULT',
      RECEIPT: 'TRANSACTION_VIRTUAL_ACCOUNT_PUT_TRANSFER_RECEIPT',
    },
    SWIFT: {
      BANKS: 'TRANSACTION_SWIFT_PUT_BANKS',
      ACCESS: 'TRANSACTION_SWIFT_PUT_ACCESS',
      RECEIPT: 'TRANSACTION_SWIFT_PUT_RECEIPT',
      TAC_RESULT: 'TRANSACTION_SWIFT_TAC_RESULT',
      LLD_DETAIL: 'TRANSACTION_SWIFT_PUT_LLD_DETAIL',
      TRANSFER_RESULT: 'TRANSACTION_SWIFT_PUT_RESULT',
      BANK_DETAIL: 'TRANSACTION_SWIFT_PUT_BANK_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_SWIFT_PUT_TRANSFER_CONFIRMATION',
    },
    E_WALLET: {
      TRANSFER_DETAIL: 'TRANSACTION_E_WALLET_PUT_TRANSFER_DETAIL',
      TRANSFER_CONFIRMATION: 'TRANSACTION_E_WALLET_PUT_TRANSFER_CONFIRMATION',
      TRANSFER_TAC: 'TRANSACTION_E_WALLET_PUT_TRANSFER_TAC',
      TRANSFER_RESULT: 'TRANSACTION_E_WALLET_PUT_TRANSFER_RESULT',
      TRANSFER_RECEIPT: 'TRANSACTION_E_WALLET_PUT_TRANSFER_RECEIPT',
    },
  },
};

export const putResetTransfer = () => ({ type: actions.PUT.RESET_TRANSFER });

// SENDER & RECEIVER ACCOUNT
export const getSenderAccount = payload => ({ type: actions.GET.SENDER_ACCOUNT, payload });
export const getReceiverAccount = payload => ({ type: actions.GET.RECEIVER_ACCOUNT, payload });

export const putSenderAccountResult = res => ({ type: actions.PUT.SENDER_ACCOUNT_RESPONSE, res });
export const putReceiverAccountResult = res => ({ type: actions.PUT.RECEIVER_ACCOUNT_RESPONSE, res });
export const putSelectedReceiverAccount = body => ({ type: actions.PUT.SELECTED_RECEIVER_ACCOUNT, payload: body });

// OWN ACCOUNT
export const getOwnTransferReceipt = body => ({ type: actions.GET.OWN_ACCOUNT.RECEIPT, payload: body });
export const getOwnTransferTac = body => ({ type: actions.GET.OWN_ACCOUNT.REQUEST_TAC, payload: body });
export const getOwnTransferResult = body => ({ type: actions.GET.OWN_ACCOUNT.TRANSFER_RESULT, payload: body });
export const getOwnTransferConfirmation = body => ({ type: actions.GET.OWN_ACCOUNT.TRANSFER_CONFIRMATION, payload: body });

export const putOwnTransferTac = res => ({ type: actions.PUT.OWN_ACCOUNT.TAC_RESULT, payload: res });
export const putOwnTransferResult = res => ({ type: actions.PUT.OWN_ACCOUNT.TRANSFER_RESULT, payload: res });
export const putOwnTransferReceipt = res => ({ type: actions.PUT.OWN_ACCOUNT.RECEIPT, payload: res });
export const putOwnTransferConfirmation = res => ({ type: actions.PUT.OWN_ACCOUNT.TRANSFER_CONFIRMATION, payload: res });

// INTERBANK
export const getInterbankReceipt = body => ({ type: actions.GET.INTERBANK.RECEIPT, payload: body });
export const requestInterbankTAC = body => ({ type: actions.GET.INTERBANK.REQUEST_TAC, payload: body });
export const getInterbankResult = body => ({ type: actions.GET.INTERBANK.TRANSFER_RESULT, payload: body });
export const getInterbankTransferDetail = body => ({ type: actions.GET.INTERBANK.TRANSFER_DETAIL, payload: body });
export const getInterbankTransferConfirmation = body => ({ type: actions.GET.INTERBANK.TRANSFER_CONFIRMATION, payload: body });
export const getInterbankFavouriteDetail = payload => ({ type: actions.GET.INTERBANK.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putInterbankReceipt = res => ({ type: actions.PUT.INTERBANK.RECEIPT, res });
export const putInterbankTacResult = res => ({ type: actions.PUT.INTERBANK.TAC_RESULT, res });
export const putInterbankTransferResult = res => ({ type: actions.PUT.INTERBANK.TRANSFER_RESULT, res });
export const putInterbankConfirmation = res => ({ type: actions.PUT.INTERBANK.TRANSFER_CONFIRMATION, res });
export const putInterbankTransferDetail = res => ({ type: actions.PUT.INTERBANK.TRANSFER_DETAIL, payload: res });

// INTRABANK
export const getIntrabankReceipt = body => ({ type: actions.GET.INTRABANK.RECEIPT, payload: body });
export const requestIntrabankTAC = body => ({ type: actions.GET.INTRABANK.REQUEST_TAC, payload: body });
export const getIntrabankDetail = body => ({ type: actions.GET.INTRABANK.TRANSFER_DETAIL, payload: body });
export const getIntrabankResult = body => ({ type: actions.GET.INTRABANK.TRANSFER_RESULT, payload: body });
export const getIntrabankConfirmation = body => ({ type: actions.GET.INTRABANK.TRANSFER_CONFIRMATION, payload: body });
export const getIntrabankFavouriteDetail = payload => ({ type: actions.GET.INTRABANK.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putIntrabankTac = res => ({ type: actions.PUT.INTRABANK.TAC_RESULT, payload: res });
export const putIntrabankReceipt = res => ({ type: actions.PUT.INTRABANK.RECEIPT, payload: res });
export const putIntrabankDetail = res => ({ type: actions.PUT.INTRABANK.TRANSFER_DETAIL, payload: res });
export const putIntrabankResult = res => ({ type: actions.PUT.INTRABANK.TRANSFER_RESULT, payload: res });
export const putIntrabankConfirmation = res => ({ type: actions.PUT.INTRABANK.TRANSFER_CONFIRMATION, payload: res });

// SKN
export const getSknTransferDetail = payload => ({ type: actions.GET.SKN.TRANSFER_DETAIL, payload });
export const getSknTransferConfirmation = payload => ({ type: actions.GET.SKN.TRANSFER_CONFIRMATION, payload });
export const getSknTransferTac = payload => ({ type: actions.GET.SKN.REQUEST_TAC, payload });
export const getSknTransferResult = payload => ({ type: actions.GET.SKN.TRANSFER_RESULT, payload });
export const getSknTransferReceipt = payload => ({ type: actions.GET.SKN.RECEIPT, payload });
export const getSknTransfeFavouriteDetail = payload => ({ type: actions.GET.SKN.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putSknTransferTac = res => ({ type: actions.PUT.SKN.TAC_RESULT, payload: res });
export const putSknTransferReceipt = res => ({ type: actions.PUT.SKN.RECEIPT, payload: res });
export const putSknTransferDetail = res => ({ type: actions.PUT.SKN.TRANSFER_DETAIL, payload: res });
export const putSknTransferResult = res => ({ type: actions.PUT.SKN.TRANSFER_RESULT, payload: res });
export const putSknTransferConfirmation = res => ({ type: actions.PUT.SKN.TRANSFER_CONFIRMATION, payload: res });

// RTGS
export const getRtgsReceipt = payload => ({ type: actions.GET.RTGS.RECEIPT, payload });
export const requestRtgsTAC = payload => ({ type: actions.GET.RTGS.REQUEST_TAC, payload });
export const getRtgsDetail = payload => ({ type: actions.GET.RTGS.TRANSFER_DETAIL, payload });
export const getRtgsResult = payload => ({ type: actions.GET.RTGS.TRANSFER_RESULT, payload });
export const getRtgsConfirmation = payload => ({ type: actions.GET.RTGS.TRANSFER_CONFIRMATION, payload });
export const getRtgsFavouriteDetail = payload => ({ type: actions.GET.RTGS.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putRtgsTac = res => ({ type: actions.PUT.RTGS.TAC_RESULT, payload: res });
export const putRtgsReceipt = res => ({ type: actions.PUT.RTGS.RECEIPT, payload: res });
export const putRtgsDetail = res => ({ type: actions.PUT.RTGS.TRANSFER_DETAIL, payload: res });
export const putRtgsResult = res => ({ type: actions.PUT.RTGS.TRANSFER_RESULT, payload: res });
export const putRtgsConfirmation = res => ({ type: actions.PUT.RTGS.TRANSFER_CONFIRMATION, payload: res });

// SWIFT
export const getSwiftBank = body => ({ type: actions.GET.SWIFT.BANKS, payload: body });
export const getSwiftAccess = body => ({ type: actions.GET.SWIFT.ACCESS, payload: body });
export const getSwiftReceipt = body => ({ type: actions.GET.SWIFT.RECEIPT, payload: body });
export const requestSwiftTAC = body => ({ type: actions.GET.SWIFT.REQUEST_TAC, payload: body });
export const getSwiftLldDetail = body => ({ type: actions.GET.SWIFT.LLD_DETAIL, payload: body });
export const getSwiftBankDetail = body => ({ type: actions.GET.SWIFT.BANK_DETAIL, payload: body });
export const getSwiftResult = body => ({ type: actions.GET.SWIFT.TRANSFER_RESULT, payload: body });
export const getSwiftConfirmation = body => ({ type: actions.GET.SWIFT.TRANSFER_CONFIRMATION, payload: body });
export const getSwiftBankFavouriteDetail = body => ({ type: actions.GET.SWIFT.TRANSFER_FROM_FAVOURITE_DETAIL, payload: body });

export const putSwiftBank = res => ({ type: actions.PUT.SWIFT.BANKS, payload: res });
export const putSwiftTAC = res => ({ type: actions.PUT.SWIFT.TAC_RESULT, payload: res });
export const putSwiftAccess = res => ({ type: actions.PUT.SWIFT.ACCESS, payload: res });
export const puttSwiftReceipt = res => ({ type: actions.PUT.SWIFT.RECEIPT, payload: res });
export const putSwiftLldDetail = res => ({ type: actions.PUT.SWIFT.LLD_DETAIL, payload: res });
export const putSwiftBankDetail = res => ({ type: actions.PUT.SWIFT.BANK_DETAIL, payload: res });
export const putSwiftResult = res => ({ type: actions.PUT.SWIFT.TRANSFER_RESULT, payload: res });
export const putSwiftTransferConfirmation = res => ({ type: actions.PUT.SWIFT.TRANSFER_CONFIRMATION, payload: res });

// VIRTUAL ACCOUNT TRANSFER
export const getVirtualAccountDetail = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.TRANSFER_DETAIL, payload });
export const getVirtualAccountConfirmation = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.TRANSFER_CONFIRMATION, payload });
export const requestVirtualAccountTac = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.TAC_RESULT, payload });
export const getVirtualAccountResult = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.TRANSFER_RESULT, payload });
export const getVirtualAccountReceipt = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.RECEIPT, payload });
export const getVAFavouriteDetail = payload => ({ type: actions.GET.VIRTUAL_ACCOUNT.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putVirtualAccountDetail = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.TRANSFER_DETAIL, payload: res });
export const putVirtualAccountConfirmation = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.TRANSFER_CONFIRMATION, payload: res });
export const putVirtualAccountTac = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.TAC_RESULT, payload: res });
export const putVirtualAccountResult = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.TRANSFER_RESULT, payload: res });
export const putVirtualAccountReceipt = res => ({ type: actions.PUT.VIRTUAL_ACCOUNT.RECEIPT, payload: res });

// EWALLET TRANSFER
export const getEWalletDetail = payload => ({ type: actions.GET.E_WALLET.TRANSFER_DETAIL, payload });
export const getEWalletConfirmation = payload => ({ type: actions.GET.E_WALLET.TRANSFER_CONFIRMATION, payload });
export const requestEWalletTac = payload => ({ type: actions.GET.E_WALLET.REQUEST_TAC, payload });
export const getEWalletResult = payload => ({ type: actions.GET.E_WALLET.TRANSFER_RESULT, payload });
export const getEWalletReceipt = payload => ({ type: actions.GET.E_WALLET.TRANSFER_RECEIPT, payload });
export const putEWalletDetail = res => ({ type: actions.PUT.E_WALLET.TRANSFER_DETAIL, payload: res });
export const getEWalletFavouriteDetail = payload => ({ type: actions.GET.E_WALLET.TRANSFER_FROM_FAVOURITE_DETAIL, payload });

export const putEWalletConfirmation = res => ({ type: actions.PUT.E_WALLET.TRANSFER_CONFIRMATION, payload: res });
export const putEWalletTac = res => ({ type: actions.PUT.E_WALLET.TRANSFER_TAC, payload: res });
export const putEWalletResult = res => ({ type: actions.PUT.E_WALLET.TRANSFER_RESULT, payload: res });
export const putEWalletReceipt = res => ({ type: actions.PUT.E_WALLET.TRANSFER_RECEIPT, payload: res });
