import { actions } from '../actions/error';

const { ERROR_RESPONSE } = actions.PUT;

const initialState = {
  errorResponse: {},
};

const ErrorReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ERROR_RESPONSE:
      return { ...state, errorResponse: action.payload };

    default:
      return state;
  }
};

export default ErrorReducer;
