import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { actions, putEWalletTac, putEWalletDetail, putEWalletResult, putEWalletConfirmation } from 'middleware/actions/favourite/transfer';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.EWALLET;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.EWALLET;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const deleteEWalletFavourite = ({ body }) => Instance.post(endpoint.eWallet.favourite.delete, body);
const eWalletResultFavourite = ({ body }) => Instance.post(endpoint.eWallet.favourite.result, body);
const newFavouriteDetail = ({ body }) => Instance.post(endpoint.eWallet.favourite.addNewDetail, body);
const deleteEWalletResult = ({ body }) => Instance.post(endpoint.eWallet.favourite.deleteResult, body);
const requestDeleteEWalletTac = ({ body }) => Instance.post(endpoint.eWallet.favourite.deleteRequestTac, body);
const eWalletConfirmationFavourite = ({ body }) => Instance.post(endpoint.eWallet.favourite.confirmation, body);
const eWalletAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.eWallet.favourite.addNewRequestTac, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.eWallet.favourite.addNewDetailFromTransfer, body);
const eWalletEditFormDetail = ({ body }) => Instance.post(endpoint.eWallet.favourite.editDetail, body);
const eWalletEditConfirmDetail = ({ body }) => Instance.post(endpoint.eWallet.favourite.editConfirm, body);
const eWalletEditTac = ({ body }) => Instance.post(endpoint.eWallet.favourite.editRequestTac, body);
const eWalletEditResult = ({ body }) => Instance.post(endpoint.eWallet.favourite.editResult, body);

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });
    yield put(putEWalletDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });
    yield put(putEWalletDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(eWalletConfirmationFavourite, { body });
    yield put(putEWalletConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(eWalletAddNewRequestTacFavourite, { body });

    yield put(putEWalletTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(eWalletResultFavourite, { body });
    yield put(putEWalletResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteEWalletFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteEWalletFavourite, { body });
    yield put(putEWalletConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteEWalletTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteEWalletTac, { body });
    yield put(putEWalletTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteEWalletResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteEWalletResult, { body });
    yield put(putEWalletResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(eWalletEditFormDetail, { body });
    yield put(putEWalletDetail(formatTransferConfirmation({ ...data, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(eWalletEditConfirmDetail, { body });
    yield put(putEWalletConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(eWalletEditTac, { body });
    yield put(putEWalletTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* eWalletEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(eWalletEditResult, { body });
    yield put(putEWalletResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* EWalletFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, eWalletResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteEWalletResultSaga),
    takeLatest(REQUEST_TAC, eWalletAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, eWalletConfirmationFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteEWalletTacSaga),
    takeLatest(DELETE_CONFIRMATION, deleteEWalletFavouriteSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, eWalletEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, eWalletEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, eWalletEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, eWalletEditResultFavouriteSaga),
  ]);
}
