import { useState } from 'react';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { checkFormFormat, validate, validResult } from 'utils/form-validation';

import { DEFAULT_TEXTFIELD_MAX_CHARS, HAS_OPTION_GRID_SIZE, NO_OPTION_GRID_SIZE } from 'settings/constants/transaction';
import {
  GRID_SIZE_HORIZONTAL,
  GRID_SIZE_VERTICAL,
  GRID_VARIANT_DEFAULT,
  TABLET_SCREEN_SIZE,
  MODAL_FULLSCREEN_SIZE as MOBILE_SCREEN_SIZE,
} from 'settings/constants/ui-control';

import { Select, Textfield, TextLabel } from 'components/common';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DynoTextfield = ({
  value,
  name,
  label,
  variant,
  readOnly,
  rightLabel,
  hasRightLabel,
  validationRules,
  onChange,
  getValue,
  isLeftLabelNoSpace,
  ...props
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const tabletScreen = useMediaQuery(theme.breakpoints.up(TABLET_SCREEN_SIZE));
  const mobileScreen = useMediaQuery(theme.breakpoints.down(MOBILE_SCREEN_SIZE));

  const [validationResult, setValidationResult] = useState(validResult);
  const [optionValidationResult, setOptionValidationResult] = useState(validResult);

  const className = get(props, 'className', '');
  const leftLabel = get(props, 'leftLabel', '');
  const bottomLabel = get(props, 'bottomLabel', '');
  const isHeader = get(props, 'isHeader', false);
  const format = get(props, 'format', false);
  const placeholderId = get(props, 'placeholder', '');
  const isForm = get(props, 'isForm', true);
  const isThinPadding = get(props, 'isThinPadding', false);

  const leftLabelClass = leftLabel ? 'textfield--amount textfield--label' : '';
  const rightLabelClass = hasRightLabel ? 'textfield--label' : '';
  const readOnlyClass = readOnly ? 'readonly' : '';
  const textFieldClass = `${className} ${leftLabelClass} ${rightLabelClass} ${readOnlyClass}`;
  const headerClassname = isHeader ? 'pv3 ph4 bg-light-gray' : `pb0 pt3 ${isThinPadding ? 'ph3' : 'ph4'}`;

  const validField = getValue('validField', {});
  const maxLength = get(props, 'maxLength', DEFAULT_TEXTFIELD_MAX_CHARS);
  // indicate if the field is the first on form
  const isFirstField = get(props, 'isFirstField', false);

  const hasTooltip = get(props, 'hasToolTip', false);
  const tooltipTitleID = get(props, 'toolTipTitle', '');
  const toolTipTitle = hasTooltip ? formatMessage({ id: tooltipTitleID }) : '';

  // optionField
  const hasOption = get(props, 'hasOption', false);
  const optionField = get(props, 'optionField', {});
  const optionsFieldName = get(optionField, 'name', '');
  const optionsValidationRules = get(optionField, 'validationRules', '');
  const selectValue = getValue(optionsFieldName);
  const options = get(optionField, 'options', []);
  const isTextField = get(optionField, 'isTextField', false);
  const optionReadOnly = get(optionField, 'readOnly', false);
  const childGridSize = hasOption ? HAS_OPTION_GRID_SIZE : NO_OPTION_GRID_SIZE;

  const handleOnBlur = event => {
    const { isValid, messageId, param } = validate(validationRules, event);
    setValidationResult({ isValid, messageId, param });
    onChange({ validField: { ...validField, [name]: isValid } });
  };

  const handleOptionChange = event => {
    const { isValid, messageId, param } = validate(optionsValidationRules, event);
    setOptionValidationResult({ isValid, messageId, param });
    onChange({ [optionsFieldName]: event.target.value, validField: { ...validField, [optionsFieldName]: isValid } });
  };

  const handleOnClose = e => {
    // no selection made, component will return undefined, selection made component will return 0
    if (isUndefined(e.target.value) && !selectValue) {
      const event = { target: { value: selectValue, name: optionsFieldName } };

      const { isValid, messageId, param } = validate(optionsValidationRules, event);
      setOptionValidationResult({ isValid, messageId, param });
      onChange({ validField: { ...validField, [optionsFieldName]: isValid } });
    }
  };

  const { isValid, messageId, param } = validationResult;
  const errorMessage = !isValid && formatMessage({ id: messageId, defaultMessage: messageId }, param);
  const placeholder = placeholderId ? formatMessage({ id: placeholderId }) : '';

  const { isValid: optionIsValid, messageId: optionMessageId, param: optionParam } = optionValidationResult;
  const optionErrorMessage = !optionIsValid && formatMessage({ id: optionMessageId, defaultMessage: optionMessageId }, optionParam);

  const gridSize = variant === GRID_VARIANT_DEFAULT ? GRID_SIZE_VERTICAL : GRID_SIZE_HORIZONTAL;

  return (
    <Grid container alignItems={!isValid && (!isFirstField || hasOption) ? `baseline` : `center`} className={headerClassname}>
      <Grid item xs={gridSize.label.xs} sm={gridSize.label.sm} className="pb2">
        {label && (
          <TextLabel
            label={formatMessage({ id: label, defaultMessage: label })}
            hasIcon={hasTooltip}
            tooltipTitle={toolTipTitle}
            className="pl1-ns"
          />
        )}
      </Grid>

      <Grid container item sm={gridSize.input.sm} md={gridSize.input.sm}>
        {hasOption && (
          <Grid
            item
            xs={12}
            sm={3}
            className="select-padding pb3-ns"
            style={{ paddingRight: tabletScreen ? '0.5rem' : '0', paddingBottom: mobileScreen ? '1rem' : '0' }}
          >
            {isTextField ? (
              <Textfield value={selectValue} readOnly={optionReadOnly} disabled={!optionReadOnly} className="black" />
            ) : (
              <Select
                name={optionsFieldName}
                fullWidth
                options={options}
                value={selectValue}
                onChange={handleOptionChange}
                onClose={handleOnClose}
                hasError={!optionIsValid}
              />
            )}
          </Grid>
        )}
        <Grid item xs={gridSize.input.xs} sm={childGridSize}>
          {!isForm ? (
            <span className={textFieldClass}>{value}</span>
          ) : (
            <Textfield
              hasError={!isValid}
              errorMessage={errorMessage}
              id={name}
              name={name}
              value={value}
              rightLabel={rightLabel}
              hasRightLabel={hasRightLabel}
              hasLeftLabel={!isEmpty(leftLabel)}
              leftLabel={leftLabel}
              readOnly={readOnly}
              className={textFieldClass}
              onBlur={e => handleOnBlur(e)}
              placeholder={placeholder}
              maxLength={maxLength}
              isLeftLabelNoSpace={isLeftLabelNoSpace}
              onChange={e => onChange({ [name]: checkFormFormat(e.target.value, format) })}
            />
          )}
          {!isEmpty(bottomLabel) && <TextLabel label={formatMessage({ id: bottomLabel })} className="pt2" />}
          {!optionIsValid && isValid && <p className="pt2 red">{optionErrorMessage}</p>}
        </Grid>
      </Grid>
    </Grid>
  );
};

DynoTextfield.defaultProps = {
  value: '',
  label: '',
  rightLabel: '',
  readOnly: false,
  hasRightLabel: false,
  validationRules: [],
  isLeftLabelNoSpace: false,
  variant: GRID_VARIANT_DEFAULT,
};

DynoTextfield.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  readOnly: PropTypes.bool,
  rightLabel: PropTypes.string,
  hasRightLabel: PropTypes.bool,
  isLeftLabelNoSpace: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  validationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

export default DynoTextfield;
