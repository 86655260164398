export const gbi = {
  title: 'Simulate Goal',
  stepper: {
    chooseFinancialPlan: 'Select Financial Goal',
    planning: 'Planning',
    simulationResult: 'Simulation Result',
  },
  pensionFund: {
    type: 'Retirement Fund',
    title: 'Prepare a Pension Fund',
    description: 'I want to prepare a pension fund so I can enjoy my retirement peacefully and comfortably',
    now: 'Now',
    retireAt: 'Retire at {targetAge}',
    lastExpense: 'My life expectancy',
    planningTitle: 'Simulate Retirement Fund',
    resultHeader: 'My Retirement Goal',
    resultTitle: 'Pension Fund Simulation',
    targetTitle: 'Target Fund required during Retirement',
    investmentTitle: 'To achieve your financial goal, your monthly investment will be:',
  },
  maxInvestment: {
    type: 'Maximize Investment',
    title: 'Maximize Investment',
    description: 'I want to invest my funds to the fullest',
    now: 'Now',
    targetAchieve: 'Target achieve at {targetAge}',
    planningTitle: 'Simulate Maximize Investment',
    resultHeader: 'My Investment Goal',
    resultTitle: 'Maximize Investment Simulation',
    targetTitle: 'Projection',
    investmentTitle: 'To achieve your financial goal, your monthly investment will be:',
  },
  educationFund: {
    type: 'Education Fund',
    title: 'Prepare Education Fund',
    description: "I want to make sure my or my child's education funds are available",
    now: 'Now',
    targetAchieve: 'Target achieve at ',
    planningTitle: 'Simulate Education Fund',
    resultHeader: 'My Education Goal',
    resultTitle: 'Education Fund Simulation',
    targetTitle: 'Target fund required for Education',
    investmentTitle: 'To achieve your financial goal, your monthly investment will be:',
  },
  form: {
    name: {
      label: 'Name of goal',
      placeholder: 'Max 40 chars',
    },
    years: 'Years',
    yearsOld: 'Years Old',
    investmentPeriod: {
      label: 'Investment period (years)',
      shortLabel: 'Investment period',
      placeholder: 'Min 2 years',
    },
    monthlyAmount: {
      label: 'Monthly investment amount',
      shortLabel: 'Monthly investment',
      placeholder: 'Min IDR 100K multiple of IDR 10K',
    },
    targetFund: {
      label: 'Target funds to be achieved',
      shortLabel: 'Target fund',
      placeholder: 'Min IDR 1M multiple IDR 10K',
    },
    initialDeposit: {
      label: 'Initial Deposits (Optional)',
      shortLabel: 'Initial fund placement',
      placeholder: 'Min IDR 100K multiple IDR 10K',
    },
    initialFundPlacement: {
      label: 'Initial fund placement amount (optional)',
      shortLabel: 'Initial fund placement',
      placeholder: 'Min IDR 100K multiple IDR 10K',
    },
    riskProfile: 'Goals risk profile',
    retireAge: {
      label: 'Retire at age (years)',
      shortLabel: 'Retirement Age',
      placeholder: 'Min +5 years from current age',
    },
    lifeExpectancy: {
      label: 'My life expectancy (years)',
      shortLabel: 'My life expectancy',
      placeholder: 'Between 60 - 100 years old',
    },
    monthlyExpense: {
      label: 'Monthly expenses during retirement (IDR)',
      shortLabel: 'Monthly expenses',
      bottomShortLabel: 'Expenses during retirement',
      placeholder: 'Min IDR 1M multiple IDR 10K',
    },
    fundraisingPeriod: {
      label: 'Fundraising period (years)',
      shortLabel: 'Fundraising period',
      placeholder: 'Min 2 years',
    },
    bpjsTitle: 'Include my BPJS Pension Fund (optional)',
    bpjsDescription: 'Get more accurate simulation by including BPJS Pension Fund into the calculation',
    bpjsBalance: 'BPJS Account Balance',
    bpjsContribution: 'BPJS Monthly Contribution',
    bpjsBalanceTooltip: 'Total balance of your BPJS Ketenagakerjaan Pension Fund at this moment',
    bpjsContributionTooltip:
      'Total monthly contribution to your BPJS Ketenagakerjaan Pension Fund (including employee contribution, if any)',
  },
  riskProfile: {
    conservative: 'Conservative',
    aggressive: 'Aggressive',
    moderate: 'Moderate',
    conservativeMessage: 'Low risk with lower return',
    aggressiveMessage: 'High risk with higher return',
    moderateMessage: 'Medium risk with medium return',
  },
  chart: {
    million: 'Million',
    newTarget: 'New Target',
    prevTarget: 'Initial Target',
    simulation: '{goalName} Goal Simulation',
    disclaimer:
      'The calculation of target funds does not consider inflation or other financial factors and is solely based on the specified monthly expenditure.<br/><br/>The above simulation are indicative and such information is not intended to provide professional advice and should not be interpreted as an offer to sell or a solicitation to buy any investment or savings product. This information is not a guarantee to predict the performance of future investment returns.',
    notation: {
      k: 'k=thousand',
      m: 'm=million',
      b: 'b=billion',
      t: 't=trillion',
    },
  },
  resultTitle: 'Investment Allocation Recommendation',
  appTitle: 'Continue to Finalize and Reach Your Financial Goal in M2U ID App',
  appDescription: 'Make your {goalType} plan come true in M2U ID App',
  appCaption: 'In M2U ID App, you can simulate, choose investment products and setup a recurring investment to reach your Goal.',
  tutorialLink:
    'https://www.maybank.co.id/en/ebanking/how-to/Maybank2u/panduan-digital-wealth-customer-360?utm_source=WA&utm_medium=eksternal&utm_campaign=vb-21032022-panduan-digital-wealth-customer-360',
  errorMessage: {
    required: 'This field cannot be empty',
    minAmount: 'The value you entered is less than the minimum limit of {amount}',
    maxAmount: 'The value you entered is exceeding the maximum limit of {amount}',
    goalName: 'Please fill in Name of Goal',
    retireAge: 'Please fill in Retire at age',
    lifeExpectancy: 'Please fill in My life expectancy',
    monthlyExpense: 'Please fill in Monthly expenses during retirement',
    investmentPeriod: 'Please fill in Investment period',
    monthlyAmount: 'Please fill in Monthly investment amount',
    targetFund: 'Please fill in Target funds to be achieved',
    fundraisingPeriod: 'Please fill in Fundraising period',
    validLifeExpectancy: 'Life Expectancy must be between 60 - 100 years old at least 1 year from Retirement Age',
    minMaxAmount: 'Please enter with minimum limit {min} and maximum limit {max}',
    minAmountForDW: 'Please enter with minimum limit {min}',
    minAmountWithMultiple: 'Please enter with minimum limit {min} in multiplies of {multiple}',
    minMaxAmountWithMultiple: 'Please enter with minimum limit {min} in multiplies of {multiple} and maximum limit {max}.',
    minMaxAmountWithMultipleAndZero:
      'Please enter with minimum limit {min} in multiplies of {multiple} and maximum limit {max}. Or you can input IDR 0.',
    minMaxInvestmentPeriod: 'Please enter tenor more than 1 year or maximum 30 years',
  },
};
