import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useProgressBarStyle } from './style/material';

const ProgressBar = ({ value, colour }) => {
  const classes = useProgressBarStyle();
  return <LinearProgress classes={classes} variant="determinate" value={value} className={`common progress-bar ${colour}`} />;
};

ProgressBar.defaultProps = { value: 0, colour: '' };

ProgressBar.propTypes = { value: PropTypes.number, colour: PropTypes.string };

export default ProgressBar;
