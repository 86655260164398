import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';

import { get } from 'utils/lodash';
import { encrypt } from 'utils/crypto';
import { formatArrayKeys, formatKeys } from 'utils/formatter';
import { registerEndpoint as endpoint } from 'providers/endpoints/registration';
import {
  formatConfirmRegisterBody,
  formatCredentialBody,
  formatCredentialResult,
  formatPinResult,
  getRequestTacBody,
} from 'utils/registration';

import { KEYMAP } from 'settings/constants/keymap';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

import { putErrorResponse } from 'middleware/actions/error';
import { actions, putRegisterAccess, putRegisterResult, putCredentialResult, putPinResult, putTacResult } from '../actions/registration';

const getRegisterAccess = ({ body }) => Instance.post(endpoint.get.access, body);
const verifyPin = ({ body }) => Instance.post(endpoint.verify.pin, body);
const requestTAC = ({ body }) => Instance.post(endpoint.requestTAC, body);
const getRegisterResult = ({ body }) => Instance.post(endpoint.get.result, body);
const verifyCredential = ({ body }) => Instance.post(endpoint.verify.credential, body);

const getLocale = state => state.LanguageReducer.locale;
const getPinResult = state => state.RegistrationReducer.pinResult;
const getTacResult = state => state.RegistrationReducer.tacResult;
const getCredentialResult = state => state.RegistrationReducer.credentialResult;

const { PIN, CREDENTIAL } = actions.VERIFY;
const { REGISTER_ACCESS, REGISTER_RESULT } = actions.GET;

function* getRegisterAccessSaga() {
  try {
    const locale = yield select(getLocale);
    const body = { locale };

    const { data } = yield call(getRegisterAccess, { body });
    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      const initialCurrencies = get(data, 'currencyMap', []);
      const res = {
        currencies: formatArrayKeys(initialCurrencies),
        accessStatus: get(data, 'serviceInfoBean.status', ''),
        policyLink: get(data, 'serviceInfoBean.mainNote6', ''),
        registerLink: get(data, 'serviceInfoBean.mainNote5', ''),
      };
      yield put(putRegisterAccess(res));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* verifyPinSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const renamedBody = formatKeys(payload, KEYMAP);
    const registerInfo = get(renamedBody, 'encRegistrationData', {});
    // NOTE: Need to stringify the object first, else will get error because system cannot decrypt
    const encryptedBody = { ...renamedBody, encRegistrationData: encrypt(JSON.stringify(registerInfo)) };
    const body = { locale, ...encryptedBody };

    const { data } = yield call(verifyPin, { body });
    const responseCode = get(data, 'responseCode', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield put(putPinResult({ isSuccess: true, responseCode: SUCCESS_RESPONSE_CODE, res: formatPinResult(data, body) }));
    } else {
      yield put(putPinResult({ isSuccess: false, responseCode, responseMessage: get(data, 'responseMessage', '') }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTacSaga({ payload = {} }) {
  try {
    const body = formatKeys(payload, KEYMAP);

    const { data: res } = yield call(requestTAC, { body });
    const responseCode = get(res, 'responseCode', UNRESPONSIVE_HOST);
    const responseMessage = get(res, 'responseMessage', '');

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield put(putTacResult({ isSuccess: true, responseCode: SUCCESS_RESPONSE_CODE, res }));
    } else {
      yield put(putTacResult({ isSuccess: false, responseCode, responseMessage }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* verifyCredentialSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);

    const initialBody = formatCredentialBody(payload, locale);
    const renamedBody = formatKeys(initialBody, KEYMAP);
    const userInfo = get(renamedBody, 'encUserData', {});
    const encryptedBody = { ...renamedBody, encUserData: encrypt(JSON.stringify(userInfo)) };
    const body = { locale, ...encryptedBody };

    const { data } = yield call(verifyCredential, { body });
    const responseCode = get(data, 'responseCode', '');
    const userAlias = get(renamedBody, 'encUserData.userAlias', '');
    const credentialResult = formatCredentialResult(data, payload, userAlias);

    if (responseCode === SUCCESS_RESPONSE_CODE) {
      yield put(putCredentialResult({ isSuccess: true, responseCode: SUCCESS_RESPONSE_CODE, res: credentialResult }));

      // Auto request TAC number
      const tacPayload = getRequestTacBody(credentialResult, locale);
      yield call(requestTacSaga, { payload: tacPayload });
    } else {
      yield put(putCredentialResult({ isSuccess: false, responseCode, responseMessage: get(data, 'responseMessage', '') }));
    }
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getRegisterResultSaga({ payload }) {
  try {
    const pinResult = yield select(getPinResult);
    const credentialResult = yield select(getCredentialResult);
    const tacResponseResult = yield select(getTacResult);

    const prepareBody = formatConfirmRegisterBody(pinResult, credentialResult, tacResponseResult);
    const renamedBody = formatKeys(prepareBody, KEYMAP);
    const tacValue = payload;
    const locale = yield select(getLocale);

    const body = { tacValue, locale, ...renamedBody };

    const { data } = yield call(getRegisterResult, { body });
    const responseCode = get(data, 'responseCode', '');
    const responseMessage = get(data, 'responseMessage', '');
    const isSuccess = responseCode === SUCCESS_RESPONSE_CODE;

    yield put(putRegisterResult({ isSuccess, responseCode, responseMessage, res: data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Registration() {
  yield all([
    takeLatest(PIN, verifyPinSaga),
    takeLatest(CREDENTIAL, verifyCredentialSaga),
    takeLatest(REGISTER_ACCESS, getRegisterAccessSaga),
    takeLatest(REGISTER_RESULT, getRegisterResultSaga),
  ]);
}
