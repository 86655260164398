import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Textfield } from 'components/common';

import { alphaNumeric, formatBankName } from 'utils/formatter';

import { SWIFT_CODE_MAX_LENGTH } from 'settings/constants/transfer/swift';

import { BENEFICIARY_ADDRESS_MAX_LENGTH } from 'settings/constants/transaction';

const SwiftTextfields = ({ bankName, bankSwiftCode, onChange }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container spacing={2} className="flex items-center">
      <Grid item xs={12} md={5}>
        <Textfield
          value={bankName}
          maxLength={BENEFICIARY_ADDRESS_MAX_LENGTH}
          onChange={e => onChange({ bankName: e.target.value })}
          placeholder={formatMessage({ id: 'transaction.transfer.swift.placeholder.bankName' })}
        />
      </Grid>
      <Grid item xs>
        <Typography className="gray b tc">{formatMessage({ id: 'transaction.transfer.or' })}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Textfield
          value={bankSwiftCode}
          maxLength={SWIFT_CODE_MAX_LENGTH}
          onChange={e => onChange({ bankSwiftCode: e.target.value })}
          placeholder={formatMessage({ id: 'transaction.transfer.swift.placeholder.swiftCode' })}
        />
      </Grid>
    </Grid>
  );
};

SwiftTextfields.defaultProps = { bankName: '', bankSwiftCode: '' };

SwiftTextfields.propTypes = {
  bankName: PropTypes.string,
  bankSwiftCode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SwiftTextfields;
