import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putVirtualAccountTac,
  putVirtualAccountDetail,
  putVirtualAccountResult,
  putVirtualAccountConfirmation,
  putVirtualAccountDetailAfterTransfer,
} from 'middleware/actions/favourite/transfer';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import { formatTransferConfirmation } from 'utils/transfer/formatter';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.VIRTUAL_ACCOUNT;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.VIRTUAL_ACCOUNT;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const newFavouriteDetail = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.addNewDetail, body);
const deleteVirtualAccountFavourite = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.delete, body);
const virtualAccountResultFavourite = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.result, body);
const deleteVirtualAccountResult = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.deleteResult, body);
const requestDeleteVirtualAccountTac = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.deleteRequestTac, body);
const virtualAccountConfirmationFavourite = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.confirmation, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.addNewDetailFromTransfer, body);
const virtualAccountAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.addNewRequestTac, body);
const virtualAccountEditFormDetail = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.editDetail, body);
const virtualAccountEditConfirmDetail = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.editConfirm, body);
const virtualAccountEditTac = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.editRequestTac, body);
const virtualAccountEditResult = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.editResult, body);

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });

    yield put(putVirtualAccountDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });

    yield put(putVirtualAccountDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(virtualAccountConfirmationFavourite, { body });
    yield put(putVirtualAccountConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(virtualAccountAddNewRequestTacFavourite, { body });

    yield put(putVirtualAccountTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(virtualAccountResultFavourite, { body });

    yield put(putVirtualAccountResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteVirtualAccountFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteVirtualAccountFavourite, { body });
    yield put(putVirtualAccountConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteVirtualAccountTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteVirtualAccountTac, { body });
    yield put(putVirtualAccountTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteVirtualAccountResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteVirtualAccountResult, { body });
    yield put(putVirtualAccountResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(virtualAccountEditFormDetail, { body });
    yield put(putVirtualAccountDetail(formatTransferConfirmation({ ...data, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(virtualAccountEditConfirmDetail, { body });
    yield put(putVirtualAccountConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(virtualAccountEditTac, { body });
    yield put(putVirtualAccountTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* virtualAccountEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(virtualAccountEditResult, { body });
    yield put(putVirtualAccountResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* VirtualAccountFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, virtualAccountResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteVirtualAccountResultSaga),
    takeLatest(REQUEST_TAC, virtualAccountAddNewTacFavouriteSaga),
    takeLatest(CONFIRMATION, virtualAccountConfirmationFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteVirtualAccountTacSaga),
    takeLatest(DELETE_CONFIRMATION, deleteVirtualAccountFavouriteSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, virtualAccountEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, virtualAccountEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, virtualAccountEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, virtualAccountEditResultFavouriteSaga),
  ]);
}
