import { BELOW_TRANSACTION_LIMIT, EXCEEED_TRANSCTION_LIMIT, INVALID_BENEFICIARY_ACCOUNT } from 'settings/constants/response-codes';
import {
  FAV_PAYMENT_MAYBANK_CC,
  FAV_PAYMENT_OTHER_BILL,
  FAV_PAYMENT_OTHER_CC,
  FAV_PAYMENT_POSTPAID,
  FAV_PAYMENT_UTILITY,
  MOBILE_POSTPAID,
  OTHER_BANK_CC,
  OTHER_MAYBANK_CC,
  PAYMENT_CREDIT_CARD,
  PAYMENT_OTHER_BILL,
  PAYMENT_UTILITY,
} from 'settings/constants/transaction';

export const BANCA_TABLE_UNWANTED_KEYS = [
  'actionCode',
  'asOfDate',
  'balanceAmount',
  'errorCode',
  'errorMsg',
  'currencyCode',
  'hostDateTime',
  'hostFrontEndErrorMsg',
  'hostResourceServiceName',
  'index',
  'payorName',
  'receiptNumber',
  'responseCode',
  'responseDescription',
  'policyNumber',
  'productAccountNo',
];

export const INTERBANK_ERROR_CODES = [BELOW_TRANSACTION_LIMIT, EXCEEED_TRANSCTION_LIMIT, INVALID_BENEFICIARY_ACCOUNT];

export const BALANCE_SUMMARY_ORDERS = [{ type: 'CASA' }, { type: 'TD' }, { type: 'CC' }, { type: 'LOAN' }, { type: 'WEALTH' }];

export const FAVOURITE_PAYMENT_BILLTYPE_KEYS = new Map([
  [FAV_PAYMENT_MAYBANK_CC, PAYMENT_CREDIT_CARD],
  [FAV_PAYMENT_OTHER_CC, PAYMENT_CREDIT_CARD],
  [FAV_PAYMENT_POSTPAID, MOBILE_POSTPAID],
  [FAV_PAYMENT_UTILITY, PAYMENT_UTILITY],
  [FAV_PAYMENT_OTHER_BILL, PAYMENT_OTHER_BILL],
]);

export const FAVOURITE_PAYMENT_CC_TYPE_KEYS = new Map([
  [FAV_PAYMENT_MAYBANK_CC, OTHER_MAYBANK_CC],
  [FAV_PAYMENT_OTHER_CC, OTHER_BANK_CC],
]);
