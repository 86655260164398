import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { actions, putComposeAccess, putComposeSend } from 'middleware/actions/inboxs';
import { inboxsEndpoint as endpoint } from 'providers/endpoints/inboxs';
import { putErrorResponse } from 'middleware/actions/error';

import Instance from 'providers/instance';

const getLocale = state => state.LanguageReducer.locale;
const getComposeAccessList = ({ body }) => Instance.post(endpoint.compose.access, body);
const sendCompose = ({ body }) => Instance.post(endpoint.compose.send, body);

function* getComposeAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getComposeAccessList, { body });
    yield put(putComposeAccess(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* sendComposeSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(sendCompose, { body });

    yield put(putComposeSend(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Compose() {
  yield all([
    takeLatest(actions.GET.INBOX.INBOXS_COMPOSE_ACCESS, getComposeAccessSaga),
    takeLatest(actions.GET.INBOX.INBOXS_COMPOSE_SEND, sendComposeSaga),
  ]);
}
