export const MODALS = formatMessage => [
  {
    key: '2193',
    label: formatMessage({ id: 'lldDescription.modal.2193' }),
  },
  {
    key: '2194',
    label: formatMessage({ id: 'lldDescription.modal.2194' }),
  },
  {
    key: '2197',
    label: formatMessage({ id: 'lldDescription.modal.2197' }),
  },
  {
    key: '2198',
    label: formatMessage({ id: 'lldDescription.modal.2198' }),
  },
  {
    key: '2203',
    label: formatMessage({ id: 'lldDescription.modal.2203' }),
  },
  {
    key: '2204',
    label: formatMessage({ id: 'lldDescription.modal.2204' }),
  },
  {
    key: '2206',
    label: formatMessage({ id: 'lldDescription.modal.2206' }),
  },
  {
    key: '2207',
    label: formatMessage({ id: 'lldDescription.modal.2207' }),
  },
  {
    key: '2221',
    label: formatMessage({ id: 'lldDescription.modal.2221' }),
  },
  {
    key: '2222',
    label: formatMessage({ id: 'lldDescription.modal.2222' }),
  },
  {
    key: '2231',
    label: formatMessage({ id: 'lldDescription.modal.2231' }),
  },
  {
    key: '2232',
    label: formatMessage({ id: 'lldDescription.modal.2232' }),
  },
  {
    key: '2233',
    label: formatMessage({ id: 'lldDescription.modal.2233' }),
  },
  {
    key: '2383',
    label: formatMessage({ id: 'lldDescription.modal.2383' }),
  },
  {
    key: '2384',
    label: formatMessage({ id: 'lldDescription.modal.2384' }),
  },
  {
    key: '2385',
    label: formatMessage({ id: 'lldDescription.modal.2385' }),
  },
  {
    key: '2386',
    label: formatMessage({ id: 'lldDescription.modal.2386' }),
  },
  {
    key: '2387',
    label: formatMessage({ id: 'lldDescription.modal.2387' }),
  },
  {
    key: '2388',
    label: formatMessage({ id: 'lldDescription.modal.2388' }),
  },
  {
    key: '2389',
    label: formatMessage({ id: 'lldDescription.modal.2389' }),
  },
  {
    key: '2390',
    label: formatMessage({ id: 'lldDescription.modal.2390' }),
  },
  {
    key: '2391',
    label: formatMessage({ id: 'lldDescription.modal.2391' }),
  },
  {
    key: '2392',
    label: formatMessage({ id: 'lldDescription.modal.2392' }),
  },
  {
    key: '2393',
    label: formatMessage({ id: 'lldDescription.modal.2393' }),
  },
  {
    key: '2394',
    label: formatMessage({ id: 'lldDescription.modal.2394' }),
  },
  {
    key: '2395',
    label: formatMessage({ id: 'lldDescription.modal.2395' }),
  },
  {
    key: '2396',
    label: formatMessage({ id: 'lldDescription.modal.2396' }),
  },
  {
    key: '2397',
    label: formatMessage({ id: 'lldDescription.modal.2397' }),
  },
  {
    key: '2398',
    label: formatMessage({ id: 'lldDescription.modal.2398' }),
  },
  {
    key: '2808',
    label: formatMessage({ id: 'lldDescription.modal.2808' }),
  },
  {
    key: '2809',
    label: formatMessage({ id: 'lldDescription.modal.2809' }),
  },
  {
    key: '2811',
    label: formatMessage({ id: 'lldDescription.modal.2811' }),
  },
  {
    key: '2812',
    label: formatMessage({ id: 'lldDescription.modal.2812' }),
  },
  {
    key: '2813',
    label: formatMessage({ id: 'lldDescription.modal.2813' }),
  },
  {
    key: '2814',
    label: formatMessage({ id: 'lldDescription.modal.2814' }),
  },
  {
    key: '2815',
    label: formatMessage({ id: 'lldDescription.modal.2815' }),
  },
  {
    key: '2240',
    label: formatMessage({ id: 'lldDescription.modal.2240' }),
  },
  {
    key: '2241',
    label: formatMessage({ id: 'lldDescription.modal.2241' }),
  },
  {
    key: '2242',
    label: formatMessage({ id: 'lldDescription.modal.2242' }),
  },
  {
    key: '2243',
    label: formatMessage({ id: 'lldDescription.modal.2243' }),
  },
  {
    key: '2244',
    label: formatMessage({ id: 'lldDescription.modal.2244' }),
  },
  {
    key: '2245',
    label: formatMessage({ id: 'lldDescription.modal.2245' }),
  },
  {
    key: '2246',
    label: formatMessage({ id: 'lldDescription.modal.2246' }),
  },
  {
    key: '2247',
    label: formatMessage({ id: 'lldDescription.modal.2247' }),
  },
  {
    key: '2251',
    label: formatMessage({ id: 'lldDescription.modal.2251' }),
  },
  {
    key: '2252',
    label: formatMessage({ id: 'lldDescription.modal.2252' }),
  },
  {
    key: '2255',
    label: formatMessage({ id: 'lldDescription.modal.2255' }),
  },
  {
    key: '2256',
    label: formatMessage({ id: 'lldDescription.modal.2256' }),
  },
  {
    key: '2257',
    label: formatMessage({ id: 'lldDescription.modal.2257' }),
  },
  {
    key: '2261',
    label: formatMessage({ id: 'lldDescription.modal.2261' }),
  },
  {
    key: '2262',
    label: formatMessage({ id: 'lldDescription.modal.2262' }),
  },
  {
    key: '2263',
    label: formatMessage({ id: 'lldDescription.modal.2263' }),
  },
  {
    key: '2264',
    label: formatMessage({ id: 'lldDescription.modal.2264' }),
  },
];
