import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';
import { validate, validResult } from 'utils/form-validation';

import { Select, TextLabel, Tooltip } from 'components/common';

import { SELECT_PLACEHOLDER_DEFAULT_VALUE, DROPDOWN_LABEL_TYPE_ACCOUNT } from 'settings/constants/common';
import { GRID_SIZE_HORIZONTAL, GRID_SIZE_VERTICAL, GRID_VARIANT_DEFAULT } from 'settings/constants/ui-control';
import { TD_DDL_ACCOUNT_BALANCE_END, TD_DDL_ACCOUNT_DEFAULT_VAL } from 'settings/constants/dashboard';
import DynoDropdownLabel from './DropdownLabel';

import './style.scss';

const DynoSelectAccount = ({ label, options, value, name, variant, onChange, getValue, bottomLabel, ...props }) => {
  const { formatMessage } = useIntl();

  const [isSelectOpened, setSelectOpen] = useState(false);
  const [validationResult, setValidationResult] = useState(validResult);

  const accounts = get(props, 'foundAccount', {});
  const className = get(props, 'className', '');
  const validationRules = get(props, 'validationRules', []);
  const placeholderId = get(props, 'placeholder', '');
  const placeholder = placeholderId ? formatMessage({ id: placeholderId }) : formatMessage({ id: 'dashboard.td.placeholder.pleaseSelect' });
  const isThinPadding = get(props, 'isThinPadding', false);

  const validField = getValue('validField', {});

  const handleOnChange = event => {
    const newValue = event.target.value;
    const { isValid, messageId, param } = validate(validationRules, event);
    setValidationResult({ isValid, messageId, param });
    onChange({ validField: { ...validField, [name]: isValid }, [name]: newValue });
  };

  const handleOnClose = e => {
    // no selection made, component will return undefined, selection made component will return 0
    if (isUndefined(e.target.value) && !value) {
      const event = { target: { value, name } };

      const { isValid, messageId, param } = validate(validationRules, event);
      setValidationResult({ isValid, messageId, param });
    }
  };

  const { isValid, messageId, param } = validationResult;
  const errorMessage = !isValid && formatMessage({ id: messageId, defaultMessage: messageId }, param);

  const gridSize = variant === GRID_VARIANT_DEFAULT ? GRID_SIZE_VERTICAL : GRID_SIZE_HORIZONTAL;
  const leftLabel = get(accounts, 'dropdownLabel', '');
  const realBalance = get(accounts, 'realBalance', '');
  const rightLabel = get(accounts, 'balance', '');
  const balanceEndingPoints = rightLabel.endsWith(TD_DDL_ACCOUNT_BALANCE_END);
  const withTooltip = leftLabel.trim() !== TD_DDL_ACCOUNT_DEFAULT_VAL && !isSelectOpened && balanceEndingPoints;
  const selectedMenuElement = document.querySelector('.MuiMenu-list.select-account');
  const tooltipTitle = balanceEndingPoints ? parse(`<p>${leftLabel} ${realBalance}</p>`) : parse(`<p>${leftLabel} ${rightLabel}</p>`);

  useEffect(() => {
    if (!isEmpty(selectedMenuElement)) {
      setSelectOpen(true);
    } else {
      setSelectOpen(false);
    }
  }, [selectedMenuElement]);

  return (
    <Grid container alignItems={!isValid ? `baseline` : `center`} className={`pb0 pt3 ${isThinPadding ? 'ph3' : 'ph4'} ${className}`}>
      <Grid item xs={gridSize.label.xs} sm={gridSize.label.sm} className="pb2">
        {label && <TextLabel label={formatMessage({ id: label })} className="pl1-ns" />}
      </Grid>
      <Grid item xs={gridSize.input.xs} sm={gridSize.input.sm}>
        {withTooltip && (
          <Tooltip title={tooltipTitle} placement="bottom-start" className="tooltip--selectaccount" noMaxWidth>
            <div id="select-account-holder">
              <Select
                className="select-account"
                fullWidth
                options={options}
                value={value || SELECT_PLACEHOLDER_DEFAULT_VALUE}
                onClose={handleOnClose}
                onChange={handleOnChange}
                placeholder={placeholder}
                hasError={!isValid}
                errorMessage={errorMessage}
                renderValue={() => {
                  return <DynoDropdownLabel isSelected option={accounts} type={DROPDOWN_LABEL_TYPE_ACCOUNT} />;
                }}
              />
            </div>
          </Tooltip>
        )}
        {!withTooltip && isEmpty(value) && (
          <Select
            fullWidth
            options={options}
            value={value || SELECT_PLACEHOLDER_DEFAULT_VALUE}
            onClose={handleOnClose}
            onChange={handleOnChange}
            placeholder={placeholder}
            hasError={!isValid}
            errorMessage={errorMessage}
          />
        )}
        {!withTooltip && !isEmpty(value) && (
          <Select
            fullWidth
            options={options}
            value={value || SELECT_PLACEHOLDER_DEFAULT_VALUE}
            onClose={handleOnClose}
            onChange={handleOnChange}
            placeholder={placeholder}
            hasError={!isValid}
            errorMessage={errorMessage}
            renderValue={() => {
              return <DynoDropdownLabel isSelected option={accounts} type={DROPDOWN_LABEL_TYPE_ACCOUNT} />;
            }}
          />
        )}
        {!isEmpty(bottomLabel) && <TextLabel label={formatMessage({ id: bottomLabel })} className="pt2" />}
      </Grid>
    </Grid>
  );
};

DynoSelectAccount.defaultProps = { options: [], value: '', bottomLabel: '', variant: GRID_VARIANT_DEFAULT };

DynoSelectAccount.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  variant: PropTypes.string,
  bottomLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default DynoSelectAccount;
