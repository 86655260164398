// TODO: Changed to MSS endpoint
export const paymentEndpoint = {
  get: {
    favouriteAccount: 'payments/v1/bill/fav/source/accounts',
    maintenances: 'payments/v1/bill/fav/dest/list',
    senderAccount: 'payments/v1/bill/source/accounts',
  },
  utility: {
    getResult: 'payments/v1/bill/utility/confirm',
    getReceipt: 'payments/v1/bill/utility/reciept',
    getAccess: 'payments/v1/bill/utility/billers',
    getBillerDetail: 'payments/v1/bill/utility/account',
    getConfirmation: 'payments/v1/bill/utility/initiate',
    requestTac: 'notification/v1/tac/payments/utility/request',
    favourite: {
      add: {
        getDetail: 'payments/v1/bill/fav/add/utility/details',
        getDetailFromPayment: 'payments/v1/bill/fav/add/txn/utility/details',
        getConfirmation: 'payments/v1/bill/fav/add/utility/initiate',
        getTac: 'notification/v1/tac/payments/fav/add/utility/request',
        getResult: 'payments/v1/bill/fav/add/utility/confirm',
      },
      edit: {
        getDetail: 'payments/v1/bill/fav/edit/utility/details',
        getConfirmation: 'payments/v1/bill/fav/edit/utility/initiate',
        getTac: 'notification/v1/tac/payments/fav/edit/utility/request',
        getResult: 'payments/v1/bill/fav/edit/utility/confirm',
      },
      delete: {
        getConfirmation: 'payments/v1/bill/fav/del/utility/initiate',
        getTac: 'notification/v1/tac/payments/fav/del/utility/request',
        getResult: 'payments/v1/bill/fav/del/utility/confirm',
      },
      payment: {
        getDetail: 'payments/v1/bill/fav/utility/account',
        getTac: 'notification/v1/tac/payments/fav/utility/request',
        getReceipt: 'payments/v1/bill/fav/utility/receipt',
      },
    },
  },
  creditCard: {
    getAccess: 'payments/v1/bill/creditcard/list',
    getMaybankAccess: 'payments/v1/bill/creditcard/mb/list',
    getOwnMaybankCards: 'payments/v1/bill/creditcard/mb/accounts',
    getOwnCardPaymentMode: 'payments/v1/bill/creditcard/mb/accounts/mode',
    getMaybankConfirmation: 'payments/v1/bill/creditcard/mb/initiate',
    getOtherBankConfirmation: 'payments/v1/bill/creditcard/ob/initiate',
    requestMaybankTac: 'notification/v1/tac/payments/creditcard/mb/request',
    requestOtherBankTac: 'notification/v1/tac/payments/creditcard/ob/request',
    getMaybankResult: 'payments/v1/bill/creditcard/mb/confirm',
    getOtherBankResult: 'payments/v1/bill/creditcard/ob/confirm',
    getMaybankReceipt: 'payments/v1/bill/creditcard/mb/reciept',
    getOtherBankReceipt: 'payments/v1/bill/creditcard/ob/reciept',
    favourite: {
      add: {
        getDetail: 'payments/v1/bill/fav/add/creditcard/details',
        getDetailFromPayment: 'payments/v1/bill/fav/add/txn/creditcard/details',
        getConfirmation: 'payments/v1/bill/fav/add/creditcard/initiate',
        getTac: 'notification/v1/tac/payments/fav/add/creditcard/request',
        getResult: 'payments/v1/bill/fav/add/creditcard/confirm',
      },
      edit: {
        getDetail: 'payments/v1/bill/fav/edit/creditcard/details',
        getConfirmation: 'payments/v1/bill/fav/edit/creditcard/initiate',
        getTac: 'notification/v1/tac/payments/fav/edit/creditcard/request',
        getResult: 'payments/v1/bill/fav/edit/creditcard/confirm',
      },
      delete: {
        getConfirmation: 'payments/v1/bill/fav/del/creditcard/initiate',
        getTac: 'notification/v1/tac/payments/fav/del/creditcard/request',
        getResult: 'payments/v1/bill/fav/del/creditcard/confirm',
      },
      payment: {
        getMaybankDetail: 'payments/v1/bill/fav/creditcard/mb/details',
        getOtherDetail: 'payments/v1/bill/fav/creditcard/ob/details',
        getMaybankTac: 'notification/v1/tac/payments/fav/creditcard/mb/request',
        getOtherTac: 'notification/v1/tac/payments/fav/creditcard/ob/request',
      },
    },
  },
  mobilePostpaid: {
    biller: 'payments/v1/bill/mobile/postpaid/billers',
    detail: 'payments/v1/bill/mobile/postpaid/account',
    confirm: 'payments/v1/bill/mobile/postpaid/initiate',
    requestTac: 'notification/v1/tac/payments/mobile/postpaid/request',
    result: 'payments/v1/bill/mobile/postpaid/confirm',
    receipt: 'payments/v1/bill/mobile/postpaid/receipt',
    favourite: {
      add: {
        getDetail: 'payments/v1/bill/fav/add/mobile/postpaid/details',
        getDetailFromPayment: 'payments/v1/bill/fav/add/txn/mobile/postpaid/details',
        getTac: 'notification/v1/tac/payments/fav/add/mobile/postpaid/request',
        getConfirmation: 'payments/v1/bill/fav/add/mobile/postpaid/initiate',
        getResult: 'payments/v1/bill/fav/add/mobile/postpaid/confirm',
      },
      edit: {
        getDetail: 'payments/v1/bill/fav/edit/mobile/postpaid/details',
        getConfirmation: 'payments/v1/bill/fav/edit/mobile/postpaid/initiate',
        getTac: 'notification/v1/tac/payments/fav/edit/mobile/postpaid/request',
        getResult: 'payments/v1/bill/fav/edit/mobile/postpaid/confirm',
      },
      delete: {
        getConfirmation: 'payments/v1/bill/fav/del/mobile/postpaid/initiate',
        getTac: 'notification/v1/tac/payments/fav/del/mobile/postpaid/request',
        getResult: 'payments/v1/bill/fav/del/mobile/postpaid/confirm',
      },
      payment: {
        getDetail: 'payments/v1/bill/fav/mobile/postpaid/account',
        getTac: 'notification/v1/tac/payments/fav/mobile/postpaid/request',
        getReceipt: '/payments/v1/bill/fav/mobile/postpaid/receipt',
      },
    },
  },
  otherBill: {
    getBiller: 'payments/v1/bill/others/categories',
    getPayee: 'payments/v1/bill/others/payees',
    getDetail: 'payments/v1/bill/others/account',
    getConfirm: 'payments/v1/bill/others/initiate',
    requestTac: 'notification/v1/tac/payments/others/request',
    getResult: 'payments/v1/bill/others/confirm',
    getReceipt: 'payments/v1/bill/others/reciept',
    favourite: {
      add: {
        getPayeeList: 'payments/v1/bill/fav/add/others/payees',
        getDetail: 'payments/v1/bill/fav/add/others/billers',
        getDetailFromPayment: 'payments/v1/bill/fav/add/txn/others/account',
        getConfirmation: 'payments/v1/bill/fav/add/others/initiate',
        getTac: 'notification/v1/tac/payments/fav/add/others/request',
        getResult: 'payments/v1/bill/fav/add/others/confirm',
      },
      edit: {
        getDetail: 'payments/v1/bill/fav/edit/others/details',
        getConfirmation: 'payments/v1/bill/fav/edit/others/initiate',
        getTac: 'notification/v1/tac/payments/fav/edit/others/request',
        getResult: 'payments/v1/bill/fav/edit/others/confirm',
      },
      delete: {
        getConfirmation: 'payments/v1/bill/fav/del/others/initiate',
        getTac: 'notification/v1/tac/payments/fav/del/others/request',
        getResult: 'payments/v1/bill/fav/del/others/confirm',
      },
      payment: {
        getDetail: 'payments/v1/bill/fav/others/account',
        getTac: 'notification/v1/tac/payments/fav/others/request',
        getReceipt: 'payments/v1/bill/fav/others/receipt',
      },
    },
  },
};
