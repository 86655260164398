import { actions } from '../actions/dashboard';

const {
  CASA_ACCOUNT,
  LOAN_ACCOUNTS,
  WEALTH_ACCOUNT,
  WEALTH_ACCOUNT_SUMMARY,
  WEALTH_ACCOUNT_DETAIL_AND_HISTORY,
  WEALTH_ACCOUNT_HISTORY,
  ALL_ACCOUNT_BALANCE,
  LOAN_ACCOUNT_DETAIL,
  CREDIT_CARD_ACCOUNTS,
  TIME_DEPOSIT_ACCOUNTS,
  SAVING_ACCOUNT_DETAIL,
  CURRENT_ACCOUNT_DETAIL,
  UNIT_TRUST_ACCOUNT_DETAIL,
  CREDIT_CARD_ACCOUNT_DETAIL,
  TIME_DEPOSIT_ACCOUNT_DETAIL,
  DASHBOARD_RESPONSE_ERROR,
  DASHBOARD_MENUS_RESPONSE_ERRORS,
  CASA_TRANSACTION_HISTORY,
  CREDIT_CARD_TRANSACTION_HISTORY,
  TIME_DEPOSIT_INITIATE_STOP_ARO,
  TIME_DEPOSIT_STOP_ARO_TAC,
  TIME_DEPOSIT_CONFIRM_STOP_ARO,
  TIME_DEPOSIT_STOP_ARO_RECEIPT,
  TIME_DEPOSIT_RESET_STOP_ARO,
  TIME_DEPOSIT_PRODUCTS,
  TIME_DEPOSIT_INTEREST_RATE,
  TIME_DEPOSIT_LIST_ACCOUNTS,
  TIME_DEPOSIT_VALIDATE,
  TIME_DEPOSIT_REQ_TAC,
  TIME_DEPOSIT_DO_OPEN_ACCOUNT,
  TOGGLE_DASHBOARD_RIGHT_SIDEBAR_OPEN,
  ACTIVE_DASHBOARD_TAB,
  CASA_TRANSACTION_TYPE,
  CASA_TRANSACTION_M2U_RECEIPT,
} = actions.PUT;

const { CLEAR } = actions;

const initialState = {
  isSuccess: false,
  toggleRightSidebar: true,
  casaAccount: {},
  wealthAccounts: [],
  wealthAccountSummary: {},
  wealthAccountDetail: {},
  wealthAccountHistories: [],
  allAccountBalance: {},
  loanAccountDetail: {},
  savingAccountDetail: {},
  currentAccountDetail: {},
  unitTrustAccountDetail: {},
  creditCardAccountDetail: {},
  timeDepositAccountDetail: {},
  loanAccounts: [],
  creditCardAccounts: [],
  timeDepositBalance: {},
  casaTransactionHistories: [],
  creditCardTransactionHistory: {},
  timeDepositInitiateStopAro: {},
  timeDepositStopAroTac: {},
  timeDepositConfirmStopAro: {},
  timeDepositStopAroReceipt: {},
  timeDepositProducts: {},
  timeDepositInterestRate: {},
  timeDepositListAccounts: {},
  timeDepositValidate: {},
  timeDepositRequestTAC: {},
  timeDepositOpenAccount: {},
  activeTab: '',
  errorCodes: {},
  casaTransactionM2UReceipt: {},
};

const DashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ALL_ACCOUNT_BALANCE:
      return { ...state, isSuccess: action.payload.isSuccess, allAccountBalance: action.payload.res };

    case CASA_ACCOUNT:
      return { ...state, isSuccess: action.payload.isSuccess, casaAccount: action.payload.res };

    case WEALTH_ACCOUNT:
      return { ...state, isSuccess: action.payload.isSuccess, wealthAccounts: action.payload.res };

    case WEALTH_ACCOUNT_SUMMARY:
      return { ...state, isSuccess: action.payload.isSuccess, wealthAccountSummary: action.payload.res };

    case WEALTH_ACCOUNT_DETAIL_AND_HISTORY:
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
        wealthAccountDetail: action.payload.detailResponse,
        wealthAccountHistories: action.payload.historyResponse,
      };

    case WEALTH_ACCOUNT_HISTORY:
      return { ...state, isSuccess: action.payload.isSuccess, wealthAccountHistories: action.payload.res };

    case LOAN_ACCOUNTS:
      return { ...state, isSuccess: action.payload.isSuccess, loanAccounts: action.payload.res };

    case CREDIT_CARD_ACCOUNTS:
      return { ...state, isSuccess: action.payload.isSuccess, creditCardAccounts: action.payload.res };

    case TIME_DEPOSIT_ACCOUNTS:
      return {
        ...state,
        timeDepositBalance: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          exchangeRate: action.payload.exchangeRate,
          accounts: action.payload.res,
        },
      };

    case TIME_DEPOSIT_ACCOUNT_DETAIL:
      return {
        ...state,
        timeDepositAccountDetail: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          detail: action.payload.res,
        },
      };

    case TIME_DEPOSIT_PRODUCTS:
      return {
        ...state,
        timeDepositProducts: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          detail: action.payload.res,
        },
      };

    case TIME_DEPOSIT_INTEREST_RATE:
      return {
        ...state,
        timeDepositInterestRate: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          detail: action.payload.res,
        },
      };

    case TIME_DEPOSIT_LIST_ACCOUNTS:
      return {
        ...state,
        timeDepositListAccounts: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          detail: action.payload.res,
        },
      };

    case TIME_DEPOSIT_VALIDATE:
      return {
        ...state,
        timeDepositValidate: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          rate: action.payload.res,
        },
      };

    case TIME_DEPOSIT_REQ_TAC:
      return {
        ...state,
        timeDepositRequestTAC: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          sendSmsSuccess: action.payload.sendSmsSuccess,
          tacSentDatetime: action.payload.tacSentDatetime,
        },
      };

    case TIME_DEPOSIT_DO_OPEN_ACCOUNT:
      return {
        ...state,
        timeDepositOpenAccount: {
          errorCode: action.payload.errorCode,
          isSuccess: action.payload.isSuccess,
          detail: action.payload.res,
        },
      };

    case TOGGLE_DASHBOARD_RIGHT_SIDEBAR_OPEN:
      return {
        ...state,
        toggleRightSidebar: action.payload.isOpen,
      };

    case CREDIT_CARD_ACCOUNT_DETAIL:
      return { ...state, isSuccess: action.payload.isSuccess, creditCardAccountDetail: action.payload.res };

    case SAVING_ACCOUNT_DETAIL:
      return { ...state, isSuccess: action.payload.isSuccess, savingAccountDetail: action.payload.res };

    case CURRENT_ACCOUNT_DETAIL:
      return { ...state, isSuccess: action.payload.isSuccess, currentAccountDetail: action.payload.res };

    case LOAN_ACCOUNT_DETAIL:
      return { ...state, isSuccess: action.payload.isSuccess, loanAccountDetail: action.payload.res };

    case UNIT_TRUST_ACCOUNT_DETAIL:
      return { ...state, isSuccess: action.payload.isSuccess, unitTrustAccountDetail: action.payload.res };

    case DASHBOARD_RESPONSE_ERROR:
      return { ...state, isSuccess: action.payload.isSuccess, errorCode: action.payload.errorCode };

    case DASHBOARD_MENUS_RESPONSE_ERRORS:
      return { ...state, errorCodes: { ...state.errorCodes, [action.payload.key]: action.payload.errorCode } };

    case CASA_TRANSACTION_TYPE:
      return { ...state, isSuccess: action.payload.isSuccess, casaTransactionHistories: action.payload.res };

    case CASA_TRANSACTION_HISTORY:
      return { ...state, isSuccess: action.payload.isSuccess, casaTransactionHistories: action.payload.res };

    case CREDIT_CARD_TRANSACTION_HISTORY:
      return { ...state, isSuccess: action.payload.isSuccess, creditCardTransactionHistory: action.payload.res };

    case TIME_DEPOSIT_INITIATE_STOP_ARO:
      return {
        ...state,
        timeDepositInitiateStopAro: { ...action.payload },
      };

    case TIME_DEPOSIT_STOP_ARO_TAC:
      return {
        ...state,
        timeDepositStopAroTac: { ...action.payload },
      };

    case TIME_DEPOSIT_CONFIRM_STOP_ARO:
      return {
        ...state,
        timeDepositConfirmStopAro: { ...action.payload },
      };

    case TIME_DEPOSIT_STOP_ARO_RECEIPT:
      return {
        ...state,
        timeDepositStopAroReceipt: { ...action.payload },
      };

    case TIME_DEPOSIT_RESET_STOP_ARO:
      return {
        ...state,
        timeDepositInitiateStopAro: {},
        timeDepositStopAroTac: {},
        timeDepositConfirmStopAro: {},
        timeDepositStopAroReceipt: {},
      };

    case CLEAR.CASA_DETAIL:
      return {
        ...state,
        savingAccountDetail: {},
        currentAccountDetail: {},
        casaTransactionHistories: [],
      };

    case ACTIVE_DASHBOARD_TAB:
      return { ...state, activeTab: action.payload };

    case CASA_TRANSACTION_M2U_RECEIPT:
      return {
        ...state,
        casaTransactionM2UReceipt: { ...action.payload },
      };

    default:
      return state;
  }
};

export default DashboardReducer;
