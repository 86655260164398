import { Suspense, lazy, useState, useEffect, useLayoutEffect, useMemo, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { Loader } from 'components/common';
import Coachmark from 'components/coachmark';

import { postCoachmark } from 'middleware/actions/coachmark';

import { useWindowSize } from 'utils/transaction';
import { get } from 'utils/lodash';

import { COACHMARK_CHECKED, COACHMARK_UNCHECKED, DEFAULT_INT_ZERO, INT_ONE, TOTAL_COACHMARK } from 'settings/constants/common';

import history from './history';

import { routes } from './routes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const LoginPage = lazy(() => import('container/login'));
const LandingPage = lazy(() => import('pages/Landing'));
const LogoutPage = lazy(() => import('container/logout'));
const SettingsPage = lazy(() => import('pages/Settings'));
const InboxPage = lazy(() => import('pages/Inbox'));
const DashboardPage = lazy(() => import('pages/Dashboard'));
const TransactionPage = lazy(() => import('pages/Transaction'));
const RegisterPage = lazy(() => import('pages/Registration'));
const DigitalWealthPage = lazy(() => import('pages/DigitalWealth'));

const { login, selfUnlock, digitalWealth, dashboard, registration, logout, sessionExpired, transaction, settings, inboxs } = routes;

export const MainRoute = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isCoachmarkActionOpen, setCoachmarkActionOpen] = useState(false);
  const [isCoachmarkOpen, setCoachmarkOpen] = useState(false);
  const [hasRemember, setHasRemember] = useState(false);
  const [coachmarkIndex, setCoachmarkIndex] = useState(1);
  const [coachmarkImage, setCoachmarkImage] = useState(null);
  const [size, setSize] = useState([0, 0]);
  const [language, setLanguage] = useState('');
  const [coachmarkLoading, setCoachmarkLoading] = useState(true);

  const { coachmarkChecked, coachmarkSubmitted, isSuccess, customerProfile } = useSelector(
    state => ({
      coachmarkChecked: state.CoachmarkReducer.coachmarkChecked,
      coachmarkSubmitted: state.CoachmarkReducer.coachmarkSubmitted,
      isSuccess: state.CoachmarkReducer.isSuccess,
      customerProfile: state.AuthReducer.customerProfile,
    }),
    shallowEqual
  );

  const widthWindows = useWindowSize(size, setSize);

  const loadCoachmarkImage = () => {
    setCoachmarkLoading(true);
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let type;
    if (screenWidth > screenHeight) {
      if (screenWidth > theme.breakpoints.width('md')) {
        type = 'website';
      } else {
        type = 'mobile-landscape';
      }
    } else if (screenWidth < screenHeight) {
      if (screenWidth < theme.breakpoints.width('sm')) {
        type = 'mobile';
      } else {
        type = 'tablet';
      }
    }
    setCoachmarkImage(`${process.env.REACT_APP_FILE_URL}coach${language}/${type}/${coachmarkIndex}.jpg`);
  };
  const openCoachmark = () => {
    setCoachmarkActionOpen(false);
    loadCoachmarkImage(coachmarkIndex);
    setCoachmarkOpen(true);
  };
  const handleCoachmarkPrev = () => {
    setCoachmarkIndex(index => index - 1);
    setCoachmarkLoading(true);
  };
  const handleCoachmarkNext = () => {
    if (coachmarkIndex === TOTAL_COACHMARK) {
      setCoachmarkOpen(false);
      setCoachmarkIndex(INT_ONE);
      return;
    }
    setCoachmarkIndex(index => index + 1);
    setCoachmarkLoading(true);
  };
  const handleTutorial = () => {
    setCoachmarkOpen(true);
  };

  useEffect(() => {
    loadCoachmarkImage(coachmarkIndex);
  }, [coachmarkIndex]);

  useLayoutEffect(() => {
    loadCoachmarkImage();
  }, [window.innerWidth, widthWindows[0]]);

  useEffect(() => {
    if (isSuccess) {
      if (coachmarkSubmitted === COACHMARK_CHECKED) {
        if (coachmarkChecked === COACHMARK_CHECKED || coachmarkChecked?.length === DEFAULT_INT_ZERO) {
          setCoachmarkActionOpen(true);
        }
      }
      if (coachmarkSubmitted?.length === DEFAULT_INT_ZERO) {
        setCoachmarkActionOpen(true);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    setLanguage(get(customerProfile, 'language', ''));
  }, [customerProfile]);

  useEffect(() => {
    loadCoachmarkImage();
  }, [language]);

  useMemo(() => {
    if (hasRemember && !isCoachmarkActionOpen) {
      if (isCoachmarkOpen) {
        dispatch(postCoachmark(COACHMARK_CHECKED));
        return;
      }
      dispatch(postCoachmark(COACHMARK_UNCHECKED));
    }
  }, [hasRemember, isCoachmarkActionOpen]);

  const CoachmarkComponent = (
    <Coachmark
      loading={coachmarkLoading}
      isCoachmarkOpen={isCoachmarkOpen}
      isCoachmarkActionOpen={isCoachmarkActionOpen}
      hasRemember={hasRemember}
      coachmarkIndex={coachmarkIndex}
      coachmarkImage={coachmarkImage}
      setLoading={setCoachmarkLoading}
      setCoachmarkActionOpen={setCoachmarkActionOpen}
      setCoachmarkOpen={setCoachmarkOpen}
      setHasRemember={setHasRemember}
      openCoachmark={openCoachmark}
      handleCoachmarkPrev={handleCoachmarkPrev}
      handleCoachmarkNext={handleCoachmarkNext}
    />
  );

  return (
    <Suspense fallback={<Loader isOpen />}>
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/" component={LandingPage} />
          <PublicRoute component={LoginPage} path={[login, selfUnlock]} />
          <PublicRoute component={RegisterPage} path={registration.default} />
          <PublicRoute exact component={LogoutPage} path={[logout, sessionExpired]} />

          <PrivateRoute
            exact
            component={DigitalWealthPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[digitalWealth.path, digitalWealth.goalDetail, digitalWealth.financialGoal]}
          />
          <PrivateRoute
            exact
            component={DashboardPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[dashboard.default, dashboard.details, dashboard.path, dashboard.summary, dashboard.apply]}
          />
          <PrivateRoute
            component={TransactionPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[transaction.default, transaction.path]}
            exact
          />
          <PrivateRoute
            component={SettingsPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[settings.path, settings.default]}
            exact
          />
          {/* <PrivateRoute
            component={InboxPage}
            handleTutorial={handleTutorial}
            coachmarkComponent={CoachmarkComponent}
            path={[inboxs.path, inboxs.default]}
            exact
          /> */}

          {/* if no path is found handling */}
          <PublicRoute component={LoginPage} />
        </Switch>
      </Router>
    </Suspense>
  );
};
