export const UNREQUITTEDS = formatMessage => [
  {
    key: '2271',
    label: formatMessage({ id: 'lldDescription.unrequitted.2271' }),
  },
  {
    key: '2272',
    label: formatMessage({ id: 'lldDescription.unrequitted.2272' }),
  },
  {
    key: '2273',
    label: formatMessage({ id: 'lldDescription.unrequitted.2273' }),
  },
  {
    key: '2274',
    label: formatMessage({ id: 'lldDescription.unrequitted.2274' }),
  },
  {
    key: '2275',
    label: formatMessage({ id: 'lldDescription.unrequitted.2275' }),
  },
  {
    key: '2276',
    label: formatMessage({ id: 'lldDescription.unrequitted.2276' }),
  },
  {
    key: '2277',
    label: formatMessage({ id: 'lldDescription.unrequitted.2277' }),
  },
  {
    key: '2278',
    label: formatMessage({ id: 'lldDescription.unrequitted.2278' }),
  },
  {
    key: '2279',
    label: formatMessage({ id: 'lldDescription.unrequitted.2279' }),
  },
  {
    key: '2280',
    label: formatMessage({ id: 'lldDescription.unrequitted.2280' }),
  },
  {
    key: '2282',
    label: formatMessage({ id: 'lldDescription.unrequitted.2282' }),
  },
  {
    key: '2311',
    label: formatMessage({ id: 'lldDescription.unrequitted.2311' }),
  },
  {
    key: '2321',
    label: formatMessage({ id: 'lldDescription.unrequitted.2321' }),
  },
  {
    key: '2322',
    label: formatMessage({ id: 'lldDescription.unrequitted.2322' }),
  },
  {
    key: '2323',
    label: formatMessage({ id: 'lldDescription.unrequitted.2323' }),
  },
  {
    key: '2331',
    label: formatMessage({ id: 'lldDescription.unrequitted.2331' }),
  },
  {
    key: '2332',
    label: formatMessage({ id: 'lldDescription.unrequitted.2332' }),
  },
  {
    key: '2333',
    label: formatMessage({ id: 'lldDescription.unrequitted.2333' }),
  },
  {
    key: '2341',
    label: formatMessage({ id: 'lldDescription.unrequitted.2341' }),
  },
  {
    key: '2342',
    label: formatMessage({ id: 'lldDescription.unrequitted.2342' }),
  },
  {
    key: '2351',
    label: formatMessage({ id: 'lldDescription.unrequitted.2351' }),
  },
  {
    key: '2352',
    label: formatMessage({ id: 'lldDescription.unrequitted.2352' }),
  },
  {
    key: '2353',
    label: formatMessage({ id: 'lldDescription.unrequitted.2353' }),
  },
  {
    key: '2354',
    label: formatMessage({ id: 'lldDescription.unrequitted.2354' }),
  },
  {
    key: '2361',
    label: formatMessage({ id: 'lldDescription.unrequitted.2361' }),
  },
  {
    key: '2362',
    label: formatMessage({ id: 'lldDescription.unrequitted.2362' }),
  },
  {
    key: '2363',
    label: formatMessage({ id: 'lldDescription.unrequitted.2363' }),
  },
  {
    key: '2364',
    label: formatMessage({ id: 'lldDescription.unrequitted.2364' }),
  },
  {
    key: '2365',
    label: formatMessage({ id: 'lldDescription.unrequitted.2365' }),
  },
  {
    key: '2366',
    label: formatMessage({ id: 'lldDescription.unrequitted.2366' }),
  },
  {
    key: '2371',
    label: formatMessage({ id: 'lldDescription.unrequitted.2371' }),
  },
  {
    key: '2372',
    label: formatMessage({ id: 'lldDescription.unrequitted.2372' }),
  },
  {
    key: '2375',
    label: formatMessage({ id: 'lldDescription.unrequitted.2375' }),
  },
  {
    key: '2376',
    label: formatMessage({ id: 'lldDescription.unrequitted.2376' }),
  },
  {
    key: '2377',
    label: formatMessage({ id: 'lldDescription.unrequitted.2377' }),
  },
  {
    key: '2378',
    label: formatMessage({ id: 'lldDescription.unrequitted.2378' }),
  },
  {
    key: '2379',
    label: formatMessage({ id: 'lldDescription.unrequitted.2379' }),
  },
  {
    key: '2380',
    label: formatMessage({ id: 'lldDescription.unrequitted.2380' }),
  },
  {
    key: '2381',
    label: formatMessage({ id: 'lldDescription.unrequitted.2381' }),
  },
  {
    key: '2382',
    label: formatMessage({ id: 'lldDescription.unrequitted.2382' }),
  },
  {
    key: '2821',
    label: formatMessage({ id: 'lldDescription.unrequitted.2821' }),
  },
  {
    key: '2822',
    label: formatMessage({ id: 'lldDescription.unrequitted.2822' }),
  },
  {
    key: '2823',
    label: formatMessage({ id: 'lldDescription.unrequitted.2823' }),
  },
  {
    key: '2824',
    label: formatMessage({ id: 'lldDescription.unrequitted.2824' }),
  },
  {
    key: '2825',
    label: formatMessage({ id: 'lldDescription.unrequitted.2825' }),
  },
  {
    key: '2826',
    label: formatMessage({ id: 'lldDescription.unrequitted.2826' }),
  },
  {
    key: '2827',
    label: formatMessage({ id: 'lldDescription.unrequitted.2827' }),
  },
  {
    key: '2828',
    label: formatMessage({ id: 'lldDescription.unrequitted.2828' }),
  },
];
