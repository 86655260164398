import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Icon from 'components/common/Icon';
import { IconButton, ActionButton } from 'components/common/buttons';
import { PanelHeaderCard, PanelItemCard, CardContentList } from 'components/common/cards';

import { getRecurringLabel, getStatusMessage } from 'utils/transaction';

import { TAC_STEP_FINAL } from 'settings/constants/tac';
import { DEFAULT_LOCALE } from 'settings/constants/language';
import { getSiRecurringLabel } from 'utils/settings/manage-transaction/getter';

const DynoConfirmationCards = ({
  result,
  onEdit,
  tacStep,
  frequency,
  lldDetails,
  totalAmount,
  amountLabel,
  senderDetails,
  hasEditButton,
  isOwnTransfer,
  receiverDetail,
  onAddFavourite,
  monetaryDetails,
  recurringDetails,
  beneficiaryDetails,
  transactionToLabel,
  hasFavouriteButton,
  disableAddFavourite,
  isDeleteTransaction,
  locale,
}) => {
  const { formatMessage } = useIntl();

  const hasPencilIcon = hasEditButton && tacStep !== TAC_STEP_FINAL && !isDeleteTransaction;

  const { receiverName, receiverAccount, transactionType, transactionAmount } = receiverDetail;

  const { isFavAccount, isResultSuccess, isTransactionSuccess, statusCode, statusMessage } = result;
  const resultStatusMessage = getStatusMessage({ statusCode, statusMessage, formatMessage });

  const recurringLabel = isDeleteTransaction
    ? formatMessage({ id: getSiRecurringLabel(frequency, locale) })
    : formatMessage({ id: getRecurringLabel(frequency) });

  const headerColor = isTransactionSuccess ? 'green' : 'red';
  const iconType = isTransactionSuccess ? 'check' : 'close';
  const headerStyle = isResultSuccess ? headerColor : '';

  const headerAction = () => {
    if (isTransactionSuccess && hasFavouriteButton && !isFavAccount && !isOwnTransfer && !isDeleteTransaction) {
      return (
        <ActionButton
          hasRightIcon
          hasLeftIcon={false}
          onClick={onAddFavourite}
          isAssetIcon={false}
          disabled={disableAddFavourite}
          title={formatMessage({ id: 'buttonText.addFavourite' })}
          icon="favorite"
          className="btn--favourite db"
        />
      );
    }

    // this is for own account transfer, no add to fav button only amount on right
    if (isTransactionSuccess && !hasFavouriteButton && isOwnTransfer) {
      return <span>{transactionAmount}</span>;
    }

    return <p className="status--message">{resultStatusMessage}</p>;
  };

  return (
    <Grid container item className="mt4 confirmation--section" justify="center" alignContent="flex-start" spacing={1} md={10}>
      {!isEmpty(senderDetails) && (
        <Grid item xs={12} sm={12} md={10}>
          <Paper>
            <CardContentList contents={senderDetails} />
          </Paper>
        </Grid>
      )}

      <Grid item xs={12} md={10}>
        <PanelHeaderCard
          hasHeaderAction
          hasHeaderIcon={isResultSuccess}
          headerStyle={headerStyle}
          iconType={iconType}
          title={
            <>
              <div className={`di ${transactionToLabel ? `mr1` : ``}`}>{transactionToLabel}</div>
              <strong>{receiverName}</strong>
              <p className="mv1">{receiverAccount}</p>
              <p className="mv1">{isDeleteTransaction ? transactionType + recurringLabel : transactionType}</p>
              {/*
                  if not isOwnTransfer then show amount, owntransfer amount is on right
                  https://dev.azure.com/mbid/M2U_ID_Web_Revamp-UAT/_workitems/edit/5424
               */}
              {isTransactionSuccess && !isOwnTransfer && <p className="mv1">{transactionAmount}</p>}
            </>
          }
          headerAction={
            isResultSuccess ? (
              headerAction()
            ) : (
              <span>
                {transactionAmount}
                {hasPencilIcon && (
                  <IconButton onClick={onEdit}>
                    <Icon isAssetIcon type="edit" />
                  </IconButton>
                )}
              </span>
            )
          }
        >
          {!isEmpty(beneficiaryDetails) && <CardContentList contents={beneficiaryDetails} receiverName={receiverName} />}
        </PanelHeaderCard>
      </Grid>

      {!isEmpty(recurringDetails) && !isDeleteTransaction && (
        <Grid item xs={12} md={10}>
          <PanelHeaderCard title={recurringLabel}>
            <CardContentList contents={recurringDetails} />
          </PanelHeaderCard>
        </Grid>
      )}

      {!isEmpty(lldDetails) && (
        <Grid item xs={12} md={10}>
          <PanelHeaderCard title={formatMessage({ id: 'transaction.transfer.swift.lld.label' })}>
            <CardContentList contents={lldDetails} />
          </PanelHeaderCard>
        </Grid>
      )}

      {!isEmpty(monetaryDetails) && (
        <Grid item xs={12} md={10}>
          <PanelHeaderCard title={formatMessage({ id: 'transaction.transfer.monetaryInfo' })}>
            <CardContentList contents={monetaryDetails} />
          </PanelHeaderCard>
        </Grid>
      )}

      {!isEmpty(totalAmount) && (
        <Grid item xs={12} md={10} className="debit--amount">
          <PanelItemCard title={formatMessage({ id: amountLabel })} info={<span className="red">{totalAmount}</span>} />
        </Grid>
      )}
    </Grid>
  );
};

DynoConfirmationCards.defaultProps = {
  result: {},
  tacStep: '',
  frequency: '',
  lldDetails: [],
  totalAmount: '',
  senderDetails: [],
  receiverDetail: {},
  monetaryDetails: [],
  hasEditButton: true,
  recurringDetails: [],
  onAddFavourite: null,
  isOwnTransfer: false,
  transactionToLabel: '',
  beneficiaryDetails: [],
  hasFavouriteButton: true,
  disableAddFavourite: false,
  isDeleteTransaction: true,
  locale: DEFAULT_LOCALE,
  amountLabel: 'transaction.transfer.amount',
};

DynoConfirmationCards.propTypes = {
  result: PropTypes.object,
  locale: PropTypes.string,
  tacStep: PropTypes.string,
  frequency: PropTypes.string,
  lldDetails: PropTypes.array,
  amountLabel: PropTypes.string,
  hasEditButton: PropTypes.bool,
  isOwnTransfer: PropTypes.bool,
  senderDetails: PropTypes.array,
  onAddFavourite: PropTypes.func,
  receiverDetail: PropTypes.object,
  monetaryDetails: PropTypes.array,
  recurringDetails: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  transactionToLabel: PropTypes.node,
  hasFavouriteButton: PropTypes.bool,
  beneficiaryDetails: PropTypes.array,
  disableAddFavourite: PropTypes.bool,
  isDeleteTransaction: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default DynoConfirmationCards;
