import { useReducer, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { BasicButton } from '../buttons';
import FormModal from './FormModal';

import './style.scss';

// Custom react-router Prompt modal to block user navigation
const PromptModal = ({ className, content, title, isBlocked, proceedLabel, cancelLabel, onCancelTransaction }) => {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    lastLocation: location,
    isModalOpen: false,
    isUnloaded: false,
    isProceed: false,
  });
  const { isModalOpen, lastLocation, isUnloaded, isProceed } = state;

  const handleCloseModal = () => setState({ isModalOpen: false, isUnloaded: false });

  const handleShowModal = nextLocation => setState({ isModalOpen: true, lastLocation: nextLocation });

  const handleBlockedRoute = nextLocation => {
    if (!isProceed && isBlocked) {
      handleShowModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleProceed = () => {
    setState({ isModalOpen: false, isProceed: true });
    onCancelTransaction();
  };

  const handleBeforeUnload = event => {
    if (!isBlocked) return;
    event.preventDefault();
    // assign the custom message on alert box (if browser supports it)
    // eslint-disable-next-line no-param-reassign
    event.returnValue = content;
  };

  // Block react routes
  useEffect(() => {
    if (isProceed && lastLocation) {
      // Navigate to the previous blocked location
      setState({ isUnloaded: true });

      history.push(lastLocation.pathname);
    }
  }, [isProceed, lastLocation]);

  // Block non-react routes (use native browser alert)
  useEffect(() => {
    // beforeunload event is fired when user tries to close browser/tab
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isBlocked, isUnloaded]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />

      <FormModal
        fullWidth
        maxWidth="sm"
        className={`modal modal-prompt ${className}`}
        hasCloseButton={false}
        isOpen={isModalOpen}
        title={title}
        description={content}
        onClose={handleCloseModal}
        footer={
          <Grid container justify="flex-end">
            <BasicButton className="transparent-bb db" onClick={handleCloseModal} title={cancelLabel} />
            <BasicButton className="db ph4 ml3 primary-btn" onClick={handleProceed} title={proceedLabel} />
          </Grid>
        }
      />
    </>
  );
};

PromptModal.defaultProps = {
  title: '',
  content: '',
  className: '',
  proceedLabel: '',
  cancelLabel: '',
  isBlocked: false,
  onCancelTransaction: null,
};

PromptModal.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  isBlocked: PropTypes.bool,
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancelTransaction: PropTypes.func,
};

export default PromptModal;
