import { memo } from 'utils/react';
import { useIntl } from 'react-intl';
import { useFirstRender } from 'hooks/useFirstRender';

import './style.scss';

const MaintenancePage = () => {
  // temporary delete desc
  // const { formatMessage } = useIntl();
  // const firstRender = useFirstRender();

  return (
    <div className="pages maintenance">
      <div className="flex flex-column flex-auto items-center justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="169" height="34">
          <defs>
            <path id="a" d="M17.134 21.718V.172H.204v21.546h16.93z" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path
              fill="#000"
              d="M107.601 14.914c-.625 0-1.284.137-1.713.3v7.907c.33.11.956.19 1.583.19 2.239 0 3.556-1.563 3.556-4.308 0-2.608-1.252-4.09-3.426-4.09m-.494 11.914c-2.634 0-5.17-.495-6.654-1.07V4.95h5.435v6.999c.693-.247 1.878-.44 2.8-.44 4.612 0 7.775 2.582 7.775 7.109 0 5.544-3.327 8.209-9.356 8.209M127.332 19.69c-2.932.11-5.137.384-5.137 2.14 0 1.209 1.218 1.867 3.161 1.867.758 0 1.614-.11 1.976-.22V19.69zm-1.812 7.137c-5.6 0-8.366-1.73-8.366-4.887 0-3.925 4.81-4.831 10.178-5.078v-.576c0-1.18-.988-1.73-2.832-1.73-1.714 0-3.756.467-5.04.907l-1.318-3.02c1.581-.522 3.779-1.175 7.017-1.175 4.842 0 7.279 1.642 7.279 5.567v8.922c-1.349.575-4.086 1.07-6.918 1.07zM144.495 26.496v-9.607c0-1.235-.7-2.045-2.644-2.045a5.58 5.58 0 0 0-1.902.37v11.282h-5.436V12.443c1.976-.63 4.315-1.175 7.41-1.175 5.57 0 8.006 1.888 8.006 5.1v10.128h-5.434z"
            />
            <g transform="translate(151.834 4.778)">
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path
                fill="#000"
                d="M11.074 21.718l-5.436-7.383h-.066v7.383H.203V.172h5.369v13.091h.066l4.91-6.339h6.126l-5.535 6.89 5.995 7.904z"
                mask="url(#b)"
              />
            </g>
            <path
              fill="#000"
              d="M90.229 32.398H84.53l2.668-5.902-6.687-14.794h5.912l3.607 10.509 3.572-10.51h5.914zM61.957 6.625h-6.325L51.45 18.9 47.268 6.625h-6.326l-2.866 19.87h5.171l1.648-13.659 4.842 13.66h3.426l4.841-13.66 1.648 13.66h5.172zM75.689 19.69c-2.932.11-5.137.384-5.137 2.14 0 1.209 1.217 1.867 3.161 1.867.756 0 1.614-.11 1.976-.22V19.69zm-1.812 7.137c-5.6 0-8.366-1.73-8.366-4.887 0-3.925 4.81-4.831 10.178-5.078v-.576c0-1.18-.988-1.73-2.832-1.73-1.714 0-3.756.467-5.04.907l-1.319-3.02c1.582-.522 4.25-1.175 7.017-1.175 4.843 0 7.28 1.642 7.28 5.567v8.922c-1.35.575-4.086 1.07-6.918 1.07zM16.547 31.767c-8.407 0-15.22-6.814-15.22-15.22 0-8.407 6.813-15.221 15.22-15.221 8.408 0 15.22 6.814 15.22 15.22 0 8.407-6.812 15.221-15.22 15.221m0-31.767C7.408 0 0 7.407 0 16.546c0 9.14 7.408 16.546 16.547 16.546s16.547-7.406 16.547-16.546S25.686 0 16.547 0"
            />
            <path
              fill="#000"
              d="M9.946 15.787c-1.185-.3-2.226 1.083-2.804 2.135.2-1.434 1.958-3.777 2.804-2.135M7.343 15.807c.399-1.363 1.338-1.863 1.837-1.462-.679.22-1.179.62-1.837 1.462M10.365 17.991c.607-.516.547-1.277-.398-1.526-.7-.18-2.206 1.084-2.367 2.485.6-.777 2.346-2.2 2.765-.959M19.864 21.617c.107-1.796-1.631-2.633-1.964-4.464-.327-1.837 2.716-4.769.792-7.771 3.63 2.924.786 4.654 2.617 7.086-.706-.208-1.412-.844-1.623-1.173-1.02 1.342 2.504 3.186.178 6.322M14.023 7.174c.427.003 1.165.3.867.646-1.008.94-2.367.56-3.444 1.503.439-1.183 2.41-1.35 2.577-2.15"
            />
            <path
              fill="#000"
              d="M12.784 11.13c-.133 0-.254-.187-.286-.424.172.445.605.224.358-.4.154.003.4.016.854-.003-.473.25-.686.834-.926.827m4.503-2.63c.42-.473.033-1.123-1.693-1.339 1.8.883-1.099 1.066-1.198 2.58-.946.377-1.705-.242-2.172.183-.454.408-.979.135-.826-.349-.546.284-.439 1.023.094 1.253.107.053.198.154.12.229-.581.545-.854 1.023-.247 1.569.333-.615.5-.83.872-1.138.348.24.96.078 1.786-.824.039-.046.094-.026.073.027-.654 2.252-2.326 2.62-2.864 2.51.327.486 2.451.788 2.717-.164.607-2.14 2.671-3.072.887-3.039.48-.562 2.104-1.095 2.451-1.499M18.186 7.348c.865.552 1.265.928 1.832 1.597.107-.915-.414-2.731-1.18-3.147-.332-.182-.811 1.083-.652 1.55"
            />
            <path
              fill="#000"
              d="M4.41 14.688c.553-1.459 1.912-3.204 4.111-4.512.505-1.308.339-2.202 5.662-4.26.133-.79.107-1.729.333-1.729.227 0 1.011.346 1.573 1.16.232-.025.439-.05.584-.07.094-.461.414-1.72.827-2.367.385-.599 1.125-.66 2.08.123.99.815 1.69 1.148 3.048 2.882 1.147.515 2.945 1.176 4.498 3.078-.674-.442-1.44-.61-2.066-.686 1.266 1.111 2.104 2.944 1.465 5.625-.246-2.505-2.091-3.55-3.217-4.35.613 2.116-2.038 1.386-2.844-.2.266.083.541.07.705-.036-.086-3.23-1.605-5.086-2.284-5.653-.866-.714-1.332 1.013-1.625 2.319a.906.906 0 0 1-.939.725c.107-.155.226-.575.246-.704-1.339.101-2.85.896-3.777 1.312-2.351 1.062-2.943 1.596-3.463 3.386-1.866.783-4.018 2.94-4.916 3.957"
            />
            <path
              fill="#000"
              d="M16.155 14.245c.26.293 1.812 2.638.013 5.624.493-2.194-.873-6.393-2.384-4.248 1.024.445 1.358 1.278 1.084 2.088-.418-.78-1.485-1.623-2.537-1.273-.526.177-1.227.18-1.746-.589-.68-.997.46-2.228-1.485-2.128 2.352-1.193 1.672 1.125 2.805 1.399 1.318.316 1.471-.498 1.924-1.023.367-.433 1.04-.766 1.746-.3.339-1.03-.152-2.65.806-3.51.354-.316 1.485-.28 2.071.188-.832-.015-1.811.977-2.297 3.772"
            />
            <path
              fill="#000"
              d="M10.784 23.714c-.106-.094-.419-.5-.39-.936.39.255.93.375.93.375s-.251.437-.54.56m2.367-2.58c.232.022.432.217.645.062.208-.153-.006-.783-.168-.985.2-.04 1.02.04.98.34-.098.765-.579 1.013-1.752 1.582.201-.187.168-.594.295-1m-4.678-.636c.1-.238.547-.545.747-.566-.075.673.167 1.588.167 1.588s-.906-.57-.914-1.022M5.49 17.51c-.427-.304-1.098-.99-1.167-1.247.082-.097.44-.49.894-.614.333-.088.547.097.5.204-.047.107-.34.107-.427.36-.132.394.294.503.38.68.053.113.167.39-.18.617m22.681-7.366c1.197 4.174.859 8.192-.373 10.592.154-1.505-1.167-3.706-.634-6.274-2.504 2.43 1.407 8.392-4.268 12.301.846-1.36 1.232-4.757.931-5.99.301.116.607.493.8.809.472-1.551.534-3.767-.361-5.995.308.037.946.32 1.24.599.14-3.454-2.385-3.714-2.857-4.653 0 1.008 1.178 1.86 1.392 3.174-.826-.389-1.426-.436-1.851-.389.639.461 2.057 3.211 1.637 5.419-.612-.517-1.178-.753-1.79-.798.75 1.138 1.06 4.125.118 5.488-.025-.308-.14-1.2-.49-1.573-.447 1.67-1.753 2.377-2.647 2.584 1.114-1.161.894-3.22-.732-3.777-1.466-.502-3.33.493-4.083 3.987-1.059.312-4.236-.37-2.338-2.415 1.159.39 4.104-1.585 3.904-2.964-.282-1.923-2.033-2.598-3.425-2.582-1.718.022-2.878 1.623-4.282 2.322-1.174-.179-1.573-.345-2.173-1.907-.072-.957.947-1.63.927-2.616-.833.178-1.04-1.079-2.665.221-.127-.236.087-.793.087-.793-.6.54-.713 1.187-.726 1.32.293.578.705 1.105 1.504 1.627.958 2.56 1.945 2.849 2.958 2.943.173.245.926 1.144 1.88 1.284-.162-.48-.187-1.838-.088-2.178 2.079-2.082 3.624-.813 3.824-.261-1.845.382-.54 4.331-3.603 2.573-.128.403.046 1.412.433 1.997.313 2.709 3.716 2.043 4.768 2.518-.113-2.628 1.34-4.55 2.59-4.392 1.254.16 1.58 2.205-.558 4.141.527.277 2.817-.107 3.983-.954.106.67-.751 2.643-1.825 4.033 8.986-2.059 13.003-11.807 8.793-19.42"
            />
          </g>
        </svg>
        {/*
          //temporary delete desc
          <h1 className="normal tc mb0">{!firstRender ? formatMessage({ id: 'errorMessages.ERR_MAINTENANCE_PAGE' }) : ''}</h1>
          <h2 className="normal f4 tc">{!firstRender ? formatMessage({ id: 'errorMessages.ERR_MAINTENANCE_PAGE_WITH_SORRY' }) : ''}</h2> 
        */}
      </div>
    </div>
  );
};

export default memo(MaintenancePage);
