import { CURRENCY_IDR, DEFAULT_EWALLET_FORMAT, EWALLET_NUMBER_MAX_LENGTH } from 'settings/constants/common';
import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_SELECT,
  FORMAT_NUMBER,
  DEFAULT_MIN_TRANSFER_AMOUNT,
  DEFAULT_MAX_TRANSFER_AMOUNT,
  FORMAT_FAVOURITE_NICKNAME,
  FAVOURITE_NICKNAME_MAX_LENGTH,
} from 'settings/constants/transaction';

export const E_WALLET_FIELDS = [
  {
    name: 'accountNumber',
    label: 'transaction.transfer.eWallet.customerNumber',
    type: FIELD_TYPE_NUMBER,
    allowLeadingZero: true,
    format: DEFAULT_EWALLET_FORMAT,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'customerNumberLength', isValidateRequired: true },
    ],
  },
  {
    name: 'beneficiaryBankLabel',
    label: 'transaction.transfer.eWallet.name',
    type: FIELD_TYPE_TEXT,
    readOnly: true,
  },
  {
    name: 'transferAmount',
    label: 'transaction.transfer.amount',
    type: FIELD_TYPE_NUMBER,
    leftLabel: CURRENCY_IDR,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'minAmount', value: DEFAULT_MIN_TRANSFER_AMOUNT, isValidateRequired: true },
      { type: 'maxAmount', value: DEFAULT_MAX_TRANSFER_AMOUNT, isValidateRequired: true },
    ],
  },
];

// Add new favourite from main transfer page
export const E_WALLET_NEW_FAVOURITE = [
  {
    name: 'accountNickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    options: [],
    name: 'payeeCode',
    label: 'transaction.transfer.eWallet.name',
    placeholder: 'transaction.transfer.swift.placeholder.select',
    type: FIELD_TYPE_SELECT,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'toAccountNo',
    label: 'transaction.transfer.eWallet.customerNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    maxLength: EWALLET_NUMBER_MAX_LENGTH,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'customerNumberLength', isValidateRequired: true },
    ],
  },
  {
    isHidden: true,
    readOnly: true,
    type: FIELD_TYPE_TEXT,
    name: 'accountHolderName',
    label: 'transaction.transfer.beneficiary.name',
  },
];

export const E_WALLET_EDIT_FAVOURITE = [
  {
    name: 'accountNickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'payeeName',
    label: 'transaction.transfer.eWallet.name',
    type: FIELD_TYPE_TEXT,
  },
  {
    readOnly: true,
    name: 'toAccountNo',
    label: 'transaction.transfer.eWallet.customerNumber',
    type: FIELD_TYPE_TEXT,
  },
  {
    readOnly: true,
    type: FIELD_TYPE_TEXT,
    name: 'accountHolderName',
    label: 'transaction.transfer.beneficiary.name',
  },
];

export const PAYEE_CODE_DANA_HIDDEN = '1090';
export const PAYEE_CODE_SHOPEEPAY_HIDDEN = '1095';
