export const SETTINGS_PERSONAL_TAB_VALUE = 'personal';
export const SETTINGS_SECURITY_TAB_VALUE = 'security';
export const SETTINGS_MANAGE_TRANSACTION_TAB_VALUE = 'manage-transaction';
export const DEFAULT_SETTINGS_SUBMENU_TAB_VALUE = 0;

export const CHANGE_PASSWORD_MAX_LENGTH = 20;
export const CHANGE_PASSWORD_MIN_LENGTH = 8;

export const SI_MODE_RECURRING_TRANSFER = '3';
export const SI_MODE_LATER_TRANSFER = '2';

export const SI_FREQUENCY_WEEKLY = { en: 'Week', id: 'Minggu' };
export const SI_FREQUENCY_MONTHLY = { en: 'Month', id: 'Bulan' };
export const SI_FREQUENCY_LATER = { en: 'Transfer Later', id: 'Transfer Nanti' };

export const SI_CONTENT = 'Managing Transaction';

export const SETTINGS_PERSONAL_MODULE_TAB_VALUE = {
  PERSONAL_INFO: 0,
  THEME: 1,
  PASSWORD: 0,
  SECURITY_IMAGE: 1,
  MANAGE_DEVICE: 2,
  RECURRING_TRANSFER: 0,
  LATER_TRANSFER: 1,
};
