import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';

import { createStore, applyMiddleware, compose } from 'redux';

import rootSagas from 'middleware/sagas';
import rootReducer from 'middleware/reducers';

const sagaMiddleware = createSagaMiddleware();

// Setup react dev tools
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

export { store, sagaMiddleware };

// then run the saga
sagaMiddleware.run(rootSagas);
