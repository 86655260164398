import { actions } from 'middleware/actions/payment';

const { RESET_PAYMENT, RESET_CONFIRM_PAYMENT, SENDER_ACCOUNT_RESPONSE, UTILITY, CREDIT_CARD, MOBILE_POSTPAID, OTHER_BILL } = actions.PUT;

const initialState = {
  senderAccountResult: {},
  utilityBillers: [],
  utilityAccess: {},
  utilityBillerDetail: {},
  utilityTac: {},
  utilityResult: {},
  utilityReceipt: {},
  ccAccess: {},
  ccTypes: [],
  ccMaybankAccess: {},
  ccMaybankHolders: [],
  ccOwnMaybankCards: {},
  ccPaymentModes: [],
  ccMaybankTac: {},
  ccOtherBankTac: {},
  ccMaybankResult: {},
  ccOtherBankResult: {},
  ccMaybankReceipt: {},
  ccOtherBankReceipt: {},
  mobileAccess: {},
  mobileBillers: [],
  mobileDetail: {},
  mobileTac: {},
  mobileResult: {},
  mobileReceipt: {},
  otherBillAccess: {},
  otherBillBillers: [],
  otherBillPayees: [],
  otherBillDetail: {},
  otherBillTac: {},
  otherBillResult: {},
  otherBillReceipt: {},
};

const confirmInitialState = {
  utilityConfirmation: {},
  ccMaybankConfirmation: {},
  ccOtherBankConfirmation: {},
  mobileConfirmation: {},
  otherBillConfirmation: {},
};

const allInitialState = { ...initialState, confirmInitialState };

const PaymentReducer = (state = allInitialState, action = {}) => {
  switch (action.type) {
    case RESET_PAYMENT:
      return allInitialState;
    case RESET_CONFIRM_PAYMENT:
      return { ...state, ...confirmInitialState };
    case SENDER_ACCOUNT_RESPONSE:
      return { ...state, senderAccountResult: action.res };
    case UTILITY.ACCESS:
      return { ...state, utilityAccess: action.res };
    case UTILITY.BILLERS:
      return { ...state, utilityBillers: action.res };
    case UTILITY.BILLER_DETAIL:
      return { ...state, utilityBillerDetail: action.res };
    case UTILITY.RECEIPT:
      return { ...state, utilityReceipt: action.res };
    case UTILITY.RESULT:
      return { ...state, utilityResult: action.res };
    case UTILITY.TAC_RESULT:
      return { ...state, utilityTac: action.res };
    case UTILITY.CONFIRMATION:
      return { ...state, utilityConfirmation: action.res };
    // CREDIT CARD
    case CREDIT_CARD.ACCESS:
      return { ...state, ccAccess: action.res };
    case CREDIT_CARD.TYPES:
      return { ...state, ccTypes: action.res };
    case CREDIT_CARD.MAYBANK_ACCESS:
      return { ...state, ccMaybankAccess: action.res };
    case CREDIT_CARD.MAYBANK_HOLDERS:
      return { ...state, ccMaybankHolders: action.res };
    case CREDIT_CARD.OWN_MAYBANK_CARDS:
      return { ...state, ccOwnMaybankCards: action.res };
    case CREDIT_CARD.PAYMENT_MODES:
      return { ...state, ccPaymentModes: action.res };
    case CREDIT_CARD.MAYBANK_CONFIRMATION:
      return { ...state, ccMaybankConfirmation: action.res };
    case CREDIT_CARD.OTHER_BANK_CONFIRMATION:
      return { ...state, ccOtherBankConfirmation: action.res };
    case CREDIT_CARD.REQUEST_MAYBANK_TAC:
      return { ...state, ccMaybankTac: action.res };
    case CREDIT_CARD.REQUEST_OTHER_BANK_TAC:
      return { ...state, ccOtherBankTac: action.res };
    case CREDIT_CARD.MAYBANK_RESULT:
      return { ...state, ccMaybankResult: action.res };
    case CREDIT_CARD.OTHER_BANK_RESULT:
      return { ...state, ccOtherBankResult: action.res };
    case CREDIT_CARD.MAYBANK_RECEIPT:
      return { ...state, ccMaybankReceipt: action.res };
    case CREDIT_CARD.OTHER_BANK_RECEIPT:
      return { ...state, ccOtherBankReceipt: action.res };
    // MOBILE POSTPAID
    case MOBILE_POSTPAID.ACCESS:
      return { ...state, mobileAccess: action.res };
    case MOBILE_POSTPAID.BILLERS:
      return { ...state, mobileBillers: action.res };
    case MOBILE_POSTPAID.DETAIL:
      return { ...state, mobileDetail: action.res };
    case MOBILE_POSTPAID.CONFIRM:
      return { ...state, mobileConfirmation: action.res };
    case MOBILE_POSTPAID.TAC:
      return { ...state, mobileTac: action.res };
    case MOBILE_POSTPAID.RESULT:
      return { ...state, mobileResult: action.res };
    case MOBILE_POSTPAID.RECEIPT:
      return { ...state, mobileReceipt: action.res };
    // OTHER BILL
    case OTHER_BILL.ACCESS:
      return { ...state, otherBillAccess: action.res };
    case OTHER_BILL.BILLERS:
      return { ...state, otherBillBillers: action.res };
    case OTHER_BILL.PAYEES:
      return { ...state, otherBillPayees: action.res };
    case OTHER_BILL.DETAIL:
      return { ...state, otherBillDetail: action.res };
    case OTHER_BILL.CONFIRMATION:
      return { ...state, otherBillConfirmation: action.res };
    case OTHER_BILL.TAC:
      return { ...state, otherBillTac: action.res };
    case OTHER_BILL.RESULT:
      return { ...state, otherBillResult: action.res };
    case OTHER_BILL.RECEIPT:
      return { ...state, otherBillReceipt: action.res };
    default:
      return state;
  }
};

export default PaymentReducer;
