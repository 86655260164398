import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './style.scss';

const CardContentItem = ({ title, info, className }) => (
  <Grid container className={`content--item ${className}`}>
    <Grid item xs={6}>
      <Typography className="item--left">{title}</Typography>
    </Grid>

    <Grid item xs={6}>
      <Typography className="item--right">{info}</Typography>
    </Grid>
  </Grid>
);

CardContentItem.defaultProps = { info: '', title: '', className: '' };

CardContentItem.propTypes = { title: PropTypes.node, info: PropTypes.node, className: PropTypes.string };

export default CardContentItem;
