// favourite initial Step: Choose purchase type
import { FIELD_TYPE_SELECT } from '../transaction';

export const FAVOURITE_PAYMENT_TYPES = options => [
  {
    name: 'favouritePaymentType',
    label: 'transaction.favourite.payment.for',
    placeholder: 'transaction.transfer.swift.placeholder.select',
    type: FIELD_TYPE_SELECT,
    options,
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];
