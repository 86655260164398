export const actions = {
  GET: {
    SENDER_ACCOUNT: 'PURCHASE_GET_SENDER_ACCOUNT',
    ACCESS: 'PURCHASE_GET_ACCESS',
    MOBILE_PREPAID: {
      DETAIL: 'PURCHASE_GET_MOBILE_PREPAID_DETAIL',
      CONFIRMATION: 'PURCHASE_GET_MOBILE_PREPAID_CONFIRMATION',
      REQUEST_TAC: 'PURCHASE_GET_MOBILE_PREPAID_REQUEST_TAC',
      RESULT: 'PURCHASE_GET_MOBILE_PREPAID_RESULT',
      RECEIPT: 'PURCHASE_GET_MOBILE_PREPAID_RECEIPT',
    },
    TICKET: {
      TAC: 'PURCHASE_TICKET_GET_TAC',
      RESULT: 'PURCHASE_TICKET_GET_RESULT',
      RECEIPT: 'PURCHASE_TICKET_GET_RECEIPT',
      CONFIRM: 'PURCHASE_TICKET_GET_CONFIRMATION',
    },
    PLN_RELOAD: {
      DETAIL: 'PURCHASE_GET_PLN_DETAIL',
      CONFIRMATION: 'PURCHASE_GET_PLN_CONFIRMATION',
      REQUEST_TAC: 'PURCHASE_GET_PLN_REQUEST_TAC',
      RESULT: 'PURCHASE_GET_PLN_RESULT',
      RECEIPT: 'PURCHASE_GET_PLN_RECEIPT',
    },
  },
  PUT: {
    RESET_PURCHASE: 'TRANSACTION_PUT_RESET_PURCHASE',
    SENDER_ACCOUNT_RESPONSE: 'PURCHASE_PUT_SENDER_ACCOUNT_RESPONSE',
    ACCESS: 'PURCHASE_PUT_ACCESS',
    MOBILE_PREPAID: {
      DETAIL: 'PURCHASE_PUT_MOBILE_PREPAID_DETAIL',
      CONFIRMATION: 'PURCHASE_PUT_MOBILE_PREPAID_CONFIRMATION',
      REQUEST_TAC: 'PURCHASE_PUT_MOBILE_PREPAID_REQUEST_TAC',
      RESULT: 'PURCHASE_PUT_MOBILE_PREPAID_RESULT',
      RECEIPT: 'PURCHASE_PUT_MOBILE_PREPAID_RECEIPT',
    },
    TICKET: {
      TAC: 'PURCHASE_TICKET_PUT_TAC',
      RESULT: 'PURCHASE_TICKET_PUT_RESULT',
      RECEIPT: 'PURCHASE_TICKET_PUT_RECEIPT',
      CONFIRM: 'PURCHASE_TICKET_PUT_CONFIRMATION',
    },
    PLN_RELOAD: {
      DETAIL: 'PURCHASE_PUT_PLN_DETAIL',
      CONFIRMATION: 'PURCHASE_PUT_PLN_CONFIRMATION',
      TAC: 'PURCHASE_PUT_PLN_REQUEST_TAC',
      RESULT: 'PURCHASE_PUT_PLN_RESULT',
      RECEIPT: 'PURCHASE_PUT_PLN_RECEIPT',
    },
  },
};

export const putResetPurchase = () => ({ type: actions.PUT.RESET_PURCHASE });

// Common
export const getPurchaseSenderAccount = payload => ({ type: actions.GET.SENDER_ACCOUNT, payload });
export const getPurchaseAccess = payload => ({ type: actions.GET.ACCESS, payload });

export const putSenderAccountResult = res => ({ type: actions.PUT.SENDER_ACCOUNT_RESPONSE, res });
export const putPurchaseAccess = res => ({ type: actions.PUT.ACCESS, res });

// Mobile Prepaid
export const getMobilePrepaidDetail = payload => ({ type: actions.GET.MOBILE_PREPAID.DETAIL, payload });
export const getMobilePrepaidConfirmation = payload => ({ type: actions.GET.MOBILE_PREPAID.CONFIRMATION, payload });
export const getMobilePrepaidRequestTac = payload => ({ type: actions.GET.MOBILE_PREPAID.REQUEST_TAC, payload });
export const getMobilePrepaidResult = payload => ({ type: actions.GET.MOBILE_PREPAID.RESULT, payload });
export const getMobilePrepaidReceipt = payload => ({ type: actions.GET.MOBILE_PREPAID.RECEIPT, payload });

export const putMobilePrepaidDetail = res => ({ type: actions.PUT.MOBILE_PREPAID.DETAIL, res });
export const putMobilePrepaidConfirmation = res => ({ type: actions.PUT.MOBILE_PREPAID.CONFIRMATION, res });
export const putMobilePrepaidRequestTac = res => ({ type: actions.PUT.MOBILE_PREPAID.REQUEST_TAC, res });
export const putMobilePrepaidResult = res => ({ type: actions.PUT.MOBILE_PREPAID.RESULT, res });
export const putMobilePrepaidReceipt = res => ({ type: actions.PUT.MOBILE_PREPAID.RECEIPT, res });

// TICKET
export const requestTicketTAC = payload => ({ type: actions.GET.TICKET.TAC, payload });
export const getTicketResult = payload => ({ type: actions.GET.TICKET.RESULT, payload });
export const getTicketReceipt = payload => ({ type: actions.GET.TICKET.RECEIPT, payload });
export const getTicketConfirmation = payload => ({ type: actions.GET.TICKET.CONFIRM, payload });

export const putTicketTAC = res => ({ type: actions.PUT.TICKET.TAC, res });
export const putTicketResult = res => ({ type: actions.PUT.TICKET.RESULT, res });
export const putTicketReceipt = res => ({ type: actions.PUT.TICKET.RECEIPT, res });
export const putTicketConfirmation = res => ({ type: actions.PUT.TICKET.CONFIRM, res });

// Mobile Prepaid
export const getPlnReloadDetail = payload => ({ type: actions.GET.PLN_RELOAD.DETAIL, payload });
export const getPlnReloadConfirmation = payload => ({ type: actions.GET.PLN_RELOAD.CONFIRMATION, payload });
export const requestPlnReloadTac = payload => ({ type: actions.GET.PLN_RELOAD.REQUEST_TAC, payload });
export const getPlnReloadResult = payload => ({ type: actions.GET.PLN_RELOAD.RESULT, payload });
export const getPlnReloadReceipt = payload => ({ type: actions.GET.PLN_RELOAD.RECEIPT, payload });

export const putPlnReloadDetail = res => ({ type: actions.PUT.PLN_RELOAD.DETAIL, res });
export const putPlnReloadConfirmation = res => ({ type: actions.PUT.PLN_RELOAD.CONFIRMATION, res });
export const putPlnReloadTac = res => ({ type: actions.PUT.PLN_RELOAD.TAC, res });
export const putPlnReloadResult = res => ({ type: actions.PUT.PLN_RELOAD.RESULT, res });
export const putPlnReloadReceipt = res => ({ type: actions.PUT.PLN_RELOAD.RECEIPT, res });
