import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import './style.scss';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const TextButton = ({ title, onClick, variant, disabled, size, fullWidth, children, className, disableRipple, disabledElevation }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button size={size} onClick={onClick} disabled={disabled} className={`btn-text ${className}`}>
        {title || children}
      </Button>
    </div>
  );
};

TextButton.defaultProps = {
  title: '',
  className: '',
  size: 'medium',
  onClick: null,
  children: null,
  disabled: false,
  fullWidth: false,
  variant: 'contained',
  disableRipple: false,
  disabledElevation: true,
};

TextButton.propTypes = {
  title: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  disabledElevation: PropTypes.bool,
};

export default TextButton;
