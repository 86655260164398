import { TRANSACTION_TYPE } from 'settings/constants/transaction';

const { PAYMENT, PURCHASE, TRANSFER } = TRANSACTION_TYPE;

export const searchFieldPlacholder = type => {
  const handler = {
    [PURCHASE]: 'transaction.favourite.placeholder.searchPurchase',
    [PAYMENT]: 'transaction.favourite.placeholder.searchPayment',
    [TRANSFER]: 'transaction.favourite.placeholder.searchTransfer',
    default: '',
  };
  return handler[type] || handler.default;
};
