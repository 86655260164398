import { get } from 'utils/lodash';

import { CC_MINIMUM_PAYMENT, CC_OUTSTANDING_BALANCE } from 'settings/constants/transaction';

import DynoCardDetail from 'components/dyno-template/CardDetail';

export const formatOwnMaybankCards = cards =>
  Array.isArray(cards) &&
  cards.map(card => ({
    ...card,
    value: card.creditCardNoDisplay,
    label: <DynoCardDetail detail={card} />,
  }));

export const formatCcPaymentModes = detail => {
  const paymentModes = get(detail, 'paymentModeList', []);

  return paymentModes.map(mode => {
    const modeDetail = { label: mode.value, value: mode.key, expiryDate: detail.cardExpiryDate };

    if (mode.key === CC_OUTSTANDING_BALANCE) return { ...modeDetail, amount: detail.outStandingAmount };
    if (mode.key === CC_MINIMUM_PAYMENT) return { ...modeDetail, amount: detail.minimumAmount };
    return modeDetail;
  });
};

export const formatCcAddFavouriteResultPayload = payload => {
  const tacValue = get(payload, 'tacValue', '');
  const isFromTransferResult = get(payload, 'confirmationDetail.isFromTransferResult', false);

  return {
    isFromTransferResult,
    ...(!isFromTransferResult ? { tacValue } : {}),
    userId: get(payload, 'userId', ''),
    restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', {}),
    nickname: get(payload, 'confirmationDetail.nickname', ''),
    cardType: get(payload, 'formDetail.cardType', ''),
    cardHolderName: get(payload, 'confirmationDetail.cardHolderName', ''),
    creditCardNo: get(payload, 'confirmationDetail.creditCardNo', ''),
    issuingBank: get(payload, 'confirmationDetail.issuingBank', ''),
  };
};

export const formatCCEditFavouritePayload = payload => ({
  userId: get(payload, 'userId', ''),
  nickname: get(payload, 'nickname', ''),
  cardType: get(payload, 'cardType', ''),
  creditCardNo: get(payload, 'creditCardNumber', ''),
  cardHolderName: get(payload, 'cardHolderName', ''),
  issuingBank: get(payload, 'issuingBank', ''),
});

export const formatCCEditFavouriteResultPayload = payload => ({
  userId: get(payload, 'userId', ''),
  tacValue: get(payload, 'tacValue', ''),
  restSMSService: get(payload, 'tacDetail.tacViewBean.restSMSService', {}),
  nickname: get(payload, 'confirmationDetail.nickname', ''),
  creditCardNo: get(payload, 'confirmationDetail.creditCardNo', ''),
});
