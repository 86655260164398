export const inbox = {
  action: {
    delete: 'Delete',
    print: 'Print',
    reply: 'Reply',
    titleModal: 'Delete Mail',
    notification: 'Notification',
    descNotif: 'Stop composing messages? Messages will not be saved',
    successSent: 'Message successfully sent!',
    successReply: 'Message successfully reply!',
    failSent: 'Message Failed to send!',
    failReply: 'Message Failed to reply!',
    ok: 'YES',
  },
  subHeader: { label: 'INBOX' },
  label: 'Inbox',
  sent: {
    label: 'Sent Items',
  },
  message: {
    label: 'Message',
    successDelete: 'Mail deleted successfully.',
    errorDelete: 'Failed to delete mail',
    successCompose: 'Mail created successfully.',
    errorCompose: 'Failed to compose mail',
  },
  modal: {
    description: 'Do you want to permanently delete the selected item(s)?',
  },
  compose: 'Compose',
  reply: 'Reply',
  subject: 'Subject',
  messageInput: 'Message',
  mail: 'Mail',
  replyConversation: 'Reply Conversation',
  placeholderSubject: 'Choose Subject',
};
