import { actions } from '../actions/self-unlock';

const {
  SELF_UNLOCK_ACCESS,
  VERIFY_SELF_UNLOCK_DETAILS,
  RESET_SELF_UNLOCK_STATE,
  REQUEST_USERNAME_SEND_TO_EMAIL_RESPONSE,
  RESET_PASSWORD_TAC_REQUEST_RESPONSE,
  RESET_PASSWORD_TAC_SUBMIT_RESPONSE,
  UNLOCK_USER_RESPONSE,
} = actions.PUT;

const initialState = {
  currencies: [],
  verifiedDetail: {},
  emailDetail: {},
  isSuccess: false,
  resetPasswordTacRequestResponse: {},
  resetPasswordTacSubmitResponse: {},
  userUnlockResponse: {},
};

const SelfUnlockReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELF_UNLOCK_ACCESS:
      return { ...state, currencies: action.payload };
    case VERIFY_SELF_UNLOCK_DETAILS:
      return { ...state, verifiedDetail: action.payload, isSuccess: action.isSuccess };
    case REQUEST_USERNAME_SEND_TO_EMAIL_RESPONSE:
      return { ...state, emailDetail: action.payload, isSuccess: action.isSuccess };
    case RESET_SELF_UNLOCK_STATE:
      // doesnt need to clear the currencies since user might open back the selfunlock
      return { ...initialState, currencies: state.currencies };
    case RESET_PASSWORD_TAC_REQUEST_RESPONSE:
      return { ...state, resetPasswordTacRequestResponse: action.payload };

    case RESET_PASSWORD_TAC_SUBMIT_RESPONSE:
      return { ...state, resetPasswordTacSubmitResponse: action.payload };

    case UNLOCK_USER_RESPONSE:
      return { ...state, userUnlockResponse: action.payload };
    default:
      return state;
  }
};

export default SelfUnlockReducer;
