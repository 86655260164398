export const payment = {
  label: 'Payment',
  from: 'Payment from',
  to: 'Payment for',
  pay: 'Pay',
  fine: 'Fine',
  resultFail: 'Your Request is failed!',
  resultSuccess: 'Transaction Successful!',
  monthYear: 'Month/Year',
  transaction: 'Transaction',
  foreignExchange: 'Foreign Exchange',
  placeHolder: {
    autocomplete: 'Select your Biller',
    selectPayee: 'Select your Payee',
    selectCc: 'Select your Credit Card',
    selectMaybankCc: 'Select the Maybank Credit Card Holder',
    selectPaymentOption: 'Select Payment Option',
    maxChar: 'Max 120 characters',
    selectAmount: 'Select Amount',
    pleaseSelect: 'Please Select',
  },
  creditCard: {
    creditCards: 'Credit Cards',
    ownCreditCard: 'Own Credit Card',
  },
  otherBill: {
    vaNumber: 'Virtual Account Number',
    billAccountNumber: 'Bill Account Number',
    message: 'Message (Optional)',
    billRef: 'Bill Reference Number',
    billRefOptional: 'Bill Reference Number (Optional)',
    beneficiaryMessage: 'Message',
  },
  modal: {
    title: `Payment for {billerName}`,
    titleOwnCard: `Payment for Own Maybank Credit Card`,
    titleOtherMaybankCard: `Payment for Other Maybank Credit Card`,
    titleOtherBankCard: `Payment for Other Bank Credit Card`,
    customerNumber: 'Customer Number',
    mobileNumber: 'Mobile Number',
    ccNumber: 'Credit Card Number',
    cardNumber: 'Card Number',
    paymentOption: 'Payment Option',
    cardType: 'Card Type',
    biller: 'Biller',
    payee: 'Payee',
    amount: 'Amount',
  },
  errorMessage: {
    billerNotFound: 'No Billers found',
    invalidMobileNumber: 'Invalid mobile number',
    invalidCustomerNumber: 'Invalid Customer Number',
    invalidReferenceNumber: 'Invalid Reference Number',
    invalidCreditCardNumber: 'Invalid credit card number',
    invalidBillAccountNumber: 'Invalid Bill Account Number',
    invalidVirtualAccountNumber: 'Invalid Virtual Account Number',
  },
};
