import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putMobilePrepaidDetail,
  putMobilePrepaidConfirmation,
  putMobilePrepaidRequestTac,
  putMobilePrepaidResult,
  putMobilePrepaidReceipt,
} from 'middleware/actions/purchase';

import { formatKeys } from 'utils/formatter';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';

const { ACCESS, DETAIL, CONFIRMATION, REQUEST_TAC, RESULT, RECEIPT } = actions.GET.MOBILE_PREPAID;

const getClientIP = () => remoteIpInstance.get();

const getDetail = ({ body }) => Instance.post(endpoint.mobilePrepaid.detail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.mobilePrepaid.confirm, body);
const requestTAC = ({ body }) => Instance.post(endpoint.mobilePrepaid.requestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.mobilePrepaid.result, body);
const getReceipt = ({ body }) => Instance.post(endpoint.mobilePrepaid.receipt, body);

const getLocale = state => state.LanguageReducer.locale;

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });

    yield put(putMobilePrepaidDetail(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getConfirmation, { body });

    yield put(putMobilePrepaidConfirmation(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });

    yield put(putMobilePrepaidRequestTac(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });

    yield put(putMobilePrepaidResult(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });

    yield put(putMobilePrepaidReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PurchaseMobilePrepaid() {
  yield all([
    takeLatest(DETAIL, getDetailSaga),
    takeLatest(CONFIRMATION, getConfirmationSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(RESULT, getResultSaga),
    takeLatest(RECEIPT, getReceiptSaga),
  ]);
}
