import { combineReducers } from 'redux';

import { actions } from 'middleware/actions/login';

import AuthReducer from './auth';
import LoginReducer from './login';
import LogoutReducer from './logout';
import ErrorReducer from './error';
import PaymentReducer from './payment';
import SettingsReducer from './settings';
import PurchaseReducer from './purchase';
import TransferReducer from './transfer';
import LanguageReducer from './language';
import DashboardReducer from './dashboard';
import CoachmarkReducer from './coachmark';
import SelfUnlockReducer from './self-unlock';
import RegistrationReducer from './registration';
import DigitalWealthReducer from './digital-wealth';
import FavouriteTransferReducer from './favourite/transfer';
import FavouritePaymentReducer from './favourite/payment';
import FavouritePurchaseReducer from './favourite/purchase';
import InboxsReducer from './inboxs';

const appReducer = combineReducers({
  AuthReducer,
  LoginReducer,
  LogoutReducer,
  ErrorReducer,
  PaymentReducer,
  SettingsReducer,
  TransferReducer,
  LanguageReducer,
  PurchaseReducer,
  DashboardReducer,
  CoachmarkReducer,
  SelfUnlockReducer,
  RegistrationReducer,
  DigitalWealthReducer,
  FavouriteTransferReducer,
  FavouritePaymentReducer,
  FavouritePurchaseReducer,
  InboxsReducer,
});

const { PUT } = actions;

const rootReducer = (state, action) => {
  const { type } = action;

  if (type === PUT.LOGOUT || type === PUT.LOGOUT_EXPIRED_SESSION) {
    // NOTE: below will reset all state except LanguageReducer & LogoutReducer
    // eslint-disable-next-line no-param-reassign
    state = {
      LanguageReducer: state.LanguageReducer,
      LogoutReducer: state.LogoutReducer,
      AuthReducer: {
        customerProfile: {
          headerImageLink: state.AuthReducer.customerProfile.headerImageLink,
          userId: state.AuthReducer.customerProfile.userId,
        },
      },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
