import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';
import { userAgent } from 'providers/fingerprint';

import { putErrorResponse } from 'middleware/actions/error';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';
import {
  actions,
  putRtgsConfirmation,
  putRtgsDetail,
  putRtgsDetailAfterTransfer,
  putRtgsResult,
  putRtgsTac,
} from 'middleware/actions/favourite/transfer';
import { formatTransferConfirmation } from 'utils/transfer/formatter';

const { DETAIL, DETAIL_AFTER_TRANSFER, CONFIRMATION, REQUEST_TAC, RESULT, EDIT_DETAILS, EDIT_CONFIRMATION, EDIT_REQUEST_TAC, EDIT_RESULT } =
  actions.GET.RTGS;
const { CONFIRMATION: DELETE_CONFIRMATION, REQUEST_TAC: REQUEST_TAC_DELETE, RESULT: DELETE_RESULT } = actions.DELETE.RTGS;

const getClientIP = () => remoteIpInstance.get();

const getLocale = state => state.LanguageReducer.locale;

const deleteRtgsFavourite = ({ body }) => Instance.post(endpoint.rtgs.favourite.delete, body);
const rtgsResultFavourite = ({ body }) => Instance.post(endpoint.rtgs.favourite.result, body);
const deleteRtgsResult = ({ body }) => Instance.post(endpoint.rtgs.favourite.deleteResult, body);
const newFavouriteDetail = ({ body }) => Instance.post(endpoint.rtgs.favourite.addNewDetail, body);
const requestDeleteRtgsTac = ({ body }) => Instance.post(endpoint.rtgs.favourite.deleteRequestTac, body);
const rtgsConfirmationFavourite = ({ body }) => Instance.post(endpoint.rtgs.favourite.confirmation, body);
const rtgsAddNewRequestTacFavourite = ({ body }) => Instance.post(endpoint.rtgs.favourite.addNewRequestTac, body);
const newFavouriteDetailAfterTransfer = ({ body }) => Instance.post(endpoint.rtgs.favourite.addNewDetailFromTransfer, body);
const rtgsEditFormDetail = ({ body }) => Instance.post(endpoint.rtgs.favourite.editDetail, body);
const rtgsEditConfirmDetail = ({ body }) => Instance.post(endpoint.rtgs.favourite.editConfirm, body);
const rtgsEditTac = ({ body }) => Instance.post(endpoint.rtgs.favourite.editRequestTac, body);
const rtgsEditResult = ({ body }) => Instance.post(endpoint.rtgs.favourite.editResult, body);

function* newFavouriteDetailSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetail, { body });

    yield put(putRtgsDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* newFavouriteDetailAfterTransferSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(newFavouriteDetailAfterTransfer, { body });

    yield put(putRtgsDetail(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(rtgsConfirmationFavourite, { body });
    yield put(putRtgsConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsAddNewTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(rtgsAddNewRequestTacFavourite, { body });
    yield put(putRtgsTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(rtgsResultFavourite, { body });

    yield put(putRtgsResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteRtgsFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(deleteRtgsFavourite, { body });
    yield put(putRtgsConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestDeleteRtgsTacSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(requestDeleteRtgsTac, { body });
    yield put(putRtgsTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* deleteRtgsResultSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(deleteRtgsResult, { body });
    yield put(putRtgsResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsEditDetailFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(rtgsEditFormDetail, { body });
    yield put(putRtgsDetail(formatTransferConfirmation({ ...data, isEditFavourite: true })));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsEditConfirmationFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { ...payload, locale };
    const { data } = yield call(rtgsEditConfirmDetail, { body });
    yield put(putRtgsConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsEditRequestTacFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(rtgsEditTac, { body });
    yield put(putRtgsTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* rtgsEditResultFavouriteSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };
    const { data } = yield call(rtgsEditResult, { body });
    yield put(putRtgsResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* RtgsFavourite() {
  yield all([
    takeLatest(DETAIL, newFavouriteDetailSaga),
    takeLatest(RESULT, rtgsResultFavouriteSaga),
    takeLatest(DELETE_RESULT, deleteRtgsResultSaga),
    takeLatest(REQUEST_TAC, rtgsAddNewTacFavouriteSaga),
    takeLatest(DELETE_CONFIRMATION, deleteRtgsFavouriteSaga),
    takeLatest(CONFIRMATION, rtgsConfirmationFavouriteSaga),
    takeLatest(REQUEST_TAC_DELETE, requestDeleteRtgsTacSaga),
    takeLatest(DETAIL_AFTER_TRANSFER, newFavouriteDetailAfterTransferSaga),
    takeLatest(EDIT_DETAILS, rtgsEditDetailFavouriteSaga),
    takeLatest(EDIT_CONFIRMATION, rtgsEditConfirmationFavouriteSaga),
    takeLatest(EDIT_REQUEST_TAC, rtgsEditRequestTacFavouriteSaga),
    takeLatest(EDIT_RESULT, rtgsEditResultFavouriteSaga),
  ]);
}
