import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { Grid, Typography, Link } from '@material-ui/core';

import Icon from './Icon';

// Provides placeholder for Header elements, with predefined styling
const Header = ({
  background,
  className,
  hasBackground,
  hasLeftSidebar,
  hasRightSidebar,
  hasTimestamp,
  logo,
  logoLink,
  timestamp,
  links,
  leftSidebar,
  rightSidebar,
}) => {
  const headerStyle = hasBackground ? { backgroundImage: `url(${background})` } : null;

  return (
    <Grid container className={`common header ${className}`} style={headerStyle}>
      <Grid item xs={11} sm={3} md={4}>
        <Grid container direction="row">
          {hasLeftSidebar && leftSidebar}
          <Link href={logoLink}>
            <img src={logo} className="logo" alt="Maybank2u" />
          </Link>
        </Grid>

        {hasTimestamp && (
          <Typography className="timestamp hidden-xs">
            <Icon isAssetIcon type="clockWhite" className="mr2" />
            <FormattedMessage id="dashboard.header.lastLogin" values={{ timestamp }} />
          </Typography>
        )}
      </Grid>
      <Grid container justify="flex-end" item xs={1} sm={9} md={8}>
        {links}
        {hasRightSidebar && rightSidebar}
      </Grid>
    </Grid>
  );
};

Header.defaultProps = {
  background: '',
  className: '',
  timestamp: '',
  hasBackground: false,
  hasLeftSidebar: false,
  hasRightSidebar: false,
  hasTimestamp: false,
  logoLink: null,
  links: null,
  rightSidebar: '',
  leftSidebar: '',
};

Header.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  hasBackground: PropTypes.bool,
  hasLeftSidebar: PropTypes.bool,
  hasRightSidebar: PropTypes.bool,
  hasTimestamp: PropTypes.bool,
  logoLink: PropTypes.string,
  timestamp: PropTypes.string,
  rightSidebar: PropTypes.node,
  leftSidebar: PropTypes.node,
  links: PropTypes.node,
  logo: PropTypes.string.isRequired,
};

export default Header;
