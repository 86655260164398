import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Autocomplete, Select } from 'components/common';
import SwiftSearchSection from 'components/transfer/swift/TextfieldSection';

import { memo } from 'utils/react';

import { E_WALLET, INTERBANK, SKN_TRANSFER, RTGS_TRANSFER } from 'settings/constants/transaction';

import DynoDropdownLabel from '../DropdownLabel';

import '../style.scss';

const DynoSelectionSection = ({
  type,
  fromLabel,
  toLabel,
  sourceAccount,
  sourceAccounts,
  sourceAccountValue,
  transactionType,
  transactionTypes,
  onSearchBank,
  hasOptions,
  optionLabels,
  isServiceOnline,
  isSearchSectionDisplay,
  onAutocompleteChange,
  onSourceAccountChange,
  onTransactionTypeChange,
  optionPlaceholder,
  firstOptionValue,
  secondOptionLabels,
  secondOptionValue,
  onSecondAutocompleteChange,
  secondOptionPlaceholder,
}) => {
  const { formatMessage } = useIntl();

  const isDisabled = isEmpty(sourceAccounts);
  const labelClassname = isDisabled ? 'disabled' : '';
  const isNotEligibleTransfer = isDisabled && !isEmpty(transactionTypes);
  // NOTE: Temporarily solution for placeholder display
  // TODO: Move to utils along with types
  const autocompleteEmptyLabel =
    type !== 'payment' && [E_WALLET, INTERBANK, SKN_TRANSFER, RTGS_TRANSFER].includes(transactionType)
      ? 'transaction.transfer.errorMessage.beneficiaryBankNotFound'
      : 'transaction.payment.errorMessage.billerNotFound';

  return (
    <>
      <Grid container spacing={1} className="pb2">
        <Grid item xs={12} md={2}>
          <Typography className={labelClassname}>{fromLabel}</Typography>
        </Grid>

        <Grid item xs={12} md={10}>
          <Select
            disabled={isDisabled}
            value={sourceAccountValue}
            options={sourceAccounts}
            onChange={onSourceAccountChange}
            displayEmpty={isNotEligibleTransfer}
            renderValue={() => {
              if (isNotEligibleTransfer) {
                return <div className="black tc">{formatMessage({ id: 'transaction.transfer.errorMessage.noEligibleAccount' })}</div>;
              }
              return <DynoDropdownLabel isSelected option={sourceAccount} />;
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <Typography className={labelClassname}>{toLabel}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Select disabled={isDisabled} value={transactionType} options={transactionTypes} onItemClick={onTransactionTypeChange} />
        </Grid>
      </Grid>

      {hasOptions && isServiceOnline && (
        <Grid container spacing={1} className="pb2 pt1" direction="row-reverse">
          <Grid item xs={12} md={10}>
            <Autocomplete
              value={firstOptionValue}
              options={optionLabels}
              isTextCapitalized={false}
              onChange={onAutocompleteChange}
              placeholder={formatMessage({ id: optionPlaceholder })}
              noOptionsText={formatMessage({ id: autocompleteEmptyLabel })}
            />
          </Grid>

          {/* note: display when second options are populated and first autocomplete have value */}
          {!isEmpty(secondOptionLabels) && firstOptionValue && (
            <Grid item xs={12} md={10}>
              <Autocomplete
                value={secondOptionValue}
                isTextCapitalized={false}
                options={secondOptionLabels}
                onChange={onSecondAutocompleteChange}
                placeholder={formatMessage({ id: secondOptionPlaceholder })}
                noOptionsText={formatMessage({ id: autocompleteEmptyLabel })}
              />
            </Grid>
          )}
        </Grid>
      )}

      {isSearchSectionDisplay && <SwiftSearchSection onSearchBank={onSearchBank} />}
    </>
  );
};

DynoSelectionSection.defaultProps = {
  type: '',
  fromLabel: '',
  toLabel: '',
  transactionType: '',
  sourceAccountValue: '',
  sourceAccount: {},
  sourceAccounts: PropTypes.array,
  transactionTypes: PropTypes.array,
  onSourceAccountChange: null,
  onTransactionTypeChange: null,
  onSearchBank: null,
  onAutocompleteChange: null,
  hasOptions: false,
  optionLabels: [],
  isSearchSectionDisplay: false,
  firstOptionValue: '',
  secondOptionLabels: [],
  secondOptionValue: '',
  onSecondAutocompleteChange: null,
  secondOptionPlaceholder: 'transaction.payment.placeHolder.autocomplete',
};

DynoSelectionSection.propTypes = {
  type: PropTypes.string,
  toLabel: PropTypes.node,
  fromLabel: PropTypes.node,
  hasOptions: PropTypes.bool,
  optionLabels: PropTypes.array,
  sourceAccount: PropTypes.object,
  sourceAccounts: PropTypes.array,
  sourceAccountValue: PropTypes.any,
  transactionType: PropTypes.string,
  transactionTypes: PropTypes.array,
  isSearchSectionDisplay: PropTypes.bool,
  onSourceAccountChange: PropTypes.func,
  onTransactionTypeChange: PropTypes.func,
  onSearchBank: PropTypes.func,
  isServiceOnline: PropTypes.bool.isRequired,
  onAutocompleteChange: PropTypes.func,
  optionPlaceholder: PropTypes.string.isRequired,
  secondOptionPlaceholder: PropTypes.string,
  firstOptionValue: PropTypes.string,
  secondOptionLabels: PropTypes.array,
  secondOptionValue: PropTypes.string,
  onSecondAutocompleteChange: PropTypes.func,
};

export default memo(DynoSelectionSection);
