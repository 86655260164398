import { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Table as MaterialTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { get } from 'utils/lodash';

import { ALIGN_INHERIT } from 'settings/constants/ui-control';
import { WEALTH_COLOR_INDICATOR_BLACK, WEALTH_COLOR_INDICATOR_RED } from 'settings/constants/dashboard';
import { TABLE_CLASS_UPPERCASE } from 'settings/constants/common';

import { BasicButton } from './buttons';
import { CommonModal } from './modals';

import Checkbox from './Checkbox';
import Pagination from './Pagination';

const INDEX_NOT_FOUND = -1;
const FIRST_PAGE = 1;

const Table = ({
  capslockHeader,
  rows,
  size,
  label,
  columns,
  hasRows,
  onChange,
  pageSize,
  className,
  hasCheckbox,
  hasPagination,
  emptyRowContent,
  ActionComponent,
  valueCheckbox,
  iconButton,
  titleButton,
  commonTitleModal,
  commonDescriptionModal,
  onOkModal,
  state,
  requestTac,
}) => {
  const { isModalOpen, isClicked, resetCheckBoxRemoveDevice } = state;

  const [checkedRows, setCheckedRows] = useState([]); // for checkbox
  const [checkedValue, setCheckedValue] = useState([]);
  const [isOpenModal, setOpenModal] = useState(isModalOpen);
  const [page, setPage] = useState(FIRST_PAGE); // default page is first page

  // check or uncheck ALL boxes
  const handleCheckAll = event => {
    const isChecked = event.target.checked;
    const checkedItems = isChecked ? rows.map((row, index) => get(row, 'id', index)) : [];
    const checkedValues = isChecked ? rows.map((row, index) => get(row, valueCheckbox, index)) : [];

    setCheckedRows(checkedItems);
    setCheckedValue(checkedValues);

    onChange(checkedItems); // pass back ALL checkedRows [id] or none
    onChange(checkedValues);
  };

  // check on uncheck a particular box
  const handleCheck = (event, id, value) => {
    const isChecked = event.target.checked;

    const checkedItems = isChecked ? checkedRows.concat(id) : checkedRows.filter(item => item !== id); // if uncheck, remove the checkedRows id
    const checkedValues = isChecked ? checkedValue.concat(value) : checkedValue.filter(item => item !== value); // if uncheck, remove the checkedRows value

    setCheckedRows(checkedItems);
    setCheckedValue(checkedValues);

    onChange(checkedItems); // pass back checkedRows [id] to application
    onChange(checkedValues);
  };

  // eslint-disable-next-line no-param-reassign
  state.checkedValueTableRemoveDevice = checkedValue;

  const handlePageChange = (event, newPage) => setPage(newPage);

  const isItemChecked = id => checkedRows.indexOf(id) !== INDEX_NOT_FOUND;

  const closeModal = () => setOpenModal(false);

  const rowCount = rows.length;

  const isCheckedAll = checkedRows.length === rowCount;

  const currentPageIndex = page - 1; // -1 because index starts at 0 while page starts at 1
  const startRowIndex = currentPageIndex * pageSize;
  const endRowIndex = currentPageIndex * pageSize + pageSize;
  // update which row to show based on current page
  const updatedRows = hasPagination ? rows.slice(startRowIndex, endRowIndex) : rows;

  // Round up for number of pages
  const pageCount = Math.ceil(rowCount / pageSize);

  const columnSpan = columns.length;

  // received new data reset to first page
  useEffect(() => {
    setPage(FIRST_PAGE);
  }, [rows]);

  useEffect(() => {
    if (isModalOpen) setOpenModal(true);

    // eslint-disable-next-line no-param-reassign
    state.isModalOpen = false;
  }, [isModalOpen]);

  useEffect(() => {
    setOpenModal(false);

    // eslint-disable-next-line no-param-reassign
    state.isClicked = false;
  }, [isClicked]);

  useEffect(() => {
    if (resetCheckBoxRemoveDevice) setCheckedRows([]);
  }, [resetCheckBoxRemoveDevice]);

  return (
    <TableContainer className={`common table ${className}`}>
      <MaterialTable aria-label={label} size={size}>
        <TableHead>
          <TableRow>
            {hasCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox variant="round" isChecked={isCheckedAll} onChange={value => handleCheckAll(value)} />
              </TableCell>
            )}
            {Children.toArray(
              columns.map(column => {
                const width = get(column, 'width', null);
                // default align attribute is inherit
                const textAlign = get(column, 'align', ALIGN_INHERIT);

                return (
                  <TableCell className={capslockHeader ? TABLE_CLASS_UPPERCASE : ''} align={textAlign} style={{ width }}>
                    {column.headerName}
                  </TableCell>
                );
              })
            )}
            {ActionComponent && <TableCell className="ttu" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {hasRows ? (
            Children.toArray(
              updatedRows.map((row, index) => {
                const id = get(row, 'id', index);
                const isChecked = isItemChecked(id);
                const value = get(row, 'customerKey', '');

                return (
                  <TableRow>
                    {hasCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox variant="round" isChecked={isChecked} onChange={event => handleCheck(event, id, value)} />
                      </TableCell>
                    )}

                    {Children.toArray(
                      columns.map(column => {
                        const field = get(column, 'field', '');
                        // default align attribute is inherit
                        const textAlign = get(column, 'align', ALIGN_INHERIT);

                        // for wealth mutual fund
                        const colorIndicator = get(row, 'colorIndicator', WEALTH_COLOR_INDICATOR_BLACK);
                        const isAmount = field === 'amountDisp';
                        const isRed = colorIndicator === WEALTH_COLOR_INDICATOR_RED;
                        const color = isAmount && isRed ? 'red' : 'black';

                        return (
                          <TableCell align={textAlign} className={color}>
                            {row[field]}
                          </TableCell>
                        );
                      })
                    )}
                    {ActionComponent && (
                      <TableCell>
                        <ActionComponent {...row} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={columnSpan}>
                {emptyRowContent}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>

      {hasPagination && <Pagination className="mt3" page={page} count={pageCount} onChange={handlePageChange} />}
      {hasCheckbox && valueCheckbox && (
        <Grid item xs={12}>
          <div className="flex flex-row justify-end items-center" id="btn-remove">
            <BasicButton icon={iconButton} title={titleButton} onClick={onOkModal} />
          </div>
          <CommonModal
            open={isOpenModal}
            onCancel={closeModal}
            onSubmit={requestTac}
            title={commonTitleModal}
            description={commonDescriptionModal}
          />
        </Grid>
      )}
    </TableContainer>
  );
};

Table.defaultProps = {
  capslockHeader: true,
  className: '',
  emptyRowContent: '',
  hasCheckbox: false,
  hasPagination: false,
  hasRows: true,
  label: 'simple table',
  pageSize: 1,
  size: 'small',
  onChange: null,
  rows: [],
  ActionComponent: null,
  valueCheckbox: '',
  iconButton: null,
  titleButton: '',
  commonTitleModal: '',
  commonDescriptionModal: '',
  onOkModal: null,
  state: {},
  requestTac: null,
};

Table.propTypes = {
  className: PropTypes.string,
  capslockHeader: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  hasPagination: PropTypes.bool,
  hasRows: PropTypes.bool,
  emptyRowContent: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  pageSize: PropTypes.number, // num of rows to display per page (for pagination)
  rows: PropTypes.array, // rows item must have an id property
  columns: PropTypes.array.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  ActionComponent: PropTypes.func,
  valueCheckbox: PropTypes.string,
  iconButton: PropTypes.element,
  titleButton: PropTypes.string,
  commonTitleModal: PropTypes.string,
  commonDescriptionModal: PropTypes.string,
  onOkModal: PropTypes.func,
  state: PropTypes.object,
  requestTac: PropTypes.func,
};

export default Table;
