import isNull from 'lodash/isNull';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putVirtualAccountDetail,
  putVirtualAccountConfirmation,
  putVirtualAccountTac,
  putVirtualAccountResult,
  putVirtualAccountReceipt,
} from 'middleware/actions/transfer';

import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatIntrabankDetail } from 'utils/transfer/intrabank/formatter';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

const { TRANSFER_CONFIRMATION, TRANSFER_DETAIL, TRANSFER_RESULT, TAC_RESULT, RECEIPT, TRANSFER_FROM_FAVOURITE_DETAIL } =
  actions.GET.VIRTUAL_ACCOUNT;

const getClientIP = () => remoteIpInstance.get();

const getDetail = ({ body }) => Instance.post(endpoint.virtualAccount.getDetail, body);
const requestTAC = ({ body }) => Instance.post(endpoint.virtualAccount.requestTac, body);
const getResult = ({ body }) => Instance.post(endpoint.virtualAccount.getResult, body);
const getReceipt = ({ body }) => Instance.post(endpoint.virtualAccount.getReceipt, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.virtualAccount.getConfirmation, body);
const getFavouriteDetail = ({ body }) => Instance.post(endpoint.virtualAccount.favourite.getTransferDetail, body);

const getLocale = state => state.LanguageReducer.locale;

function* getDetailSaga({ payload = {} }) {
  try {
    yield put(putVirtualAccountDetail({}));
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getDetail, { body });
    const res = formatIntrabankDetail(data);

    yield put(putVirtualAccountDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getFavouriteDetailSaga({ payload = {} }) {
  try {
    yield put(putVirtualAccountDetail({}));
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getFavouriteDetail, { body });
    const res = formatIntrabankDetail(data);

    yield put(putVirtualAccountDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);

    const body = { locale, remoteIPAddress: ip, ...payload };

    const { data } = yield call(getConfirmation, { body });
    yield put(putVirtualAccountConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });
    yield put(putVirtualAccountTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });
    yield put(putVirtualAccountResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };

    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    const responseCode = get(data, 'responseCode', '');

    yield put(putVirtualAccountReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferVirtualAccount() {
  yield all([
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(TAC_RESULT, requestTACSaga),
    takeLatest(TRANSFER_DETAIL, getDetailSaga),
    takeLatest(TRANSFER_RESULT, getResultSaga),
    takeLatest(TRANSFER_CONFIRMATION, getConfirmationSaga),
    takeLatest(TRANSFER_FROM_FAVOURITE_DETAIL, getFavouriteDetailSaga),
  ]);
}
