import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';

import { get } from 'utils/lodash';
import { formatGoalResponse } from 'utils/digital-wealth/formatter';

import {
  actions,
  putEducationGoal,
  putPensionGoal,
  putMaxInvestmentGoal,
  putGoalPlan,
  putExpandedPlan,
  putPlanDetail,
} from 'middleware/actions/digital-wealth';

import { putErrorResponse } from 'middleware/actions/error';
import { digitalWealthEndpoint as endpoint } from 'providers/endpoints/digital-wealth';

import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const { PENSION_GOAL, EDUCATION_GOAL, MAX_INVESTMENT_GOAL, GOAL_PLAN, PLAN_DETAIL, EXPANDED_PLAN } = actions.GET.GBI;

const getGoalPlan = ({ body }) => Instance.post(endpoint.get.goalPlans, body);
const getExpandedPlan = ({ body }) => Instance.post(endpoint.get.expandedList, body);
const getPlanDetail = ({ body }) => Instance.post(endpoint.get.planDetail, body);
const getPensionGoal = ({ body }) => Instance.post(endpoint.get.pensionGoal, body);
const getEducationGoal = ({ body }) => Instance.post(endpoint.get.educationGoal, body);
const getMaxInvestmentGoal = ({ body }) => Instance.post(endpoint.get.maxInvestmentGoal, body);

const getLocale = state => state.LanguageReducer.locale;

function* getPensionGoalSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getPensionGoal, { body });
    yield put(putPensionGoal(formatGoalResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getEducationGoalSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getEducationGoal, { body });
    yield put(putEducationGoal(formatGoalResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMaxInvestmentGoalSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getMaxInvestmentGoal, { body });
    yield put(putMaxInvestmentGoal(formatGoalResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getGoalPlanSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getGoalPlan, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putGoalPlan({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getExpandedPlanSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getExpandedPlan, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putExpandedPlan({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPlanDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getPlanDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putPlanDetail({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* DigitalWealthGbi() {
  yield all([
    takeLatest(GOAL_PLAN, getGoalPlanSaga),
    takeLatest(PLAN_DETAIL, getPlanDetailSaga),
    takeLatest(EXPANDED_PLAN, getExpandedPlanSaga),
    takeLatest(PENSION_GOAL, getPensionGoalSaga),
    takeLatest(EDUCATION_GOAL, getEducationGoalSaga),
    takeLatest(MAX_INVESTMENT_GOAL, getMaxInvestmentGoalSaga),
  ]);
}
