import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { get } from 'utils/lodash';

import { DROPDOWN_LABEL_TYPE_TRANSFER } from 'settings/constants/common';
import { MODAL_FULLSCREEN_SIZE } from 'settings/constants/ui-control';

const DynoDropdownLabel = ({ option, isSelected, type }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(MODAL_FULLSCREEN_SIZE));
  // cant use label since it intertwine with label from MUI Select options
  const dropdownLabel = get(option, 'dropdownLabel', '');
  const accountNumber = get(option, 'accountNumber', '');
  const balance = get(option, 'balance', '');
  const alignBalanceContainer = matches ? 'flex-start' : 'flex-end';
  const balanceContainerClass = matches ? 'pr4 pt2' : 'pr4';
  const balanceClass = matches ? 'green' : 'green pl1';

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        {dropdownLabel}
      </Grid>
      {isSelected ? (
        <Grid item xs={6} sm={6} container justify={alignBalanceContainer} className={balanceContainerClass}>
          {type === DROPDOWN_LABEL_TYPE_TRANSFER && <FormattedMessage id="transaction.transfer.availableBalance" />}
          <span className={balanceClass}>{balance}</span>
        </Grid>
      ) : (
        <Grid item xs={6} sm={6} container justify={alignBalanceContainer} className="pr3">
          {accountNumber}
        </Grid>
      )}
    </Grid>
  );
};

DynoDropdownLabel.defaultProps = { isSelected: false, type: DROPDOWN_LABEL_TYPE_TRANSFER };

DynoDropdownLabel.propTypes = { option: PropTypes.object.isRequired, isSelected: PropTypes.bool, type: PropTypes.string };

export default DynoDropdownLabel;
