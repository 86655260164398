import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { checkIsToday } from 'utils/date-time';
import { getDebitDate } from 'utils/transfer/getter';
import { validate, validResult } from 'utils/form-validation';

import {
  RECURRING_DISABLED_DATES,
  TRANSFER_FUTURE,
  TRANSFER_NOW,
  TRANSFER_RECURRING,
  TRANSFER_RECURRING_MONTHLY,
} from 'settings/constants/transaction';

import { CalendarPicker, TextLabel } from 'components/common';

const DynoDate = ({
  label,
  recurringLabel,
  value,
  name,
  onChange,
  validationRules,
  isRecurring,
  getValue,
  blockedDays,
  initialDate,
  disabledBeforeDate,
  disabledAfterDate,
  duplicateDateValue,
  hasYearList,
  hasLeftLabel,
  currentMonth,
  hasMonthList,
  hasMonthNavbar,
}) => {
  const { formatMessage } = useIntl();

  const didMountRef = useRef(false);
  const [validationResult, setValidationResult] = useState(validResult);

  const { isValid, messageId, param } = validationResult;

  const isToday = checkIsToday(value);
  const isMonthly = getValue('transferFrequency') === TRANSFER_RECURRING_MONTHLY;
  const isEndDate = name === 'transferEndDate';
  const errorMessage = !isValid ? formatMessage({ id: messageId }, param) : '';

  const disabledDates = isRecurring ? RECURRING_DISABLED_DATES : [];
  const disabledDays = [{ before: disabledBeforeDate, after: disabledAfterDate }].concat(blockedDays);

  const oneTimeLeftLabel = isToday ? 'calendar.today' : 'calendar.later';
  const leftLabel = isRecurring ? recurringLabel : oneTimeLeftLabel;
  const placeHolder = !value ? formatMessage({ id: 'transaction.transfer.selectDate' }) : '';

  const validField = getValue('validField', {});

  const handleChange = date => {
    const event = { target: { name, value: date } };
    const validated = validate(validationRules, event, duplicateDateValue);

    setValidationResult(validated);
    onChange({ validField: { ...validField, [name]: validated.isValid }, [name]: date });

    // this logic only apply to transferStartDate and isRecurring
    if (isRecurring && !isEndDate) {
      const { dayName, dayDate } = getDebitDate(date);
      onChange({ debitDate: isMonthly ? dayDate : formatMessage(dayName) });
    }

    // Only update if this is a startDate
    if (!isEndDate && !checkIsToday(date)) {
      const transferMode = isRecurring ? TRANSFER_RECURRING : TRANSFER_FUTURE;
      onChange({ transferMode, isToday: false });
      return;
    }
    const transferMode = isRecurring ? TRANSFER_RECURRING : TRANSFER_NOW;
    onChange({ transferMode, isToday: true });
  };

  // used to clear the error if the duplicateDateValue change to before the value
  useEffect(() => {
    // to avoid showing inline error when toggled isRecurring
    if (!value) return;
    const event = { target: { name, value } };
    const validated = validate(validationRules, event, duplicateDateValue);

    setValidationResult(validated);
    onChange({ validField: { ...validField, [name]: validated.isValid } });
  }, [duplicateDateValue]);

  // eslint-disable-next-line no-return-assign
  useEffect(() => (didMountRef.current = true), []);

  // reset date to null/today for following mockup
  useEffect(() => {
    // didMountRef are used to avoid useEffect being triggered onMount
    // and avoid transferMode changed based on initialDate when onMount
    // eslint-disable-next-line no-return-assign
    if (didMountRef.current) return (didMountRef.current = false);
    // dont runt this effect if not transferEndDate or transferDate
    if (!['transferEndDate', 'transferDate'].includes(name)) return null;
    if (isRecurring && value) {
      // re-validate on recurring change and update value to null if recurring is true and value is present
      const event = { target: { name, value } };
      const validated = validate(validationRules, event, duplicateDateValue);

      // based on bug https://dev.azure.com/mbid/M2U%20ID%20Web%20Revamp%20-%20Penril/_workitems/edit/2135/
      // we need to re-validate the value after isRecurring is turned on since value on start and end date is null
      setValidationResult(validated);
      return onChange({ [name]: null, validField: { ...validField, [name]: validated.isValid } });
    }

    const oneTimeTransferMode = checkIsToday(initialDate) ? TRANSFER_NOW : TRANSFER_FUTURE;
    const transferMode = isRecurring ? TRANSFER_RECURRING : oneTimeTransferMode;
    return onChange({ [name]: initialDate, transferMode, debitDate: '' });
  }, [isRecurring]);

  useEffect(() => {
    // update when this is startDate and startDate have value after frequency changed
    if (isEndDate || !value) return;
    const { dayName, dayDate } = getDebitDate(value);
    onChange({ debitDate: isMonthly ? dayDate : formatMessage(dayName) });
  }, [isMonthly]);

  return (
    <Grid container alignItems="center" className="pb0 pt3 ph4">
      <Grid item xs={12} sm={4} className="pb2">
        {label && <TextLabel className="ml2 pl1-ns" label={formatMessage({ id: label })} />}
      </Grid>
      <Grid item xs={12} sm={8}>
        <CalendarPicker
          // show year list if its recurring
          hasYearList={isRecurring || hasYearList}
          hasMonthList={hasMonthList}
          // disable field if it is endDate and transferDate is null
          disabled={isEndDate && !duplicateDateValue}
          hasError={!isValid}
          errorMessage={errorMessage}
          leftLabel={hasLeftLabel ? formatMessage({ id: leftLabel }) : ''}
          value={value}
          placeholder={placeHolder}
          onChange={date => handleChange(date)}
          disabledDays={disabledDays}
          disabledDates={disabledDates}
          currentMonth={currentMonth}
          hasMonthNavbar={hasMonthNavbar}
        />
      </Grid>
    </Grid>
  );
};

DynoDate.defaultProps = {
  value: '',
  currentMonth: '',
  hasYearList: false,
  hasLeftLabel: true,
  blockedDays: [],
  validationRules: [],
  recurringLabel: 'transaction.transfer.start',
  duplicateDateValue: '',
  initialDate: '',
  hasMonthList: false,
  hasMonthNavbar: true,
};

DynoDate.propTypes = {
  hasYearList: PropTypes.bool,
  hasMonthList: PropTypes.bool,
  hasLeftLabel: PropTypes.bool,
  hasMonthNavbar: PropTypes.bool,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getValue: PropTypes.func.isRequired,
  blockedDays: PropTypes.array,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  recurringLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validationRules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  initialDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  duplicateDateValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabledAfterDate: PropTypes.object.isRequired,
  disabledBeforeDate: PropTypes.object.isRequired,
};

export default DynoDate;
