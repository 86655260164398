export const login = {
  intro: 'Humanising Banking',
  footerLinks: {
    covid19: 'Covid-19 Assistance',
    branches: 'Operating Branches',
    openAccount: 'Open Account',
    insureMe: 'Insure Me',
    cards: 'Maybank Cards',
    loan: 'Get a Loan',
    returnToClassic: 'Return to Classic',
    whatsNew: `What's New`,
    promotions: 'Promotions',
  },
  resetCredential: 'Reset Credential',
  forgotLogin: 'Having Problem Logging In?',
  accessMobile: 'For Mobile Banking Access',
  greeting: 'Hello',
  securePhrase: {
    label: 'Is your security phrase {phrase}',
  },
  captcha: {
    label: 'Is your security image correct?',
  },
  username: {
    placeholder: 'My User Name',
    errorMessages: {
      invalid: 'Invalid User Name',
      length: 'Input User Name between 6-20 characters',
      specialChar: 'Username cannot include special characters except underscore bar',
    },
  },
  password: {
    placeholder: 'My Password',
    errorMessages: {
      invalid: 'Invalid username/password. Click "Forgot Login" to reset your password',
      length: 'Input Password between 8 and 20 characters',
    },
  },
  feedbackDrawer: {
    usernameUnlocked: 'Your Username has been unlocked!',
    successPasswordChange: 'Your password has been changed!',
    usernameSentToEmail: 'Your username Information has been sent to your registered email!',
  },
  oldCriteriaAlert: {
    title: 'Notification',
    description: "You're using old password criteria. For security purpose we're redirecting you to change your password",
  },
  renewPasswordAlert: {
    title: 'Renew Your Password',
  },
  successRenewPasswordAlert: {
    caption1: 'Awesome!',
    caption2: 'Password renewal successful',
  },
  forceChangePassword: {
    updatePassword: 'Update Password',
    subHeader: 'Your User ID has been enabled and you are required to change your password.',
    currentPassword: 'New Password',
    newPassword: 'Confirm New Password',
    placeholderCurrentPassword: 'Enter New Password',
    placeholderNewPassword: 'Enter Confirm New Password',
    errorMessage: {
      invalidTac: 'Invalid TAC',
      invalidOldPassword: 'You cannot use the same 5 previous password. Please try another password',
      userBlocked:
        'Your User ID has been locked. Please unlock through nearest Maybank Branch or call Maybank Call Center 1500611 / +622178869811 (from overseas).',
      maxTac: 'Maximum TAC error limit reached.',
      invalidLockedUser: 'Your User ID is blocked, Please Unlock via the "Unlock User ID/TAC" menu',
    },
    tac: {
      title: 'Please confirm your new password',
      description: 'To continue, please submit your <b>TAC</b> request.',
      descriptionFinal: 'Your password changed successfully. You can continue logging in using the password you have created.',
    },
  },
  changePhoneNumber: {
    updatePhoneNumber: 'Update Phone Number',
    subHeader:
      'If your phone number has not been registered, please visit the nearest Maybank branch office or contact our call center to register your new phone number',
    successDesc: 'Your phone number has been successfully changed',
    label: {
      prevNumber: 'Previous Mobile Number',
      newNumber: 'New Mobile Number',
    },
    placeholder: {
      prevNumber: 'Previous Mobile Number',
      newNumber: 'New Mobile Number',
    },
    modal: {
      title: 'Announcement',
      description: 'Are you sure you want to proceed with this new phone number?',
    },
    button: {
      next: 'Next',
      yes: 'Yes',
      no: 'No',
      login: 'Login',
    },
    error: {
      sameNumber: 'The old phone number cannot be the same as the new phone number',
      numberNotSame: 'The old phone number does not match',
      errorUpdate: 'Changing your mobile number has not been successfull. Please try again',
    },
  },
};
