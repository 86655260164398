import { actions } from '../actions/login';

const {
  LOGIN_ACCESS_STATUS,
  USER_VALIDATION_RESPONSE,
  USER_ALIAS,
  PASSWORD_VALIDATION_RESPONSE,
  CUSTOMER_INFO,
  CAPTCHA_RESET_CREDENTIAL,
  VERIFY_CAPTCHA_RESET_CREDENTIAL,
  TAC_RESET_CREDENTIAL,
  VERIFY_TAC_RESET_CREDENTIAL,
  UPDATE_PASSWORD_RESET_CREDENTIAL,
  CHECK_USER_STATE_FORCE_CHANGE_PASSWORD,
  GENERATE_TAC_FORCE_CHANGE_PASSWORD,
  VERIFY_TAC_FORCE_CHANGE_PASSWORD,
  CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER,
} = actions.PUT;

const initialState = {
  isSuccess: false,
  loginAccessStatus: 0,
  isUsernameValidate: false,
  userValidationResponse: {},
  userAlias: '',
  customerInfo: {},
  validatePasswordResponse: {},
  captchaResetCredential: {},
  verifyCaptchaResetCredential: {},
  getTacResetCredential: {},
  verifyTacResetCredential: {},
  updatePasswordResetCredential: {},
  checkUserStateForceChangePassword: {},
  generateTacForceChangePassword: {},
  verifyTacForceChangePassword: {},
  checkUserStateForceChangePhoneNumber: {},
  updatePhoneNumber: {},
};

const LoginReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_ACCESS_STATUS:
      return { ...state, isSuccess: action.payload.isSuccess, loginAccessStatus: action.payload.status };
    case USER_VALIDATION_RESPONSE:
      return { ...state, isUsernameValidate: action.payload.isSuccess, userValidationResponse: action.payload.res };
    case USER_ALIAS:
      return { ...state, userAlias: action.payload.userAlias };
    case PASSWORD_VALIDATION_RESPONSE:
      return { ...state, validatePasswordResponse: action.payload };
    case CUSTOMER_INFO:
      return { ...state, customerInfo: action.res };
    case CAPTCHA_RESET_CREDENTIAL:
      return { ...state, captchaResetCredential: action.payload };
    case VERIFY_CAPTCHA_RESET_CREDENTIAL:
      return { ...state, verifyCaptchaResetCredential: action.payload };
    case TAC_RESET_CREDENTIAL:
      return { ...state, getTacResetCredential: action.payload };
    case VERIFY_TAC_RESET_CREDENTIAL:
      return { ...state, verifyTacResetCredential: action.payload };
    case UPDATE_PASSWORD_RESET_CREDENTIAL:
      return { ...state, updatePasswordResetCredential: action.payload };
    case CHECK_USER_STATE_FORCE_CHANGE_PASSWORD:
      return { ...state, checkUserStateForceChangePassword: action.payload };
    case GENERATE_TAC_FORCE_CHANGE_PASSWORD:
      return { ...state, generateTacForceChangePassword: action.payload };
    case VERIFY_TAC_FORCE_CHANGE_PASSWORD:
      return { ...state, verifyTacForceChangePassword: action.payload };
    case CHECK_USER_STATE_FORCE_CHANGE_PHONE_NUMBER:
      return { ...state, checkUserStateForceChangePhoneNumber: action.payload };
    case UPDATE_PHONE_NUMBER:
      return { ...state, updatePhoneNumber: action.payload };
    default:
      return state;
  }
};

export default LoginReducer;
