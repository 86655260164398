export const logout = {
  header: {
    personal: 'Pribadi',
    business: 'Bisnis',
    login: 'MASUK',
    table: {
      date: 'Tanggal',
      activity: 'Detil Aktivitas',
      amount: 'Jumlah',
      status: 'Status & Referensi',
      print: 'Cetak',
    },
  },
  message: {
    logoutSuccess: 'Anda telah berhasil keluar. {br} Terima kasih telah menggunakan M2U ID Web',
    logoutTransaction: 'Terima kasih untuk menggunakan maybank2u untuk transaksi perbankan Anda',
    multiSession:
      'Anda telah berhasil keluar. {br} {br} Saat ini Anda sedang melakukan sesi login secara bersamaan di perangkat lain. {br} Untuk keamanan dan kenyamanan transaksi Anda, sesi ini akan kami akhiri.',
    activity: 'Anda tidak memiliki aktivitas apa pun untuk hari ini.',
    summaryMessage: 'Berikut ringkasan transaksi Anda: ',
  },
};

export const sessionExpired = {
  header: {
    personal: 'Pribadi',
    business: 'Bisnis',
  },
  message: {
    expired:
      'Sesi Anda telah berakhir! Browser Anda tidak melakukan aktivitas selama lebih dari 5 menit. {br} Silakan login kembali ke M2U ID Web.',
  },
};
