export const actions = {
  GET: {
    SELF_UNLOCK_ACCESS: 'SELF_UNLOCK_GET_ACCESS',
    VERIFY_SELF_UNLOCK_DETAILS: 'GET_VERIFY_SELF_UNLOCK_DETAILS',
    REQUEST_USERNAME_SEND_TO_EMAIL: 'GET_REQUEST_USERNAME_SEND_TO_EMAIL',
  },
  POST: {
    USERNAME_TO_EMAIL: 'POST_USERNAME_TO_EMAIL',
    RESET_PASSWORD_TAC_REQUEST: 'RESET_PASSWORD_TAC_REQUEST',
    RESET_PASSWORD_TAC_SUBMIT: 'RESET_PASSWORD_TAC_SUBMIT',
    UNLOCK_USER: 'POST_UNLOCK_USER',
  },
  PUT: {
    SELF_UNLOCK_ACCESS: 'SELF_UNLOCK_PUT_ACCESS',
    VERIFY_SELF_UNLOCK_DETAILS: 'PUT_VERIFY_SELF_UNLOCK_DETAILS',
    RESET_SELF_UNLOCK_STATE: 'RESET_SELF_UNLOCK_STATE',
    EMAIL_STATUS: 'PUT_EMAIL_STATUS',
    RESET_PASSWORD_TAC_REQUEST_RESPONSE: 'RESET_PASSWORD_TAC_RESPONSE',
    RESET_PASSWORD_TAC_SUBMIT_RESPONSE: 'RESET_PASSWORD_TAC_SUBMIT_RESPONSE',
    REQUEST_USERNAME_SEND_TO_EMAIL_RESPONSE: 'REQUEST_USERNAME_SEND_TO_EMAIL_RESPONSE',
    UNLOCK_USER_RESPONSE: 'PUT_USER_UNLOCK_RESPONSE',
  },
};

// Self unlock
export const getSelfUnlockAccess = () => ({ type: actions.GET.SELF_UNLOCK_ACCESS });
export const getVerifySelfUnlockDetails = payload => ({ type: actions.GET.VERIFY_SELF_UNLOCK_DETAILS, payload });

export const putSelfUnlockAccess = payload => ({ type: actions.PUT.SELF_UNLOCK_ACCESS, payload });
export const putVerifiedSelfUnlockDetails = (payload, isSuccess) => ({ type: actions.PUT.VERIFY_SELF_UNLOCK_DETAILS, payload, isSuccess });

export const resetSelfUnlockState = () => ({ type: actions.PUT.RESET_SELF_UNLOCK_STATE });

// Reset password
export const putResetPasswordTacSubmitResponse = res => ({ type: actions.PUT.RESET_PASSWORD_TAC_SUBMIT_RESPONSE, payload: res });
export const putResetPasswordTacRequestResponse = res => ({ type: actions.PUT.RESET_PASSWORD_TAC_REQUEST_RESPONSE, payload: res });

export const postResetPasswordTacSubmit = body => ({ type: actions.POST.RESET_PASSWORD_TAC_SUBMIT, payload: body });
export const postResetPasswordTacRequest = body => ({ type: actions.POST.RESET_PASSWORD_TAC_REQUEST, payload: body });

// Unlock user
export const putUnlockUserResponse = res => ({ type: actions.PUT.UNLOCK_USER_RESPONSE, payload: res });

export const postUnlockUser = body => ({ type: actions.POST.UNLOCK_USER, payload: body });

// Email
export const putEmailStatus = (payload, isSuccess) => ({ type: actions.PUT.REQUEST_USERNAME_SEND_TO_EMAIL_RESPONSE, payload, isSuccess });

export const postUsernameToEmail = payload => ({ type: actions.GET.REQUEST_USERNAME_SEND_TO_EMAIL, payload });
