import isNull from 'lodash/isNull';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putSknTransferTac,
  putSknTransferResult,
  putSknTransferDetail,
  putSknTransferReceipt,
  putSknTransferConfirmation,
} from 'middleware/actions/transfer';
import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { formatSknRtgsDetail } from 'utils/transfer/sknRtgs/formatter';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';
import { transferEndpoint as endpoint } from 'providers/endpoints/transfer';

const { TRANSFER_CONFIRMATION, TRANSFER_RESULT, TRANSFER_DETAIL, RECEIPT, REQUEST_TAC, TRANSFER_FROM_FAVOURITE_DETAIL } = actions.GET.SKN;

const getClientIP = () => remoteIpInstance.get();

const getResult = ({ body }) => Instance.post(endpoint.skn.getResult, body);
const requestTAC = ({ body }) => Instance.post(endpoint.skn.requestTac, body);
const getReceipt = ({ body }) => Instance.post(endpoint.skn.getReceipt, body);
const getDetail = ({ body }) => Instance.post(endpoint.skn.getSknDetails, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.skn.getConfirmation, body);
const getFavouriteDetail = ({ body }) => Instance.post(endpoint.skn.favourite.getTransferDetail, body);

const getLocale = state => state.LanguageReducer.locale;

function* getDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getDetail, { body });

    const res = formatSknRtgsDetail(data);
    yield put(putSknTransferDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getFavouriteDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getFavouriteDetail, { body });

    const res = formatSknRtgsDetail(data);
    yield put(putSknTransferDetail(res));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getConfirmation, { body });

    yield put(putSknTransferConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(requestTAC, { body });

    yield put(putSknTransferTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(getResult, { body });

    yield put(putSknTransferResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };

    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    const responseCode = get(data, 'responseCode', '');

    yield put(putSknTransferReceipt({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* TransferSKN() {
  yield all([
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(TRANSFER_DETAIL, getDetailSaga),
    takeLatest(TRANSFER_RESULT, getResultSaga),
    takeLatest(TRANSFER_CONFIRMATION, getConfirmationSaga),
    takeLatest(TRANSFER_FROM_FAVOURITE_DETAIL, getFavouriteDetailSaga),
  ]);
}
