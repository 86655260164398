import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';

import { get } from 'utils/lodash';
import { formatPortfolioResponse } from 'utils/digital-wealth/formatter';
import {
  actions,
  putPortfolio,
  putBancas,
  putBonds,
  putMutualFunds,
  putBondDetail,
  putMutualFundDetail,
  putWealthDashboard,
  putBancaDetail,
} from 'middleware/actions/digital-wealth';

import { putErrorResponse } from 'middleware/actions/error';
import { digitalWealthEndpoint as endpoint } from 'providers/endpoints/digital-wealth';

import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const { OVERVIEW, DASHBOARD, BANCAS, BONDS, MUTUAL_FUNDS, BANCA_DETAIL, BOND_DETAIL, MUTUAL_FUND_DETAIL } = actions.GET.PORTFOLIO;

const getPortfolio = ({ body }) => Instance.post(endpoint.get.portfolio, body);
const getBonds = ({ body }) => Instance.post(endpoint.get.bonds, body);
const getBancas = ({ body }) => Instance.post(endpoint.get.bancas, body);
const getMutualFunds = ({ body }) => Instance.post(endpoint.get.mutualFunds, body);
const getBondDetail = ({ body }) => Instance.post(endpoint.get.bondDetail, body);
const getBancaDetail = ({ body }) => Instance.post(endpoint.get.bancaDetail, body);
const getMutualFundDetail = ({ body }) => Instance.post(endpoint.get.mutualFundDetail, body);
const getWealthDashboard = ({ body }) => Instance.post(endpoint.get.dashboard, body);

const getLocale = state => state.LanguageReducer.locale;

function* getPortfolioSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getPortfolio, { body });

    yield put(putPortfolio(formatPortfolioResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBondsSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBonds, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putBonds({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBancasSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBancas, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putBancas({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMutualFundsSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getMutualFunds, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putMutualFunds({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBondDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBondDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putBondDetail({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBancaDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBancaDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putBancaDetail({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getMutualFundDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getMutualFundDetail, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putMutualFundDetail({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getWealthDashboardSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getWealthDashboard, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putWealthDashboard({ ...data, isSuccess: responseCode === SUCCESS_RESPONSE_CODE }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* DigitalWealthPortfolio() {
  yield all([
    takeLatest(BONDS, getBondsSaga),
    takeLatest(BANCAS, getBancasSaga),
    takeLatest(MUTUAL_FUNDS, getMutualFundsSaga),
    takeLatest(BOND_DETAIL, getBondDetailSaga),
    takeLatest(BANCA_DETAIL, getBancaDetailSaga),
    takeLatest(OVERVIEW, getPortfolioSaga),
    takeLatest(DASHBOARD, getWealthDashboardSaga),
    takeLatest(MUTUAL_FUND_DETAIL, getMutualFundDetailSaga),
  ]);
}
