export const SERVICES = formatMessage => [
  {
    key: '2400',
    label: formatMessage({ id: 'lldDescription.services.2400' }),
  },
  {
    key: '2405',
    label: formatMessage({ id: 'lldDescription.services.2405' }),
  },
  {
    key: '2411',
    label: formatMessage({ id: 'lldDescription.services.2411' }),
  },
  {
    key: '2412',
    label: formatMessage({ id: 'lldDescription.services.2412' }),
  },
  {
    key: '2413',
    label: formatMessage({ id: 'lldDescription.services.2413' }),
  },
  {
    key: '2421',
    label: formatMessage({ id: 'lldDescription.services.2421' }),
  },
  {
    key: '2422',
    label: formatMessage({ id: 'lldDescription.services.2422' }),
  },
  {
    key: '2423',
    label: formatMessage({ id: 'lldDescription.services.2423' }),
  },
  {
    key: '2431',
    label: formatMessage({ id: 'lldDescription.services.2431' }),
  },
  {
    key: '2432',
    label: formatMessage({ id: 'lldDescription.services.2432' }),
  },
  {
    key: '2433',
    label: formatMessage({ id: 'lldDescription.services.2433' }),
  },
  {
    key: '2441',
    label: formatMessage({ id: 'lldDescription.services.2441' }),
  },
  {
    key: '2442',
    label: formatMessage({ id: 'lldDescription.services.2442' }),
  },
  {
    key: '2443',
    label: formatMessage({ id: 'lldDescription.services.2443' }),
  },
  {
    key: '2450',
    label: formatMessage({ id: 'lldDescription.services.2450' }),
  },
  {
    key: '2461',
    label: formatMessage({ id: 'lldDescription.services.2461' }),
  },
  {
    key: '2462',
    label: formatMessage({ id: 'lldDescription.services.2462' }),
  },
  {
    key: '2466',
    label: formatMessage({ id: 'lldDescription.services.2466' }),
  },
  {
    key: '2467',
    label: formatMessage({ id: 'lldDescription.services.2467' }),
  },
  {
    key: '2468',
    label: formatMessage({ id: 'lldDescription.services.2468' }),
  },
  {
    key: '2469',
    label: formatMessage({ id: 'lldDescription.services.2469' }),
  },
  {
    key: '2480',
    label: formatMessage({ id: 'lldDescription.services.2480' }),
  },
  {
    key: '2490',
    label: formatMessage({ id: 'lldDescription.services.2490' }),
  },
  {
    key: '2495',
    label: formatMessage({ id: 'lldDescription.services.2495' }),
  },
  {
    key: '2501',
    label: formatMessage({ id: 'lldDescription.services.2501' }),
  },
  {
    key: '2502',
    label: formatMessage({ id: 'lldDescription.services.2502' }),
  },
  {
    key: '2511',
    label: formatMessage({ id: 'lldDescription.services.2511' }),
  },
  {
    key: '2512',
    label: formatMessage({ id: 'lldDescription.services.2512' }),
  },
  {
    key: '2521',
    label: formatMessage({ id: 'lldDescription.services.2521' }),
  },
  {
    key: '2522',
    label: formatMessage({ id: 'lldDescription.services.2522' }),
  },
  {
    key: '2523',
    label: formatMessage({ id: 'lldDescription.services.2523' }),
  },
  {
    key: '2524',
    label: formatMessage({ id: 'lldDescription.services.2524' }),
  },
  {
    key: '2525',
    label: formatMessage({ id: 'lldDescription.services.2525' }),
  },
  {
    key: '2526',
    label: formatMessage({ id: 'lldDescription.services.2526' }),
  },
  {
    key: '2531',
    label: formatMessage({ id: 'lldDescription.services.2531' }),
  },
  {
    key: '2532',
    label: formatMessage({ id: 'lldDescription.services.2532' }),
  },
  {
    key: '2533',
    label: formatMessage({ id: 'lldDescription.services.2533' }),
  },
  {
    key: '2541',
    label: formatMessage({ id: 'lldDescription.services.2541' }),
  },
  {
    key: '2546',
    label: formatMessage({ id: 'lldDescription.services.2546' }),
  },
  {
    key: '2547',
    label: formatMessage({ id: 'lldDescription.services.2547' }),
  },
  {
    key: '2560',
    label: formatMessage({ id: 'lldDescription.services.2560' }),
  },
  {
    key: '2570',
    label: formatMessage({ id: 'lldDescription.services.2570' }),
  },
  {
    key: '2580',
    label: formatMessage({ id: 'lldDescription.services.2580' }),
  },
  {
    key: '2590',
    label: formatMessage({ id: 'lldDescription.services.2590' }),
  },
  {
    key: '2600',
    label: formatMessage({ id: 'lldDescription.services.2600' }),
  },
  {
    key: '2610',
    label: formatMessage({ id: 'lldDescription.services.2610' }),
  },
  {
    key: '2615',
    label: formatMessage({ id: 'lldDescription.services.2615' }),
  },
  {
    key: '2616',
    label: formatMessage({ id: 'lldDescription.services.2616' }),
  },
  {
    key: '2630',
    label: formatMessage({ id: 'lldDescription.services.2630' }),
  },
  {
    key: '2640',
    label: formatMessage({ id: 'lldDescription.services.2640' }),
  },
  {
    key: '2651',
    label: formatMessage({ id: 'lldDescription.services.2651' }),
  },
  {
    key: '2652',
    label: formatMessage({ id: 'lldDescription.services.2652' }),
  },
  {
    key: '2660',
    label: formatMessage({ id: 'lldDescription.services.2660' }),
  },
  {
    key: '2129',
    label: formatMessage({ id: 'lldDescription.services.2129' }),
  },
];
