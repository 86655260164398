export const actions = {
  GET: {
    COACHMARK: 'GET_COACHMARK',
  },
  PUT: {
    PUT_COACHMARK: 'PUT_COACHMARK',
    PUT_COACHMARK_UPDATE_RESPONSE: 'PUT_COACHMARK_UPDATE_RESPONSE',
  },
  POST: {
    POST_COACHMARK: 'POST_COACHMARK',
  },
};
// All
export const getCoachmark = body => ({ type: actions.GET.COACHMARK, payload: body });

export const putCoachmarkResponse = res => ({ type: actions.PUT.PUT_COACHMARK, payload: res });
export const putCoachmarkUpdateResponse = res => ({ type: actions.PUT.PUT_COACHMARK_UPDATE_RESPONSE, payload: res });

export const postCoachmark = body => ({ type: actions.POST.POST_COACHMARK, payload: body });
