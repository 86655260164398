import { useIdleTimer } from 'react-idle-timer';
import { IDLE_TIME } from 'settings/constants/common';

export const IdleTimer = onIdle => {
  const events = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ];

  return useIdleTimer({
    timeout: IDLE_TIME,
    startOnMount: true,
    crossTab: false,
    events,
    onIdle,
  });
};
