import { actions } from '../actions/coachmark';

const { COACHMARK } = actions.GET;
const { PUT_COACHMARK, PUT_COACHMARK_UPDATE_RESPONSE } = actions.PUT;
const { POST_COACHMARK } = actions.POST;

const initialState = {
  isSuccess: false,
  isPostSuccess: '',
  coachmarkChecked: '',
  coachmarkSubmitted: '',
};

const CoachmarkReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case COACHMARK:
      return { ...state };

    case PUT_COACHMARK:
      return {
        ...state,
        isSuccess: true,
        coachmarkChecked: action.payload.tooltipChecked,
        coachmarkSubmitted: action.payload.submitPressed,
      };

    case POST_COACHMARK:
      return { ...state };

    case PUT_COACHMARK_UPDATE_RESPONSE:
      return {
        ...state,
        isPostSuccess: action.payload.description,
      };

    default:
      return state;
  }
};

export default CoachmarkReducer;
