import { FormattedMessage } from 'react-intl';

import { ALIGN_CENTER, ALIGN_RIGHT } from 'settings/constants/ui-control';
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_SELECT_ACCOUNT,
  FIELD_TYPE_ACCORDION,
  FORMAT_ALPHANUMERIC,
  FORMAT_NUMBER,
} from './transaction';

export const CASA_DEFAULT_HISTORY_VALUE = 'today';

export const INITIAL_DASHBAORD_TAB = 'casa';
export const BALANCE_TYPE_WEALTH = 'wealth';

// for exchange rate disclaimer
export const BASE_CURRENCY_DISPLAY = '1';

// To parse debitCreditIndicator from API
export const DEFAULT_DEBIT_CREDIT_INDICATOR = 1;

export const DEFAULT_CREDIT_CARD_HISTORY_VALUE = 'currentStatement';

export const WEALTH_CASA = '100';
export const WEALTH_TIME_DEPOSIT = '200';
export const WEALTH_MUTUAL_FUND = '300';
export const WEALTH_BANCA_FUND = '400';
export const WEALTH_BOND_FUND = '500';
export const WEALTH_MORTGAGE_AND_LOAN = '600';
export const WEALTH_CREDIT_CARD = '700';

export const WEALTH_HISTORY_30_DAY = '30';
export const WEALTH_HISTORY_60_DAY = '60';
export const WEALTH_HISTORY_90_DAY = '90';

export const WEALTH_COLOR_INDICATOR_RED = 'R';
export const WEALTH_COLOR_INDICATOR_BLACK = 'B';

export const CREDIT_CARD_MIN_AMOUNT = '2';
export const CREDIT_CARD_OTHER_AMOUNT = '3';
export const CREDIT_CARD_BILLING_AMOUNT = '1';

export const FINANCIAL_USER = 1;
export const NON_FINANCIAL_USER = 2;

export const CASA_DETAILS = 1;
export const CASA_PAYMENT = 2;
export const CASA_TRANSFER = 3;
export const CASA_PURCHASE = 4;

export const DASHBOARD_CASA = 'CASA';
export const DASHBOARD_TIME_DEPOSIT = 'TD';
export const DASHBOARD_CREDIT_CARD = 'CC';
export const DASHBOARD_LOAN = 'LOAN';
export const DASHBOARD_WEALTH = 'WEALTH';

export const BASE_DECIMAL = 10;
export const CURRENCY_CODE_IDR = '016';
export const TD_MAX_LEN_DISPLAYED_BALANCE = 6;
export const TD_MAX_LEN_REFERRAL_CODE = 5;
export const TD_MIN_LEN_REFERRAL_CODE = 0;
export const TD_MAX_LEN_PROMO_CODE = 2;
export const TD_MIN_LEN_PROMO_CODE = 0;
export const TD_MIN_AMOUNT_IDR = '10000000';
export const TD_MIN_AMOUNT_USD = '2000';
export const TD_PRODUCT_TYPE_DEPOSIT = 248;
export const TD_PRODUCT_TYPE_DEPOSIT_IB = 249;
export const TD_COMPARE_ZAKAT_VALUE = 'YES';
export const TD_DDL_ACCOUNT_BALANCE_LENGTH = 15;
export const TD_DDL_ACCOUNT_LABEL_LENGTH = 25;
export const TD_DDL_ACCOUNT_LABEL_MAX_LENGTH = 30;
export const TD_DEFAULT_XSMALL_WIDTH = 768;
export const TD_DEFAULT_SMALL_WIDTH = 1024;
export const TD_DEFAULT_XLARGE_WIDTH = 1432;
export const TD_DEFAULT_LINK_VALUE = '#';
export const TD_DEFAULT_AMOUNT_VALUE = '0';
export const TD_DEFAULT_TERM_CODE = '611';
export const TD_SMALL_SCREEN_WIDTH = 820;
export const TD_MEDIUM_SCREEN_WIDTH = 1024;
export const TD_XSMALL_SCREEN_HEIGHT = 695;
export const TD_SMALL_SCREEN_HEIGHT = 800;
export const TD_DDL_ACCOUNT_DEFAULT_VAL = '-';
export const TD_DDL_ACCOUNT_BALANCE_END = '...';
export const TD_CHECKBOX_CLASS_CLASSIC = 'chkClassic';
export const TD_CHECKBOX_CLASS_IB = 'chkIB';

// screen variable on Time Deposit
export const TD_SCREEN_PRODUCTS = 'products';
export const TD_SCREEN_FEATURES = 'features';
export const TD_SCREEN_ACCOUNT_AND_PRODUCT_DETAILS = 'accountAndProductDetail';
export const TD_SCREEN_TAC = 'tac';

export const DEFAULT_DASHBOARD_BALANCE = [{ type: 'TD' }, { type: 'CC' }, { type: 'CASA' }, { type: 'LOAN' }, { type: 'WEALTH' }];

export const INITIAL_ACCOUNT_BALANCES = [
  {
    value: 'casa',
    label: <FormattedMessage id="dashboard.casa.label" />,
    link: '/dashboard/casa',
  },
  {
    value: 'td',
    label: <FormattedMessage id="dashboard.td.label" />,
    link: '/dashboard/td',
  },
  {
    value: 'cc',
    label: <FormattedMessage id="dashboard.cc.label" />,
    link: '/dashboard/cc',
  },
  {
    value: 'loan',
    label: <FormattedMessage id="dashboard.loan.label" />,
    link: '/dashboard/loan',
  },
  {
    value: 'wealth',
    label: <FormattedMessage id="dashboard.wealth.label" />,
    link: '/dashboard/wealth',
  },
];

export const CASA_TRANSACTION_COLUMNS = [
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'description', headerName: 'Description' },
  { field: 'amount', headerName: 'Amount' },
];

export const TIME_DEPOSIT_INSTRUCTION = {
  NON_APPLICABLE: -1,
  PRINCIPAL_ROLLOVER: 0,
  PRINCIPAL_INTEREST_ROLLOVER: 1,
  TERMINATED: 2,
};

export const TIME_DEPOSIT_INTEREST_RATE_TABLE_HEADER = [
  { field: 'placement', headerName: <FormattedMessage id="dashboard.td.features.interestRateTableHeaders.placement" /> },
  { field: 'value1', headerName: <FormattedMessage id="dashboard.td.features.interestRateTableHeaders.value1" /> },
  { field: 'value2', headerName: <FormattedMessage id="dashboard.td.features.interestRateTableHeaders.value2" /> },
  { field: 'value3', headerName: <FormattedMessage id="dashboard.td.features.interestRateTableHeaders.value3" /> },
  { field: 'value4', headerName: <FormattedMessage id="dashboard.td.features.interestRateTableHeaders.value4" /> },
];

export const LOAN_PAYMENT_HEADER_ITEMS = [
  { field: 'maturityDate', headerName: <FormattedMessage id="dashboard.loan.details.maturity" /> },
  { field: 'currentPrincipalAmount', headerName: <FormattedMessage id="dashboard.loan.details.totalBilled" /> },
  { field: 'loanOutstanding', headerName: <FormattedMessage id="dashboard.loan.details.outstanding" /> },
  { field: 'loanPayOffAmountAsToday', headerName: <FormattedMessage id="dashboard.loan.details.payoffAmount" /> },
  { field: 'loanTenor', headerName: <FormattedMessage id="dashboard.loan.details.tenor" /> },
];

export const MUTUAL_FUND_SUMMARY_TABLE_HEADER_ITEMS = [
  { field: 'fundName', headerName: <FormattedMessage id="dashboard.wealth.details.fundName" /> },
  { field: 'fundManager', headerName: <FormattedMessage id="dashboard.wealth.details.fundManager" /> },
  { field: 'totalUnit', headerName: <FormattedMessage id="dashboard.wealth.details.totalUnit" /> },
  { field: 'navDisp', headerName: <FormattedMessage id="dashboard.wealth.details.nav" /> },
  { field: 'growthFund', headerName: <FormattedMessage id="dashboard.wealth.details.growthFund" /> },
  { field: 'balanceDisp', headerName: <FormattedMessage id="dashboard.wealth.details.balance" /> },
];

export const BOND_TABLE_SUMMARY_HEADER_ITEMS = [
  { field: 'productName', headerName: <FormattedMessage id="dashboard.wealth.details.productName" /> },
  { field: 'coupon', headerName: <FormattedMessage id="dashboard.wealth.details.coupon" /> },
  { field: 'maturityDateDisp', headerName: <FormattedMessage id="dashboard.wealth.details.maturityDate" /> },
  { field: 'tenor', headerName: <FormattedMessage id="dashboard.wealth.details.tenor" /> },
  { field: 'balanceDisp', headerName: <FormattedMessage id="dashboard.wealth.details.balance" /> },
  { field: 'action', headerName: '' },
];

export const BANCA_TABLE_SUMMARY_HEADER_ITEMS = [
  { field: 'productName', headerName: <FormattedMessage id="dashboard.wealth.details.productName" /> },
  { field: 'policyNo', headerName: <FormattedMessage id="dashboard.wealth.details.policyNumber" /> },
  { field: 'policyHolderName', headerName: <FormattedMessage id="dashboard.wealth.details.policyHolderName" /> },
  { field: 'fundName', headerName: <FormattedMessage id="dashboard.wealth.details.fundName" /> },
  { field: 'balanceUnitDisp', headerName: <FormattedMessage id="dashboard.wealth.details.unit" /> },
  { field: 'balanceDisp', headerName: <FormattedMessage id="dashboard.wealth.details.balance" /> },
];

export const MUTUAL_FUND_HISTORY_TABLE_HEADER_ITEMS = [
  { field: 'dateDisp', headerName: <FormattedMessage id="dashboard.wealth.history.date" /> },
  { field: 'description', headerName: <FormattedMessage id="dashboard.wealth.history.description" /> },
  { field: 'amountDisp', headerName: <FormattedMessage id="dashboard.wealth.history.amount" /> },
  { field: 'navDisp', headerName: <FormattedMessage id="dashboard.wealth.history.nav" /> },
  { field: 'unit', headerName: <FormattedMessage id="dashboard.wealth.history.unit" /> },
];

export const BOND_TABLE_HISTORY_HEADER_ITEMS = [
  { field: 'dateDisp', headerName: <FormattedMessage id="dashboard.wealth.history.date" /> },
  { field: 'txnType', headerName: <FormattedMessage id="dashboard.wealth.history.transactionType" /> },
  { field: 'faceValueDisp', headerName: <FormattedMessage id="dashboard.wealth.history.faceValue" /> },
  { field: 'price', headerName: <FormattedMessage id="dashboard.wealth.history.price" /> },
  { field: 'amountDisp', headerName: <FormattedMessage id="dashboard.wealth.history.amount" /> },
];

export const CASA_TRANSACTION_HEADER_ITEMS = [
  { field: 'transactionDate', headerName: <FormattedMessage id="dashboard.casa.details.date" /> },
  { field: 'transactionDescription', headerName: <FormattedMessage id="dashboard.casa.details.description" /> },
  { field: 'debitCreditAmount', headerName: <FormattedMessage id="dashboard.casa.details.amount" />, align: ALIGN_RIGHT },
];

export const CASA_TRANSACTION_M2U_HEADER_ITEMS = [
  { field: 'transactionDate', headerName: <FormattedMessage id="dashboard.casa.details.date" /> },
  { field: 'description', headerName: <FormattedMessage id="dashboard.casa.details.description" /> },
  { field: 'amount', headerName: <FormattedMessage id="dashboard.casa.details.amount" />, align: ALIGN_RIGHT },
  // { field: 'download', headerName: <FormattedMessage id="dashboard.casa.details.download" />, align: ALIGN_CENTER },
];

export const CREDIT_CARD_TABLE_HEADER_ITEMS = [
  { field: 'transactionDate', headerName: <FormattedMessage id="dashboard.cc.details.date" /> },
  { field: 'transactionDescription', headerName: <FormattedMessage id="dashboard.cc.details.description" /> },
  { field: 'amount', headerName: <FormattedMessage id="dashboard.cc.details.amount" />, align: ALIGN_RIGHT },
];

export const WEALTH_HISTORY_DAY_ITEMS = [
  { label: <FormattedMessage id="dashboard.wealth.history.day30" />, value: WEALTH_HISTORY_30_DAY },
  { label: <FormattedMessage id="dashboard.wealth.history.day60" />, value: WEALTH_HISTORY_60_DAY },
  { label: <FormattedMessage id="dashboard.wealth.history.day90" />, value: WEALTH_HISTORY_90_DAY },
];

export const CASA_TITLE_PRINT_TRANSACTION = 'All Transaction - Maybank2u';
export const CASA_TRANSACTION_TYPE_ALL = 'all';
export const CASA_TRANSACTION_TYPE_M2U = 'm2u';
export const CASA_TRANSACTION_PRINTABLE = '0';

export const MULTICURRENCY_PRODUCT_TYPE = '999';
export const MULTICURRENCY_SAMPLE_DATA = [
  {
    currency: 'IDR',
    equivalentAmountDisplay: 'IDR 50,000,000.00',
  },
  {
    currency: 'USD',
    equivalentAmountDisplay: 'USD 0.00',
    exchangeRate: 'USD 1 = IDR 15,000.00',
  },
];

export const TIME_DEPOSIT_ACCOUNT_FORM_INPUT = {
  isRecurring: false,
  fromAccount: '',
  validField: {
    fromAccount: true,
  },
};

export const TIME_DEPOSIT_PRODUCT_FORM_INPUT = {
  isRecurring: false,
  term: '',
  currency: '',
  amountDeposit: '',
  instructionAtMaturity: '',
  akad: 'Mudharabah',
  zakat: '',
  validField: {
    term: true,
    currency: true,
    amountDeposit: true,
    instructionAtMaturity: true,
    akad: true,
    zakat: true,
  },
};

export const TIME_DEPOSIT_REFERRAL_FORM_INPUT = {
  isRecurring: false,
  promoCodeValue: '',
  referralCodeValue: '',
  validField: {},
};

export const TD_AKAD_OPTIONS = [
  {
    value: 'Mudharabah',
    label: <FormattedMessage id="dashboard.td.features.form.akadValue1" />,
  },
];

export const TD_ZAKAT_OPTIONS = [
  {
    value: 'YES',
    label: <FormattedMessage id="buttonText.yes" />,
  },
  {
    value: 'NO',
    label: <FormattedMessage id="buttonText.no" />,
  },
];

export const TIME_DEPOSIT_ACCOUNT_DETAIL_FORM_FIELD = [
  {
    name: 'fromAccount',
    label: 'dashboard.td.features.form.sof',
    placeholder: 'dashboard.td.placeholder.pleaseSelect',
    type: FIELD_TYPE_SELECT_ACCOUNT,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
];

export const TIME_DEPOSIT_PRODUCT_DETAIL_FORM_FIELD = [
  {
    name: 'term',
    label: 'dashboard.td.features.form.term',
    type: FIELD_TYPE_SELECT,
    validationRules: [{ type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' }],
  },
  {
    name: 'amountDeposit',
    label: 'dashboard.td.features.form.amount',
    thousandSeparator: true,
    type: FIELD_TYPE_NUMBER,
    hasOption: true,
    optionField: {
      name: 'currency',
      isTextField: true,
      readOnly: true,
      options: [],
      validationRules: [],
    },
    validationRules: [],
  },
  {
    name: 'instructionAtMaturity',
    label: 'dashboard.td.features.form.instructionAtMaturity',
    type: FIELD_TYPE_SELECT,
    validationRules: [{ type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' }],
  },
];

export const TIME_DEPOSIT_IB_PRODUCT_DETAIL_FORM_FIELD = [
  {
    name: 'term',
    label: 'dashboard.td.features.form.term',
    type: FIELD_TYPE_SELECT,
    validationRules: [{ type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' }],
  },
  {
    name: 'amountDeposit',
    label: 'dashboard.td.features.form.amount',
    thousandSeparator: true,
    type: FIELD_TYPE_NUMBER,
    hasOption: true,
    optionField: {
      name: 'currency',
      isTextField: true,
      readOnly: true,
      options: [],
      validationRules: [],
    },
    validationRules: [],
  },
  {
    name: 'instructionAtMaturity',
    label: 'dashboard.td.features.form.instructionAtMaturity',
    type: FIELD_TYPE_SELECT,
    validationRules: [{ type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' }],
  },
  {
    name: 'akad',
    label: 'dashboard.td.features.form.akad',
    readOnly: true,
    type: FIELD_TYPE_TEXT,
    options: TD_AKAD_OPTIONS,
  },
  {
    name: 'zakat',
    label: 'dashboard.td.features.form.zakat',
    type: FIELD_TYPE_SELECT,
    options: TD_ZAKAT_OPTIONS,
    validationRules: [{ type: 'required', isValidateRequired: true, message: 'dashboard.td.errorMessage.required' }],
  },
];

export const TIME_DEPOSIT_REFERRAL_PROMO_CODE = [
  {
    name: 'promoCodeValue',
    label: 'dashboard.td.features.form.promoCode',
    type: FIELD_TYPE_TEXT,
    readOnly: false,
    isEditable: true,
    format: FORMAT_ALPHANUMERIC,
    maxLength: TD_MAX_LEN_PROMO_CODE,
    validationRules: [
      {
        type: 'validPromoCode',
        isValidateRequired: true,
        maxLength: TD_MAX_LEN_PROMO_CODE,
        minLength: TD_MIN_LEN_PROMO_CODE,
      },
    ],
  },
  {
    name: 'referralCodeValue',
    label: 'dashboard.td.features.form.referralCode',
    type: FIELD_TYPE_TEXT,
    readOnly: false,
    isEditable: true,
    format: FORMAT_NUMBER,
    maxLength: TD_MAX_LEN_REFERRAL_CODE,
    validationRules: [
      {
        type: 'validReferralCode',
        isValidateRequired: true,
        maxLength: TD_MAX_LEN_REFERRAL_CODE,
        minLength: TD_MIN_LEN_PROMO_CODE,
      },
    ],
  },
];
