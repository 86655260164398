import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatPaymentAccess } from 'utils/payment/formatter';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';
import { formatCcPaymentModes, formatOwnMaybankCards } from 'utils/payment/credit-card/formatter';
import {
  ccPutTacHandler,
  ccCallTacHandler,
  ccPutAccessHandler,
  ccPutResultHandler,
  ccCallAccessHandler,
  ccCallResultHandler,
  ccPutReceiptHandler,
  ccCallReceiptHandler,
  ccPutConfirmationHandler,
  ccCallConfirmationHandler,
} from 'utils/payment/credit-card/sagaHandler';
import {
  actions,
  putMaybankCardTac,
  putOtherBankCardTac,
  putMaybankCardResult,
  putMaybankCardReceipt,
  putCreditCardOwnCards,
  putOtherBankCardResult,
  putOtherBankCardReceipt,
  putCreditCardPaymentModes,
  putMaybankCardConfirmation,
  putOtherBankCardConfirmation,
} from 'middleware/actions/payment';
import { actions as favouriteActions } from 'middleware/actions/favourite/payment';

import { UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const getClientIP = () => remoteIpInstance.get();

const getOwnMaybankCards = ({ body }) => Instance.post(endpoint.creditCard.getOwnMaybankCards, body);
const getOwnCardPaymentMode = ({ body }) => Instance.post(endpoint.creditCard.getOwnCardPaymentMode, body);

const getLocale = state => state.LanguageReducer.locale;

const {
  ACCESS,
  PAYMENT_MODES,
  MAYBANK_ACCESS,
  MAYBANK_RESULT,
  MAYBANK_RECEIPT,
  OWN_MAYBANK_CARDS,
  OTHER_BANK_RESULT,
  OTHER_BANK_RECEIPT,
  REQUEST_MAYBANK_TAC,
  MAYBANK_CONFIRMATION,
  REQUEST_OTHER_BANK_TAC,
  OTHER_BANK_CONFIRMATION,
} = actions.GET.CREDIT_CARD;

const {
  MAYBANK_CONFIRM: FAV_MAYBANK_CONFIRM,
  OTHER_CONFIRM: FAV_OTHER_CONFIRM,
  MAYBANK_TAC: FAV_MAYBANK_TAC,
  OTHER_TAC: FAV_OTHER_TAC,
  MAYBANK_RECEIPT: FAV_MAYBANK_RECEIPT,
  OTHER_RECEIPT: FAV_OTHER_RECEIPT,
} = favouriteActions.GET.CREDIT_CARD.PAYMENT_FROM_FAVOURITE;

function* getCardAccessSaga({ type, payload }) {
  const getAccess = ccCallAccessHandler(type);

  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getAccess, { body });

    const [access, billers] = formatPaymentAccess(data, true, type === MAYBANK_ACCESS);
    const accessDispatchers = ccPutAccessHandler({ type, access, billers });

    yield all(accessDispatchers);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getOwnMaybankCardSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getOwnMaybankCards, { body });
    const ownMaybankCards = formatOwnMaybankCards(get(data, 'ownCreditCardList', []));
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);

    yield put(putCreditCardOwnCards({ ownMaybankCards, responseCode }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getPaymentModeSaga({ payload }) {
  const locale = yield select(getLocale);
  const body = { locale, ...payload };
  const { data } = yield call(getOwnCardPaymentMode, { body });

  yield put(putCreditCardPaymentModes(formatCcPaymentModes(data)));
}

function* getConfirmationSaga({ type, payload = {} }) {
  const getConfirmation = ccCallConfirmationHandler(type);
  try {
    // clear all cc confirmation saga first
    yield all([put(putOtherBankCardConfirmation({})), put(putMaybankCardConfirmation({}))]);

    const locale = yield select(getLocale);

    const body = { locale, ...payload };

    const { data } = yield call(getConfirmation, { body });

    const confirmationDispatchers = ccPutConfirmationHandler(type, formatTransferConfirmation(data));

    yield put(confirmationDispatchers);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTacSaga({ type, payload = {} }) {
  try {
    const requestTac = ccCallTacHandler(type);
    // clear all cc TAC saga first
    yield all([put(putMaybankCardTac({})), put(putOtherBankCardTac({}))]);

    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);

    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(requestTac, { body });
    const tacDispatcher = ccPutTacHandler(type, formatTransferConfirmation(data));

    yield put(tacDispatcher);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ type, payload = {} }) {
  const getResult = ccCallResultHandler(type);
  try {
    // clear all cc result
    yield all([put(putMaybankCardResult({})), put(putOtherBankCardResult({}))]);

    const locale = yield select(getLocale);
    const { ip: remoteIPAddress } = yield call(getClientIP);
    const body = { locale, remoteIPAddress, userAgent, ...payload };

    const { data } = yield call(getResult, { body });
    const resultDispatcher = ccPutResultHandler(type, formatTransferConfirmation(data));

    yield put(resultDispatcher);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ type, payload = {} }) {
  const getResult = ccCallReceiptHandler(type);
  try {
    // clear all receipt
    yield all([put(putMaybankCardReceipt({})), put(putOtherBankCardReceipt({}))]);

    const locale = yield select(getLocale);
    const body = { locale, ...payload };

    const { data } = yield call(getResult, { body });
    const responseCode = get(data, 'responseCode', '');
    const receiptDispatcher = ccPutReceiptHandler(type, data);

    yield put(receiptDispatcher);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PaymentCreditCard() {
  yield all([
    takeLatest(ACCESS, getCardAccessSaga),
    takeLatest(MAYBANK_ACCESS, getCardAccessSaga),
    takeLatest(OWN_MAYBANK_CARDS, getOwnMaybankCardSaga),
    takeLatest(PAYMENT_MODES, getPaymentModeSaga),
    takeLatest(MAYBANK_CONFIRMATION, getConfirmationSaga),
    takeLatest(OTHER_BANK_CONFIRMATION, getConfirmationSaga),
    takeLatest(FAV_MAYBANK_CONFIRM, getConfirmationSaga),
    takeLatest(FAV_OTHER_CONFIRM, getConfirmationSaga),
    takeLatest(REQUEST_MAYBANK_TAC, requestTacSaga),
    takeLatest(REQUEST_OTHER_BANK_TAC, requestTacSaga),
    takeLatest(FAV_MAYBANK_TAC, requestTacSaga),
    takeLatest(FAV_OTHER_TAC, requestTacSaga),
    takeLatest(MAYBANK_RESULT, getResultSaga),
    takeLatest(OTHER_BANK_RESULT, getResultSaga),
    takeLatest(MAYBANK_RECEIPT, getReceiptSaga),
    takeLatest(OTHER_BANK_RECEIPT, getReceiptSaga),
    takeLatest(FAV_MAYBANK_RECEIPT, getReceiptSaga),
    takeLatest(FAV_OTHER_RECEIPT, getReceiptSaga),
  ]);
}
