export const resetCredential = {
  modal: {
    title: 'Reset Credential',
    header: {
      title: 'Kami Akan Melakukan Reset Credential',
      description: 'Pastikan Anda berada di Kantor Cabang Maybank terdekat untuk melakukan Reset Credential.',
    },
    container: {
      user: 'User Id',
      code: 'Masukkan Kode',
      accountNumber: 'Nomor Rekening',
      otpNumber: 'Nomor OTP',
      messageOtp: 'OTP Anda akan berlaku selama 10 menit',
    },
    placeholder: {
      numberPlaceholder: 'Masukkan Nomor Rekening',
      otpPlaceholder: 'Masukkan Nomor OTP disini',
    },
    passwordForm: {
      newPassword: 'Kata Sandi Baru',
      confirmNewPassword: 'Konfirmasi Kata Sandi Baru',
      placeholder: {
        newPassword: 'Masukkan Kata Sandi Baru',
        confirmNewPassword: 'Konfirmasi Kata Sandi Baru',
      },
    },
  },
  errorMessage: {
    invalidUserId: 'User ID Tidak Valid',
    invalidCode: 'Kode Captcha Tidak valid',
    invalidUserAndCode: 'User ID dan Kode Captcha Tidak Valid',
    invalidAccountNumber: 'Nomor Rekening Tidak Valid',
    invalidOtpNumber: 'Nomor OTP Tidak Valid',
    invalidAccountOtpNumber: 'Nomor Rekening atau Nomor OTP Tidak Valid',
    invalidTac: 'TAC Salah',
    invalidOldPassword: 'Anda tidak dapat menggunakan 5 kata sandi sebelumnya yang sama. Silakan coba kata sandi lain',
    maxTac: 'Batas kesalahan TAC maksimum telah tercapai.',
  },
  tac: {
    title: 'Silakan mengkonfirmasi kata sandi baru Anda ',
    description: 'Untuk melanjutkan, silakan ajukan permintaan <b>TAC</b> Anda. ',
    descriptionFinal: 'Reset Credential Anda berhasil dilakukan, Anda dapat melanjutkan Login menggunakan password yang telah Anda buat.',
  },
};
