import PropTypes from 'prop-types';

import { Drawer, Grid } from '@material-ui/core';

import { SIDEBAR_VARIANT } from 'settings/constants/ui-control';
import { MAYBANK_CREDIT_CARD_WEBSITE } from 'settings/constants/common';

import TransactionSection from './content/TransactionSection';

import './style.scss';

const { PERSISTENT } = SIDEBAR_VARIANT;

const TransactionDrawer = ({ className, description, variant, onClose, onClick, isOpen, buttonTitle }) => {
  return (
    <>
      <Drawer anchor="bottom" open={isOpen} variant={variant} onClose={onClose} className={`drawer drawer-tac ${className}`}>
        <Grid container justify="center">
          <Grid container sm={12} md={10} item>
            <TransactionSection description={description} buttonTitle={buttonTitle} onClick={onClick} />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

TransactionDrawer.defaultProps = {
  className: '',
  description: '',
  buttonTitle: '',
  isOpen: false,
  onClose: null,
  onClick: null,
  variant: PERSISTENT,
};

TransactionDrawer.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.node,
  buttonTitle: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default TransactionDrawer;
