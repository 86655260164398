import { actions } from 'middleware/actions/settings';

const { THEME, SECURITY_IMAGE, PERSONAL_INFO, PASSWORD, MANAGE_TRANSACTION, MANAGE_DEVICE } = actions.PUT;

const initialState = {
  themeAccess: {},
  themeResult: {},
  passwordTac: {},
  passwordAccess: {},
  passwordResult: {},
  personalInfoAccess: {},
  personalInfoResult: {},
  securityImages: {},
  securityImageTac: {},
  securityImageResult: {},
  securityImageAccess: {},
  siMaintenanceList: {},
  senderAccountResult: {},
  siDeleteConfirmation: {},
  siDeleteTac: {},
  siDeleteResult: {},
  accessRemoveDevice: {},
  confirmRemoveDevice: {},
  removeDeviceTac: {},
  removeDeviceResult: {},
};

const SettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PASSWORD.ACCESS:
      return { ...state, passwordAccess: action.res };
    case PASSWORD.TAC:
      return { ...state, passwordTac: action.res };
    case PASSWORD.RESULT:
      return { ...state, passwordResult: action.res };
    case THEME.ACCESS:
      return { ...state, themeAccess: action.res };
    case THEME.RESULT:
      return { ...state, themeResult: action.res };
    case SECURITY_IMAGE.ACCESS:
      return { ...state, securityImageAccess: action.res };
    case SECURITY_IMAGE.RESULT:
      return { ...state, securityImageResult: action.res };
    case SECURITY_IMAGE.TAC:
      return { ...state, securityImageTac: action.res };
    case SECURITY_IMAGE.IMAGES:
      return { ...state, securityImages: action.res };
    case PERSONAL_INFO.ACCESS:
      return { ...state, personalInfoAccess: action.res };
    case PERSONAL_INFO.RESULT:
      return { ...state, personalInfoResult: action.res };
    case MANAGE_TRANSACTION.SI_MAINTENANCE_LIST:
      return { ...state, siMaintenanceList: action.res };
    case MANAGE_TRANSACTION.SI_SENDER_ACCOUNT:
      return { ...state, senderAccountResult: action.res };
    case MANAGE_TRANSACTION.SI_DELETE_CONFIRMATION:
      return { ...state, siDeleteConfirmation: action.payload };
    case MANAGE_TRANSACTION.SI_REQUEST_TAC:
      return { ...state, siDeleteTac: action.payload };
    case MANAGE_TRANSACTION.SI_DELETE_RESULT:
      return { ...state, siDeleteResult: action.payload };
    case MANAGE_TRANSACTION.SI_RESET_ALL:
      return { ...state, siDeleteConfirmation: {}, siDeleteTac: {}, siDeleteResult: {} };
    case MANAGE_DEVICE.ACCESS_REMOVE_DEVICE:
      return { ...state, accessRemoveDevice: action.res };
    case MANAGE_DEVICE.CONFIRM_ACCESS_REMOVE_DEVICE:
      return { ...state, confirmRemoveDevice: action.res };
    case MANAGE_DEVICE.REMOVE_DEVICE_TAC:
      return { ...state, removeDeviceTac: action.res };
    case MANAGE_DEVICE.REMOVE_DEVICE_RESULT:
      return { ...state, removeDeviceResult: action.res };
    default:
      return state;
  }
};

export default SettingsReducer;
