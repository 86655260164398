import { actions } from 'middleware/actions/favourite/payment';

const {
  RESET_FAVOURITE,
  RESET_TAC,
  MAINTENANCE_LIST,
  FAVOURITE_ACCOUNT,
  MOBILE_POSTPAID,
  CREDIT_CARD,
  UTILITY,
  OTHER_BILL,
  RESET_ADD_FAVOURITE,
} = actions.PUT;

const initialState = {
  maintenancePayment: {},
  favouriteAccount: {},
  creditCardDetail: {},
  creditCardConfirm: {},
  creditCardTac: {},
  creditCardResult: {},
  mobilePostpaidDetail: {},
  mobilePostpaidConfirm: {},
  mobilePostpaidTac: {},
  mobilePostpaidResult: {},
  utilityDetail: {},
  utilityConfirm: {},
  utilityTac: {},
  utilityResult: {},
  otherBillDetail: {},
  otherBillPayeeDetail: {},
  otherBillConfirm: {},
  otherBillTac: {},
  otherBillResult: {},
  creditCardFavDetail: {},
  mobilePostpaidFavDetail: {},
  utilityFavDetail: {},
  otherBillFavDetail: {},
};

const addNewInitialState = {
  creditCardDetail: {},
  creditCardConfirm: {},
  mobilePostpaidDetail: {},
  mobilePostpaidConfirm: {},
  utilityDetail: {},
  utilityConfirm: {},
  otherBillDetail: {},
  otherBillPayeeDetail: {},
  otherBillConfirm: {},
};

const initialTac = {
  creditCardTac: {},
  mobilePostpaidTac: {},
  utilityTac: {},
  otherBillTac: {},
};

const FavouritePaymentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_FAVOURITE:
      return { ...state, ...initialState };
    case RESET_TAC:
      return { ...state, ...initialTac };
    case RESET_ADD_FAVOURITE:
      return { ...state, ...addNewInitialState };
    case FAVOURITE_ACCOUNT:
      return { ...state, favouriteAccount: action.res };
    case MAINTENANCE_LIST:
      return { ...state, maintenancePayment: action.res };
    case CREDIT_CARD.FAVOURITE_ACTION.DETAIL:
      return { ...state, creditCardDetail: action.res };
    case CREDIT_CARD.FAVOURITE_ACTION.CONFIRM:
      return { ...state, creditCardConfirm: action.res };
    case CREDIT_CARD.FAVOURITE_ACTION.TAC:
      return { ...state, creditCardTac: action.res };
    case CREDIT_CARD.FAVOURITE_ACTION.RESULT:
      return { ...state, creditCardResult: action.res };
    case CREDIT_CARD.PAYMENT_FROM_FAVOURITE.DETAIL:
      return { ...state, creditCardFavDetail: action.res };
    case MOBILE_POSTPAID.FAVOURITE_ACTION.DETAIL:
      return { ...state, mobilePostpaidDetail: action.res };
    case MOBILE_POSTPAID.FAVOURITE_ACTION.CONFIRM:
      return { ...state, mobilePostpaidConfirm: action.res };
    case MOBILE_POSTPAID.FAVOURITE_ACTION.TAC:
      return { ...state, mobilePostpaidTac: action.res };
    case MOBILE_POSTPAID.FAVOURITE_ACTION.RESULT:
      return { ...state, mobilePostpaidResult: action.res };
    case UTILITY.FAVOURITE_ACTION.DETAIL:
      return { ...state, utilityDetail: action.res };
    case UTILITY.FAVOURITE_ACTION.CONFIRM:
      return { ...state, utilityConfirm: action.res };
    case UTILITY.FAVOURITE_ACTION.TAC:
      return { ...state, utilityTac: action.res };
    case UTILITY.FAVOURITE_ACTION.RESULT:
      return { ...state, utilityResult: action.res };
    case OTHER_BILL.FAVOURITE_ACTION.DETAIL:
      return { ...state, otherBillDetail: action.res };
    case OTHER_BILL.FAVOURITE_ACTION.PAYEE_DETAIL:
      return { ...state, otherBillPayeeDetail: action.res };
    case OTHER_BILL.FAVOURITE_ACTION.CONFIRM:
      return { ...state, otherBillConfirm: action.res };
    case OTHER_BILL.FAVOURITE_ACTION.TAC:
      return { ...state, otherBillTac: action.res };
    case OTHER_BILL.FAVOURITE_ACTION.RESULT:
      return { ...state, otherBillResult: action.res };
    default:
      return state;
  }
};

export default FavouritePaymentReducer;
