import PropTypes from 'prop-types';
import { useRef, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MODAL_FULLSCREEN_SIZE } from 'settings/constants/ui-control';

import { Textfield, Tooltip } from 'components/common';
import { BasicButton } from 'components/common/buttons';

import { searchFieldPlacholder } from 'utils/favourite/handler';
import { useWindowSize } from 'utils/transaction';

const DynoSearchFavourite = ({ searchValue, transactionType, onKeywordChange, onAddFavourite, disableAddFavourite }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  let overflowTextRef = useRef();

  const searchPlaceholder = searchFieldPlacholder(transactionType);
  const fullScreen = useMediaQuery(theme.breakpoints.down(MODAL_FULLSCREEN_SIZE));
  const messagePlaceholder = formatMessage({ id: searchPlaceholder });

  const [size, setSize] = useState([0, 0]);
  const [hasTooltip, setTooltip] = useState(false);

  const isOverflowText = event => {
    const input = event;
    input.value = input.placeholder;

    const overflowText = input.scrollWidth > input.clientWidth;

    // set back to original value, so that it is not the same as placeholder value.
    input.value = '';

    return overflowText;
  };

  const widthWindows = useWindowSize(size, setSize);

  useLayoutEffect(() => {
    if (isOverflowText(overflowTextRef) && widthWindows[0] && window.innerWidth) {
      setTooltip(true);
      return;
    }
    setTooltip(false);
  }, [window.innerWidth, widthWindows[0]]);

  return (
    <Grid container spacing={fullScreen ? 1 : 3}>
      <Grid item xs={12} sm={9}>
        {hasTooltip ? (
          <Tooltip title={messagePlaceholder} placement="bottom-end">
            <span>
              <Textfield
                hasLeftIcon
                leftIcon="search"
                value={searchValue}
                className="textfield--search"
                onChange={onKeywordChange}
                placeholder={messagePlaceholder}
                inputRef={input => {
                  overflowTextRef = input;
                }}
              />
            </span>
          </Tooltip>
        ) : (
          <Textfield
            hasLeftIcon
            leftIcon="search"
            value={searchValue}
            className="textfield--search"
            onChange={onKeywordChange}
            placeholder={messagePlaceholder}
            inputRef={input => {
              overflowTextRef = input;
            }}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={3}>
        <BasicButton
          onClick={onAddFavourite}
          disabled={disableAddFavourite}
          className={`transparent-b--grey db w-100 ${disableAddFavourite ? 'disabled' : ''}`}
          title={formatMessage({ id: 'buttonText.addFavourite' })}
        />
      </Grid>
    </Grid>
  );
};

DynoSearchFavourite.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onAddFavourite: PropTypes.func.isRequired,
  onKeywordChange: PropTypes.func.isRequired,
  transactionType: PropTypes.string.isRequired,
  disableAddFavourite: PropTypes.bool.isRequired,
};

export default DynoSearchFavourite;
