import { createMuiTheme } from '@material-ui/core/styles';

// Override material-ui theme globally
export const materialTheme = createMuiTheme({
  // decide if we're going for this breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
  typography: {
    fontFamily: 'Lato, Calibri, Helvetica, Arial, sans-serif',
  },
});
