import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Radio from 'components/common/Radio';

import { FAVOURITE_MAXIMUM_LIST } from 'settings/constants/transaction';

import DynoSearchFavourite from './SearchFavourite';

const DynoFavouriteSection = ({
  label,
  onChange,
  isFormOpen,
  onKeywordChange,
  searchValue,
  hasSearchSection,
  onAddFavourite,
  radioOptions,
  transactionType,
  isNumberValue,
}) => {
  const isOverflowRef = useRef();
  const [isOverflow, setOverflow] = useState(false);
  // disable add favourite button if favourite list reached max count
  const disableAddFavourite = radioOptions.length >= FAVOURITE_MAXIMUM_LIST;

  useEffect(() => {
    if (isOverflowRef.current.clientHeight < isOverflowRef.current.scrollHeight) {
      setOverflow(true);
      return;
    }
    setOverflow(false);
  }, [radioOptions]);

  return (
    <Grid item xs={12} md={10} xl={8} className="account-selector--container pt4 ph3">
      <Grid container spacing={0} className="pb2">
        <Grid item xs={12} md={2}>
          <Typography className="pt2-l pb3">{label}</Typography>
        </Grid>
        <Grid item xs={12} md={10} className="vh-25">
          {hasSearchSection && (
            <DynoSearchFavourite
              searchValue={searchValue}
              onKeywordChange={onKeywordChange}
              onAddFavourite={onAddFavourite}
              disableAddFavourite={disableAddFavourite}
              transactionType={transactionType}
            />
          )}
          <div
            ref={isOverflowRef}
            className={
              isOverflow
                ? 'favourite-list overflow-y-auto overflow-x-hidden overflow-favourite-list'
                : 'favourite-list overflow-y-auto overflow-x-hidden'
            }
          >
            {radioOptions.length ? (
              <Radio
                isNumberValue={isNumberValue}
                variant="tick"
                onChange={onChange}
                options={radioOptions}
                // clear selection value when modal close
                clearValue={!isFormOpen}
                className="flex flex-column justify-between radio-light outlined"
              />
            ) : (
              <div className="tc f5 pt4">{searchValue && <FormattedMessage id="transaction.favourite.placeholder.searchEmpty" />}</div>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

DynoFavouriteSection.defaultProps = {
  hasSearchSection: false,
  radioOptions: [],
  searchValue: '',
  onKeywordChange: null,
  onChange: null,
  onAddFavourite: null,
  isNumberValue: true,
  isFormOpen: false,
  transactionType: '',
};

DynoFavouriteSection.propTypes = {
  onChange: PropTypes.func,
  isNumberValue: PropTypes.bool,
  searchValue: PropTypes.string,
  onKeywordChange: PropTypes.func,
  hasSearchSection: PropTypes.bool,
  label: PropTypes.node.isRequired,
  onAddFavourite: PropTypes.func,
  radioOptions: PropTypes.array,
  isFormOpen: PropTypes.bool,
  transactionType: PropTypes.string,
};

export default DynoFavouriteSection;
