// specifically for ActionButton / links labels
export const buttonText = {
  save: 'Save',
  addAccount: 'Add account',
  addTD: 'Add Time Deposit',
  apply: 'Apply',
  applyNow: 'Apply Now',
  confirm: 'confirm',
  continue: 'Continue',
  login: 'Login',
  loginFc: 'Login',
  yes: 'Yes',
  no: 'No',
  notMe: 'Not me',
  forgotLogin: 'Forgot login',
  donateNow: 'Donate now',
  makePlacement: 'Make placement',
  pay: 'Pay',
  registerNow: 'Register now',
  transfer: 'Transfer',
  skip: 'Skip',
  viewStatement: 'View statement',
  requestTac: 'Request TAC',
  downloadReceipt: 'Download Receipt',
  done: 'Done',
  ok: 'OK',
  cancel: 'Cancel',
  addFavourite: 'Add Favorite',
  newTransfer: 'New Transfer',
  search: 'Search',
  back: 'Back',
  next: 'Next',
  nextFc: 'Next',
  edit: 'Edit',
  delete: 'Delete',
  update: 'Update',
  seeTutorial: 'See Tutorial',
  newPayment: 'New Payment',
  newPurchase: 'New Purchase',
  viewSimulation: 'View Simulation',
  createGoal: 'Create Goal in M2U',
  showMore: 'Show more',
  showLess: 'Show Less',
  simulateGoal: 'Simulate Goal',
  simulateFinancialGoal: 'Simulate your financial goal',
  clickHere: 'Click here',
  letsGo: "Let's Go",
  close: 'Close',
  addCurrency: 'Add Currency',
  remove: 'Remove',
  compose: 'Send',
  reply: 'Reply',
  addDeposit: 'Add Deposit',
  register: 'Register',
};
