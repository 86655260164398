export const PRODUCTIONS = formatMessage => [
  {
    key: '2701',
    label: formatMessage({ id: 'lldDescription.production.2701' }),
  },
  {
    key: '2702',
    label: formatMessage({ id: 'lldDescription.production.2702' }),
  },
  {
    key: '2705',
    label: formatMessage({ id: 'lldDescription.production.2705' }),
  },
  {
    key: '2710',
    label: formatMessage({ id: 'lldDescription.production.2710' }),
  },
  {
    key: '2716',
    label: formatMessage({ id: 'lldDescription.production.2716' }),
  },
  {
    key: '2717',
    label: formatMessage({ id: 'lldDescription.production.2717' }),
  },
  {
    key: '2720',
    label: formatMessage({ id: 'lldDescription.production.2720' }),
  },
  {
    key: '2725',
    label: formatMessage({ id: 'lldDescription.production.2725' }),
  },
  {
    key: '2730',
    label: formatMessage({ id: 'lldDescription.production.2730' }),
  },
  {
    key: '2731',
    label: formatMessage({ id: 'lldDescription.production.2731' }),
  },
  {
    key: '2150',
    label: formatMessage({ id: 'lldDescription.production.2150' }),
  },
  {
    key: '2163',
    label: formatMessage({ id: 'lldDescription.production.2163' }),
  },
  {
    key: '2741',
    label: formatMessage({ id: 'lldDescription.production.2741' }),
  },
  {
    key: '2742',
    label: formatMessage({ id: 'lldDescription.production.2742' }),
  },
  {
    key: '2743',
    label: formatMessage({ id: 'lldDescription.production.2743' }),
  },
  {
    key: '2751',
    label: formatMessage({ id: 'lldDescription.production.2751' }),
  },
  {
    key: '2752',
    label: formatMessage({ id: 'lldDescription.production.2752' }),
  },
  {
    key: '2760',
    label: formatMessage({ id: 'lldDescription.production.2760' }),
  },
  {
    key: '2765',
    label: formatMessage({ id: 'lldDescription.production.2765' }),
  },
  {
    key: '2766',
    label: formatMessage({ id: 'lldDescription.production.2766' }),
  },
  {
    key: '2767',
    label: formatMessage({ id: 'lldDescription.production.2767' }),
  },
  {
    key: '2770',
    label: formatMessage({ id: 'lldDescription.production.2770' }),
  },
];
