import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatSourceAccounts } from 'utils/transaction';
import { formatTransferConfirmation as formatTransactionResponse } from 'utils/transfer/formatter';

import { putErrorResponse } from 'middleware/actions/error';
import { actions, putSenderAccountResult, putPurchaseAccess } from 'middleware/actions/purchase';

import { purchaseEndpoint as endpoint } from 'providers/endpoints/purchase';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const { SENDER_ACCOUNT, ACCESS } = actions.GET;

const getClientIP = () => remoteIpInstance.get();

const getAccess = ({ body }) => Instance.post(endpoint.get.biller, body);
const getSenderAccount = ({ body }) => Instance.post(endpoint.get.senderAccount, body);

const getLocale = state => state.LanguageReducer.locale;

function* getSenderAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, userId: get(payload, 'userId', '') };
    const { data } = yield call(getSenderAccount, { body });
    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    yield put(
      putSenderAccountResult({
        responseCode,
        purchaseTypes: get(data, 'transferToMap', []),
        senderAccounts: formatSourceAccounts(get(data, 'fromAccountList', [])),
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getAccess, { body });

    yield put(putPurchaseAccess(formatTransactionResponse(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Purchase() {
  yield all([takeLatest(SENDER_ACCOUNT, getSenderAccountSaga), takeLatest(ACCESS, getAccessSaga)]);
}
