import { actions } from '../actions/registration';

const { REGISTER_RESET } = actions;
const { PIN_RESULT, TAC_RESULT, CREDENTIAL_RESULT, REGISTER_RESULT } = actions.PUT;

const initialState = { pinResult: {}, credentialResult: {}, tacResult: {}, registerResult: {} };

const formatResult = (resultName, action) => ({
  [resultName]: action.payload.res,
  isSuccess: action.payload.isSuccess,
  responseCode: action.payload.responseCode,
  responseMessage: action.payload.responseMessage,
});

const RegistrationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PIN_RESULT:
      return { ...state, pinResult: formatResult('userDetail', action) };

    case CREDENTIAL_RESULT:
      return { ...state, credentialResult: formatResult('tacDetail', action) };

    case TAC_RESULT:
      return { ...state, tacResult: formatResult('responseDetail', action) };

    case REGISTER_RESULT:
      return { ...state, registerResult: formatResult('confirmDetail', action) };

    case REGISTER_RESET:
      return initialState;

    default:
      return state;
  }
};

export default RegistrationReducer;
