import PropTypes from 'prop-types';

import { Drawer as MaterialDrawer } from '@material-ui/core';

import IconButton from 'components/common/buttons/IconButton';
import Icon from 'components/common/Icon';

import './style.scss';

// Drawer does not manage its open state. Other component that manages this drawer needs to handle it
const Drawer = ({ anchor, children, isOpen, onClose, className, hasCloseButton, hideBackdrop, variant, hideBackDropWoModal }) => (
  <MaterialDrawer
    anchor={anchor}
    open={isOpen}
    onClose={onClose}
    variant={variant}
    className={`drawer ${className}`}
    ModalProps={{ hideBackdrop }}
    BackdropProps={{ invisible: hideBackDropWoModal }}
  >
    {hasCloseButton && (
      <IconButton onClick={onClose} className="drawer-btn-close">
        <Icon isAssetIcon={false} type="close" />
      </IconButton>
    )}
    {children}
  </MaterialDrawer>
);

Drawer.defaultProps = {
  className: '',
  anchor: 'bottom',
  variant: 'temporary',
  onClose: null,
  hasCloseButton: false,
  hideBackdrop: true,
  hideBackDropWoModal: false,
};

Drawer.propTypes = {
  anchor: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  hideBackDropWoModal: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
};

export default Drawer;
