import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import {
  actions,
  putUtilityTac,
  putUtilityAccess,
  putUtilityResult,
  putUtilityBillers,
  putUtilityReceipt,
  putUtilityConfirmation,
  putUtilityBillerDetail,
} from 'middleware/actions/payment';

import { get } from 'utils/lodash';
import { formatKeys } from 'utils/formatter';
import { userAgent } from 'providers/fingerprint';
import { putErrorResponse } from 'middleware/actions/error';
import { formatPaymentAccess } from 'utils/payment/formatter';
import { formatTransferConfirmation } from 'utils/transfer/formatter';
import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';

import { RECEIPT_KEYMAP } from 'settings/constants/keymap';
import { SUCCESS_RESPONSE_CODE } from 'settings/constants/response-codes';

const { CONFIRMATION, RESULT, REQUEST_TAC, RECEIPT, ACCESS, BILLER_DETAIL } = actions.GET.UTILITY;

const getClientIP = () => remoteIpInstance.get();

const getResult = ({ body }) => Instance.post(endpoint.utility.getResult, body);
const getAccess = ({ body }) => Instance.post(endpoint.utility.getAccess, body);
const getReceipt = ({ body }) => Instance.post(endpoint.utility.getReceipt, body);
const requestTAC = ({ body }) => Instance.post(endpoint.utility.requestTac, body);
const getBillerDetail = ({ body }) => Instance.post(endpoint.utility.getBillerDetail, body);
const getConfirmation = ({ body }) => Instance.post(endpoint.utility.getConfirmation, body);

const getLocale = state => state.LanguageReducer.locale;

function* getAccessSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, userId: get(payload, 'userId', '') };
    const { data } = yield call(getAccess, { body });
    const [access, billers] = formatPaymentAccess(data);
    yield all([put(putUtilityAccess(access)), put(putUtilityBillers(billers))]);
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getBillerDetailSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const body = { locale, ...payload };
    const { data } = yield call(getBillerDetail, { body });
    const responseCode = get(data, 'responseCode', '');

    yield put(putUtilityBillerDetail({ isSuccess: responseCode === SUCCESS_RESPONSE_CODE, ...data }));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getConfirmationSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getConfirmation, { body });
    yield put(putUtilityConfirmation(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* requestTACSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(requestTAC, { body });
    yield put(putUtilityTac(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getResultSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const { ip } = yield call(getClientIP);
    const body = { locale, userAgent, remoteIPAddress: ip, ...payload };
    const { data } = yield call(getResult, { body });
    yield put(putUtilityResult(formatTransferConfirmation(data)));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

function* getReceiptSaga({ payload = {} }) {
  try {
    const locale = yield select(getLocale);
    const initialBody = { locale, ...payload };
    const body = formatKeys(initialBody, RECEIPT_KEYMAP);
    const { data } = yield call(getReceipt, { body });
    yield put(putUtilityReceipt(data));
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* PaymentUtility() {
  yield all([
    takeLatest(RESULT, getResultSaga),
    takeLatest(ACCESS, getAccessSaga),
    takeLatest(RECEIPT, getReceiptSaga),
    takeLatest(REQUEST_TAC, requestTACSaga),
    takeLatest(CONFIRMATION, getConfirmationSaga),
    takeLatest(BILLER_DETAIL, getBillerDetailSaga),
  ]);
}
