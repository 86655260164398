import { Provider } from 'react-redux';

import moment from 'moment-timezone';

import { ThemeProvider } from '@material-ui/core';

import { MainRoute } from 'router';
import { materialTheme } from 'theme/global';
import { store } from 'providers/store';

import TranslationProvider from 'providers/translation';

import HttpLoader from 'components/common/HttpLoader';
import ErrorBoundary from 'components/common/ErrorBoundary';

import { DEFAULT_TIMEZONE } from 'settings/constants/common';

import 'styles/tachyons.css';
import 'styles/fonts.css';
import 'styles/common.scss';

const App = () => {
  moment.tz.setDefault(DEFAULT_TIMEZONE);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={materialTheme}>
        <Provider store={store}>
          <TranslationProvider>
            <HttpLoader />
            <MainRoute />
          </TranslationProvider>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
