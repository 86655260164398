import { MOBILE_NUMBER_PREFIX } from 'settings/constants/common';
import {
  FORMAT_NUMBER,
  FIELD_TYPE_TEXT,
  FIELD_TYPE_SELECT,
  MOBILE_NUMBER_MAX_LENGTH,
  FORMAT_FAVOURITE_NICKNAME,
  FAVOURITE_NICKNAME_MAX_LENGTH,
} from 'settings/constants/transaction';

export const MOBILE_POSTPAID_FIELDS = [
  {
    name: 'mobileNumber',
    label: 'transaction.payment.modal.mobileNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    maxLength: MOBILE_NUMBER_MAX_LENGTH,
    leftLabel: MOBILE_NUMBER_PREFIX,
    isLeftLabelNoSpace: true,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'validMobileNumber', isValidateRequired: true },
    ],
  },
];

export const MOBILE_POSTPAID_NEW_FAVOURITE = [
  {
    name: 'accountNickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    name: 'billerType',
    label: 'transaction.payment.modal.biller',
    placeholder: 'transaction.payment.placeHolder.pleaseSelect',
    type: FIELD_TYPE_SELECT,
    options: [],
    validationRules: [{ type: 'required', isValidateRequired: true }],
  },
  {
    name: 'mobileNumber',
    label: 'transaction.payment.modal.mobileNumber',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_NUMBER,
    maxLength: MOBILE_NUMBER_MAX_LENGTH,
    leftLabel: MOBILE_NUMBER_PREFIX,
    isLeftLabelNoSpace: true,
    validationRules: [
      { type: 'required', isValidateRequired: true },
      { type: 'validMobileNumber', isValidateRequired: true },
    ],
  },
];

export const MOBILE_POSTPAID_EDIT_FAVOURITE = [
  {
    name: 'nickname',
    label: 'transaction.favourite.form.beneficiaryNickname',
    type: FIELD_TYPE_TEXT,
    format: FORMAT_FAVOURITE_NICKNAME,
    maxLength: FAVOURITE_NICKNAME_MAX_LENGTH,
    validationRules: [{ type: 'required' }, { type: 'validNickname' }],
  },
  {
    readOnly: true,
    name: 'payeeName',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.biller',
  },
  {
    readOnly: true,
    name: 'mobileNumber',
    type: FIELD_TYPE_TEXT,
    label: 'transaction.payment.modal.mobileNumber',
  },
];
