import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Instance from 'providers/instance';
import remoteIpInstance from 'providers/remote-ip';

import { get } from 'utils/lodash';
import { formatSourceAccounts } from 'utils/transaction';
import { putErrorResponse } from 'middleware/actions/error';
import { actions, putSenderAccountResult } from 'middleware/actions/payment';

import { paymentEndpoint as endpoint } from 'providers/endpoints/payment';
import { SUCCESS_RESPONSE_CODE, UNRESPONSIVE_HOST } from 'settings/constants/response-codes';

const { SENDER_ACCOUNT } = actions.GET;

const getClientIP = () => remoteIpInstance.get();

const getSenderAccount = ({ body }) => Instance.post(endpoint.get.senderAccount, body);

const getLocale = state => state.LanguageReducer.locale;

function* getSenderAccountSaga({ payload }) {
  try {
    const locale = yield select(getLocale);
    const userId = get(payload, 'userId', '');

    const body = { userId, locale };

    const { data } = yield call(getSenderAccount, { body });

    const responseCode = get(data, 'responseCode', UNRESPONSIVE_HOST);
    const senderAccounts = formatSourceAccounts(get(data, 'fromAccountList', []));
    const paymentTypes = get(data, 'transferToMap', []);

    yield put(
      putSenderAccountResult({
        senderAccounts,
        paymentTypes,
        responseCode,
        isSuccess: responseCode === SUCCESS_RESPONSE_CODE,
      })
    );
  } catch (error) {
    yield put(putErrorResponse(error));
  }
}

export default function* Payment() {
  yield all([takeLatest(SENDER_ACCOUNT, getSenderAccountSaga)]);
}
